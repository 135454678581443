.careers.page-wrapper {
    // --mapHover:drop-shadow(4px 4px 6px #FF91FF);
    --mapHover: hue-rotate(74deg) brightness(100%) contrast(100%);
    --mapTextStroke: #f5f5f5;
    --mapTextHover: #B41863;
    
    main {
        padding-top:0;
    }


    .search-header {
        display:flex;
        align-items: center;
        gap:8px;
        margin-bottom:var(--vertical-space);
        .link-btn {
            flex:none; min-width:40px;text-align:center; 
            svg {
                margin:auto;
                fill: var(--text-default);
            }
            .mobile-only {
                display: block;
                width:40px;
                height:40px;
                padding:8px;
                
                border-radius:50%;
            }
            &:hover, &:focus-visible {
                .mobile-only {
                    background: var(--map-glow-bg);
                }
            }
        }
        .career-search-list {
            flex:1;
        }
        @media (min-width: 808px) {
            gap: 24px;

        }
    }
    @media (prefers-color-scheme: dark) {
        // --mapHover:drop-shadow(4px 4px 6px #3FE8FF);
        --mapHover: hue-rotate(28deg) brightness(160%) contrast(110%);
        background-color: #020917;
        --mapTextStroke: #181031;
        --mapTextHover: #F69D8A;
    }
}


.careers.page-wrapper .career-map {
    max-width:100%;
    min-height:100%;
    position:relative;
    
    overflow-x:hidden;
    padding-bottom: 64px;
    + .footer-container {
        margin-top:-64px;
        // padding-top:0;
        z-index: 3;
    }
    &:before {
        content:'';
        position:fixed; //workaround since safari doesnt like background-attachment:fixed 
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index: -1;
        background-image: url('../images/map/map_light_mobile.png');
        background-size:cover;
        background-position: top center;
        // background-attachment: fixed;
        background-repeat: no-repeat;
    }
   
    &:after {
        content:'';
        position:absolute;
        width:100vw;
        left:50%;
        right:0;
        top:0;
        height:600px;
        transform:translateX(-50%);
        background:linear-gradient(165deg, #E8EEFA 27.59%, rgba(232, 232, 232, 0.00) 67.16%);
        // background:linear-gradient(to right, #05112A, #3E0953);
        mask-image: linear-gradient(to bottom, black ,transparent);
        z-index: 0;
        pointer-events: none;


    }
    @media (prefers-color-scheme:dark) {
        &:before {
            background-image: url('../images/map/map_dark_mobile.png');
        }
        
        &:after {
            background:linear-gradient(to right, #05112A, #3E0953);
            // background:linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0.00) 55.93%), linear-gradient(180deg, #05112A 50%, rgba(5, 17, 42, 0.00) 100%);
            opacity: 0.7;
        }
    }

    .intro {
        // padding-top:48px;
        position: relative;
        padding: 48px var(--outside-padding) 32px var(--outside-padding);
        width:100%;
        max-width:calc(1440px + var(--outside-padding) + var(--outside-padding));
        margin: 0 auto;
        z-index: 11;
        
        .search-input {
            max-width:100%;
            pointer-events:all;
            // color:#fefefe;
            z-index: 222;
            .gr-input {
                background:transparent;
                max-width:100%;
               border-color:#020917;
               font-size:1.125rem;
               &::placeholder {
                color:#020917;
               }
               &:focus-visible {
                background:var(--bg-level0);
              }
            }
            @media (prefers-color-scheme: dark) {
                color:#fefefe;
                
                .gr-input {
                    background:transparent;
                    color:#fefefe;
                    border-color:#fefefe;
                    &::placeholder {
                        color:#fefefe;
                    }
                    &:focus-visible {
                        background:linear-gradient(180deg, #05112A 50%, rgba(5, 17, 42, 0.00)100%);
                    }
                }
             }
        }
        h1, p {
            max-width:550px;
            
            z-index: 2;
            margin:0;
            @media (prefers-color-scheme: dark) {
                color:#fefefe;
             }
        }
        p {
            margin-top:12px;
        }

       

        
    }
    .map-container, .map-image {
        // padding:0;
        // margin-bottom:0;
        // padding: 48px var(--outside-padding) 32px var(--outside-padding);
        width:100%;
        max-width:calc(1440px + var(--outside-padding) + var(--outside-padding));
        margin: 0 auto;
    }
    
    @media (min-width: ($breakpointS + 1)) {
        // background:none;
        background-image: url('../images/map/map_light_xwide.png');
        background-attachment: scroll;
        background-size:cover;
        background-position:  center -70px;
        margin-bottom: -134px; //70 plus 64
        padding-top:60px;

        &:before {
            content:none;
        }
        .intro {
            position:absolute;
            top:0;
            left:0;
            right:0;
            z-index: 11;
            // pointer-events: none;
        }

        section.map-image {
            &:before {
                // content:'';
                height:100%;
                width:340px;
                background: linear-gradient(88deg, #E8EEFA 30%, rgba(232, 232, 232, 0.00) 75%);
                position:absolute;
                left:-276px;
                top:0;
                bottom:0;
                z-index: 1;
            }
            &:after {
                // content:'';
                height:100%;
                width:340px;
                background:linear-gradient(272deg, #E8EEFA  30%, rgba(232, 232, 232, 0.00) 75%);
                position:absolute;
                right:-276px;
                top:0;
                bottom:0;
                z-index: 1;
            }
        }
        

        @media (prefers-color-scheme:dark) {
            background-image: url('../images/map/map_dark_xwide.png');
        }
    }
    @media (min-width: (1025px)) {
        padding-top:0px;
    }
    
    @media (min-width:2200px) {
        // max-width:1920px;
        background-size:cover;
        background-position-x: center;
        background-position-y: 70%;
      
    }
}
.career-info {
    position:relative;
    z-index: 1;
    text-align:left;
    padding: 32px var(--outside-padding) 32px var(--outside-padding);
    width:100%;
    max-width:calc(1440px + var(--outside-padding) + var(--outside-padding));
    margin: 0 auto;
    pointer-events: none;
    transition:opacity 0.3s ease-in-out;
    
    h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        // color: #FEFEFE;
        // margin-top: 40px;
        max-width: 550px;
        margin:0;
        // mix-blend-mode: color;
        padding-right: 60px;
    }
    p {
        margin-top: 16px;
        margin-bottom:0;
        max-width: 550px;
        // mix-blend-mode: multiply;
    }
    .career-info {

    }

    @media (min-width:($breakpointXS + 1)) {
        padding-right:calc(var(--outside-padding) + 48px);

        h1 {
            padding-right:0;
        }
        h1, p {
            color: #FEFEFE;
        }
    }
    
}
.banner-image {    
    width:100%;
    overflow:hidden;
    position:relative;

    .video-container {
        // position:absolute;
        // min-height:360px;
        width:100%;
        height:200px;
        .video-actions {
            position:absolute;
            z-index: 2;
            right:24px;
            bottom:24px;
            display:flex;
            flex-direction: row;
            gap:12px;
            button {
                color:#fefefe;
                background-color:rgba(0, 0, 0, 0.20);
                border:1px solid #fefefe;
                padding:8px;
                border-radius:8px;
                &:hover {
                    background-color: #05112A;
                }
            }
        }

        video, iframe {
            &:hover {
                .career-info {opacity: 0.5;}
            }
        }
    }
    video, img, iframe {
        // position:absolute;
        // top:0;
        // left:0;
        // right:0;
        width:100%;
        height:100%;
        min-height:100%;
        min-width:100%;
        object-fit: cover;
        object-position: center;
        z-index: 0;
    }
    &:after {
        
        width:100%;
        height:50%;
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        // background: linear-gradient(352deg, rgba(0, 0, 0, 0.00) 36.87%, rgba(28, 10, 50, 0.76) 75.46%, #160F37 99.18%);
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.00) 5.35%, #160F37 91.99%);

        pointer-events: none;
    }

    @media (min-width:($breakpointXS + 1)) {
        min-height:360px;
        &:after {
            content:'';
            height:100%;
            // background: linear-gradient(345deg, rgba(0, 0, 0, 0.00) 50.35%, rgba(14, 5, 25, 0.45) 56.36%, rgba(28, 10, 50, 0.7) 62.25%, rgba(25, 12, 52, 0.87) 79.2%, #160F37 91.99%);
        }
        .video-container {
            position:absolute;
            min-height:360px;
            width:100%;
            height:100%;
        }
    }
    @media (min-width:($breakpointS + 1)) {
        &:after {
            content:'';
            height:100%;
            background: linear-gradient(345deg, rgba(0, 0, 0, 0.00) 50.35%, rgba(14, 5, 25, 0.45) 56.36%, rgba(28, 10, 50, 0.7) 62.25%, rgba(25, 12, 52, 0.87) 79.2%, #160F37 91.99%);
        }
    }
}
.career-body {
    // padding-top:40px;
    .career-body-header {
        margin-top:40px;
    }
    h2 {
        margin-top:0;
        margin-bottom:0;
        + p {
            margin-top:8px;
            margin-bottom:0;
        }
    }
    h3, h4 {margin:0;}
    .pathway-group {
        margin-top:40px;
    }
    .career-information {
        padding:40px;
        border-bottom:1px solid #C7CCD6;
        margin-bottom:40px;
        .career-features {
            display:flex;
            flex-direction:row;
            align-items: flex-start;
            flex-wrap:wrap;
            gap:24px;
            justify-content: space-between;

            .feature-group {
                flex:1 1 360px;
                text-align:center;
            }
        
            .feature-number {
                font-size:2.5rem;
                line-height:1;
                font-weight:400;
                display:block;
            }
            .feature-description {
                margin-top:12px;
                font-size:1.125rem;
                line-height:1.25rem;
                font-weight:700;
                display:block;
            }
        }
    }
    .detail-group {
        margin-bottom:80px;
        .desktop-h4 {margin:0 0 24px 0;}
        &:last-of-type {margin-bottom:0;}

        .jobs-list {
            p {
                margin:0;
                + p {
                    margin-top:8px;
                }
                a {font-weight:normal;}
            }
        }
        .skills-list {
            gap:12px;
            .chip-btn {
                margin:0;
                line-height:20px;
                min-height:34px;
                .verified {
                    height:20px;
                    width:20px;
                }
            }
        }
        .detail-list-container {
            transition:max-height 0.3s ease;
            overflow:hidden;
            &.condensed {
                max-height:320px;
                mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
            }
            &.expanded {
                max-height:1000vh;
            }
            + .action-row {
                position:relative;
                display:flex;
                align-items: center;
                justify-content: center;
                margin-top:16px;
            }
        }
        .detail-list {
            
            ul {
                padding-left:2rem;
                margin:0;
                li {
                    &::marker {
                        font-size: 24px;
                        line-height:28px;
                      }
                    p {margin:0;}
                    + li {
                        margin-top:24px;
                    }
                }
            }
           
            @media (min-width: ($breakpointS + 1)) {
                columns:2;
                li {
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;
                }
            }
        }
    }
    .quick-facts {
        margin-top:80px;

        h3 {margin-bottom:24px;}

        .quick-facts-grid {
            display:grid;
            grid-gap:24px;
            grid-template-columns: 1fr;
            grid-template-rows: auto;

            @media (min-width:($breakpointS + 1)) {
                grid-template-columns: 1fr 1fr;
            }
        }
        .card {border-radius:8px}
        .quick-fact {
            h4 {margin-bottom:12px;}
            p {
                margin:0;
            }
        }
    }
    .pathway-group-header {
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
        flex-wrap:wrap;
        gap:24px;
        margin-bottom:24px;
    }
}


.careers-grid {
    margin: 20px auto 0px;
    // padding-bottom: 56px;
    display: flex;
    flex-flow: row wrap;
    // -webkit-box-pack: center;
    justify-content: flex-start;
    gap: 16px;
    // max-width: 1440px;
    position: relative;

    @media (min-width:808px) {
        gap: 24px;
    }
}

.career-pop-up {
    position:absolute;
    // background: rgb(18, 33, 66);
    // background-color:#05112A;
    border-radius:16px;
    padding:0px;
    color:var(--text-default);
    text-align:left;
    overflow:hidden;
    z-index: 111;
    opacity: 0;
    // transform-origin: top left;
    &.odd {
        left:0;top:0;
    }
    &.even {
        // transform-origin: top right;
        right:0;top:0;
    }

    .modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // color:var(--color-white);
        border-bottom: 1px solid #4F5561;
        padding-bottom:8px;
        
        h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            margin:0;
        }
        button {
            // color:var(--color-white);
        }
    }
    .description {
        font-size:18px;
        line-height:24px;
    }
    
    .link-btn {
        font-size:18px;
        line-height:24px;
        // color: var;

    }
    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
    }

    &.open {
        border: 1px solid #05112A;
        padding:16px;
        animation: open 600ms ease-in-out;
        animation-fill-mode: forwards;
      }
      &.close {
        animation: close 600ms ease-in-out;
        animation-fill-mode: forwards;
      }
}

@media (max-width:$breakpointS) {
    .desktop-only {
        display:none !important;
    }
}
@media (min-width:($breakpointS + 1)) {
    .mobile-only {
        display:none !important;
    } 
}

@keyframes open {
    0% {
      opacity: 0;
      width: 0px;
      max-height: 0px;
    //   border-radius:100%;
      overflow:hidden;
    }

    90% {
      width:100%;
      max-height: calc(60vh - 98px);
    }
    100% {
      opacity: 1;
      width:100%;
      max-height: calc(70vh);
    }
  }
  @keyframes close {
    0% {
      opacity: 1;
      width:100%;
      max-height: calc(70vh);
      padding:16px;
    }
    100% {
      opacity: 1;
      width:0;
      max-height: 0px;
      padding:0px;
    }
  }

  @keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
  }




  .career-details {
    text-align:left;
    // background: linear-gradient(225deg, #6D0176 -45.86%, rgba(109, 1, 118, 0) 55.93%);
    // background-color: var(--color-dark-blue);
    // color: var(--color-white);
    .tab-container {
        // max-width:1440px;
        margin: 0px auto;
        padding:16px;
    }
    @media (min-width:769px) {
        background:transparent;
        color: var(--color-black);
       .tab-container { 
        padding: 0 var(--outside-padding);
        section {
            padding:0;
        }
    }
       .tab-header {
        // min-height:250px;
        margin-bottom:32px;
       }
    }

    .menu-icon {display: none;}
    section {
        margin-bottom:40px;
    }

   
    h3 {
        font-size:24px;
        line-height:28px;
        margin-top:0;
        margin-bottom:24px;
    }
    p, h4 {
        font-size:18px;
        line-height:24px;
    }

   
  }



  

  .career-fast-facts {
    .card-footer {
        text-align:center;
        margin-top:0;
    }

    .fast-facts-title {
        width:100%;
        border-bottom: 1px solid #4F5561;
        font-size:24px;
        line-height:28px;
        font-weight:400;
        padding-top:16px;
        padding-bottom: 16px;
        margin:0 0 18px 0;
    }
   .facts-row {
    display:flex;
    align-items: center;
    flex-direction: column;
    gap:26px;
    margin: 26px;
    text-align:center;
    h6, p {text-align: center;}
   }
   h6 {
    // font-size:20px;
    // line-height:24px;
    // font-weight:700;
    margin: 0 0 4px 0;
    svg, img {
        margin-right:8px;
    }
    + p {text-align:left;margin-top:0;margin-bottom:0;font-size:18px;line-height:24px;}
   }
        .card-header {
            border-bottom:1px solid var(--color-border);
            h3 {
                margin-bottom:16px;
            }
        }
        h4 {margin:0;}

        h4 + p {margin-top:0;}
        ul {
            list-style:none;
            margin:0;
            padding:0;
            // p {margin:0;}
            li:last-child p {
                margin-bottom:0;
            }
        }
    
  }

  
  .map-container {
    position:relative;
    width:100%;
    // height:100%;
    height:calc(100vh - 70px);
    .toolbar {
        position:absolute;
        z-index: 11;
        bottom:24px;
        left:16px;
        display:flex;
        flex-direction:row;
        gap:12px;

        button {
            width:30px;
            height:30px;
            padding:6px;
            color:var(--text-default);
            background-color: rgba(255,255,255,0.9);
            border-radius:50%;
        }
    }



    .drawer-btn {
        // box-shadow: 0 2px 4px blue;
        background:transparent;
        border:none;
        position:relative;

        img {
            width:100%;
        }
        .map-hover {
            position:absolute;
            left:0;
            top:0;
            color:#F05B95;
            opacity:0;
            pointer-events: none;
        }
        
        &:hover, &:focus {
            .map-hover {
                mix-blend-mode:color;
                transform: translate3d(0,0,0);
                opacity: 1;
            }
        //    box-shadow: 0 2px 10px magenta; 
            img{
                filter: var(--mapHover);
            }
        
        }
        
    } 
}

.map-image {
    position:relative;
    z-index: 2;
    .image {
        overflow:hidden;
        width:100%;
        // padding-top:80px;
        background:none;


        // margin-left: -64px;
        // margin-right: -64px;
        // // transform: translateY(-78px);
        // width: calc(100% + 128px);

        img {
            width:100%;
            max-width:100%;
            opacity: 0;
        }

        &.dark-mode {display:none}

        // @media (min-width:1440px) {
        //     img {opacity: 0;}
        // }
    }
    .hotspot-container {
        background:none;
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        top:0;
        max-width:calc(1440px + var(--outside-padding) + var(--outside-padding));
        margin:0 auto;
        // transform:translateY(70px);
        &.dark-mode {display:none}
        .drawer-btn {
            position:absolute;
            width:20%;
            height:20%;
            text-align:center;
            text-decoration:none;
            font-weight:normal;
            // left:20%;
            // top:20%;
            img {
                width:100%;
                height:100%;
                object-fit: contain;
                transform:translateY(0);
                // transition:transform 0.6s ease;
                animation: hotspot-easeOutBounce 0.6s;
            }
            .map-hover {
                position:absolute;
                left:0;
                top:0;
                color:#F05B95;
                opacity:0;
                pointer-events: none;
            }
            .recommended-glow {
                position: absolute;
                left:50%;
                top:0;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                margin: 0 auto;
                transform:scaleX(1) scaleY(0.65) translateY(35%) translateX(-50%);
                z-index: -1;
                &:before {
                    content: '';
                    border: 30px solid var(--map-glow-bg);
                    border-radius: 50%;
                    height: calc(100% - 60px);
                    width:calc(100% - 60px);
                    position: absolute;
                    left: 2px;
                    bottom: 0;
                    animation: pulsate 1.6s ease-out;
                    animation-iteration-count: infinite;
                    opacity: 0;
                    z-index: 99;
                  }
                  &:after {
                    content: '';
                    display:block;
                    height: 100%;
                width: 100%;
                border-radius: 50%;
                    border: 2px solid transparent; /*2*/
                    background: var(--map-glow-border) border-box; /*3*/
                    -webkit-mask: /*4*/
                    linear-gradient(#fff 0 0) padding-box, 
                    linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor; /*5'*/
                    mask-composite: exclude; /*5*/
                    
                  }
            }
            span {
                display: inline-block;
                text-align:center;
                color:var(--text-default);
                font-size:1.4vw;
                line-height: 1.1;
                margin-top:8px;
                // -webkit-text-stroke-width: 1;
                // -webkit-text-stroke-color: var(--mapTextStroke);
                // text-shadow:
                //     -1px -1px 0 var(--mapTextStroke),  
                //         1px -1px 0 var(--mapTextStroke),
                //         -1px 1px 0 var(--mapTextStroke),
                //         1px 1px 0 var(--mapTextStroke);

                @media (min-width: 1280px) {
                    font-size: 1.125rem; //18px;
                }
            }
            &:focus-visible {
                    outline:0;
                    // box-shadow: 0px 0px 0px 2px #B41863;
                    span {text-decoration: underline;}
            }
            &:hover, &:focus-visible {
            
                //    box-shadow: 0 2px 10px magenta; 
                    img{
                        transform:translateY(-10%);
                        // -webkit-filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(2678%) hue-rotate(314deg) brightness(94%) contrast(97%);
                        // filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(2678%) hue-rotate(314deg) brightness(94%) contrast(97%);
                        // -webkit-filter: var(--mapHover);
                        // filter: var(--mapHover);
                        transition:transform 0.4s ease;
                        animation:none;
                    }
                    .map-hover {
                        // mix-blend-mode:plus-darker;
                        mix-blend-mode: overlay;
                        transform: translate3d(0,-10%,0);
                        opacity: 0.5;
                        transition:transform 0.4s ease, opacity 0.3s ease;
                        @media (prefers-color-scheme:dark) {
                            mix-blend-mode: color-dodge;
                        }
                    }
                    span {
                        color:var(--mapTextHover); 
                    }
                
                }

            @media (prefers-reduced-motion:reduce) {
                img {animation:none !important;}
                .recommended-glow {
                    &:before {
                        opacity: 0.5;
                        animation:none !important;
                    }
                }
            }
            &.cluster2 {
                //architecture
                left:4.5%;
                top:20.5%;
                height:11%;
                width:15%;
            }
            &.cluster6 {
                //finance
                top:21.25%;
                left:31.5%;
                width:12%;
                height:11%;
            }
            &.cluster13 {
                //manufacturing
                top:19.5%;
                left:54%;
                width:12%;
                height:12.7%;
                .recommended-glow {
                    transform-origin: center;
                    transform:translateX(-50%) scaleX(1.35) scaleY(0.6) translateY(38%);
                    &:after {
                        // width:110%;
                    }
                }
            }
            &.cluster14 {
                //marketing
                height:9%;
                width:11.5%;
                top:19%;
                right:15%;
            }
            &.cluster18 {
                //education
                top:22.3%;
                right:-4%;
                height:10%;
                width:16.5%;
                span {
                    display:inline-block;
                    width:85%;
                }
                .recommended-glow {
                    transform-origin: center;
                    transform:translateX(-50%) scaleX(1.15) scaleY(0.85) translateY(15%);
                    &:after {
                        // width:110%;
                    }
                }
            }

            &.cluster4 {
                //business
                top:37.5%;
                left:0%;
                height:19.5%;
                width:17.55%;
                height:14.7%;

                .recommended-glow {
                    transform-origin: center;
                    transform:translateX(-50%) translateY(35%) scaleX(.65) scaleY(0.35) ;
                    &:after {
                        // width:110%;
                    }
                }
            }
            &.cluster7 {
                //govt
                top:34%;
                left:18.7%;
                width:16.3%;
                height:11.6%;
                .recommended-glow {
                    transform-origin: center;
                    transform:translateX(-50%) translateY(10%) scaleX(1.25) scaleY(0.9);
                    &:after {
                        // width:110%;
                    }
                }
            }
            &.cluster8 {
                //health
                top:37%;
                left:44.5%;
                width:11.65%;
                height:9.3%
            }
            &.cluster11 {
                //IT
                top:33.5%;
                right:21%;
                height:12.4%;
                width:11%;

                .recommended-glow {
                    transform-origin: center;
                    transform:translateX(-50%) scaleX(1.15) scaleY(0.5) translateY(55%);
                    &:after {
                        // width:110%;
                    }
                }
            }
            &.cluster3 {
                //art
                top:43%;
                right:1.8%;
                width:16%;
                height:9%;

                span {margin-top:0;}
            }
            &.cluster17 {
                //human services
                top:60%;
                left:0.5%;
                width:12.6%;
                height:7.6%;
            }
            &.cluster9 {
                //hospitality
                top:53%;
                left:21%;
                width:11%;
                height:9.3%;
            }
            &.cluster15 {
                //stem
                top:51%;
                left:39%;
                width:17%;
                height:11.8%;
                span {margin-top:0.25rem;}

                .recommended-glow {
                    transform-origin: center;
                    transform:translateX(-50%) scaleX(0.65) scaleY(0.45) translateY(65%);
                    &:after {
                        // width:110%;
                    }
                }
            }
            &.cluster19 {
                //law
                top:53%;
                left:62.5%;
                width:15%;
                height:8.5%;
            }
            &.cluster1 {
                //food
                height:6.2%;
                width:13%;
                top:67.5%;
                left:27.5%;
            }
            &.cluster16 {
                //transportation
                top:68%;
                right:15.3%;
                height:11%;
                width:17.5%;

                span {margin-top:0;}
            }
        }
    }

    @media (prefers-color-scheme: dark) {
        .image.dark-mode {display:block}
        .image.light-mode {display:none;}
        .hotspot-container.dark-mode {display: block;}
        .hotspot-container.light-mode {display:none}
        .card-row.dark-mode {display:flex;}
        .card-row.light-mode {display:none;}
    }
}

.cluster-grid {
    .card-row {
        flex-direction:row;
        flex-wrap:wrap;
        margin-top:0;
        &.dark-mode {display:none;}

        @media (prefers-color-scheme: dark) {
            
            &.dark-mode {display:flex;}
            &.light-mode {display:none;}
        }
    }
    .card {
        
        flex: 1 1 200px;
        text-decoration:none;
        h2 {text-align:center;font-weight:normal;text-decoration:none;color:var(--text-default)}
        .card-title {
            font-size:1.25rem; //20px h5
            // font-weight:700;
            line-height:1.5rem;
            margin-top:1rem;
        }
        .image {
            height:200px;
            width:200px;
            margin: 0 auto;
            img {
                object-fit: contain;
                height:100%;
                width:100%;
                object-position: bottom;
            }
        }
        &:hover, &:focus {
            outline:none;
            // z-index: 1;
            // background-clip: padding-box; /* !importanté */
            // margin: -1px;
            // border-image-source: var(--gradient-01-hover);
            // border-image-slice: 1;
            // border: 2px solid ;
            img{
                // filter: var(--mapHover);
            }
            &:after {
                content:'';
                top: 0; right: 0; bottom: 0; left: 0;
                border-radius: inherit; 
                position:absolute;
                border-radius: 24px; /*1*/
                border: 2px solid transparent; /*2*/
                background: var(--gradient-01-hover) border-box; /*3*/
                -webkit-mask: /*4*/
                    linear-gradient(#fff 0 0) padding-box, 
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor; /*5'*/
                mask-composite: exclude; /*5*/
            }
            .bookmark-btn {opacity:1;}
            .card-title {text-decoration:underline;}
        }
    }
    @media (min-width: ($breakpointXS + 1)) {
        .card-row {
            // display:grid;
            // grid-template-columns: 1fr 1fr;
            // grid-gap:24px;
        }
    }
    
}

@keyframes hotspot-easeOutBounce {
	0% {
		transform: translateY(-10%);
	}

	16% {
		transform: translateY(-3.227%);
	}

	28% {
		transform: translateY(1.4%);
	}

	44% {
		transform: translateY(-2.463%);
	}

	59% {
		transform: translateY(1.17%);
	}

	73% {
		transform: translateY(0.058%);
	}

	88% {
		transform: translateY(0.5%);
	}

	100% {
		transform: translateY(0%);
	}

}

@keyframes pulsate {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
@import "colors";
@import "typography";
@import "buttons";
// @import "dark-mode";

@import "header";
@import "accountmenu";
@import "cards";
@import "inputs";
@import "navigation";
@import "profile";
@import "toggle";
@import "page";
@import "unauth";
@import "switcher";
@import "infographics";
@import "responsive";
@import "careers";
@import "tabs";
@import "footer";
@import "accordion";
@import "carousel";
@import "insights";
@import "skills";
@import "modal";
@import "toast";
@import "badge";
@import "create-account";
@import "jobs";
@import "pathway";
@import "email";
@import "megamenu";
@import "coachmark";
@import "recruiter";
* {
    box-sizing: border-box;
}
[hidden] {
    display:none !important;
}
.icon-16 {
    width:16px;
    height:16px;
    fill:currentColor;
}
.icon-18 {
    width:18px;
    height:18px;
    fill:currentColor;
}
.icon-24 {
    width:24px;
    height:24px;
    fill:currentColor;
}


:root {
    --card-padding: 24px;
    --card-padding-small: 16px;
    --card-gap: 24px;
    --column-gap: 24px;
    --outside-padding: 24px;
    --vertical-space: 32px;
    --card-padding-skills: 4px;
    --card-padding-mini: 12px 20px;
}


@media (max-width: $breakpointMobile) {
    :root {
        --outside-padding: 24px;
        --card-padding-skills: 4px;
    }
}

@media (min-width: ($breakpointS + 1)) {
    :root {
        --outside-padding: 32px;
        --card-padding-skills: 8px;
    }
}
@media (min-width: ($breakpointM + 1)) {
    :root {
        --outside-padding: 64px;
    }
}
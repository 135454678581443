.recruiter-home {
    // padding-left: var(--outside-padding);
    // padding-right: var(--outside-padding);

    header {
        position:fixed;
        top:0;
        left:0;
        right:0;
        background:var(--color-black);
        color:var(--color-white);
        width:100%;
        z-index: 111;
    }
    main {
        max-width:1100px;
        margin:auto;
        padding-left: var(--outside-padding);
        padding-right: var(--outside-padding);
    }
    .main-header {
        .flex-row {
            align-items: center;
        }
        a {
            flex:none;
        }
    }
    .form-group {
        margin: var(--vertical-space) 0;
    }
    .search-input {
        width:100%;
        margin-bottom:var(--vertical-space);
        .gr-input {max-width:100%;border-radius:4px;}
    }
    .autocomplete-container .search-input {
        margin-bottom:0;
    }
    .form-actions {
        justify-content: space-between;
        .flex-row {
            gap:12px;
        }
    }
    .filter-btn {
        display:flex;
        align-items: center;
        gap:8px;
        border-radius: 4px;
        border: 1px solid var(--text-default);
        background: transparent; 
        color:var(--text-default);
        padding: 8px 8px 8px 16px;
        min-height: 44px;
        font-size:18px;
        transition:background-color 0.3s ease;

        svg {
            transition:transform 0.3s ease;
        }

        .filter-amount {
            padding:2px 8px;
            border-radius:4px;
            background-color: #FEFEFE29; //353652
            margin-left:8px;
            // font-size:0.825em;
            vertical-align: text-bottom;
        }
        &.mobile-view {
            position:relative;
            background:transparent;
            border:0;
            padding:8px 0;

            .filter-amount {
                font-size: 0.75em;
                border-radius: 24px;
                position: absolute;
                top: 0px;
                right: 0;
                transform: translateX(calc(-50% + 10px));
                border: 1px solid #3a0950;
                padding: 2px 4px;
                min-width: 20px;
                min-height: 20px;
                line-height: 14px;
                color: var(--text-default);
                background: var(--bg-level0);
                font-weight: 600;
            }
        }
        &[aria-expanded=true] {
            background:var(--color-white);
            color: var(--background-dark-theme-bg-level-2, #272D39); 

            .filter-amount {
                background: #02091729; //D0D3D8

            }
            svg {
                transform:rotate(180deg);
            }
        }

        @media (prefers-color-scheme:dark) {
            // border-color:var(--color-white);

            &[aria-expanded=true] {
                color:var(--color-white);
                background-color:var(--bg-level0);
            }
        }
        
    }
    .skills-table {
        margin:var(--vertical-space) 0;
        width:100%;
        // font-size:1.125rem;
        border-collapse: collapse;
        thead {
            th {
                text-align:left;
                font-weight:600;
                vertical-align: middle;
                text-transform: uppercase;
                padding:8px;
                strong, span {
                    display:block;
                }
                button {
                    background:transparent;
                    border:none;
                    text-align:left;
                    font-weight:600;
                    vertical-align: middle;
                    text-transform: uppercase;
                    text-align:left;
                    display:flex;
                    align-items: center;
                    gap:4px;
                }
                &:first-of-type {
                    text-align:left;
                    
                }
            }
        }
        tbody {
            tr:nth-child(2n+1) {
                th,td {
                    background: none;
                }
            }
            th {
                font-weight:normal;
                padding: 8px;
                text-align:left;
                .icon {
                    color: #01CBB7;
                    margin-right:4px;
                    vertical-align: middle;
                }
            }
            td {
                padding: 8px; 
                min-height:50px;
                text-align: left;
                .chip-btn {
                    // border-radius: 8px;
                    white-space: nowrap;font-size:14px;
                    &.dark {
                        font-weight: 700;

                        &:hover, &:focus {
                            //nothing
                            background: var(--background-dark-theme-bg-level-2, #272D39);
                            color: #FEFEFE;
                            border-color: var(--background-dark-theme-bg-level-2, #272D39);
                        }
                    }
                }

            }
            .null {
                width:100%;
                text-align:center;
                color: #60646D;
                font-weight:bold;
                display:inline-block;
                line-height:34px;
            }
        }
    }
}

.dot {
    width:8px;
    height:8px;
    border-radius: 50%;
    display:inline-block;
    background:var(--color-black);

    &.active {
        background-color: #01CBB7;
    }
    &.paused {
        background-color: #EEA42E;
    }
    &.closed {
        background-color: #FF4949;;
    }
}
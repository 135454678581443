.footer-container {
    position:relative;
    margin-top:32px;
    padding-top:56px; //curve
    z-index: 1;
    .footer-curve {
        top: 0px;
        background: transparent;
        width: 100%;
        height: 160px;
        z-index: -1;
        position: absolute;
        // &:after {
        //     content: "";
        //     display: block;
        //     width: 204%;
        //     height: 208%;
        //     position: absolute;
        //     border-radius: 37%;
        //     bottom: 100%;
        //     right: -18%;
        //     box-shadow: #020917 0px 280px 0px 0px;
        // }
            svg {width:100%;height:100%;display:block;}
        
    }

    @media (min-width: ($breakpointS + 1)) {
        .footer-curve {
            &:after {
                // width:
                // width: 204%;
                // height: 388%;
            }
        }
    }

    
}
.page-footer {
    display: flex;
    flex-wrap:wrap;
    gap:24px;
    font-size:16px;
    line-height:150%;
    padding: 40px calc(0.5*var(--outside-padding));
    background:#020917;

   ul li a {
        color: var(--color-white);
        text-decoration: none;
        font-weight:normal;
    }
    ul {
        margin:0;
        padding:0;
    }
    .css-1904l99 {
        background: none;
        border: none;
        cursor: pointer;
        line-height: 21px;
        font-weight:normal;
        color: var(--color-white);
    }
    .copyright {
        color: var(--color-white);
        // color: #9BA1AD;
    }

   
}
.css-bxmrmw {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    margin-left: 8px;
}
.css-15pzk {
    display: inline-block;
    width: 52px;
    height: 52px;
    background: url("https://www.pearson.com/jobmatch/assets/images/app-icon.svg");
    vertical-align: middle;
}
.css-1c9jac0 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 24px;

    flex-direction:row;
    justify-content: flex-start;
}


.css-o9b79t {
    list-style: none;
}
@import "responsive";
@media (min-width: ($breakpointXS + 1)) {
    .page-footer {
        flex-direction:column;
        align-items: center;
        gap:24px;
            .css-1c9jac0 {
                justify-content: center;
            }
    }
}
@media (min-width: ($breakpointS + 1)) {
    .page-footer {
        flex-direction:row;
        justify-content: center;
        gap:50px;
        .css-1c9jac0 {
            gap:50px;
        }
    }
}
.pathway-diagrams {

    .link-btn, .card .card-header .link-btn {
        font-size:18px;
    }
    .card.action-card {
        display: block;
        padding:var(--card-padding);
        padding-bottom:48px;
        background: linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0.00) 55.93%), #05112A;


        --icon-width: 24px;
        .card-title.edited {
            &:after {
                content: '';
                background-image:  url('../icons/Completion.svg');
                margin-left: 0.2em;
                padding-left: var(--icon-width);
                background-size: var(--icon-width) var(--icon-width);
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        .card-body {
            margin-top:0px;
            

            .flex-row {
                padding-top:24px;//show delete buttons
                justify-content: flex-start;
                align-items: stretch;
                gap:var(--column-gap);
                overflow-x: auto;
                // overflow-y: visible;
                padding-bottom:4px;
                margin-bottom:-4px;
            }

            .card {
                background:var(--color-white);
                border-color:var(--color-border);
                color:var(--color-black);
                height:auto;
               
                .card-title, p {
                    color:var(--color-black);
                }

                &:not(.completed) {
                    &:before {
                        content:url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path d="M14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.34315 0.928932C7.95262 0.538408 7.31946 0.538408 6.92893 0.928932C6.53841 1.31946 6.53841 1.95262 6.92893 2.34315L12.5858 8L6.92893 13.6569C6.53841 14.0474 6.53841 14.6805 6.92893 15.0711C7.31946 15.4616 7.95262 15.4616 8.34315 15.0711L14.7071 8.70711ZM0 9H14V7H0V9Z" fill="white"/></svg>');
                        position:absolute;
                        display:block;
                        width:15px;
                        height:16px;
                        left:-20px;
                        top:calc(50% - 12px);
                    }
                }
            }
        }
        
    }
    .card.pathway-suggestions {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top:-24px;
        .suggested-certificates {
            position:relative;

            .certificate-popup {
                // position: absolute;
            }
        }

        .card-row {
            overflow-x:auto;
            padding-bottom:4px;
            .card {
                flex:none;
            }
        }
    }
}
.associated-roles {
    .role-information {
        display:flex;
        flex-wrap:wrap;
        gap:32px;


    }
    .quick-stats {
        flex:1;
        .desktop-h5 {
            margin:0;
        }
        
        .card.outlook-item {
            padding:24px 10px;
            margin-top:32px;
            background:var(--bg-level0);
            .icon {
                // background:var(--bg-level0);
            }
            p {
                margin:0;
                font-size:1.125rem;
                + p {
                    margin-top:4px;
                }
            }
        }
    }
    .roles-list {
        display:flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap:wrap;
        gap:8px;
        margin-bottom:16px;
        // overflow-x:scroll; //for now

        .roles-list-container {
            display:flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap:wrap;
            gap:8px;

            &.condensed {
                max-height:50px;
                overflow:hidden;
            }
        }

        .chip-btn {
            white-space: nowrap;
            padding: 12px 24px;
            background: #E0E7F0;
            border-color:#E0E7F0;
            font-size: 18px;
            line-height:24px;
            font-weight: 600;
            border-radius: 8px;
            &:hover, &:focus {
                border: 1px dashed #122142;
                
            }
            &[aria-selected=true] {
                background: #122142;  
                border-color:#122142;
                color:var(--color-white);
                &:hover {
                    border: 1px solid #122142;
                    
                }
            }

            
        }
    }

    @media (max-width:$breakpointS) {
        .role-information {
            flex-direction: column;
        }
        .card.skills-table-container {
            overflow-x: auto;
        }
    }
    .secondary-tabs.tabs-card {
        background:var(--bg-level0);
    }
    .tab-list-container {
        background:var(--bg-level2);
    }
    .tab-content {
        .card {
            background:var(--bg-level1);
        }
    }
    .MuiTabs-root .MuiTabs-indicator {
        background: var(--color-black);
    }

   .card.skills-table-container {
    padding: 24px 0;
    flex:2;
    max-width:100%;
    
    // padding-left:0;
    // padding-right:0;
   }
    .skills-table {
        width:100%;
        font-size:1.125rem;
        border-collapse: collapse;
        thead {
            th {
                text-align:center;
                font-weight:normal;
                vertical-align: top;
                padding-bottom:28px;
                padding-left:28px;
                padding-right:28px;
                strong, span {
                    display:block;
                }
                &:first-of-type {
                    text-align:left;
                    
                }
            }
        }
        tbody {
            tr:nth-child(2n+1) {
                th,td {
                    background: var(--bg-level0, #FEFEFE);
                }
            }
            th {
                font-weight:normal;
                padding: 8px 28px;
                width:100%;
              
                .icon {
                    color: #01CBB7;
                    margin-right:4px;
                    vertical-align: middle;
                }
            }
            td {
                padding: 8px 28px; 
                min-height:50px;
                text-align: center;
                .chip-btn {
                    // border-radius: 8px;
                    white-space: nowrap;font-size:14px;
                    &.dark {
                        font-weight: 700;

                        &:hover, &:focus {
                            //nothing
                            background: var(--background-dark-theme-bg-level-2, #272D39);
                            color: #FEFEFE;
                            border-color: var(--background-dark-theme-bg-level-2, #272D39);
                        }
                    }
                }

            }
            .null {
                width:100%;
                text-align:center;
                color: #60646D;
                font-weight:bold;
                display:inline-block;
                line-height:34px;
            }
        }
    }
}
.pathway-card {
    padding:50px 16px 16px 16px;
    width:180px;
    flex:none;
    color:var(--text-default);
    .card-title {
        max-height:48px;
        overflow: hidden;
        text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    min-width: 0;
    word-break: break-word;
    -webkit-box-orient: vertical;
    display:-webkit-box;
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    }
    .issuer {
        overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    min-width: 0;
    word-break: break-word;
    -webkit-box-orient: vertical;
    display:-webkit-box;
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    }
    .image {
        display: flex;
        align-items: center;
        flex:none;
        width:130px !important;
        // height:130px;
        overflow:hidden;
        
        margin-bottom:8px;
        margin-left:auto;
        margin-right:auto;
        img {
            // margin-top:-25px;
        }
    }
    &.active {
        background-color: #122142;
        color:var(--color-white);
    }
    .remove-path-btn {
        position:absolute;
        right:0;
        top:0;
        transform:translate(50%, -50%);
        cursor:pointer;
        opacity:0;
        &:focus {
            opacity: 1;
        }
    }
    &:hover {
        .remove-path-btn {
            opacity:1;
        }
    }
}
.accordion-container.suggested-certificate-accordion {
    box-shadow: none;
    border:1px solid var(--color-border);
    background: var(--bg-level0);
}
.accordion-btn.pathway-card {
    border:0;
    width:100%;
    align-items: center;
    // padding:0;
    .icon-24 {
        margin-bottom:34px;
    }
    
    .pathway-info {
        display:flex;
        align-items: center;
        gap:12px;
        .image {
            max-width:100px;
            // max-height:75px;
            img {
                // margin-top:-10px;
            }
        }
    }

}
.add-to-path {
    background: var(--transparent-dark-32, rgba(2, 9, 23, 0.32));
    border: 3px dashed #393F4A;
    // border: 2.84px solid #393F4A;
    border-radius:var(--card-corner-default);
    // height:100%;
    width:180px;
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
    padding:24px;
    color:var(--color-white);
    font-size: 1.25rem;
    font-weight: 600;
    flex:none;
    &:hover, &:focus {
        background: rgba(2, 9, 23, 0.75);
        border-color:#fefefeB8;
    }
}

.outlook-items {
    gap:8px;
    flex-wrap:wrap;
}
.outlook-item {
    display:flex;
    align-items: center;
    gap:8px;

    .icon {
        background: #E8EEFA;
        border: 1px solid var(--color-border);
        color:var(--color-black);
        width:44px;
        height:44px;
        border-radius:50%;
        display:flex;
        align-items: center;
        justify-content: center;
        flex:none;
          
    }
    .outlook-title {
        font-weight:600;
        font-size:1.125rem;
        margin:0;

        + p {
            margin-top: 4px;
        }
    }
}

@keyframes hideOnScroll {
    0% {
        visibility: visible;
        // max-height:100%;
    }
    100% {
        visibility: hidden;
        // max-height:0;
        overflow:hidden;
        margin:0;
    }
}

.modal.pathway-modal {
    text-align: left;

    &.minimize {
        .modal-header {
            .autocomplete-container {
                // visibility: hidden;
                max-height:0;
                // overflow:hidden;
                animation: hideOnScroll 0.3s ease forwards;
                // animation-direction: forwards;
                animation-delay: 0.2s;
                // margin:0;
            }
            .card-row {margin-top:0;}
            .card {
                .card-text {
                    // visibility: hidden;
                    max-height:0;
                    overflow:hidden;
                    animation: hideOnScroll 0.3s ease;
                    animation-delay: 0.2s;
                }
            }
        }
    }

    .modal-header {
        .autocomplete-container {
            max-height:100px;
            transition: max-height 0.5s ease;
        }
        .card-row {transition:margin 0.2s ease;transition-delay:0.1s;}
        .card {
            .card-text {
                max-height:1000px;
                transition: max-height 0.5s ease;
            }
        }
    }

    .card-row {
        flex-direction: row;
        overflow-x: auto;
        padding-bottom:4px;
        margin-bottom:-4px;
        .pathway-card {
            flex:none;
        }
    }
    .modal-title {
        padding-right:40px;
    }

    .certificate-title {
        text-align: left;
    }
    .pathway-card:not(.active) {
        background:var(--bg-level0);
    }
    .certificate-info {
        .certificate-header {
            display:flex;
            justify-content: space-between;
            align-items: center;
            gap:12px;
            margin-bottom:16px;
            h2 {
                margin:0;
            }

            
        }
    }
    .certificate-tagline {
        display:flex;
        align-items: flex-start;
        gap:56px;
        .avatar {
            max-width:150px;
            width:100%;
            max-height:150px;
            overflow:hidden;
            img {
                // margin-top:-25px;
                width: 100%;
                max-width: 100%;
            }

        }
        p + p {margin-top:12px;}
    }
    .certificate-info-group {
        margin-top:56px;
        h3 {margin-bottom:0;}
        h4 {
            margin-top:1rem;
            margin-bottom:0;
        }
        .chip-list {
            margin-top:12px;
            .chip-btn {
                // background: var(--bg-level1);
                font-size:14px;
                // font-weight:500;
            }
        }
        .certificate-description {
            margin-top:1em;
        }
        .button-row {
            display: flex;
            align-items: center;
            flex-wrap:wrap;
            gap:12px;
            margin-top:12px;
        }
    }
    .tabs-card {
        margin-top:1rem;
        background:var(--bg-level0);
        .tab-list-container {
            background: var(--bg-level2);
            
        }
        .tab-content {
            .card {
                background:var(--bg-level1);
            }
        }
    }
    .MuiTabs-root .MuiTabs-indicator {
        background: var(--color-black);
    }
    @media (max-width: $breakpointM) {
        .certificate-tagline {gap:24px; }
    }
    @media (max-width: 807px) {
        //size of modal
        max-width:calc(100vw - 32px);
        width:100%;
        max-height:calc(100vh - 32px);

        .certificate-header {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            .certificate-title {
                text-align: center;
            }
        }
        .certificate-tagline {
            flex-direction: column;
            gap:24px;
            .avatar {margin:auto;}
        }
        .card.study-card {
            .image.video {
                img {
                    // width:100%;
                    height:auto;
                    max-width:120px;
                }
            }
        }
    }
}

.pop-up-container {
    position:absolute;
    flex:none;
    padding-top:20px;
    width:100%;
    max-width:500px;
    .certificate-popup {
        position:relative;
        z-index: 111;
        border-radius: 16px;
        border: 1px solid var(--color-border, #DDE3EE);
        background: var(--bg-level1, #F7F9FD);
    }
}
.certificate-popup {
    padding:32px;
    width:100%;
    max-width:500px;
    // margin-top:24px;
    animation: popIn 0.3s ease-in;
    &.certificate-accordion-body {
        padding:0;
        max-width:100%;

        .button-row {
            flex-wrap:wrap;

            button {white-space: nowrap;flex: 1 0 auto;}
        }
    }
    &.hide {
        display: none;
    }
    .arrow {
        position: absolute;
            bottom: 100%;
            // left: 32px;
        width:48px;
        height:29px;
        overflow:hidden;
        z-index: 112;

        &.left {
            left: 32px;
        }
        &.right {
            right: 32px;
        }
          &:before {
            content:'';
            display:block;
            position: absolute;
            left:0;
            top:0;
            width:48px;
            height: 48px;
            border-radius:4px;
            background: var(--color-border, #DDE3EE);
            // transform-origin: 0 0;
            transform:rotate(-45deg) translate(-25%, 25%);
            transform-origin: center;
          }
          &:after {
            content:'';
            display:block;
            position: absolute;
            left:0;
            top:0;
            width:48px;
            height: 48px;
            border-radius:4px;
            background:  var(--bg-level1, #F7F9FD);
            transform-origin: center;
            transform: scale(0.9) rotate(-45deg) translate(-25%, 25%);
        }
    }
    .desktop-h4 {
        margin:0 0 4px 0;
    }
    .desktop-h6 {
        margin: 24px 0 12px 0;
    }
    .chip-label {
        font-size: 14px;
        font-weight:500;
    }
    .button-row {
        margin-top:32px;
        display:flex;
        gap:12px;
        button {
            flex:1;
        }
    }
}

.remove-path-btn {
    width: 40px;
    height: 40px;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    color: #60646D;
    &:hover {
        background-color:var(--bg-level2);
        // background-color: var(--color-white);
        // color: var(--color-black);
        
    }
}


@keyframes popIn {
    0% {
        opacity:0;
        transform:translateY(-10px)
    }
    100% {
        opacity: 1;
        transform:translateY(0);
    }
}
.coachmark {
    padding:24px;
    position:fixed;
    z-index: 1111;
    background:#fefefe;
    border-radius:8px;
    max-width:345px;
    width:calc(100% - var(--outside-padding));
    filter:drop-shadow(0 4px 4px rgba(0,0,0,0.25));

    &:after {
        //arrow
        content:url('data:image/svg+xml; utf8, <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5858 1.41421C13.3668 0.633164 14.6332 0.633165 15.4142 1.41421L24.5858 10.5858C25.8457 11.8457 24.9534 14 23.1716 14H4.82843C3.04662 14 2.15428 11.8457 3.41421 10.5858L12.5858 1.41421Z" fill="%23FEFEFE"/></svg>');
        position:absolute;
        display:block;
        width:28px;
        height:14px;
    
    }

    &.top-right {
        //position
        top:calc(var(--headerHeight) + 4px);
        right:calc(var(--outside-padding) - 4px);

        &:after {
            top:-11px;
            right:26px;
        }
    }

    .coach-title {
        margin:0;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
        color:var(--color-black);
    }
    .coach-body {
        font-size: 18px;
        font-style: normal;
        font-weight:normal;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.24px;
        text-align: left;
        margin:0;
        color:var(--color-black);
    }
    .coach-actions {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top:12px;
    }
}
@import url("https://static.studychannel-prd.pearsonprd.tech/fonts/tt-commons.css");
@font-face {
    font-family: "TT Commons";
    font-display: block;
    src: url("../fonts/ttcommons-medium.woff2") format("woff2"), url("../fonts/ttcommons-medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "TT Commons";
    font-style: normal;
    font-display: block;
    src: url("../fonts/ttcommons-mediumitalic.woff2") format("woff2"), url("../fonts/ttcommons-mediumitalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "TT Commons";
    font-display: block;
    src: url("../fonts/ttcommons-demibold.woff2") format("woff2"), url("../fonts/ttcommons-demibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "TT Commons";
    font-style: normal;
    font-display: block;
    src: url("../fonts/ttcommons-demibolditalic.woff2") format("woff2"), url("../fonts/ttcommons-demibolditalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "TT Commons";
    font-display: block;
    src: url("../fonts/ttcommons-bold.woff2") format("woff2"), url("../fonts/ttcommons-bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "TT Commons";
    font-style: normal;
    font-display: block;
    src: url("../fonts/ttcommons-bolditalic.woff2") format("woff2"), url("../fonts/ttcommons-bolditalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
  }

  body {
    font-family: "TT Commons", sans-serif;
    color: var(--text-default);
  }

  .desktop-h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
  }
  .desktop-h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
  }

  //styleName: Web/Heading/Desktop/H3--Bold;
  .desktop-h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
    //styleName: Web/Heading/Desktop/H3--Regular;
    .desktop-h3-regular {
      font-size: 32px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
//styleName: Web/Heading/Mobile/H3--Bold;
.mobile-h3 {
font-size: 28px;
font-weight: 700;
line-height: 28px;
letter-spacing: 0.4000000059604645px;
text-align: left;
}

.desktop-h4, .mobile-h4 {
  font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 116.667% */
letter-spacing: 0.4px;

  &.bold {
    font-weight: 700;
  }
}

//styleName: Web/Heading/Desktop/H5--Bold;
  .desktop-h5, .mobile-h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;

    &.bold {
        font-weight: 700;
    }
  }

  .desktop-h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.4px;
    &.bold {
      font-weight:700;
    }
  }


  //styleName: Web/Heading/Mobile/H6--Regular;
  .mobile-h6 {
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.4000000059604645px;
text-align: left;
}
.body-text {
    font-size: 18px;
    font-style: normal;
    font-weight:normal;
    // font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.24px;

    &.bold {
      font-weight:bold;
    }
}
//styleName: Web/Body Text/Small--Regular;
.body-text.small {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
    &.bold {
      font-weight:bold;
    }
}
//styleName: Web/Body Text/Caption--Regular;
.body-text.caption {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
}

/* Web/Body Text/Paragraph--Link */
.body-text.link {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    color: var(--color-plum);
}

//styleName: Web/Subtitle/Subtitle-1--Medium;
.subtitle-1 {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin:0;
}

.muted {
  color: var(--text-dark-theme-muted, #989BA3);

}

.page-title {

    &.level1 {
        position: relative;
        width:fit-content;
        padding-bottom:12px;
        &:after {
            content:'';
            height:4px;
            width:100%;
            display: block;
            // margin-top:8px;
            // background:linear-gradient(135deg,#e49d2d,#ea0b7e 51.09%,#851b4a);
            background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
            position: absolute;
            bottom:0;
        }
    }
}
@import "responsive";
.page-wrapper.jobs {
    // --headerHeight: 150px;

    background:linear-gradient(to right, #05112A, #3E0953);
    color:var(--color-white);  
    // animation: fadeInBG 0.3s ease-in-out forwards;
    animation-delay: 0.1s;
    min-height: 100vh;
    .swoop {
        // transition:height 0.5s ease-in-out;
        // height:100vh;
        display: block;
        // animation: jobsSwoopEnter 0.3s ease-in-out forwards;
        max-height: 100vh;
        overflow: hidden;
    }
    .toast {
        max-width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    main a, main .link-btn {
        color: var(--text-dark-theme-link);
    }

    main {
        // padding-top:42px;
        > section:only-child {
            overflow-x:hidden;
            margin-bottom:0;
        }
        
    }
    .filter-btn {
        display:flex;
        align-items: center;
        gap:8px;
        border-radius: 4px;
        border: 1px solid var(--color-border);
        background: transparent; 
        color:var(--color-white);
        padding: 8px 8px 8px 16px;
        min-height: 44px;
        font-size:18px;
        transition:background-color 0.3s ease;

        svg {
            transition:transform 0.3s ease;
        }

        .filter-amount {
            padding:2px 8px;
            border-radius:4px;
            background-color: #FEFEFE29; //353652
            margin-left:8px;
            // font-size:0.825em;
            vertical-align: text-bottom;
        }
        &.mobile-view {
            position:relative;
            background:transparent;
            border:0;
            padding:8px 0;

            .filter-amount {
                font-size: 0.75em;
                border-radius: 24px;
                position: absolute;
                top: 0px;
                right: 0;
                transform: translateX(calc(-50% + 10px));
                border: 1px solid #3a0950;
                padding: 2px 4px;
                min-width: 20px;
                min-height: 20px;
                line-height: 14px;
                color: var(--text-default);
                background: var(--bg-level0);
                font-weight: 600;
            }
        }
        &[aria-expanded=true] {
            background:var(--color-white);
            color: var(--background-dark-theme-bg-level-2, #272D39); 
            border: 1px solid #393F4A;

            .filter-amount {
                background: #02091729; //D0D3D8

            }
            svg {
                transform:rotate(180deg);
            }
        }

        @media (prefers-color-scheme:dark) {
            // border-color:var(--color-white);

            &[aria-expanded=true] {
                color:var(--color-white);
                background-color:var(--bg-level0);
            }
        }
    }
   
    .search-header {
        display:flex;
        align-items: center;
        gap:8px;
        margin-bottom:32px;
        .link-btn, .filter-btn {
            flex:none; min-width:40px;text-align:center; 
            svg {
            margin:auto;
            }
        }
        .jobs-search-list {
            flex:1;
            .gr-input {
                -webkit-appearance:textfield;
                max-width:100%;
                background:transparent !important;
                background-color:transparent !important;
                border-color:var(--color-white);
                color:var(--color-white); 
                font-size:18px;
                transition: all 0.3s ease;
                &::placeholder {
                    color:var(--color-light-300);
                }
                // &::-internal-autofill-selected {
                //     background-color:transparent !important;
                // }
                &::-webkit-autofill,
                &::-webkit-autofill:focus {
                  transition: background-color 0s 600000s, color 0s 600000s;
                }
                &::-webkit-search-cancel-button {
                    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24px' height='24px'><path d='M12,10.5857864 L17.2928932,5.29289322 C17.6834175,4.90236893 18.3165825,4.90236893 18.7071068,5.29289322 C19.0976311,5.68341751 19.0976311,6.31658249 18.7071068,6.70710678 L13.4142136,12 L18.7071068,17.2928932 C19.0976311,17.6834175 19.0976311,18.3165825 18.7071068,18.7071068 C18.3165825,19.0976311 17.6834175,19.0976311 17.2928932,18.7071068 L12,13.4142136 L6.70710678,18.7071068 C6.31658249,19.0976311 5.68341751,19.0976311 5.29289322,18.7071068 C4.90236893,18.3165825 4.90236893,17.6834175 5.29289322,17.2928932 L10.5857864,12 L5.29289322,6.70710678 C4.90236893,6.31658249 4.90236893,5.68341751 5.29289322,5.29289322 C5.68341751,4.90236893 6.31658249,4.90236893 6.70710678,5.29289322 L12,10.5857864 Z' fill-opacity='0.8' fill='%23fefefe' /></svg>");
                    appearance:none;
                    -webkit-appearance: none;
                    // background-color:var(--color-white); 
                    color:var(--color-white); 
                }

                // &:hover {
                //     background-color:#fefefe33;
                // }
                &:hover, &:focus-within, &:focus {
                    background-color:var(--bg-level0)!important;
                    color:var(--text-muted);

                    &::placeholder {
                        color: var(--text-muted);
                    }
                   + .search-icon, ~ .clear-icon {
                        color:var(--text-muted); 
                    }
                }
            }
            .search-icon, .clear-icon {
                color:var(--color-white); 
            }
        }

        @media (min-width: ($breakpointS + 1)) {
            gap:24px;  
        }
    }
    .filter-controls {
        display:flex;
        align-items: center;
        flex-wrap:wrap;
        gap:24px;
        margin-bottom:32px;
        .menu {
            min-width:300px;
            
            .recent-searches {
                margin-top:12px;
                +.recent-searches {
                    // margin-top:24px;
                }
            }
            .group-label {
                font-size:1.125rem !important;
                margin-top:0;
                margin-bottom:4px;
            }
        }
        .dropdown .menu label {
            font-size:1.125rem;
            line-height:1.3;
        }
    }
   
    @media (min-width:($breakpointS + 1)) {
        // height:100vh;
        // overflow:hidden;
        main {
            // padding-top:82px;
            overflow: hidden;
            section {
                display: flex;
                flex-direction:column;
                height:calc(100vh - var(--headerHeight) - 48px - 24px); //adjust when header tabs change
                overflow:hidden;
                .job-search-results {
                    flex:1;
                    overflow:hidden;
                }
            }
        }
    }
}
.job-search-results {
    padding:0;
    flex:0 0 auto;

    width:200%;

    .job-search-sidebar {
        overflow-y: hidden;
        flex:1 1 auto;
        // scrollbar-gutter: stable;
        .card {height:auto;margin-bottom:24px;
            // &.no-results {margin-bottom: 16px;}
        }
        .job-search-sidebar-results {
            overflow-y: auto;
            flex:1 1 auto;
            scrollbar-gutter: stable;
        }
    }
    .job-search-sidebar-header {
        margin-bottom: 32px;

        .search-results-title,.search-results-description {
            font-size: 18px;            
            line-height: 24px;
            margin:0;
            // margin-bottom:16px;
        }
        .search-results-description {
            max-height: calc(24px * 3);
            // overflow:hidden;
            .search-text {
                // text-overflow: ellipsis;
                // overflow: hidden;

                &:before {
                    content: "“"
                }
                &:after {
                    content: "”"
                }
                + span {
                   
                }
            }
            + .search-results-description {
                margin-top:16px;
            }

            
        }
        &.search-results {
            margin-bottom:24px;
            > div {
                display:flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom:16px;
                .icon-btn {
                    color:var(--color-white);
                    width:32px;
                    height:32px;
                    margin-right:-4px;
                    margin-left:-4px;
                    &:hover {
                        background-color: rgba(255,255,255,0.3);
                    }
                }
            }
            .search-results-title {
                font-weight: 400;
                margin:0;
            }
            .job-search-alert-actions {
                display:flex;
                align-items: center;
                // justify-content: space-between;
                gap:12px;
                .toggle {
                    flex:none;
                    display: flex;
                    align-items: center;
                    gap:8px;
                    font-size:18px;
                    button {
                        border-radius: 100px;
                        border: 2px solid var(--bg-level0, #FEFEFE);
                        background: var(--text-default, #020917);
                        color:var(--text-default, #020917);
                        min-width:52px;
                        width:52px;

                    }
                }
            }
        }

        
        
        p {
            margin:0;
        }
    }
    .job-search-result {
        
        flex:1 1 auto;
    }
    
    .job-posting {
        --postingPadding: 20px;
        background:var(--bg-level0);
        color:var(--text-default);
        border-radius:12px;
        padding:var( --postingPadding);
        position:relative;
        overflow-y:auto;
        max-height:100%;

        &.no-result {
            // -webkit-box-flex: 1;
            flex-grow: 1;
            height: 100%;
            // border-radius: 8px;
            background: url(../../assets/images/nora.png) center bottom / contain no-repeat rgb(18, 33, 66);
        }

        .job-posting-actions {
            // position: absolute;
            // right:var( --postingPadding);
            // top:var( --postingPadding);
            display: flex;
            align-items: center;
            gap:16px;
            flex:none;
            margin-left:auto;

            .icon-btn {
                color:var(--text-default);
            }
        }
        .company-and-apply {
            display:flex;
            flex-direction:row-reverse;
            flex-wrap:wrap;
            align-items: flex-start;
            justify-content: flex-end;
            gap:16px;
        }
        .company-name {
            display: flex;
            align-items: center;
            gap:10px;
            .job-company {
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 120% */
                letter-spacing: 0.4px;
                margin:0;
            }
        }
        .avatar {
            // border: 2px solid var(--color-border);
            background-color: var(--bg-level2);
            color:var(--text-muted);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            width: 41px;
            height: 41px;
            flex: none;
            border-radius: 50%;
            overflow:hidden;
            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                vertical-align: middle;
                text-align: center;
                width: 100%;
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            img {
                width:100%;
            }
        }
        .job-title {
            margin:12px 0;
        }
        .job-posting-header {
            margin-bottom:32px;
        }
        .job-details {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 122.222% */
            margin:0;
            color:var(--text-muted);

            span {
                &:not(:last-child):after {
                    content: '•';
                    padding: 0 4px;
                }
            }
        }
        .job-posting-body {
            font-size:1.125rem;
                line-height:1.5rem;
            h4 {
                font-size:1.5rem;
                line-height:1.1667em;
                font-weight:700;
                margin-bottom:32px;

            }
            p {
                font-size:1.125rem;
                line-height:1.5rem;
            }
        }
    }
    @media (max-width: $breakpointS) {
        display:flex;
        align-items: flex-start;
        gap:var(--outside-padding);
        transition:transform 0.3s ease-in-out;
        transform: translateX(0);
        padding-bottom:40px;
        .back-to-results {
            margin-bottom:24px;
        }
        &.sidebar-only {
            transform: translateX(0);
            .job-search-result {
                animation: 0.1s hide forwards;
                animation-delay:0.3s;
            }
        }
        &.job-posting-only {
            transform: translateX(calc(-50% - var(--outside-padding)));
            .job-search-sidebar {
                animation: 0.1s hide forwards;
                animation-delay:0.3s;
                // animation-direction: forwards;
            }
        }
        .job-search-sidebar {
            width:50%;
            flex: 0 0 50%;
        }
        .job-search-result {
            width:50%;
            flex: 0 0 50%;
        }
        .card.job-result-card {
            &.active {
                border: 1px solid #122142;
                // border: 1px solid var(--Border-darkTheme--border, #393F4A);
                background: #122142;
                color: var(--color-white) !important;
                .bookmark-btn {
                    color: var(--color-white) !important;
                }
                .chip-label {
                    background: #020917B8;
                color:var(--color-white);
                }
            }
        }
    }
    @media (min-width:($breakpointS + 1)) {
        display:flex;
        gap:20px;
        // overflow: hidden;
        width:auto;
        .job-search-sidebar {
            
            max-width:360px;
            min-width:360px;
            width:100%;
            
            display: flex;
            flex-direction: column;
            // .card {height:auto;margin-bottom:24px;}
            .job-search-sidebar-header {
                padding-right:4px;
            }
            .job-search-sidebar-results {
                padding-right:4px;
                width:100%;
                flex:1;
                overflow-y: auto;
                height:100%;
            }
        }
        .job-search-result {
            // max-height:calc(100% - 16px);
            max-height:calc(100%);
            overflow:hidden;
            .job-posting {
                --postingPadding:56px;
                // padding:56px;
                overflow-y:auto;
                max-height:100%;
            }
           
        }
    }
}
.card.job-result-card {
    border: 1px solid #122142;
    background: #122142;
    color: var(--color-white) !important;
    // padding: 12px 20px;
    .bookmark-btn {
        color: var(--color-white) !important;
    }
    &.active {
        border: 1px solid var(--color-border);
        background: var(--bg-level1, #F7F9FD);
        color: var(--text-default) !important;
        .bookmark-btn {
            color: var(--text-default) !important;
        }
    }
    &.no-results {
        // background:transparent;
        border:none;
        font-size:1.125rem;
        flex:1;
        margin-bottom:0;
        h3 {text-align:center;margin: 10vh 0 10vh 0;}
        p {margin:1em 0;}
        button {
            margin:auto;
            font-size: inherit;
        }
        
        &.more-recommended {
            background:transparent;
            border:none;
            font-size:1.125rem;
            text-align:center;
            flex:none;
            // padding: var(--card-padding-mini);
            margin-top:-8px;
            h3 {
                margin: 0 0 12px 0;
            }
            p {margin:1em 0;}
            button {
                margin:auto;
            }
        }
        
    }
    //dont show bookmark icon on hover for >807
    //show bookmark if saved
    @media (min-width:$breakpointS) {
        .bookmark-btn {display: none;}
        &.saved {
            .bookmark-btn {display: flex;}
        }
        &.no-results {
            margin-bottom:0;
            h3 {text-align:center;margin: 10vh 0 1em 0;}
        }
    }
}

.alerts-list ul {
    margin:0;
    padding:0;
    list-style-type: none;
}
.no-alerts {
    text-align:center;

    .illustration {
        max-width:200px;
        margin:0 auto 24px auto;
        img {
            width:100%;
            max-width: 100%;
        }
    }
}
.job-alert-item {
    list-style-type: none;
    display:flex;
    align-items: flex-start;
    gap:16px;
    text-align:left;
    width:100%;
    &.disabled {
        .alert-info {opacity:0.4;}
    }
    &:not(:last-child) {
        border-bottom:1px solid var(--color-border);
        padding-bottom:24px;
        margin-bottom:24px;
    }
    .alert-info {
        flex:1;
    }
    .job-alert-title {
        text-align:left;
        margin: 0 0 4px 0;
    }
    .alert-meta {
        margin: 0;
        span:not(:last-of-type) {
            &:after {
                content: "•";
                margin: 0 5px;
            }
        }
    }
    .alert-frequency {
        border:0;
        padding:0;
        margin:0;
        
        
        legend {
            // float:left;
            font-weight:500;
            margin-bottom:12px;
            p {
                font-weight:500;
                font-size:18px;
                line-height:18px;
            }
        }

        .radio-options {
            // display:flex;
            // align-items: center;
            gap:16px;
            label, span {
                font-size:18px;
                line-height:18px;
            }
            .gr-radio {
                // margin-bottom:0;
                // min-height:24px;
                &:last-of-type {
                    margin-bottom:0;
                }
            }
        }
    }
}

.filter-drawer {
    position:fixed;
    width:100vw;
    height:100vh;
    height:100dvh;
    left:0;
    top:0;
    display:flex;
    flex-direction:column;
    background-color: var(--bg-level0);
    color:var(--text-default);
    z-index: 1112;
    animation:slideIn 0.3s ease-in-out forwards;
    font-size: 1rem;

    .modal-header {
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        padding:24px;
        margin:0;
        h1,h2 {
            margin:0;
        }
    }
    .modal-body {
        padding:24px;
        flex:1;
        overflow-y:auto;

        p {
            font-size: 18px;
        }

        .gr-radio {
            span {
                font-size: 18px;
            }
        }
        .accordion-btn {
            padding: 8px 0;
        }
        .accordion-body {
            padding: 0 4px 32px 4px;

            .gr-radio:last-of-type {margin-bottom:0;}
        }
        .recent-searches {
            margin-top:12px;
            +.recent-searches {
                // margin-top:24px;
            }
        }
        .group-label {
            font-size:1.125rem !important;
            margin-top:0;
            margin-bottom:4px;
        }
    }
    .modal-footer {
        display:flex;
        align-items: center;
        gap:12px;
        padding:24px;
        background:var(--bg-level0);
        margin-top:0;
    }
}

/* starting ENTER animation */
.filterDrawer-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: -120vw;
    bottom:0;
    opacity: 0;
    z-index: 11;
    // transform: translateY(0%) scale(0.01);

    * {
        opacity:0;
        transition: opacity 1000ms ease-in-out;
        transition-delay: 200ms;
    }
    &.with-delay {
        transition-delay: 800ms;
    }
  }
  
  /* ending ENTER animation */
  .filterDrawer-enter-active {
    opacity: 1;
    right:0;
    // transform: translateY(0%) scale(1);
    transition: opacity 1000ms ease-in-out, right 1000ms ease-in-out;
    * {
        opacity:1;
    }
  }
  
  /* starting EXIT animation */
  .filterDrawer-exit {
    opacity: 1;
    // transform: scale(1.01);
  }
  
  /* ending EXIT animation */
  .filterDrawer-exit-active {
    opacity: 0;
    right: -120vw;
    // transform: scale(4);
    // transition: opacity 1000ms ease-in-out;
    transition: opacity 1000ms ease-in-out, right 1000ms ease-in-out;
  }

  @keyframes slideIn {
    0% {
        transform:translateX(120vw);
    }
    100% {
        transform:translateX(0)
    }
  }

@keyframes jobsSwoopEnter {
    0% {
        height:300px;
        opacity: 1;
    }
    100% {
        height:150vh;
        opacity: 1;
    }
    
}
@keyframes fadeInBG {
    0% {
        background:transparent;
    }
    100% {
        background:linear-gradient(to right, #05112A, #3E0953);
    }
}

@keyframes hide {
    0% {
        visibility: visible;
    } 
    100% {
        visibility: hidden;
        max-height:0;
        overflow:hidden;
    }
}
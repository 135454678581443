.career-switcher.tab-container {
    display:flex;
    flex-direction: column;
    max-width:956px;
    margin:auto;
    // margin-top: 56px;
    .tab-list-container {
        position: relative;
        margin-bottom:0;
    
        .single-career.image {
            border-radius: calc(var(--card-corner-default));
            height:100%;
            overflow:hidden;
            margin-bottom:28px;
           img {
                width: 100%;
                height: 100%;
                object-fit: cover;
           }
        }
    }
    .tab-list {
        padding:0;
        margin:0;
        overflow:hidden;
        .tab-item {
            display:block;
            font-weight:400;
            margin-bottom:8px;

            &:last-of-type {margin-bottom:0}
            button {
                width:100%;
                font-size:20px;
                font-weight:700;
                text-align:left;
                padding: 0px;
                // background: #FEFEFE;
                background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 1.64%, rgba(255, 255, 255, 0.05) 1.65%, rgba(0, 133, 255, 0.00) 120%);
                // border: 1px solid var(--color-border);
                border:0;
                border-radius: 8px;
                margin:0;
                color: #fefefe;
                position: relative;
                max-height:60px;
                transition: max-height 0.2s ease-in-out;
                transition: none;
                overflow:hidden;
                
                .image {
                    opacity: 0;
                    height:0;
                    transition: none;
                    // transition: opacity 0.2s ease-in-out;
                 }

                strong {
                    color: var(--text-default);
                }
                .tab-label {
                    padding:6px 28px;
                    white-space:normal;
                    // white-space:nowrap;
                    overflow:hidden;
                    // text-overflow:ellipsis;
                    max-width:100%;
                    min-height: 56px;
                    text-align: left;
                    width: 100%;
                    display:flex;
                    align-items: center;
                    
                    
                    span {
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                                line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow:hidden;
                    }
                
                }
                &:after {
                    content:'';
                    width: 6px;
                    height:calc(100% + 2px);
                    display:block;
                    position:absolute;
                    left:-1px;
                    top:-1px;
                    background: linear-gradient(137.32deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%);
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    opacity:0;
                    transition:opacity 0.35s ease-in-out;
                    z-index: 1;
                }
                &[aria-selected=true] {
                    max-height: calc(170px + 56px);
                    transition: max-height 0.3s ease-in-out;
                    // background: #FEFEFE;
                    // font-weight:400;
                    .image {opacity: 1;transition: opacity 0.4s ease-in-out;transition-delay: 0.2s;}
                    &:after {
                        // content:'';
                        // width: 6px;
                        // height:calc(100% + 2px);
                        // display:block;
                        // position:absolute;
                        // left:-1px;
                        // top:-1px;
                        // background: linear-gradient(137.32deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%);
                        // border-top-left-radius: calc(0.5 * var(--card-corner-default));
                        // border-bottom-left-radius: calc(0.5 * var(--card-corner-default));
                        opacity:1;
                    }
                }
                &:hover {
                    &:after {
                        opacity:1;
                    }
                }
            }
           
            .image {
                // border-top-right-radius: calc(var(--card-corner-default));
                border-bottom-right-radius: 8px;
                overflow:hidden;
                max-height:170px;
                // margin-bottom:-1px;
                margin-right:-1px;
                margin-left:5px;
                // margin-bottom:-1px;
                width:calc(100% - 4px);
                // transform: translateY(-1px);
                img {
                    width:100%;
                    height:100%;
                    object-fit: cover;
                }
            }
            span {
                display: block;
                
                &.number {
                    font-size: 32px;
                    margin-top:16px;
                    color:var(--color-black);
                }
                svg {
                    vertical-align: middle;
                    fill:var(--color-plum);
                }
            }
        }
    }

    
    
    @media (max-width:806px) {
        .chart-container {
            background: var(--color-white);
            border: 1px solid var(--color-border);
            border-radius: var(--card-corner-default);
            padding:16px;
            margin-bottom:16px;
        }
    }
    @media (min-width:807px) {
        flex-direction: row;
        gap:32px;
        .tab-content {
            flex:0 1 66%;
            overflow:hidden;
        }
        .tab-list-container {
            flex:1 0 33%;
            display:flex;
            flex-direction:column;
            min-width:200px;
            .tab-list {
                display:flex;
                flex-direction: column;
                flex:1;
                // margin-bottom:28px;
                .tab-item.active {
                    flex:1;
                    button[aria-selected=true] {
                        max-height:100%;
                        height:100%;
                        display:flex;
                        flex-direction: column;
                        
                        .image {
                            flex:1 1 173px;
                            max-height:100%;
                        }
                    }
                }
            }
            .button {
                 // width: max-content;
                margin: 0 auto;
                text-align: center;
            }
        }
    }
}
.card.career-fast-facts {
    background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 1.64%, rgba(255, 255, 255, 0.05) 1.65%, rgba(0, 133, 255, 0.00) 120%);
    color:#fefefe;
    border:0;
    border-radius: 8px;
    padding:32px;
    height:100%;
    .facts-row {
        > div {
            flex:1;
        }
    }

    .card-footer {
        margin-top:40px;
        margin-bottom:8px;
        a {
            color:var(--text-dark-theme-link);
            font-size:18px;
        }
    }
}
.career-accordion-btn {
    display: block;
    width:100%;
    font-size:20px;
    font-weight:700;
    text-align:left;
    padding: 0px;
    background:transparent;
    border:none;
    // background: #F7F9FD;
    // border: 1px solid var(--color-border);
    // border-radius: calc(0.5 * var(--card-corner-default));
    margin:0;
    color: #fefefe;
    position: relative;
    max-height:56px;
    transition: max-height 0.3s ease-in-out;
    overflow:hidden;

    +.career-info-box {
        .card.career-fast-facts {
            background:transparent;
            border-color:transparent;
            h4 {display: none;}
            .fast-facts-title {
                padding-top:0;
            }
            .facts-row {
                gap:24px;
                margin:40px 0;
            }
            .card-footer {
                margin-top:0;
            }
        }
    }

    .image {opacity: 0;transition: opacity 0.2s ease-in-out; transition-delay: 0.2s;}

    strong {
        color: var(--text-default);
    }
    .tab-label {
        display:flex;
        align-items: center;
        padding:6px 16px;
        height:56px;
    //     white-space:nowrap;
    //     overflow:hidden;
    //     text-overflow:ellipsis;
        span {
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow:hidden;
        }
    }
    
    &[aria-expanded=true], .open {
        max-height: calc(170px + 56px);
        // background: #FEFEFE;
        // font-weight:400;
        .image {opacity: 1;}
        &:after {
            // content:'';
            // width: 6px;
            // height:calc(100% + 2px);
            // display:block;
            // position:absolute;
            // left:-1px;
            // top:-1px;
            // background: linear-gradient(137.32deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%);
            // border-top-left-radius: calc(0.5 * var(--card-corner-default));
            // border-bottom-left-radius: calc(0.5 * var(--card-corner-default));
            opacity:1;
        }
    }
    


.image {
    // border-top-right-radius: calc(1* var(--card-corner-default));
    // border-bottom-right-radius: calc(1 * var(--card-corner-default));
    overflow:hidden;
    max-height:170px;
    // margin-bottom:-1px;
    margin-right:-1px;
    margin-left:5px;
    width:calc(100% - 4px);
    transform: translateY(-1px);
    img {
        width:100%;
    }
}
span {
    display: block;
    
    &.number {
        font-size: 32px;
        margin-top:16px;
        color:var(--color-black);
    }
    svg {
        vertical-align: middle;
        fill:var(--color-plum);
    }
}

}
.career-group {
    background: linear-gradient(94deg, rgba(255, 255, 255, 0.06) 1.64%, rgba(255, 255, 255, 0.06) 1.65%, rgba(0, 133, 255, 0.00) 141.29%);
    color: #FEFEFE;
    // border: 1px solid var(--color-border);
    border-radius: 8px;
    overflow:hidden;
    margin-bottom:8px;
    position: relative;
    &:last-of-type {margin-bottom:20px}
    &.single {
        margin-bottom:20px;
        .image {
            opacity:1;
            width:100%;
            border-radius: 8px;
            margin:0;

            @media (max-width: ($breakpointS - 1)) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        &:after {
            content:none;
        }
    }
    + .button {
        // width: max-content;
        margin: 0 auto;
        text-align: center;
    }

    &:after {
        content:'';
        width: 6px;
        height:calc(100% + 2px);
        display:block;
        position:absolute;
        left:-1px;
        top:-1px;
        background: linear-gradient(137.32deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        opacity:0;
        transition:opacity 0.35s ease-in-out;
        z-index: 1;
    }
    &:hover, &.open {
        &:after {
            opacity:1;
        }
    }
    .career-accordion-btn[aria-expanded=false] {
        
    }
}
.career-info-box {
    // display:none
    height: 0;
    opacity:0;
    overflow:hidden;

    &.open {
        height: 100%;
    opacity: 1;
    }
}
.career-accordion-enter {
    height: 0;
    opacity: 0;
  }
  
  .career-accordion-appear.career-accordion-appear-active, .career-accordion-enter.career-accordion-enter-active {
    height: 100%;
    opacity: 1;
    transition: height 500ms ease,opacity 500ms ease;
  }
  .career-accordion-enter-done {
    height: 100%;
    opacity: 1;
    transition: height 500ms ease,opacity 500ms ease;
  }
  
  .career-accordion-leave {
    height: 100%;
    opacity: 1;
  }
  
  .career-accordion-leave.career-accordion-leave-active {
    height: 0;
    opacity: 0;
    transition: height 300ms ease, opacity 500ms ease;
  }
  .card.career-fast-facts .salary-chart {
    text-align:center;
    .chart-title {
        font-size:20px;
        font-weight: 600;
        line-height: 24px;
        margin:0;
    }
    h6 {text-align:center;margin:0 auto 12px auto;}
    .salary-chart-render {
        position: relative;
        padding: 0px 0 24px 0;
        font-size:16px;
        line-height:24px;
        // margin-top:16px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        img {
            display: block;
            margin:auto;
            max-width:250px;
            width:100%;
        }
        .bottom-10 {
            position: absolute;
            left:0;
            bottom:0;
            // transform: translateX(-50%);
        }
        .top-10 {
            position: absolute;
            right:0;
            bottom:0;
            text-align: right;
            // transform: translateX(50%);
        }
        .average {
            position: absolute;
            text-align:center;
            left:50%;
            top:-4px;
            transform: translateX(-50%);
            font-weight:700;
            font-size:20px;
        line-height:24px;
        }
    }

    .salary-caption {
        font-size:18px;
        line-height: 24px;
        strong {display: block;}
    }

}
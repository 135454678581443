.modal-overlay{
    background:rgba(0,0,0,.45);
    height:100%;left:0;position:fixed;top:0;width:100%;z-index:1132;
    &.hide {
        display: none;
    }
}

.modal{
    background-color:var(--bg-level1);
    border-radius: 16px;
    margin:20px auto;
    padding:32px;
    position:absolute;
    left:50%;
    top:50%;
    z-index:11;
    transform:translate(-50%, -50%);
    width:100%;
    max-height: calc(100vh - 40px);
    overflow-y: auto; //for now

    &.light-bg {
        background-color: var(--bg-level0, #fefefe);
    }
    .close-btn {
        position:absolute;
        right:24px;
        top:24px;
        color:var(--text-default);
    }
    h1, h2, h3 {
        text-align:center;
        margin:0 0 12px 0;
    }
    p, li {
        margin:0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .modal-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px
    }
    .modal-body {
        overflow-y: auto;
    }
    .course-description {
        padding-bottom:32px;
        border-bottom:1px solid var(--color-border);
        margin-bottom:32px;
        .avatar {
            flex:none;
            width:60px;
            height:60px;
            img {
                width:100%;
            }
        }
    }
    .forage-info {
        display:flex;
        flex-direction:column;
        gap:24px;

        .avatar {
            flex:none;
            width:40px;
            img {
                width:100%;
            }
        }
    }
    .forage-benefits {
        ul {
            list-style:none;
            margin:0;
            padding:0;

            li {
                display:flex;
                align-items: flex-start;
                gap:12px;
                margin-bottom:4px;
                .icon {
                    flex:none;
                    width:24px;
                    img {
                        width:100%;
                    }
                }
            }
        }

    }
    .desc-info {
        display:flex;
        flex-direction:row;
        align-items: flex-start;
        gap:12px;
    }

    .form-group + .form-group {
        margin-top:var(--vertical-space);
    }

    &.pathway-modal {
        max-width: calc(100vw - 128px);
        margin: 0;
        max-height:calc(100vh - 128px);
        overflow:hidden;
        display:flex;
        flex-direction: column;
        padding:0;
         h1, h2, h3 {
            text-align:left;
         }

        .modal-header {
            border-bottom:2px solid var(--color-border);
            margin-bottom:0;
            padding:32px;
            h1 {margin-bottom:32px;}
            .autocomplete-container {
                margin-bottom:32px;
            }
        }

        .modal-body {
            overflow-y: auto;
            padding:32px;
            background:var(--bg-level0)
        }
    }
}
.modal-header {
    
    margin-bottom:48px;
    &.border-bottom {
        padding-bottom:24px;
        border-bottom:1px solid var(--color-border);
        margin-bottom:16px;
        h1 {margin-bottom:0;}
    }
    h1 {
        &.text-left {
            text-align:left;
        }
    }
    
}

.modal-footer {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-top:48px;
}
.insights-modal {
    h1, h2 {
        text-align:left;
    }
    h2 {
        margin-top:1.5rem;
        margin-bottom:0.25rem;
    }
    &.bar-modal {
        h2 {
            margin-top:0;
        }
        .group {
            margin-top:1.5rem;
            padding-top:10px; //for triangle
            .bar-container {
                // margin-top:8px;
            }
        }
    }
}
@media (min-width:768px){
    .modal{
        max-width:600px;
        &.forage-modal {
            max-width:774px;
        }
        &.pathway-modal {
            max-width: calc(100vw - 128px);
            margin: 0;
            max-height:calc(100vh - 128px);
            overflow:hidden;
            display:flex;
            flex-direction: column;
            padding:0;
             h1, h2, h3 {
                text-align:left;
             }

            .modal-header {
                border-bottom:2px solid var(--color-border);
                margin-bottom:0;
                padding:32px;
                h1 {margin-bottom:32px;}
                .autocomplete-container {
                    margin-bottom:32px;
                }
            }

            .modal-body {
                overflow-y: auto;
                padding:32px;
                background:var(--bg-level0)
            }
        }
        .forage-info {
            display:flex;
            flex-direction:row;
            gap:32px;
            > div {
                flex:1;
            }
        }
    }
    
}
@media (max-width:768px){
    .modal{max-width:440px;padding:24px}
}
@media (max-width:480px){
    .modal{
        width: calc(100% - 40px);
        // margin:20px;
    }
}
.header-wrapper {
    display:flex;
    align-items: center;
}
.page-header {
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 0px var(--outside-padding);
    gap:24px;
    min-height:var(--headerHeight);
    // margin-bottom: var(--vertical-space);

    .logo {
        a {color: var(--color-white);}
    }

    .header-actions {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:16px;

        @media (min-width: 508px) {
            .button {
                white-space: nowrap;
            }
        }
        
        .button.outline {
            color: var(--swatch-light-400, #D2D7E2);
        }
    }
    .search-input {
        max-width:720px;
        width:100%;
        color: var(--color-white);
        input {
            border-color:var(--color-white);
            color: var(--color-white);
            background-color: transparent;
            max-width:720px;
            width:100%;
            &::placeholder {
                color: #F7F9FD;
            }
        }
        .search-icon {
            color: var(--color-white); 
        }
    }
    .account-menu-container {
        color:var(--color-white);
        .avatar {
            border-color:var(--color-white);
        }
        .icon-18 {
            color:inherit;
        }
    }
}
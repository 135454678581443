.email-wrapper {
    width:100%;
    height:100vh;
    overflow:hidden;
    display:flex;
    flex-direction: column;
    background:white;
    font-family: Arial, Helvetica, sans-serif;
    .material-symbols-rounded, .material-symbols-outlined {
        color:#6a7070;

        
    }
    .flex-row {
        display:flex;
        align-items: center;
        gap:10px;
        justify-content: flex-start;
    }
    .icon {
        &.red .material-symbols-rounded {
            color:#EA4335;
        }
        &.blue .material-symbols-rounded {
            color:#1B73E8;
        }
        &.green .material-symbols-rounded {
            color:#1F8E3E;
        }
        &.yellow .material-symbols-rounded {
            color: #F7CB58;
        }
    }
.compose-btn {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: var(--dt-surface-bright,#fff);
    border-radius: var(--dt-corner-fab,6.25rem);
    border-width: 0;
    box-shadow: var(--dt-shadow-elevation-1,0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15));
    box-sizing: border-box;
    color: var(--dt-on-surface,rgb(60,64,67));
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    fill: var(--dt-on-surface,rgb(60,64,67));
    gap: 0.75rem;
    grid-template-columns: auto 1fr;
    height: 2.75rem;
    font-size:1.125rem;
    min-width: 6.25rem;
    // padding: 1.125rem 1.25rem 1.125rem 1rem;
    padding: 0 1rem;
    place-content: center start;
    -webkit-transition: box-shadow .08s linear;
    transition: box-shadow .08s linear;
    -webkit-user-select: none;
    user-select: none;
    width:fit-content;
    margin: 20px 10px;
    svg {
        fill:currentColor;
    }
}
    .email-header {
        display: flex;
        width: 100%;
        align-items: center;
        // justify-content: space-between;
        padding:8px 20px;
        border-bottom:1px solid var(--color-border);
        > div:first-child {
            width:180px;
            flex:none;
        }
        .search {
            flex:1;
            background-color: #F5F5F5;
            color:#6a7070;
            border-radius:8px;
            min-height:40px;
            display: flex;
            align-items: center;
            gap:10px;
            position: relative;
            padding: 10px;
            // max-width:600px;
            .placeholder {flex:1;}

            +.flex-row {
                margin-left:80px;
            }
        }
    }
    .email-wrapper-body {
        display:flex;
        flex:1;
        overflow: hidden;


        .email-tools {
            display: flex;
        width: 100%;
        align-items: center;
        padding:15px 0;
        border-bottom:1px solid var(--color-border);
            .group {
                display: flex;
                align-items: center;
                gap:15px;
               
                padding: 0 15px;
                + .group {
                   border-left:1px solid var(--color-border);  
                }
            }
            .pagination {
                display: flex;
                align-items: center;
                gap:15px;
                margin-left:auto;
                color: #6a7070;
                font-size:0.825rem;
                padding: 0 15px;
                span.material-symbols-rounded:not(:last-child) {
                    opacity: 0.5;
                }
            }
            .material-symbols-rounded, .material-symbols-outlined {
                font-size:20px;
            }
        }
        .avatar {
            flex:none;
            width:40px;
            height:40px;
            border-radius: 50%;
            background-image: url("https://lh3.googleusercontent.com/a/default-user=s40-p");
            // margin: 0 0px 0 16px;
        }
        .email-wrapper-body-sidebar {
            width:200px;
            flex:none;
            overflow-y:hidden;
            border-right:1px solid var(--color-border);
            display:flex;
            flex-direction: column;
            .nav-list {
                flex:1;
                overflow-y: auto;;
            }
            .meet {
                border-top:1px solid var(--color-border);
                padding: 20px 0;
            }
        }
        .email-wrapper-body-message {
            flex:1;
            overflow-y:auto;
            position: relative;
           
            .email-tools {position:sticky;top:0;background:white;z-index: 11;}
        }
        @media (max-width:807px) {
            .email-wrapper-body-sidebar {display: none;}
        }
        .email-message-header {
            padding-bottom:20px;
            .flex-row {
                gap:20px;
            }
            h1 {
                margin:0;
                font-size:1.5rem;
                .inbox-label {
                    font-size:0.75rem;
                    font-weight:normal;
                    color:#666;
                    background-color: #ddd;
                    padding:4px 8px;
                    border-radius:4px;
                    vertical-align: top;
                    span {margin-left:4px;}
                }
                .icon {vertical-align: bottom;}
            }
        }
        .message-details {
            flex:1; 
            p {
                margin:0;
                color:#5e5e5e;
                font-size:0.75rem;
                line-height:20px;
                strong {
                    color: var(--text-default);
                }
                .ajz {
                    background-image: url("https://www.gstatic.com/images/icons/material/system_gm/1x/arrow_drop_down_black_20dp.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 20px;
                    border: none;
                    height: 20px;
                    margin: 0 0 0 auto;
                    position: relative;
                    right: 0;
                    top: 0;
                    width: 20px;
                    display: inline-block;
                    opacity: .71;
                    padding: 0;
                    vertical-align: middle;
                }
            
            
            }
        }
    }
}
.nav-item {
    padding: 2px 10px 2px 20px;
    margin-right:10px;
    margin-bottom:2px;
    display:flex;
    align-items: center;
    font-size:0.9em;
    line-height:20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    .material-symbols-rounded {
        font-size:20px;
        line-height:20px;
    }
    .icon {
        width:40px;
    }
    &.active {
        background-color: #FCE8E7;
        color:#D0201A;
        .material-symbols-rounded {color:#D0201A}
    }
}

.gmail-logo {
    display:block;
    width:109px;
    height:40px;
    background-image: url("https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_2x_r5.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.credly-badge-email {
    font-family:"TT Commons", sans-serif;
    background:#F7F9FD;
    position:relative;
    overflow: hidden;
    min-height:80vh;
    // z-index: 1;
    * {z-index: 1;}
    &:after {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        bottom:0;
        right:0;
        left:0;
        background: linear-gradient(273deg, rgba(155, 161, 173, 0.08) -5.26%, rgba(232, 238, 250, 0.08) 39.67%, rgba(232, 238, 250, 0.08) 55.59%, rgba(155, 161, 173, 0.08) 103.93%);
        transform: translateX(-75%) skewX(-30deg) ;
        transform-origin:bottom left;
        z-index: 0;
    }
    .email-top {
        background-image: url("../images/email-hero.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        min-height:360px;
        color:var(--color-white);
        position:relative;
        padding:60px 40px;

        .credly-email-header {
            position:absolute;
            top:0;
            right:0;
            left:0;
            display:flex;
            justify-content: space-between;
            
            padding:40px;
            a {color:var(--color-white);font-weight:normal;}
        }
        .text {
            text-align:center;
            .meta {
                opacity:0.68;
                font-size:0.825em;
            }
            h1 {
                margin-top:40px;
                margin-bottom:10px;
            }
            .subtitle-1 {text-align: center;}
            
        }
        .badge {
            max-width:300px;
            margin:auto;
            // transform:translateY(80px);
            margin-top:40px;
            margin-bottom:-140px;
            img {
                width:100%;
                &.comptia {
                    // transform:scale(1.2);
                }
            }
        }
    }
    .credly-email-body {
        padding: 60px 40px 40px;
        max-width:740px;
        margin:auto;
        .desktop-h3 {text-align: center;}
        .desktop-h5 {
            margin: 0 0 0.5em 0;
            font-weight:600;
        }
        .learn-more {
            margin-top:80px;

            p {margin:0;}
            .chip-list {
                gap:0;
                margin-top:10px;

                .chip-btn {
                    font-size:14px;
                    margin:2px;
                }
            }

            .flex-row {
                gap:24px;
                margin:40px auto;
                max-width:900px;

            }
            .one-col {
                flex:1;
            }
            .three-col {
                flex:3;
            }
        }
        .fine-print {
            margin-top:80px;
            padding-top:20px;
            border-top:1px solid var(--color-border);
            text-align: center;
            p {
                font-size:0.75em;
                color:var(--text-muted);
                margin: 4px 15%;
                // &.no-margin {margin:0;}
                &:first-child {
                    font-size:0.825em;
                    margin:0 0 1.5em 0;
                }
            }
        }
        .button-row {
            display:flex;
            align-items: center;
            justify-content: center;
            gap:12px;
            text-align:center;
            margin-top:40px;
            a {display: block;}
        }
    }
}
.badge-details {
    .page-nav {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        gap:4px;
        // margin-bottom:-8px;
        h1 {
            margin-bottom:0;
        }
        .icon-btn {
            color:var(--color-black);
        }
    }
}
.badge-intro {
    // border: 1px solid var(--color-border);
    // border-radius: var(--card-corner-default);
    // padding: calc(0.5 * var(--outside-padding)) var(--outside-padding);
    h2 {margin:0}
    p {margin:0}
}
.badge-information {
    display:flex;
    align-items: center;
    gap:40px;

    .badge.avatar {
        background-color:transparent !important;
        width:186px;
        height:auto;
        border-radius:0;
        flex:none;
        img {width:100%;max-width:100%;}
    }
    .flex-row {
        align-items: center;
        flex-wrap:wrap;
        gap:20px;
        margin-bottom:16px;
    } 
    h2 {
        margin: 0 0 4px 0;
    }
    .badge-issuer {
        + button {
            margin-top:32px;
        }
    }
    .badge-description {
        font-weight:300;
    }

    @media (max-width: $breakpointXS) {
        flex-direction: column;
        align-items: center;
        gap:0;
        text-align: center;
        .badge-details {
            h2,p {text-align:center}
        }
        .button {
            margin:auto;
        }
    }
    @media (max-width:$breakpointS) {
        .flex-row {
            // flex-direction: column;
            
        }
    }
    
}
.badge-skills, .badge-earn {
    margin-top:48px;
    h3 {margin:0 0 8px 0; font-weight:700;}
    .chip-list {
       padding-top:4px;
    }
    .badge-description {
        font-weight:300;
    }

    
}
.action-card.dark {
    .badge-information {
        padding:24px 0 0 0;
        .badge-details {
            padding: 24px 0;
        }
    }
}
.badge-benefits {
    .salary-caption {font-weight:600;margin-bottom:0;}
    .card {
        // max-width:300px;
    }
}

.benefit-highlight {
    text-align:center;
    height:auto;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    span {
        display:block;
        // color: #667080;
        text-align:center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.24px;
    }
    .callout {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 100% */
        margin-bottom:10px;
        &.teal {
            background: var(--gradient-02, linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &.purple {
            background: var(--gradient-blue, linear-gradient(137deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.goal-picker-container {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.4px;
    // display:flex;
    // align-items: center;
    // flex-wrap:wrap;
    // justify-content: center;
    gap:8px;
    margin:36px 0 12px 0;
    text-align:left;

    + .goal-picker-container {
        .goal-dropdown {
            z-index: 1;
            &.open {
                z-index: 3;
            }
        }
    }
}
.goal-dropdown {
    display:inline-block;
    position:relative;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 8px;
    min-width:200px;
    flex:1;
    max-width: max-content;
    z-index: 2;
    &.autocomplete {
        min-width:100%;
        @media (min-width:568px) {
            min-width:440px;
        }
    }
    &.open {
        z-index: 3;
        max-width: 550px;
       > .button {
            border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom:0;
                width:100%;
                svg {
                    transform:rotate(180deg)
                }
                input {
                    color: var(--text-muted);
                }
        }
        
    }

   > .button {
    
        border-radius: 16px;
        border: 1px solid var(--border-light-theme-border, #DDE3EE);
        background: var(--bg-level0);
        color: var(--text-light-theme-link, #655591);
        padding: 8px 18px;
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:8px;
        position:relative;
        min-height:44px;
        text-align: left;
        .none {
            
            color: var(--text-muted);
        }
        input {
            border:none;
            flex:1;
            background:transparent;
            height:100%;
            padding: 8px 28px 8px 18px;
            border-top-left-radius:16px;
            border-bottom-left-radius: 16px;
            width:100%;
            text-overflow:ellipsis;
            color: var(--text-light-theme-link, #655591);
            // text-transform: lowercase;
            :first-letter {
                text-transform: uppercase;

            }
            
        }
        // &.with-clear {
        //     input {
        //         margin-right:32px;
        //     }
        // }
        .clear-icon {
            position:absolute;
            right:48px;

        }
        svg {
            flex:none;
            transition:transform 0.15s ease;
        }
        &[aria-expanded=true] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom:0;
            width:100%;
            svg {
                transform:rotate(180deg)
            }
        }
        
    }
    .clear-icon {
        // position:absolute;

    }
    .menu {
        position:absolute;
        z-index: 1111;
        left:0;
        top:calc(100%);
        background: var(--bg-level0);
        border: 1px solid var(--color-border);
        border-top:0;
        border-radius: 0 0 16px 16px;
        // padding:24px;
        padding: 8px 0;
        box-shadow: none;
        min-width:200px;
        width: 100%;
        color:var(--color-black);
        max-height:300px;
        overflow-y: auto;
        ul {list-style: none;padding:0;margin:0; text-align:left;}
        .none {
            text-align: left;
            width:100%;
            padding: 4px 24px;
            // color: var(--text-muted);
            color: var(--text-light-theme-link, #655591);
        }
        button {
            background:transparent;
            border:none;
            text-align: left;
            width:100%;
            padding: 8px 24px;
            color: var(--text-light-theme-link, #655591);
            // text-transform: lowercase;
            font-weight:500;
            // &:first-letter {
            //     text-transform: uppercase;

            // }
            &:hover, &:focus {
                background-color: var(--bg-level2);
                font-weight:700;
            }
            &[aria-selected=true] {
                color: var(--text-light-theme-link, #655591);
            }
        }
    }
}
.share-options {
    justify-content: flex-start;
    flex-wrap:wrap;
    gap:12px;
    margin-top:20px;
}
.share-option {
    background:transparent;
    border:none;
    text-align:center;
    min-width:96px;
    text-decoration: none;
    font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    span {
        display:block;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 0.24px;
        text-decoration: none;
        color:var(--color-black);

    }
    .share-avatar {
        background-color: var(--background-light-theme-bg-level-1, #F7F9FD);
        border: 1px solid var(--border-light-theme-border, #DDE3EE);
        border-radius:40px;
        width:56px;
        height:56px;
        margin: 0 auto;
        padding:15px;

        &:hover {
            box-shadow: 1px 2px 5px rgba(0,0,0,.2);
        }
        
        &.linkedin {
            border:0;
            background-color: #2867B2;
            background-image: url("../icons/share/linkedin2.jpg");
            background-repeat:no-repeat;
            background-size:40px 40px;
            background-position: center center;
            
        }
        &.twitter {
            border:0;
            background-color: #000;
            background-image: url("../icons/share/twitter.png");
            background-repeat:no-repeat;
            background-size:28px 28px;
            background-position: center center;
            
        }
        &.facebook {
            // background-color: #2867B2;
            border:0;
            background-image: url("../icons/share/facebook.png");
            background-repeat:no-repeat;
            background-size:cover;
            background-position: center center;
            
        }
        &.ziprecruiter {
            border:0;
            background-image: url("../icons/share/ziprecruiter.svg");
            background-repeat:no-repeat;
            background-size:cover;
            background-position: center center; 
        }
    }
    .share-type {
        margin-top:10px;
    }
}

.modal.share-badge {
    max-width: 700px;
    .modal-body {
        h2, h3, h4, p {
            text-align:left;
            margin:0;
        }
        .badge-issuer {
            font-size:24px;
            font-weight:400;
            line-height:28px; 
            a {
                font-weight: 400;
            }
        }
        h3 {
            margin-top:40px;
        }
    }
    .badge-information {
        padding-bottom:40px;
        border-bottom: 1px solid #DDE3EE;
    }
}

.loader {
    position:relative;
    > .card {
        opacity:0.5;
        background-color:var(--bg-level1);
        border:0;

    }

}
.personalizing-spinner {
    position:absolute;
    left:50%;
    top: 50%;
    transform:translate(-50%,-50%);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 100% */
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:32px;
    .ellipsis {
        display: inline-block;
        // padding-left:3ch;
        position:relative;

        &:after {
        content:'';
        display: inline-block;
        position:absolute;
            //    left:0;
            bottom:0;
            animation: dots 1.5s infinite steps(1,end);
            -webkit-animation: dots 1.5s infinite steps(1,end);
        }
    }
    .spinner {
        position:relative;
        width:160px;
        height:160px;

        // @for $i from 1 through 14 {
        //     span:nth-of-type(#{$i}) {
        //         left:calc(10px  * $i);
        //         animation-delay: calc($i * 0.5s);
        //     }
        // }
        span {
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            
            &:before {
                content:'';
                display:block;
                margin:0 auto;
                width:8px;
                height:8px;
                background:var(--color-black);
                border-radius:50%;
                // transform-origin:top-left;
            // animation:spinnerDot 1.5s infinite;
            -webkit-animation: sk-circleBounceDelay 2.8s infinite ease-in-out both;
          animation: sk-circleBounceDelay 2.8s infinite ease-in-out both;
            
            }
            @for $i from 1 through 14 {
                &:nth-of-type(#{$i}) {
                    transform:rotate(calc(360deg / 14) * $i);
                    
                    &:before {
                        animation-delay: calc($i * 0.2s);
                    }
                }
            }
          }
    }

    @media (max-width: $breakpointS) {
        // top:20%; 
        display: none;
    }
}
.personalizing-modal {
    background-color: rgba(247,249,253,0.5);
    .loader {
        padding:0;
        min-height:100vh;
    }
    .personalizing-spinner {display: flex;}
    .card {
        display: none;
        .skeleton {display: none;}
    }
}
.skeleton {
    background-color: #02091714 !important;
    animation: skeleton 1s infinite;
}
@keyframes skeleton {
        0% {
            opacity: .4;
        }
        
        70% {
            opacity: .8;
        }
        
        100% {
            opacity: .4;
        }
    
}
@keyframes spinnerDot {
    0% {
        transform: scale(0.2);
    }
    100% {
        transform: scale(1.5);
    }
}
@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(2);
              transform: scale(2);
    }
  }
  
  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(2);
              transform: scale(2);
    }
  }

@-webkit-keyframes dots {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}
@keyframes dots {
    0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}
.progress-bar-container {
    .progress-bar {
        position:relative;
        width:100%;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.50);
        height:16px;

        .bar {
            height:100%;
            position:absolute;
            left:0;
            top:0;
            bottom:0;
            border-radius: 100px;
            background: var(--gradient-teal, linear-gradient(170deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
            // background: linear-gradient(117deg, #0CC0AD 17.21%, #19A292 60.52%, #1F897C 82.37%);
        }

        &.loading {
            .bar {
                width:100%;
                background: #2E839E;
                // background-image: linear-gradient(
                //   center bottom,
                //   rgb(43, 194, 83) 37%,
                //   rgb(84, 240, 84) 69%
                // );
                // box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
                //   inset 0 -2px 6px rgba(0, 0, 0, 0.4);
                position: relative;
                overflow: hidden;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-image: 
                    // linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%)
                    linear-gradient(
                      125deg,
                      rgba(12, 192, 173, 0.6) 0%,
                    
                      
                      #2E839E  40%,
                    
                      #29657A 100%
                      );
                      transform: skewX(-30deg);
                    z-index: 1;
                    background-size: 45px 45px;
                    background-position:0 0;
                    animation: move 1s linear infinite;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    overflow: hidden; 
                }
            }
        }
    }
    .progress-bar-label {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        margin-top:8px;
    }
}

// .card.dark {
//     .progress-bar-container {
//         .progress-bar {
//             background: rgba(255, 255, 255, 0.50);

//         }
//     }
// }
@keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 45px 0px;
    }
  }
  
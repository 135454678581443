.work-style-insights {
    // background-color: var(--color-medium-blue);
    // color:var(--color-white);
    margin-bottom:40px;
    
    .insights-header {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom:32px;
        h2 {margin-bottom: 0;}
        // a, button {color:var(--color-white);}
    }
    .insights-body {
        display:flex;
        flex-direction: column;
        gap:24px;
    }
    p {
        text-align:center;
    }
    a, button {
        margin: 0 auto;
        text-align:center;
    }
}
.insight-block {
    padding: 24px 32px;
    background: var(--bg-level0);
    border:1px solid var(--color-border);
    border-radius: 8px;
    display:flex;
    align-items: center;
    gap:24px;
    // color:var(--color-white);


    @media (max-width:400px) {
        flex-direction: column;
    }

    .bar-group, .insight-title {
        flex:1;
    }
    .bar-group {
        display:flex;
        flex-direction: column;
        gap:6px;
        width:100%;
    }
    .bar-container {
        width:100%;
        position: relative;
    }
    
    .bar {
        height:16px;
        border-radius:4px;
        border:2px solid transparent;
    }

    &.work {
        .bar {
            background-color: #F05B95;
        }
        .bar-container.hollow {
            .bar {
                border: 2px dashed #F05B95;
                background-color: #F05B9533;
            }
        }
    }
    &.relate {
        .bar {
            background-color: #F8BB69;
        }
        .bar-container.hollow {
            .bar {
                border: 2px dashed #F8BB69;
                background-color: #F8BB6933;
            }
        }
    }
    &.emotions {
        .bar {
            background-color: #0CC0AD;
        }
        .bar-container.hollow {
            .bar {
                border: 2px dashed #0CC0AD;
                background-color: #0CC0AD33;
            }
        }
    }
    &.thinking {
        .bar {
            background-color: #31B9E1;
        }
        .bar-container.hollow {
            .bar {
                border: 2px dashed #31B9E1;
                background-color: #31B9E133;
            }
        }
    }
}
.insights-container {
    .intro {
        margin-bottom:24px;
        
    }
    .page-nav {
        button {
            height:40px;
            flex:none;
            color:inherit;
        }
        // margin-bottom:24px;
    }

    

    .insights-level1 {
        width:50%;
        flex: 0 0 50%;
    }
    .insights-level2 {
        width:50%;
        flex: 0 0 50%;
    }

   &.is-mobile {
        display:flex;
        align-items: flex-start;
        // gap:var(--outside-padding);
        transition:transform 0.3s ease-in-out;
        transform: translateX(0);
        width:200%;

        &.level1-open {
            transform: translateX(0);
            .insights-level2 {
                animation: 0.1s hide forwards;
                    animation-delay:0.3s;
            }
        }
        &.level2-open {
            transform: translateX(-50%);
            .insights-level1 {
                animation: 0.1s hide forwards;
                    animation-delay:0.3s;
            }
        }
   }
}

@keyframes hide {
    0% {
        visibility: visible;
    } 
    100% {
        visibility: hidden;
        max-height:0;
        overflow:hidden;
    }
}

.insights, .insights-drawer {
    padding: 0 var(--outside-padding);
    .tab-header {
        margin-bottom:var(--vertical-space);
        
        h1, p, a {
            color:var(--color-white);
        }
        .icon-btn {
            min-width:40px;
            min-height:40px;
            
        }
        .page-nav {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            // gap:8px;
            margin-bottom:8px;
            color: var(--color-white);
            transform:translateX(-40px);
            
            a, button {flex:none}
            
        }
    }
    
    .employee-nav {display: none;}
    .intro {
        padding-top:60px;
        
        .back-btn {
            margin-bottom:20px;
        }
    }
    .tab-list-container {
        // overflow-x: auto;
        .tab-action {color:var(--color-white)}
    }
    .work-insights.card {
        // background:var(--color-medium-blue);
        // border-color:var(--color-medium-blue);
        // border-radius:8px;
        // color:var(--color-white);
        // padding: var(--card-padding);
        // padding:32px 0;
        // margin: 20px;
        height:auto;
        padding:0;
        border-color:var(--color-border);
        // padding: 40px;
        .section-header {
            display:flex;
            justify-content: space-between;
            flex-wrap:wrap;
            gap:12px;
            background-color: var(--color-white);
            border-top-left-radius: var(--card-padding);
            border-top-right-radius: var(--card-padding);
            // margin-bottom:32px;
            padding:32px 40px;
            h2 {margin:0;}
        }
        .tab-list-container {
            background-color: var(--color-white);
            border-bottom: 1px solid var(--color-border);
        }
        .tab-panel {
            padding:32px 40px;
        }
        .MuiTabs-root .MuiTabs-indicator {
            // background:var(--color-white);
            // display: none;
        }
        .MuiTabs-root .MuiButtonBase-root {
            // color:#989BA3;
            // font-size: 1.5rem;
            // line-height:1.3;
            // padding: 8px 45px;
            // margin: 0;
            &.Mui-selected {
                // color:var(--color-white);
            }
            &:first-of-type {
                padding-left:0;
            }

            + .MuiButtonBase-root {
                // border-left:2px solid #393F4A;
            }
        }
        @media (min-width: ($breakpointS + 1)) {
            // padding: 64px 40px;
        }
    }
    .work-insights.mobile-group {
        margin-top:40px;
        margin-left:calc(-1 * var(--outside-padding));
        margin-right:calc(-1 * var(--outside-padding));
    }
    .insights-group {
        margin-top:64px;
        margin-bottom:32px;

        &:first-of-type {
            margin-top:32px;
        }
        &:last-of-type {
            // margin-bottom:0;
        }

        &.work {
            --bar-color: #F05B95;
            // --bar-color: #EA0B7E;
        }
        &.relate {
            --bar-color: #F8BB69;
            // --bar-color: #EEA42E; //2 contrast
        }
        &.emotions {
            --bar-color:#0CC0AD;
            // --bar-color: #01CBB7; //2 contrast
        }
        &.thinking {
            --bar-color: #31B9E1;
            // --bar-color: #59D5FD; //1.6 contrast
        }
        .insights-title {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width:100%;
            background:transparent;
            border:none;
            color:var(--text-default);
            text-align:left;
            padding:0;
            font-weight: 700;
            word-break: break-word;
            hyphens: auto;

            .icon {
                display: inline-block;
                margin-right:8px;
                svg {
                transition:transform 0.3s ease;
                }
            }
            &[aria-expanded=true] {
               .icon svg { transform:rotate(180deg);}

                + .insights-body {
                    .insights-bar-container {
                        // margin-bottom:80px;
                        .labels strong {
                            transition:font-weight 70ms ease;
                            transition-delay:100ms;
                        }
                    }
                    
                    .bar-description {
                        display: block;
                        visibility: visible;
                        max-height:500px;
                        transition:  visibility 500ms ease-in, max-height 500ms ease-in;
                    }
                    .insights-more-details {
                        display: block;
                        visibility: visible;
                        max-height:2000px;
                        
                        // margin-top:0;
                        transition:  visibility 600ms ease-in, max-height 600ms ease-in;

                        // overflow: hidden;
                        .insights-meaning {
                            // padding-top:80px;
                        }
                    }
                }
            }
        }
        .insights-definition {
            margin-top:0.75rem;
            margin-bottom:1.5rem;
        }
        .insights-bar-container {
            padding-top:65px;
            
            // padding-bottom:40px;
            font-size:1em;
            line-height:1.5;
            .bar-container {
                position: relative;
                height:32px;
                width:100%;
                border:2px solid var(--bar-color);
                border-radius:4px;
                .bar {
                    height:32px;
                    background-color: var(--bar-color);
                    border-top-left-radius: 4px;
                    border-bottom-left-radius:4px;
                    position: absolute;
                    left:0;
                    top:-2px;
                    span {
                        position:absolute;
                        right:0;
                        bottom:54px;
                        font-size:1.125rem;
                        text-align:center;
                        transform: translateX(50%);
                        line-height:1.1;
                        white-space: nowrap;
                        strong {display: block;}
                        
                        &.align-right {
                            // transform:translateX(8px);
                            right:10px;
                        }
                        &.align-left {
                            transform:translateX(0px);
                            right:unset;
                            left:0;
                        }
                    }
                    &:after {
                        content:'';
                        display: block;
                        width: 0; 
                        height: 0;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 8px solid var(--bar-color);
                        // background-color:var(--bar-color);
                        z-index: 1;
                        // margin: 8px auto;
                        position:absolute;
                        top:-20px;
                        right:0;
                        transform:translateX(50%);
                    } 

                    @media (max-width: $breakpointS) {
                        span {
                            bottom: 46px;
                        }
                        &:after {
                            top:-10px;
                        }
                    }
                }
                .fifty {
                    position: absolute;
                    left:50%;
                    font-weight:700;
                    
                    // line-height: 18px;

                    > span {
                        display: flex;
                        align-items: center;
                        gap:8px;
                        margin-top:10px;
                        transform:translateX(-50%);
                        vertical-align: middle;
                        font-size:1.125rem;
                        line-height:1;
                    }
                    
                    .icon {
                        display:inline-block;
                        color:var(--text-light-theme-link);
                        border-radius:50%;
                        width:18px;
                        height:18px;
                        // border:1px solid var(--text-light-theme-link);
                        
                    }
                    &:before {
                        content:'';
                        display: block;
                        height:28px;
                        width:3px;
                        background-color:var(--color-black);
                        z-index: 1;
                        transform:translateX(-1px);
                    }
                    
                }
            }
           
            .labels {
                display:flex;
                justify-content: space-between;
                align-items: flex-start;
                gap:10%;
                padding-top:0.5rem;
                font-size:1.125rem;
                line-height:1;
                strong {display:block;font-weight:normal;margin-bottom:4px;}
                .maximum-label {text-align:right;}

                @media (min-width:1025px) {
                    padding-top:0.5rem;
                    gap:24%;
                    .minimum-label, .maximum-label {flex: 0 1 38%;}
                }
            }
            &.first-bar {
                .labels {
                    padding-top:2.5em;
                }
                @media (min-width:1025px) {
                    .labels {
                        padding-top:0.5rem;
                    }
                 }
            }
        }
        .insights-body {
            padding-left:32px;
            .insights-bar-container {
                // transition:margin 100ms ease-in;
                // transition-delay: 300ms;
                .labels strong {
                    transition:font-weight 70ms ease;
                    transition-delay:300ms;
                }
            }

            .bar-description {
                display: block;
                visibility: hidden;
                max-height:0;
                overflow: hidden;
                transition: visibility 500ms ease-in, max-height 500ms cubic-bezier(0, .6, .6, 1);
            }
            .insights-more-details {
                visibility: hidden;
                max-height:0;
                overflow: hidden;
                transition:  visibility 400ms ease-in, max-height 400ms cubic-bezier(0, .6, .6, 1);
                // transition:all 800ms ease;
                // display: none;
                // border-bottom:1px solid white;
                // padding-top:80px;
                // padding-bottom:60px;
                // padding-top:80px;
                // margin-top:-80px;

                p {
                    &:first-of-type {
                        margin-top:0;
                    }
                    &:last-of-type {
                        margin-bottom:0;
                    }
                }
            }
            h4 {
                margin-bottom:1rem;
                margin-top:0;
            }
            // p {
            //     font-size: 1.125em;
            // }
            .insights-meaning {
                padding-top:80px;
                margin-bottom:60px;
            }
        }

        &.expanded {
            // border-bottom: 1px solid #4F5561;
            .minimum-label, .maximum-label {
                strong {font-weight:700;}
            }
        }

        &.nodata {
            .insights-title {
                padding-left:32px;
            }
             .insights-bar-container {
                // padding-left:32px;
                padding-top:0;
                .bar:after {content:none}
             }
        }
    }

    .link-btn {
        // color:var(--color-bright-teal);
    }
    

    @media (max-width:1024px) {
        .intro {background:transparent;}
        .insights-bar-container {font-size:90%;}
    }
}
.part-two-message {
    text-align: center;
    padding:80px 0 0px 0;
    border-top:1px solid var(--color-border);
    margin-top:80px;
    button {
        margin: 24px auto;
    }

    @media (max-width: $breakpointS) {
        padding: var(--outside-padding);
        margin-top:24px;
        border-top:none;
    }
}
.insights-accordion {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);

    .tab-action {
        width:100%;
        text-align:left;
        // font-weight: 400;
        // font-size: 20px;
        // line-height: 24px;
        // letter-spacing: 0.4px;
        background-color:var(--color-white);
        color:var(--text-default);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:12px;
        // margin: 8px 20px;
        // margin:20px 0;
        padding: 24px;
        text-decoration: none;
        // background: none;
        border: 0;
        // border-bottom: 3px solid transparent;

        .icon {
            display: block;
            background-color: var(--color--accent-01-medium);
            border-radius:50%;
            padding:3px;
            width:30px;
            height:30px;
            .vert {
                transition:opacity 0.3s ease;
            }
            .horiz {
                transition: transform 0.3s ease;
                transform-origin: center;
            }
        }
        &[aria-expanded=true] {
            font-weight:700;

            .icon {

                .vert {
                    opacity:0;
                }
                .horiz {
                    transform:rotate(90deg)
                }
            }
            // border-bottom: 3px solid ;
        }
    }


}

.work-style-quiz {
    position: relative;
    background-color: var(--color-light-bg);
    min-height:100vh;
    &:before {
        content: "";
        background: transparent;
        background-image: url("../images/background-swoop-small.svg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: top;
        width:100%;
        min-height: 170px;
        position: absolute;
        left:0;
        right:0;
        top:0;
        transform: none;
        z-index:0;
    }

    header {
        display:flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        background:transparent;
        padding: 12px 21px;

        a {
            color:var(--color-white);
        }
    }
    main {
        position: relative;
        padding: 100px 20px 40px 20px;
        max-width:900px;
        margin:auto;
        z-index: 1;

        section h1 {
            margin-bottom:60px;
            text-align:center;

        }
        p {
            margin-bottom:1em;
        }
    }
    .assessment-header {
        text-align:center;
        padding:20px 0;
        min-height: 60px;
    }
    .question-counter {
        font-size:24px;
    }
    fieldset {
        border:0;
        margin:0;
        padding:0;

        legend {text-align:center;}
    }
    .radio-options {
        max-width:300px;
        margin:32px auto;

        .radio-option {
            position:relative;
            background-color: #E8EEFA;
            padding: 12px 40px;
            margin-top: 16px;
            border-radius:8px;

            input[type=radio] {
                opacity:0;
                position:absolute;
                ~ span {
                    border: 2px solid #707D89;
                    background: var(--color-white);
                    border-radius: 50%;
                    box-sizing: content-box;
                    color: var(--color-teal);
                    display: block;
                    height: 18px;
                    width: 18px;
                    // padding:2px;
                    pointer-events: none;
                    position: absolute;
                    top: 12px;
                    left: 40px;

                    svg {
                        position:absolute;
                        left:0;
                        top:0;
                        fill:currentColor;
                        opacity:0;
                    }
                }
                &:focus ~ span {
                    outline: 0;
                    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; 
                }
                &:checked ~ span{
                    border-color:var(--color-teal);
                }
                &:checked ~span svg {
                    opacity:1;
                }
            }
        }
        label {
            font-size: 24px;
            line-height: 28px;
            padding-left:40px;
            cursor: pointer;
        }
    }
    .button-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap:wrap;
        gap:12px;
        margin-top:80px;
        // margin-bottom:80px;
        button {white-space: nowrap;}
    }
}

/* starting ENTER animation */
.collapse-enter {
    opacity: 0;
    max-height:0;
    // transform: translateY(0%) scale(0.01);

    * {
        opacity:0;
        transition: opacity 500ms ease-in-out;
        transition-delay: 200ms;
    }
    &.with-delay {
        transition-delay: 800ms;
    }
  }
  
  /* ending ENTER animation */
  .collapse-enter-active {
    opacity: 1;
    max-height:1000px;
    // transform: translateY(0%) scale(1);
    transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
    * {
        opacity:1;
    }
  }
  
  /* starting EXIT animation */
  .collapse-exit {
    opacity: 1;
    max-height:1000px;
    // transform: scale(1.01);
  }
  
  /* ending EXIT animation */
  .ctaCard-exit-active {
    opacity: 0;
    max-height:0px;
    // transform: scale(4);
    transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
  }



  .insights-drawer {
    // position:absolute;
    // left:0;
    // top:var(--headerHeight);
    // right:0;
    // bottom:0;
    // background-color: var(--bg-level2);
    // z-index: 1112;
    // max-height:calc(100vh - var(--headerHeight));
    // overflow-y:auto;
    padding:0;

    .drawer-header {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        gap:12px;
        padding:48px 24px 40px 24px;
        margin-bottom:0 !important;

        position: sticky;
        top: calc(64px);
        z-index: 113;
        background:var(--bg-level2);

        .page-title {
            margin:0;
        }
    }
    .insights-group {
        background-color: var(--bg-level1);
        border-top:1px solid var(--color-border);
        border-bottom:1px solid var(--color-border);
        padding:0;
        margin:0;
        transition:background-color 0.3s ease-in-out;
        transition-delay:0.1s;
        &:first-of-type {
            margin:0;
        }

        .insights-title {
            // flex-direction: row-reverse;
            width:100%;
            justify-content: space-between;
            padding: 16px 24px;

            .mobile-h4 {margin:0;}

            &[aria-expanded=true] {
                position:sticky;
                top: calc(64px + 48px + 80px);
                background-color: var(--bg-level0);
                z-index: 15;
            }
            // position:sticky;
            // top: calc(64px + 48px + 80px);
            // background-color: var(--bg-level0);
            // z-index: 15;
        }

        .insights-body {
            padding:0px 24px 32px 24px;
            .insights-definition {
                padding:0px;
                // padding-bottom:16px;
                // transition:visibility 0.3s ease;
                visibility: hidden; 
                margin:0 0 16px 0;
                max-height:0px;
                overflow:hidden;
                transition: visibility 1450ms ease-in, max-height 1400ms cubic-bezier(0, 0.6, 0.6, 1), margin 1200ms cubic-bezier(0, 0.6, 0.6, 1);
                // transition-delay:300ms;
                // max-height:1000px;
                // animation: 0.1s hide forwards;
                // animation:hide 0.3s ease-in;
            }
            h3 {
                margin-top:0;
                margin-bottom:0;
            }
            .insights-meaning {
                padding-top:0;
                margin-bottom:24px;
            }
        }
        .insights-bar-container {
            background:var(--bg-level0);
            border:1px solid var(--color-border);
            border-radius:8px;
            padding:72px 16px 8px 16px;
            position:relative;
            transition:margin 500ms ease;
            

            .bar-container {
                .fifty >span {
                    font-size:1rem;
                    line-height:1.5;
                    font-weight:normal;
                }
                .bar span {
                    font-size:1rem;
                    line-height:1;

                    &:after {
                        margin: 4px auto;
                    }
                }
            }
            

            .labels {
                font-size:1rem;
                line-height:1.5;
                // gap:8px;
                padding-top:4px;
                > div {
                    flex:1;
                    // text-align:left;
                    &.fifty {
                        flex:none;
                        text-align:center;
                    }
                    &.maximum-label {
                        // text-align: left;
                    }
                }

            }
        }
        .info-btn {
            position:absolute;
            top:8px;
            right:8px;
            color:#0CC0AD;
            width:24px;
            height:24px;
            z-index: 11;
            border-radius:50%;
            svg {
                fill:currentColor;
            }
            &:hover {
                background:var(--bg-level2);
            }
        }
        &:not(.expanded) {
            .insights-definition {
                display: block;
                visibility: hidden;
                max-height:0;
                overflow: hidden;
                transition: visibility 450ms ease-in, max-height 400ms cubic-bezier(0, .6, .6, 1), margin 300ms cubic-bezier(0, .6, .6, 1);
                // animation: 0.3s hide forwards;
                // animation-delay:300ms;
            }
        }

        &.expanded {
            background-color: var(--bg-level0);

            .insights-definition {
                visibility:visible;
                display: block;
                max-height:300px;
                
                // animation:none;
            }
            .insights-bar-container {
                margin:24px 0;
            }
            .insights-body {
                padding-top:0;
                padding-bottom: 24px;

                h4 {
                    margin-bottom:0;
                }
                .insights-meaning {
                    padding-top:0;
                    margin-bottom:24px;
                }
            }
            
        }
        &.nodata {
            .insights-title {
                padding-left:24px;
            }
            .insights-definition {
                display: block;
                visibility: visible;
                max-height: 100px;
            }
            .insights-bar-container {
                padding: 16px;
            }
        }
    }
  }


  .insights-modal {
    .bar-container {
        position: relative;
        height:32px;
        width:100%;
        border:2px solid #020917;
        border-radius:4px;
        margin-bottom:4px;
        .bar {
            height:32px;
            background-color: #02091766; //40
            border-top-left-radius: 4px;
            border-bottom-left-radius:4px;
            position: absolute;
            left:0;
            top:-2px;
            span {
                position:absolute;
                right:0;
                bottom:34px;
                font-size:1.125rem;
                text-align:center;
                transform: translateX(50%);
                line-height:1.1;
                strong {display: block;}
                &:after {
                    content:'';
                    display: block;
                    width: 0; 
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid #020917;
                    // background-color:var(--bar-color);
                    z-index: 1;
                    margin: 0px auto;
                }
            }
            
        }
        .fifty {
            position: absolute;
            left:50%;
            font-weight:700;
            
            // line-height: 18px;

            > span {
                display: flex;
                align-items: center;
                gap:8px;
                margin-top:9px;
                transform:translateX(-50%);
                vertical-align: middle;
                font-size:1.125rem;
                line-height:1;
            }
            
            .icon {
                display:inline-block;
                color:var(--text-light-theme-link);
                border-radius:50%;
                width:18px;
                height:18px;
                // border:1px solid var(--text-light-theme-link);
                
            }
            &:before {
                content:'';
                display: block;
                height:28px;
                width:3px;
                background-color:var(--color-black);
                z-index: 1;
                transform:translateX(-1px);
            }
            
        }
    }
  }

  .page-wrapper.horizontal-nav .insights-main.mobile {
    overflow-x: clip;
    padding-top:0;

    .page-nav {
        padding-top:48px;
        margin-bottom:0 !important;
    }

  }
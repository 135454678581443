.megamenu-container {
    
   > .tab-action-container {
        display:flex;
        align-items: flex-start;
        flex-direction: row;
        position:relative;
        .tab-action {
            padding-right:28px;
        }
        
    }
    button.icon-btn {
        width:24px;
        height:24px;
        color:var(--color-white);
        position:absolute;
        right:10px;
        top:6px;
        &:hover {
            background-color: rgba(255,255,255,0.3);
        }
        svg {transition:transform 0.3s ease}

        &[aria-expanded=true] {
            svg {
                transform:rotate(180deg)
            }
        }
    }
}

.megamenu-menu {
    position:fixed;
    top:var(--headerHeight);
    left:0;
    // right:0;
    border-radius: 4px;
    border: 1px solid var(--primary-light-grey, #E8EEFA);
    background: var(--bg-level0);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    z-index: 111;

    display:grid;
    grid-auto-flow: column;
    color: var(--text-default);
    max-height:800px;
    height:calc(100vh - var(--headerHeight) - 60px);
    min-height:0;
    overflow:hidden;
    max-width:100%;
    &:focus {outline:none}
    &:focus-visible {
        outline:none;
        // box-shadow: 0px 0px 0px 2px #B41863;
    }
    .link-card {
        display:flex;
        align-items: center;
        gap:var(--card-padding-small);
        text-decoration:none;
        color:var(--text-default);
        margin-bottom:var(--card-padding-small);
        border:1px solid var(--color-border);
        padding: var(--card-padding-small);
        p {font-weight:normal}
        .image {
            max-width: 72px;
        }
    }
    .panel {
       
       flex-direction:column;
        padding: 12px;
        height:100%;
        overflow:hidden;
       display:none;
        ///
        
        .panel-header {
            padding: 8px 16px 8px 26px;
            border-bottom:1px solid var(--color-border);
            opacity:0;
        }
        .panel-title {
            font-size:1.125rem;
            line-height:1.5rem;
            font-weight:700;
            margin:0;
            padding:0;
        }
        &.visible {
            display:flex;
            width:calc((100vw - 360px)/3);
            // flex:0 1 25vw;
            scrollbar-gutter: stable;
            &.panel-small {
                width:auto;
                max-width:360px;
                padding-top:20px;
                padding-right:26px;
            }
            .panel-header {
                opacity: 1;
            }
        }
    }
    ul {
        flex:1;
        overflow-y:auto;
        height:100%;
        max-height:100%;
        min-height:0;
        list-style: none;
        margin:0;
        padding:0;
    }
    .megamenu-item {
        width:100%;
        padding: 10px 18px 10px 26px;
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        gap:10px;
        a {
            font-size:1.125rem;
            line-height:1.5rem;
            text-decoration:none;
            color:inherit;
            font-weight:normal;
            &:hover {text-decoration: underline;}
            &:focus-visible {
                outline:none;
                box-shadow: 0px 0px 0px 2px #B41863;
            }
        }
        button {
            color:inherit;
            svg {fill:currentColor;}
            &:focus-visible {
                outline:none;
                box-shadow: 0px 0px 0px 2px #B41863;
            }
        }
        &:hover, &.selected {
            background: var(--background-light-theme-bg-level-1, #F7F9FD);
        }
    }

    @media (prefers-color-scheme:dark) {
        border:0;
        .panel-header {
            border-color:#C7CCD6;
        }
        .megamenu-item {
            color:var(--text-muted);
            a {
                color:var(--text-muted);
            }
            &:hover, &.selected {
                background: var(--bg-level1);
            }
        }
    }
}
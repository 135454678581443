:root, .light-mode {
    --text-default: #020917;
    --text-inverse: #FEFEFE;
    --text-muted: #60646D;
    --color-border: #DDE3EE;
    --bg-level0: #fefefe;
    --bg-level1: #F7F9FD;
    --bg-level2: #E8EEFA;
    
    --color-dark-blue: #05112A;
    --color-dark-purple: #6D0176;
    --color-medium-blue: #122142;
    --color-indigo: #1D3058;
    --grey-light1: #E0E7F0;

    --color-light-base: #FEFEFE;
    --color-light-100: #F7F9FD;
    --color-light-200: #E8EEFA;
    --color-light-300: #DDE3EE;
    --color-light-400: #D2D7E2;
    --color-light-500: #C7CCD6;
    --color-light-600: #BCC1CB;
    --color-light-700: #B2B6BF;
    --color-light-800: #A7ABB4;
    --color-light-900: #989BA3;

    --color-dark-base: #020917;
    --color-dark-100: #B5B7BB;
    --color-dark-200: #9FA2A7;
    --color-dark-300: #898D93;
    --color-dark-400: #777B83;
    --color-dark-500: #60646D;
    --color-dark-600: #4C515B;
    --color-dark-700: #393F4A;
    --color-dark-800: #272D39;
    --color-dark-900: #161C29;


    --color-plum: #6D0176;
    --color-success: #19a292;
    --color-success-500: #1F897C;
    --color-bright-teal: #0CC0AD;

    --text-light-theme-link: #655591;
    --text-light-theme-link-hover: #4C406B;//color secondary 700
    // --text-light-theme-link: rgba(109, 1, 118, 1);

    --text-dark-theme-link: #9B81E2;
    --text-dark-theme-link-hover: #B79BFF;
    --border-radius: 16px;
    --card-corner-default: var(--border-radius);
    --color-white: #fefefe;
    --color-black: #020917;
    --gradient-01: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    --gradient-01-hover: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
    --checkbox-border: #60646D;
    --checkbox-checked: #1D3058B2;

    --column-gap: 24px;
    --color-dark-card: #122142;
    
    --text-link:var(--text-light-theme-link);
    --text-link-hover: var(--text-light-theme-link-hover);
    --interactive-disabled: var(--color-light-400);

    --tooltip-background:#272D39;
    --tooltip-border:#60646D;
    --map-glow-bg:rgba(164, 168, 255, 0.3);
        --map-glow-border:linear-gradient(45deg, #FFE4a7,#f49587,  #7825CB, #4b9ce8, #2af6ff);
}

.dark-mode {
    --bg-level0: #020917;
    --bg-level1: #161C29;
    --bg-level2: #272D39;
    --text-default: #fefefe;
    --text-inverse: #020917;
    --text-muted: #989BA3;


    --tooltip-background:#E8EEFA;
    --tooltip-border:#DDE3EE;
    --map-glow-bg:rgba(164, 168, 255, 0.3);
    --map-glow-border:linear-gradient(45deg, #FFE4a7,#f49587,  #7825CB, #4b9ce8, #2af6ff);
    --color-border: #393F4A;
    --text-link:var(--text-dark-theme-link);
    --text-link-hover: var(--text-dark-theme-link-hover);
    --interactive-disabled: var(--color-dark-600);
    .card {
        
    }
}
@media (prefers-color-scheme:dark) {
    :root, .dark-mode {
        --bg-level0: #020917;
        --bg-level1: #161C29;
        --bg-level2: #272D39;
        --text-default: #fefefe;
        --text-muted: #989BA3;
        --text-inverse: #020917;
        --color-border: #393F4A;
        --text-link:var(--text-dark-theme-link);
        --text-link-hover: var(--text-dark-theme-link-hover);
        --interactive-disabled: var(--color-dark-600);
        --tooltip-background:#E8EEFA;
        --tooltip-border:#DDE3EE;
        --map-glow-bg:rgba(164, 168, 255, 0.3);
        --map-glow-border:linear-gradient(45deg, #FFE4a7,#f49587,  #7825CB, #4b9ce8, #2af6ff);
    }
}


.toast {
    position:fixed;
    // bottom:24px;
    left:50%;
    bottom: -400px;
    transform: translateX(calc(-50% - 24px));
    background: var(--background-dark-theme-bg-level-0, #020917);
    padding: 16px;
    border-radius:16px;
    max-width:480px;
    min-height:56px;
    width:100%;
    margin:24px;
    color: var(--color-white);
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
    z-index: 11111;
    animation-fill-mode: forwards;
    // .toast {
    //   max-width: 450px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // flex-wrap:wrap;

      @media (max-width: 540px) {
        width:calc(100% - 32px);
      }
  
        > div {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap:16px;
            width:100%;
            padding-right:40px;
            > div {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              flex-wrap:wrap;
              flex:1;
              gap:8px;
              justify-content: space-between;

            }
        }
    .icon {
        color:var(--color-success-500);
        height:24px;
        &.info {color: var(--color-white);}
    }
    .text {
        font-size:20px;
        font-weight:400;
        line-height:24px;
        letter-spacing: 0.4px;
    }
    button.close {
      color: var(--color-white);
      position:absolute;
      right:4px;
      top:4px;
    }
    .link-btn {
      color:var(--text-dark-theme-link, #9B81E2);
      line-height:24px;
    }
    &.fadeIn {
        visibility: visible; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
        However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;  
        animation-fill-mode: forwards; 
    }
    &.fadeOut {
        visibility: visible; /* Show the snackbar */
        -webkit-animation: fadeout 0.5s;
        animation: fadeout 0.5s;
        animation-fill-mode: forwards;
    }
}

 /* Animations to fade the snackbar in and out */
 @-webkit-keyframes fadein {
    from {bottom: -400px; opacity: 0;}
    to {bottom: 0px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: -400px; opacity: 0;}
    to {bottom: 0px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 0px; opacity: 1;}
    to {bottom: -400px; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 0px; opacity: 1;}
    to {bottom: -400px; opacity: 0;}
  }
@import "responsive";
.card {
    display: block;
    position: relative;
    background: var(--bg-level1);
    border: 1px solid var(--bg-level1);
    border-radius: var(--card-padding);
    padding:var(--card-padding);
    // padding-bottom: 13px;
    // height: 100%;
    text-align:left;
    
    .bookmark-btn {
        position:absolute;
        right:24px;
        top:24px;
        opacity:0;
        color:var(--color-white);
        z-index: 2;
        width:24px;
        height:24px;
    }
    .recommended-tag {
        position:absolute;
        display:flex;
        flex-direction:row;
        align-items: center;
        gap:6px;
        white-space: nowrap;
        left:var(--card-padding);
        top:var(--card-padding);
        // max-width:38px;
        padding: 4px 8px;
        border-radius:8px;
        border: 2px solid var(--color-light-300);
        background-color: var(--color-white);
        z-index: 11;
        font-size:0.75rem;
        font-weight:600;
        color:var(--color-black);
        transition: max-width 0.15s ease-in-out;
        box-sizing: border-box;
        overflow:hidden;

        svg {display: block;flex:none;}
        
    }
    &:hover {
       > .bookmark-btn {
            opacity: 1;
        }
        // .recommended-tag {max-width:100%;}
    }
    h2, h3 {
        margin:0;

        + p {margin:0;}
    }

    > a {
        color: inherit;
        text-decoration: inherit;
        
    }
    p {
        a, .link-btn {
            font-size:inherit;
            line-height:inherit;
        }
    }
    // &:hover {
    //     // outline: 2px var(--color-border) solid;
    //     // outline-offset: -2px;
    // }
    .card-label {
        flex:none;
        display:flex;
        gap:4px;
        align-items: center;
        background: #020917B8;
        color:var(--color-white);
        padding:8px;
        border-radius:4px;
        font-weight:600;
        font-size:0.875rem;
        line-height: 1;
        position:absolute;
        left:12px;
        top:12px;

        &.completed {
            padding:12px;
        }
        @media (prefers-color-scheme:dark) {
            background: var(--color-dark-800);
        }
    }
    &.user-info:hover {
        outline: none;
    }

    &.no-padding {
        padding:0;
    }
    &.transparent {
        border:none;
        background-color: transparent;
    }

    .progress-ring {
        margin-left: auto;
        margin-right: auto;
    }
    .progress-label {
        margin-top: 5px;
        font-weight: bold;
    }

    .card {
        background-color: var(--bg-level0);
        border: 1px solid var(--color-border);
        border-radius: var(--card-corner-default);
        height: 100%; //maybe here?
        .card {
            background-color: var(--bg-level1);
            border: 1px solid var(--color-border);
        }
    }
    &.vertical-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        .image {
            
            height:100%;
            width:100%;
            margin: 0 auto;
            margin-bottom:16px;
            overflow: hidden;
            img {
                object-fit: contain;
                object-position: center;
                height:100%;
                max-height:298px;
            }
        }
    }
    &.horizontal-card {
        display: flex;
        align-items: flex-start;
        gap:24px;

        &.dashboard-cta {
            border:none;
            padding: calc(1.5 * var(--card-padding)) var(--card-padding);
            padding-right:25%;
            overflow:hidden;
            .card-body {position: relative;z-index: 1;}
            .image {
                position:absolute;
                right:0;
                top:0;
                bottom:0;
                max-width:60%;
                width:100%;
                // height:95px;

                img {
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                    object-position: left;
                }
            }

        }
        
        .image {
            // max-width:180px;
            img {width:100%;}
        }
        .card-body {
            flex:1;
           .card-text {
            flex:1;
            p + p {
                margin-top:4px;
                margin-bottom:0;
            }
           }
        }
        .card-actions {
            align-self:center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height:100%;
        }
    }
    
    .card-header {
        margin-bottom:12px;
        .flex-row {
            display:flex;
            flex-direction:column;
            align-items: flex-start;
            justify-content: space-between;
            gap:12px;
            
            @media (min-width: ($breakpointS + 1) ) {
                flex-direction:row;
                gap:12px;
            }
        }
        h2, .link-btn {
            // margin-bottom:12px;
        }
        .link-btn {
            // white-space: nowrap;
            // font-size:20px;
        }
    
        p {
            margin:0;
            .link-btn {margin:0;}
        }
    }
    .card-title {
        margin-bottom:4px;
    }
    &.edited {
        --icon-width: 0.8em;
        .card-title {
            &:after {
                content: '';
                background-image:  url('../icons/Completion.svg');
                margin-left: 0.2em;
                padding-left: var(--icon-width);
                background-size: var(--icon-width) var(--icon-width);
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }
    .card-subtitle {
        font-size:16px;
        line-height:20px;
        margin:0;
        // color:var(--text-muted);
        // color:rgba(2, 9, 23, 1);
        color: var(--text-default);

        
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        
    }
    .card-meta {
        font-size:14px;
        line-height:18px;
        color:var(--text-muted);
        display:flex;
        flex-wrap:wrap;
        margin: 8px 0 0 0;
        // span {white-space: nowrap;}
        span:not(:last-of-type) {
            &:after {
                content: "•";
                margin: 0 5px;
            }
        }
    }
    .image {
        img {
            width:100%;
            max-width:100%;
            display: block;
        }
    }
   .card-footer {
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        gap:12px;
        margin-top:24px;
        width:100%;

        button, a {
            button {white-space: nowrap;}
        }
        .link-btn {
            // white-space: nowrap;
            font-size:20px;
        }
        &.flex-start {
            justify-content: flex-start;
        }
        &.mobile-only a {
            text-align:center;
        }
   }
    .card-text {
        display: block;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-text-light);
        margin:0;
        &:last-child {margin-bottom:0;}
    }
    .card-link {
        font-size: 18px;
        line-height: 24px;
        color: var(--color-teal);
    }
    .feature-list {
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap:10px;
        grid-row-gap:16px;
        list-style:none;
        margin:0;
        padding:0;

        li {
            // white-space: nowrap; 
            hyphens: auto;
            vertical-align: middle;
            white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow:hidden;
            span {
                vertical-align: bottom;  
            }
            .circle-icon {
                color: var(--color-text-light);
            }
        }
    }
    .circle-icon {
        display: inline-block;
        width:29px;
        height:29px;
        background-color: var(--color-light-200);
        text-align:center;
        border-radius:50%;
        color: var(--color-text-muted);
        line-height:32px;
        margin-right:8px;
        padding:2px;
    }
    &.dark {
        background-color: var(--color-dark-card);
        border-color: var(--color-dark-card);
        color:var(--color-white);

        a:not(.button), .link-btn, .card-link {
            color: var(--text-light-dark-link, #9B81E2);
        }
    }
    &.occupation-card {
        text-decoration:none;
        &:hover, &:focus {
            outline:none;
            // z-index: 1;
            // background-clip: padding-box; /* !importanté */
            // margin: -1px;
            // border-image-source: var(--gradient-01-hover);
            // border-image-slice: 1;
            // border: 2px solid ;
            
            &:after {
                content:'';
                top: 0; right: 0; bottom: 0; left: 0;
                // z-index: -1;
                // margin: -2px; /* !importanté */
                border-radius: inherit; /* !importanté */
                // background: var(--gradient-01-hover);
                position:absolute;
                border-radius: var(--card-padding); /*1*/
                border: 2px solid transparent; /*2*/
                background: var(--gradient-01-hover) border-box; /*3*/
                -webkit-mask: /*4*/
                    linear-gradient(#fff 0 0) padding-box, 
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor; /*5'*/
                mask-composite: exclude; /*5*/
            }
            .bookmark-btn {opacity:1;}
            .card-title {text-decoration:underline;}
        }
        .image {
            height:200px;
        }
        .card-info {
            padding-top:16px;
            display:block;
        }
        .card-title {
            font-size:1.25rem; //20px h5
            font-weight:700;
            line-height:1.5rem;
        }
        .card-meta {
            font-size:1rem;
            line-height:1.5rem;
            margin:0;
        }
    }
    &.snapshot {
        display:flex;
        flex-direction:column;
        text-align:left;
        .number {
            font-size: 64px;
            line-height: 72px;
            font-weight:600;
        }
        .label {
            font-size: 24px;
            line-height: 28px;
        }
    }
    
    .info-btn {
        position: absolute;
        right:8px;
        top:10px;
        .info-icon {
            display: inline-block;
            width:22px;
            height:22px;
            background-color: var(--color-light-200);
            text-align:center;
            border-radius:50%;
            color: var(--color-text-muted);
            padding:2px;
        }
    }
    &.overview {
        position: relative;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 24px;
        
        .label {
            font-size: 18px;
            line-height: 24px;
            font-weight:500;
            padding-right:40px;
            margin-bottom:8px;
        }
        .number {
            font-size:48px;
            line-height:52px;
            font-weight:600;
            margin-bottom:auto;

            // @media (min-width:1025px) {
            //     font-size:48px;
            // line-height:52px;
            // }
        }
        .footnote {
            font-size: 14px;
            line-height: 18px;
            margin-top:20px;
        }
        .button-link {
            margin-top:40px;
        }
        .graph-container {
            margin-top: 12px;
            & > div {
                margin-bottom: 12px;
            }
            & > svg {
                width: 100%;
                height: auto;
            }
            &.trend-up {
                .chip-highlight {
                    svg {
                        fill: var(--color-plum);
                        position: relative;
                        top: 3px;
                        left: 2px;
                    }
                }
            }
            &.trend-down {
                .chip-highlight {
                    svg {
                        fill: var(--color-state-orange-dark);
                        position: relative;
                        top: 3px;
                        left: 2px;
                    }
                }
            }
        }
    }
    &.assessment-mini-card {
        display:flex;
        flex-direction: column;

        .card-body {
            flex:1;
        }
        
        .card-title {
            margin-bottom:4px;
        }
        .image {
            margin-bottom:24px;
            height:200px;
            padding:24px;
            // border-radius: 12px;
            &.full-width {
                padding: 0;
            }
            img {
                object-fit: contain;
                object-position: center;
                height:100%;
            }
        }
        .card-footer {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap:16px;
            margin-top:24px;
            // width:min-content;
            .button {
                white-space: nowrap;
                // flex:1 0 auto;
                // max-width:70%; // for it to grow when wrapped but not full width
                // min-width: fit-content;
            }
        }
    }
     &.career-card {
        display:flex;
        flex-direction: column;

        .card-body {
            flex:1;
        }
        
        .card-title {
            margin-bottom:4px;
        }
        .image {
            position:relative;
            margin-bottom:24px;
            height:200px;
            // padding:24px;
            border-radius: 12px;
            overflow:hidden;
            img {
                object-fit: cover;
                object-position: center;
                height:100%;
            }
            .tag {
                position:absolute;
                display:flex;
                flex-direction:row;
                align-items: center;
                gap:6px;
                white-space: nowrap;
                left:6px;
                top:6px;
                max-width:38px;
                padding: 4px 8px;
                border-radius:8px;
                border: 2px solid var(--color-light-300);
                background-color: var(--color-white);
                z-index: 11;
                font-size:0.75rem;
                font-weight:600;
                color:var(--color-black);
                transition: max-width 0.15s ease-in-out;
                box-sizing: border-box;
                overflow:hidden;

                svg {display: block;flex:none;}
                
            }

            @media (max-width: ($breakpointXS )) {
                .tag {
                    max-width:100%;
                    &:after {
                        content: attr(aria-label);
                        opacity: 1;
                    }
                }
            }
        }
        .card-text {
            .body-text.caption {
                line-height:1.5;

                a {
                    color:var(--text-link);
                    font-weight:normal;
                    &:hover, &:focus-visible {
                        color:var(--text-link-hover);
                        text-decoration: none;
                    }
                }
            }
        }
        .card-footer {
            justify-content: flex-start;
            flex-wrap: wrap;
            gap:16px;
            margin-top:24px;
            // width:min-content;
            .button {
                // white-space: nowrap;
                // flex:1 0 auto;
                // max-width:70%; // for it to grow when wrapped but not full width
                // min-width: fit-content;
            }
        }
        &:hover, &:focus-within {
        
                .tag {
                max-width:100%;
                    &:after {
                        content: attr(aria-label);
                        opacity: 1;
                    }
                }
            
        }
    }

    &.video-card, &.skill-card, &.occupation-card {
        display:flex;
        flex-direction:column;
        // max-width:320px;
        min-width: 0;
        flex: 1 1 320px;
        padding:var(--card-padding);
        height:auto;
        text-decoration: none;
        color:var(--text-default);
        font-weight:normal;
        
        .video.image {
            
            &:after {
                // content: '';
                width:48px;
                height:48px;
                display: block;
                background-image: url("../icons/play.svg");
                background-size:100% 100%;
                position:absolute;
                transform:translate(-50%, -50%);
                left:50%;
                top:50%;
            }
            .play-icon {
                width:48px;
                height:48px;
                position:absolute;
                transform:translate(-50%, -50%);
                left:50%;
                top:50%;
                border-radius:50%;
                cursor: pointer;
                // width: 62px;
                // height: 62px;
                // border-radius: 50%;
                color: rgb(245, 245, 245);
                background-color: rgba(0, 0, 0, 0.5);
                transition: all 0.3s ease 0s;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                z-index: 1;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                    box-shadow: rgb(1, 203, 183) 0px 0px 10px;
                    text-shadow: rgb(1, 203, 183) 0px 0px 10px;
                }
            }
        }
        .progress-bar-container {
            // padding: 0 8px;
            margin-top:8px;
            margin-bottom:0px;
            .progress-bar {height:12px;background:rgba(0,0,0,0.50)}
            .progress-bar-label {
                display:block;
                font-weight:500;
                margin-top:6px;
            }
        }
        .image {
            position: relative;
            overflow:hidden;
            border-radius: calc(0.5 * var(--card-corner-default));
            max-height:190px;
            width:100%;
            // top:0;
            // background: rgba(2, 9, 23, 0.45);
            background: rgba(2, 9, 23);
            
            img {
                width:100%;
                object-fit: cover;
                transition: all 0.3s ease-in-out 0s;
                height:190px;
                max-width:100%;
                // opacity: 0;
            }
            .tag {
                position:absolute;
                display:flex;
                flex-direction:row;
                align-items: center;
                gap:6px;
                white-space: nowrap;
                left:6px;
                top:6px;
                max-width:38px;
                padding: 4px 8px;
                border-radius:8px;
                border: 2px solid var(--color-light-300);
                background-color: var(--color-white);
                z-index: 11;
                font-size:0.75rem;
                font-weight:600;
                color:var(--color-black);
                transition: max-width 0.25s ease-in-out;
                box-sizing: border-box;
                overflow:hidden;

                svg {display: block;flex:none;}
                
            }
            
        }
        .avatar {
            // border: 2px solid var(--color-border);
            background-color: var(--bg-level1);
            color:var(--text-muted);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
            }
        }

        .card-body {
            display:flex;
            gap:16px;
            align-items: flex-start;
            padding-top: 16px;
        }
        .card-title {
            color:var(--text-default);
            margin:0 0 4px 0;
            // padding-right:24px;
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
        .card-meta {
            white-space:auto;
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
       
        .bookmark-btn {
            // top:16px;
            width:44px;
            height:44px;
        }
        &:hover {
            .tag {
                max-width:100%;
                &:after {
                    content: attr(aria-label);
                    opacity: 1;
                }
            }
            .image img {
                opacity:0.55;
                transform: scale(1.1);
            }
        }
        @media (max-width: $breakpointXS) {
           .image .tag {
                max-width:100%;
                &:after {
                    content: attr(aria-label);
                    opacity: 1;
                }
            }
            .bookmark-btn {
                opacity: 1;
            }
        }
    }
    &.study-card {
        .image.video {
            background:transparent;
            text-align:center;
            img {
                width:auto;
                margin:auto;
            }
        }
        .card-body {
            .avatar {
                display:flex;
                align-items: center;
                justify-content: center;
                border:1px solid var(--color-border);
                background:var(--color-white);
                // img {vertical-align: middle;}
            }
        }
    }
    &.forage-card {
        height:100%;
        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--color-border);
        }

        .forage-card-inner {
            background:transparent;
            border:none;
            padding:0;
            text-align:left;
            width:100%;
        }

        &:before {
            position:absolute;
            left:32px;
            top:32px;
            content:'';
            width:32px;
            height:32px;
            border-radius:8px;
            border: 1px solid var(--color-border);
            background-color: var(--color-white);
            color:var(--color-black);
            background-image: url("../icons/forage.png");
            background-repeat: no-repeat;
            background-position: 6px center;
            z-index: 11;
            background-size: 16px 16px;
            font-size:14px;
            transition: width 0.15s ease-in-out;
            box-sizing: border-box;
            overflow:hidden;
        }

        &:hover {
            &:before {
                content: "Forage";
                width: 80px;
                padding-left: 32px;
                line-height: 32px;
                padding-right: 8px;
            }
        }
        @media (max-width: ($breakpointXS )) {
            &:before {
                content: "Forage";
                width: 80px;
                padding-left: 32px;
                line-height: 32px;
                padding-right: 8px;
            }
        }
    }

    &.saved {
        .image img {
            opacity:0.55;
        }
        .bookmark-btn {opacity:1;}
    }

    &.mini-card {
        // min-width:309px;
        // max-width:343px;
        min-width:0;
        width:100%;
        padding: var(--card-padding);
        border-radius:16px;
        position: relative;
        text-decoration:none;
        color:var(--text-default);
        font-weight:normal;
        .bookmark-btn {
            width:40px;
            height:40px;
            top:0;
            right:0;
            color:var(--text-default);
        }
        .avatar {
            width:41px;
            height:41px;
            flex:none;
        }
        .card-title {
            font-size:20px;
            line-height: 24px;
            margin-top:0;
            margin-bottom:8px;
            overflow:hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right:8px;
        }
        
        .job-details {
            display:flex;
            gap:16px;
            align-items: center;
        }
        p {margin:0;}
        .job-company {
            // font-size:14px;
            // line-height:18px;
            font-size:1rem;
            line-height:1.25rem;
            white-space:nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
            max-height:1.25rem;
            max-width:100%;
        }
        div.meta {
            overflow:hidden;
        }
        p.meta {
            // font-size:12px;
            // line-height:18px;
            font-size:0.875rem;
            line-height: 1.125rem;
            display:flex;
            flex-wrap:wrap;
            span:not(:last-of-type) {
                &:after {
                    content: "•";
                    margin: 0 5px;
                }
            }
        }

        &.saved {
            .card-title {
                padding-right:8px;
                overflow:hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .bookmark-btn {opacity:1;}
        }

        &:not(.no-results):hover, &:focus {
            outline:none;
            // z-index: 1;
            // background-clip: padding-box; /* !importanté */
            // margin: -1px;
            // border-image-source: var(--gradient-01-hover);
            // border-image-slice: 1;
            // border: 2px solid ;
            
            &:after {
                content:'';
                top: 0; right: 0; bottom: 0; left: 0;
                // z-index: -1;
                // margin: -2px; /* !importanté */
                border-radius: inherit; /* !importanté */
                // background: var(--gradient-01-hover);
                position:absolute;
                border-radius: 16px; /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: /*4*/
     linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
          mask-composite: exclude; /*5*/
            }
            .bookmark-btn {opacity:1;}
            .card-title {text-decoration:underline;}
        }
    }

    .avatar {
        width:48px;
        height:48px;
        border-radius:50%;
        overflow:hidden;
        flex:none;
        background-color: var(--bg-level2);
            color:var(--text-muted);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                vertical-align: middle;
                text-align:center;
                width:100%;
                height:100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;

            }
        img {width:100%;}

        &.Aplus {
            img {
                // transform: scale(1.25);
            }
        }
    }
}
.link-card {
    &:hover, &:focus-visible {

        &:after {
            content:'';
            top: 0; right: 0; bottom: 0; left: 0;
            // z-index: -1;
            // margin: -2px; /* !importanté */
            border-radius: inherit; /* !importanté */
            // background: var(--gradient-01-hover);
            position:absolute;
            border-radius: var(--card-padding); /*1*/
            border: 2px solid transparent; /*2*/
            background: var(--gradient-01-hover) border-box; /*3*/
            -webkit-mask: /*4*/
            linear-gradient(#fff 0 0) padding-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor; /*5'*/
            mask-composite: exclude; /*5*/
        }
    }
}


.card-carousel {
    position: relative;
    overflow:hidden;
    width:100%;

    .prev-btn {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        left:8px;
        z-index: 11;
        display: none;;
    }
    .next-btn {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:8px;
        z-index: 11;
        display: none;;
    }

    .card-container {
        width:100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
       > ul {
            list-style-type: none;
            margin:0;
            padding:0;
            display:flex;
            gap:var(--column-gap);
            transition:transform 0.3s ease;

            > li {
                scroll-snap-align: start;
                flex-shrink: 0;
                flex:none;
            }
        }
    }

    @media (min-width:1025px) {

        &:not(.firstSlide) {
            &:before {
                content:'';
                position:absolute;
                width:80px;
                height:100%;
                left:0;
                top:0;
                bottom:0;
                background: linear-gradient(to right, var(--color-light-bg), rgba(255,255,255,0));
                z-index: 1;
            }
        }
        &:not(.lastSlide) {
            &:after {
                content:'';
                position:absolute;
                width:80px;
                height:100%;
                right:0;
                top:0;
                bottom:0;
                background:linear-gradient(to left, var(--color-light-bg), rgba(255,255,255,0));
                z-index: 1;
            }
        }
        
        .prev-btn, .next-btn {
            display: block;
            &:disabled {
                opacity: 0;
            }
        }
        .card-container {
            position: relative;
            width:100%;
            overflow: hidden;
            ul {
                width:max-content;
                li {
                    .card {height:100%;}
                }
            }
        }

         // For Dark Blue background
         &.dark-blue {
            &:not(.firstSlide) {
                &:before {
                    background: linear-gradient(to right, var(--color-dark-blue), rgba(255,255,255,0));
                }
            }
            &:not(.lastSlide) {
                &:after {
                    background:linear-gradient(to left, var(--color-dark-blue), rgba(255,255,255,0));
                }
            }

            .prev-btn, .next-btn {
                color: var(--color-black);
            }
         }
    }
}
.card.skills {
    .card-row {
        display: flex;
        justify-content: space-between;
        gap:8px;
    }
}
.split-card {
    padding:0;
    display:flex;
    flex-direction:column;
    background-color: var(--bg-level0);
    border: 1px solid var(--color-border);
    border-radius:var(--card-corner-default);
    h3 {margin:0 0 24px 0;}
    .recommended-side {
        flex:1;
        padding:var(--card-padding);
        border-top-left-radius: var(--card-corner-default);
        border-bottom-left-radius: var(--card-corner-default);
        .card-row {
            // display:grid;
            // grid-template-columns: 1fr;
            // grid-gap:16px;
            display:flex;
            flex-direction:row;
            gap:16px;
            flex-wrap:wrap;
            // margin-left:-8px;
            // margin-right:-8px;
        }
        .card {
            max-width:100%;
            flex: 1 1 calc(50% - 16px);
            min-width:180px;
            // margin:8px;
            .image {
                display:flex;
                align-items: center;
                justify-content: center;
                flex:none;
               max-height:90px; 
               img {
                height:90px;
                
                // transform: translateY(-25%);
               }
            }
            .card-info {
                min-width: 0;
            }
            .card-title {
                padding-right:0; //unless can't be bookmarked
                overflow:hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-width: 0;

            }
            .card-meta {display:none}
        }
    }
    .resume-side {
        flex:1;
        display:flex;
        flex-direction:column;
        padding:var(--card-padding);
        border-radius:0;
        border-bottom-left-radius: var(--card-corner-default);
        border-bottom-right-radius: var(--card-corner-default);
        background: var(--bg-level2);
        // border: 1px solid var(--color-border);
        .card.large {
            background-color: var(--bg-level1);
            border: 1px solid var(--color-border);
            flex:none;
            // flex:1;
            // display:flex;
            // flex-direction:column;
            max-width:100%;
            border-radius:var(--card-corner-default);
            .image {
                max-height:200px;
                img {
                    height: 200px;
                }
            }
            .progress-bar-container {
                padding: 8px 16px;
            }
           
            .progress-bar-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                color: var(--text-muted);
            }
        }
        .card-footer {
            display:flex;
            align-items: center;
            justify-content: center;
            margin-top:auto;
            padding-bottom:24px;

        }
    }
    @media (max-width: $breakpointXS) {
         .card.skill-card {
            .avatar {
                width:24px;
                height:24px;
            }
            .card-title {
                display: -webkit-box;
                font-size:18px;
                hyphens: auto;
                word-break: break-word;
                overflow-wrap: break-word;
                max-height: 50px; /* two lines*/
                overflow:hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; 
                text-overflow: ellipsis;
            }
        }
    }
    @media (min-width: ($breakpointMobile + 1)) {
        
        .recommended-side {
            .card-row {
                // display:grid;
                // grid-template-columns: repeat(2, minmax(120px, 1fr));
                // grid-column-gap:8px;
                // grid-row-gap:16px;
                .card {
                    .image {
                        
                       max-height:60px; 
                       img {
                        height:60px;
                        
                        // transform: translateY(-25%);
                       }
                    }
                }
    
            }
        }
    }
    @media (min-width: ($breakpointXS + 1) ) {
        // medium sizes
        flex-direction:row;

        .recommended-side {
            .card-row {
                grid-gap:16px;

                .card {
                    .image {
                        max-height:90px;
                        img {
                            height:90px;
                        }
                    }
                }
            }
        }

        .resume-side {
            border-radius:0;
            border-top-right-radius: var(--card-corner-default);
            border-bottom-right-radius: var(--card-corner-default);
        }
    }
}
.card .jobs-carousel {
    margin-top: 24px;
}
.card-row {
    display: flex;
    // justify-content: space-between;
    flex-direction:column;
    gap:var(--column-gap);
    margin-top: 24px;

    &.jobs-group {
        gap:16px;
    }

    &.one-two {
        
    }

    &.gap-24 {
        gap:24px;
    }

    &.overflow-scroll {
        max-width:100%;
        overflow-x: auto;
        // .card {flex:none}
    }
    &.overflow-wrap {
        flex-wrap:wrap;
            padding-bottom:0px;
        // overflow-x: scroll;
        // flex-direction:row;
        // padding-bottom:8px;
        // @media (min-width: ($breakpointM + 1)) {
        //     flex-wrap:wrap;
        //     padding-bottom:0px;
        // }
    }
    &.study-group {
        flex-wrap:wrap;
        margin-top:0;
        .card {
            flex: 1 1 360px;
        }
    }
    .card {flex:1;}
    .flex-2 {flex:2}
//    &.skills-group {
//     .card {
//         flex: 0 1 320px;
//     }
//    }

    @media (min-width:($breakpointS + 1)) {
        flex-direction:row;

        &.one-two {
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap:24px;
            .card {height:auto}
            >div:last-child {
                grid-column: 2 / 4;
            }
        }
        &.two-one{
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap:24px;
            .card {height:auto}
            >div:first-child {
                grid-column: 1 / 3;
            }
        }
        &.three-three-three {
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap:24px;
        }
        .three-col {
            flex:3;
        }
        .one-col {
            flex:1;
        }
        .two-col {
            flex:2;
        }
    }
}

.card {
    &.dark {
        // background-color: var(--color-indigo);
        border-color:transparent;

        .avatar {
            background-color: var(--color-black);
            color:var(--bg-level0);


        }
    }
    
    &.dark-gradient, &.dark.action-card {
        background:linear-gradient(209deg, rgba(118, 14, 1, 0.40) 0%, rgba(109, 1, 118, 0.00) 71.12%), linear-gradient(0deg, rgba(37, 23, 78, 0.60) 0%, rgba(37, 23, 78, 0.60) 100%), #000;
        border-color:transparent;
        color:#fefefe;
        .avatar {
            background-color: var(--color-black);
            color:var(--bg-level0);
        }

        &.edited {
            background: linear-gradient(209deg, rgba(118, 14, 1, 0.40) 0%, rgba(109, 1, 118, 0.00) 71.12%), linear-gradient(0deg, rgba(61, 74, 194, 0.60) 0%, rgba(61, 74, 194, 0.60) 100%), #000;
        }
        .card {
            border-color:transparent;
            background: rgba(49, 20, 85, 0.80);
            color:var(--color-white);
            .card-title {
                color:var(--color-white);
            }
    
            .job-company {color:#E0E7F0;}
            p.meta {color: #A7BAD0;}
            .bookmark-btn {color:var(--color-white);}
    
        
        }
    }
    
   
    &.action-card {
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        // max-width:calc((200vw - 172px)/3);
        min-width:0;
        min-height:360px;
        border:none;

        .card-image {
            margin-top:24px;
            margin-bottom:calc(-1 * var(--card-padding));
            .image {
                max-width:300px;
                margin: 0 auto;
            }
        }
        .card-actions {
            margin-top:24px;
            display: flex;
            flex-direction: row;
            flex-wrap:wrap;
            gap:16px;
        }
        .card-header {
            margin-bottom:0;
            // margin-bottom:16px;
            // h2 {margin-bottom:0;}
            .card-title, .link-btn {
                margin-bottom:16px;
            }
            .link-btn {
                font-size: 22px;
                // font-weight: 500;
                line-height: 24px;
            }
        }
        // .button.small {
        //     padding: 8px 32px;
        // }
        &.saved-jobs-cta {
            flex-direction: column;
            justify-content: center;
            padding: 24px 40px;

            .card-header {
                margin-bottom:0;
                .flex-row {
                    // align-items: center;
                }
            }
            .card-body {flex:none;margin-top:0;}
        }
        &.video-card {
            padding: var(--card-padding);
            max-width:100%;
            color: var(--color-white);
            .card-info {
                padding:0;
                margin-bottom:16px;
                display:flex;
                gap:16px;
                align-items: center;
            }
            
            .card-subtitle.author-name {color:inherit;}
            .progress-bar .bar {
                background: var(--gradient-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
            }
        }
        &.docked-image {
            display: block;
            padding: var(--card-padding);
            .card-body {
                // margin-bottom:calc(-1*var(--card-padding));
                // padding-left:10%;
                .image {
                    max-width:350px;
                    width:100%;
                    
                    img {
                        display: block;
                    }
                }
                
            }
            &.wide {
                .card-body {
                    .image {
                        max-width:100%;
                    }
                }
                
            }
        }
        &.wide {
            .card-body {
                .image {
                    max-width: 800px;
                }
            }
        }
        &.full-width {
            .card-body {
                max-width:100%;
                .image {
                    max-width:100%;
                }
            }
        }
        .overflow-scroll {
            padding-bottom:8px;
            scrollbar-color: #B070FF rgba(49, 20, 85, 0.80); 
        }
        
        .card-body {
            flex:1;
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            margin-top:12px;

            .image {
                max-width:100%;
                width:100%;
                
                img {
                    display: block;
                }
            }
        }
        .card-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap:wrap;
            width:auto;
            margin: 32px auto 0 auto;
            button {
                // white-space:nowrap;
                // flex:1;
                width:100%;
            }
        }
        
    }
    .progress-bar-container {
        margin:0 0 16px 0;
        max-width:600px;
    }
   
}
.stacked-card {
    display:flex;
    flex-direction:column;

    .card-body {
        flex:1;
    }
    &.one-col {
        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .image {
                max-width:160px;
                margin:0 auto;
            }
        }
    }
}

.badge-card {
    &:before {
        content:'';
        position:absolute;
        background-image: url("../images/badgeCTAbg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        left:0;
        right:0;
        bottom:0;
        top:0;
        z-index: 0;
    }
    .card-header, .card-body {
        z-index: 1;
    }
}

.card.goal-card, .goal-card.action-card {
    min-height:360px;
    flex-direction: column;
    
    .card-header {
        position: relative;
        z-index: 1;
    }
    .card-body {
        .goal-picker-container {
            display:flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap:wrap;
            width:100%;
            
        }

    }
    .image {
        text-align: center;
        img {
            display: block;
            max-width:300px;
            margin:auto;
        }
    //     position:absolute;
    //     bottom:0;
    //     right:24px;
    //     max-width:500px;
    }
    &.goal-mini-card {
        min-height:120px;
        .goal-picker-container {
            // margin:0;
            .goal-dropdown {
                font-size:18px;
                font-weight:normal;
                // margin:0 8px;
                .button {
                    color:var(--text-muted);
                }
                input {
                    color:var(--text-default);
                    font-weight:normal;
                }
                .menu button {
                    color:var(--text-default); 
                    font-weight:normal;
                }
                &.open {
                    > .button input {
                        // color:var(--text-muted);
                    }
                }
            }
        }
        .image {
           
            img {
                // max-width:120px;
            }
        }
    }
    @media (min-width:($breakpointS + 1)) {
        flex-direction: column;
        .card-header {
            padding-right:40%;
        }
        .card-body {
            // flex-direction:row;
            .image {
                position:absolute;
                bottom:0;
                right:24px;
                max-height:340px;
                width:100%;
                max-width:40%;
                z-index: 0;
                img {
                    max-height: 340px;
                    max-width: 100%;
                    width:auto;
                  }
            }
        }
        &.goal-mini-card {
            min-height:150px;
            .card-header {
                margin-bottom:8px;
            }
            .card-header, .card-body {
                padding-right:150px;
            }
            .goal-picker-container {
                margin:0;
                .goal-dropdown {
                    margin:0 8px;
                }
            }
            .image {
                max-width:400px;
                right:0;
                max-height:100%;
                border-bottom-right-radius: var(--card-corner-default);
                overflow:hidden;
                img {
                    max-height: 180px;
                    max-width: 100%;
                    width:auto;
                }
            }
        }
    }
}

.flex-row {
    display: flex;
    justify-content: space-between;

    &.align-start {
        justify-content: flex-start;
    }
    .image {
        img {width:100%;max-width:100%;}
    }
}
.jobs-two-col {
    .jobs-group.card-row {
        // display:grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-template-rows: auto auto;
        // grid-column-gap:var(--column-gap);
        // grid-row-gap:16px;
        // overflow:hidden;
        // grid-template-rows: auto auto auto;
        // grid-auto-rows: 0px;
        // margin-bottom: calc(-1* var(--column-gap));

        .card {
            &:nth-child(n+5) {
                display: none;
            }
        }
    }
}
@media (min-width:($breakpointS + 1)) {
// 414 and up
.card .card-header .link-btn {
    white-space: nowrap;
}

    .card.action-card {
        flex-direction: row;
        padding: 0 80px;

        &.saved-jobs-cta {
            padding: 0 80px;
        }
        &.goal-card, &.badge-card {
            flex-direction: column;
        }
        .card-message {
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 24px 0;
            // flex:0 1 400px;
            flex:1;
            max-width:401px;

            
        }
        .card-image {
            flex:1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-height:360px;
            margin:0;

            .image {
                height:100%;
                max-width:100%;
            }
            img {
                object-fit: contain;
                object-position: bottom center;
                height:100%;
            }
        }
        .card-body {
            .flex-row {
                flex-direction: row;
                align-items: flex-end;
                gap:var(--column-gap);
                // flex-wrap:wrap;
                margin-top:auto;
                .image {
                    flex:1;
                   
                    
                }
                button {white-space: nowrap;}
            }
        }
    }
    .intro .card.dark .card{
        min-width:343px;
        // min-width:309px;
        max-width:343px;
    }
    .jobs-group.card-row {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap:var(--column-gap);
        grid-row-gap:16px;
        overflow:hidden;
        grid-template-rows: auto auto;
        grid-auto-rows: 0px;
        // margin-bottom: calc(-1* var(--column-gap));
        &.single-row {
            grid-template-rows: auto;   
        }
        
       
   }
   .jobs-two-col {
    .jobs-group.card-row {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        grid-column-gap:var(--column-gap);
        grid-row-gap:16px;
        overflow:hidden;
        // grid-template-rows: auto auto auto;
        // grid-auto-rows: 0px;
        // margin-bottom: calc(-1* var(--column-gap));

        .card {
            &:nth-child(n+5) {
                display: none;
            }
        }
    }
}
   .card-row.skills-group {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:var(--column-gap);
    overflow:hidden;
    grid-template-rows: auto;
    grid-auto-rows: 0px;
    // margin-bottom: calc(-1* var(--column-gap));

    &.show-all {
        // display: block;
        grid-auto-rows: auto;
        .card {
            height:100%;
        }
        // > div {
        //     display:grid;
        //     grid-template-columns: repeat(3, 1fr);
        //     grid-gap:var(--column-gap);
        //     overflow:hidden;
        //     grid-template-rows: auto;
        //     grid-auto-rows: auto;
        // }
        
    }


    // &:not(.show-all) .card {
    //     max-width: 100%;
    //     &:nth-child(n+4) {
    //         display: none;
    //     }
    // }
   
}
  .card.action-card {
    .card-header {
        .flex-row {
            align-items: center;
            
        }
    }
    &.padding-even {
        padding:var(--card-padding);
    }
    &.docked-image {
        .card-body {
           justify-content: flex-end;
           align-items: center;
            max-width:50%;
            margin-bottom:calc(-1 * (var(--card-padding)));
            
            
        }
        &.wide {
            .card-body {
                align-items: flex-start;
                max-width:75%;
                margin-bottom:calc(-1 * (var(--card-padding) + 48px));
                .image {
                    max-width:1200px;
                }
            }
           
        }
        &.full-width {
            .card-body {
                max-width:calc(100% + var(--card-padding) + var(--card-padding));
                width:calc(100% + var(--card-padding) + var(--card-padding));
                margin-left:calc(-1 * var(--card-padding));
                margin-right:calc(-1 * var(--card-padding));
            }
        }
    }
    .card-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap:wrap;
        position:absolute;
        bottom:var(--card-padding);
        // left:0;
        right:var(--card-padding);
        width:calc(100% - 2*var(--card-padding));
        button {
            width:auto;
        }
    }
   }
}

@media (max-width: $breakpointXS) {
    .card.horizontal-card {
        flex-direction: column;
        // align-items: center;
        
        .card-body {flex-direction: column;}
        .card-text * {text-align: center;}
        .image {margin:0 auto; max-width:180px;}
    }
    .career-switcher {
        &.tab-container .tab-list {
            overflow-x: unset;
        }
    }
    .career-fast-facts .facts-row {
        flex-direction: column;
        h6 {
            text-align: left;
        }

    }
    .intro {
        > .card-row {
            display:flex;
            flex-direction: column;
        }
    }
    .card.skill-card {
        max-width:100%;
        .card-title {
            padding-right:0; //unless can't be bookmarked
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
        }
    }
}
@media (min-width: ($breakpointXS + 1)) {
    .card.horizontal-card {
        align-items: center;
        padding-right:25%;
        overflow:hidden;
        .image {
            position:absolute;
            top:0;
            bottom:0;
            right:var(--card-padding);
            
            width:25%;

            // margin-top:calc(-1 * var(--card-padding));
            // margin-bottom:calc(-1 * var(--card-padding));
            img {
                object-fit: cover;
                object-position: 25% center;
                height:100%;
                max-width:200px;
                margin:auto;
            }
        }
        &.dashboard-cta {
            border:none;
            padding: calc(1.5 * var(--card-padding)) var(--card-padding);
            padding-right:40%;
            overflow:hidden;
            
            .image {
                position:absolute;
                right:0;
                left:unset;
                bottom:0;
                top:0;
                max-width:60%;
                width:100%;
                height:100%;
                z-index: 0;
                img {
                    max-width:100%;
                    height:100%;
                    object-fit: cover;
                    object-position: left;
                }
            }

        }

    }
    
}
@media (min-width: ($breakpointXS + 1)) and (max-width:$breakpointS) {

    .career-switcher {
        &.tab-container .tab-list {
            overflow-x: unset;
        }
    }
    .career-fast-facts .facts-row {
        flex-direction: column;
         h6 {
            text-align: left;
        }
    }
   .intro {
    > .card-row {
        display:grid;
        grid-template-columns: 3fr 5fr;
        grid-gap:var(--column-gap);
        // .one-col {
        //     flex:3;
        // }
        // .two-col {
        //     flex:5;
        // }
    }
   }
   .card.video-card, .card.skill-card {
    .image {
        max-height:100px;
        img {
            height:100px;
        }
    }
   }
   .card.action-card {
    .card-footer {
        flex-direction: row;
        justify-content: flex-end;
        width:100%;
        button {
            width:auto;
        }
    }
   }
   .jobs-group.card-row, .card-row.skills-group {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap:var(--column-gap);
        overflow:hidden;
        // grid-template-rows: auto auto auto;
        // grid-auto-rows: 0px;
        // margin-bottom: calc(-1* var(--column-gap));

        .card {
            &:nth-child(n+7) {
                display: none;
            }
        }
    }
    .jobs-two-col {
        .jobs-group.card-row {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto;
            grid-column-gap:var(--column-gap);
            grid-row-gap:16px;
            overflow:hidden;
            // grid-template-rows: auto auto auto;
            // grid-auto-rows: 0px;
            // margin-bottom: calc(-1* var(--column-gap));

            .card {
                &:nth-child(n+5) {
                    display: none;
                }
            }
        }
   }
//     .card-row.skills-group {
//         // flex-direction:row;
//         display:grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: auto;
//     grid-gap:var(--column-gap);
//     .card {
//         // flex:1 1 240px;
//     }
//    }
}

@media (min-width: ($breakpointS + 1)) and (max-width: $breakpointM) {
    .card-row.skills-group {
        &:not(.show-all) .card {
            max-width: 100%;
            &:nth-child(n+4) {
                display: none;
            }
        }
    }
    .jobs-group.card-row {
        .card:nth-child(n+7) {
            display: none;
        }
    }
}
@media (min-width:($breakpointM + 1)) {
    .card.action-card {
        flex-direction: row;
        padding: 0 60px 0 120px;
        &.saved-jobs-cta {
            padding: 0 120px;
        }
    }
    .jobs-group.card-row,  .card-row.skills-group {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap:var(--column-gap);
        grid-row-gap:var(--column-gap);
        margin-bottom:0;
        .card {
            display: block;
        }
        &:not(.show-all) {
            .card {
                display: block;
                &:nth-child(n+7) {
                    display: none;
                }
            } 
        }
   }
   .jobs-two-col {
        .jobs-group.card-row {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto;
            grid-column-gap:var(--column-gap);
            grid-row-gap:16px;
            overflow:hidden;
            // grid-template-rows: auto auto auto;
            // grid-auto-rows: 0px;
            // margin-bottom: calc(-1* var(--column-gap));

            .card {
                &:nth-child(n+5) {
                    display: none;
                }
            }
        }
   }

   .card.dark .card-header .flex-row {
        display:flex;
        gap:24px;
   }
   
}

.sandbox section > .card {margin-bottom:40px}

.image.skeleton {
    max-width:455px !important;
    height:175px;
    border-radius: 8px;
    opacity: 0.8;
    background: #D9D9D9;
    display:flex;
    align-items: center;
    justify-content: center;
    color:var(--text-default);
    font-size:2em;
    flex:none;
    span {text-align:center}
}

.chip-label {
    background: #02091714;
    padding: 8px;
    border-radius:8px;
    color:var(--text-default);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
    display: inline-block;

    @media (prefers-color-scheme:dark) {
        background:#fefefe14;
    }
}
.card {
    .chip-label {
        margin-bottom:8px;
    }
    &.job-result-card:not(.active) {
        .chip-label {
            background: #020917B8;
        color:var(--color-white);
        }
    }
}

/* starting ENTER animation */
.ctaCard-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0;
    z-index: 11;
    // transform: translateY(0%) scale(0.01);

    * {
        opacity:0;
        transition: opacity 1000ms ease-in-out;
        transition-delay: 200ms;
    }
    &.with-delay {
        transition-delay: 800ms;
    }
  }
  
  /* ending ENTER animation */
  .ctaCard-enter-active {
    opacity: 1;
    // transform: translateY(0%) scale(1);
    transition: opacity 1000ms ease-in-out;
    * {
        opacity:1;
    }
  }
  
  /* starting EXIT animation */
  .ctaCard-exit {
    opacity: 1;
    // transform: scale(1.01);
  }
  
  /* ending EXIT animation */
  .ctaCard-exit-active {
    opacity: 0;
    // transform: scale(4);
    transition: opacity 1000ms ease-in-out;
  }

  /* starting ENTER animation */
.jobsCard-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0;
    z-index: 11;
    // transform: translateY(0%) scale(0.01);
   
  }
  
  /* ending ENTER animation */
  .jobsCard-enter-active {
    opacity: 1;
    // transform: translateY(0%) scale(1);
    transition: opacity 1000ms ease-in-out;
    transition-delay: 250ms;
    
  }
  
  /* starting EXIT animation */
  .jobsCard-exit {
    opacity: 1;
    // transform: scale(1.01);
  }
  
  /* ending EXIT animation */
  .jobsCard-exit-active {
    opacity: 0;
    // transform: scale(4);
    transition: opacity 1000ms ease-in-out;
    transition-delay: 250ms;
  }

  .card:not(.dark) {
    .card-footer .button.filled.light {
        outline: 1px solid var(--border-dark-theme-border, #393F4A);
        // outline-offset: -1;
    }
  }
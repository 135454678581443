.accordion-container {
    background-color:#FFFFFF;
    // border: 1px solid var(--color-border);
    box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
    border-radius: calc(0.5 *var( --card-corner-default));
    margin-bottom: 1em;
    transition: box-shadow .125s ease-in-out;
    color:var(--color-black);
    .accordion-body {
        display:none;
        padding: 0 32px 32px 32px;
        // border-top:1px solid var(--color-border);
        text-align:left;
        font-size:24px;
    }
    .accordion-btn[aria-expanded=true] {
        font-weight:bold;
        + .accordion-body {display:block;}
        .icon-24 {
            transform:rotate(180deg);
        }
    }

    &.filter-accordion {
        box-shadow:none;
        border-bottom:1px solid var(--color-border);
        margin-bottom:0;
        border-radius:0;
        &:last-child {
            border-bottom:0;
        }
        .accordion-btn {
            font-size:20px;
            padding: 8px 24px;
        }
        .filter-amount {
            background: #02091729; //D0D3D8
            font-size:0.825em;
            font-weight:500;
            padding: 4px 8px;
            margin-left:8px;
            border-radius:4px;
        }
    }
}
.accordion-btn {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    gap:10px;
    width:100%;
    // padding: 10px 24px;
    padding:26px 32px 29.5px;
    font-size:32px;
    line-height:40px;
    font-weight:500;
    background:transparent;
    border:none;
    color: var(--color-black);
    text-align:left;
    .icon-24 {
        width:40px;
        height:40px;
        display:block;
        border-radius:50%;
        transition:transform 0.3s ease;
        text-align: center;
        flex:none;
        svg {vertical-align: middle;}
    }
    &:hover {
        .icon-24 {
            background: var(--color-light-200);
        }
    }

}

// fake accordion!
.update-container {
    .accordion {
        font-size: 22px;
        line-height: 24px;
        padding: 8px 24px;
        border: 1px solid var(--color-border);
        border-radius: 8px;
        margin: 10px 0;
        .icon-18 {
            float: right;
            position: relative;
            top: 7px;
        }
    }
    .right.label {
        text-align: right;
        color: var(--color-text-muted);
        margin-bottom:8px;
    }
}

body {
    background-color: var(--bg-level2);
    // background-color: var(--bg-level1);
    margin:0;
    padding:0;

    --headerHeight: 64px;
}

.page-wrapper {
    main {
        width:100%;
        min-height:calc(100vh - var(--headerHeight) - 160px - 32px);
        max-width:calc(1440px + var(--outside-padding) + var(--outside-padding));
        margin: 0 auto;

        .floating-action-btn {
            position:fixed;
            z-index: 1110;
            bottom:var(--outside-padding);
            right:var(--outside-padding);

            // background: var(--gradient-01);
            // color:var(--color-white);

        }

        &.careers-main {
            max-width:100%;

            .intro {
                h1, p { margin:0 }
                p {
                    margin-top:12px;
                }
            }

            .floating-action-btn {
                color:var(--color-white);
                // position:sticky;
                top:calc(var(--headerHeight) + 200px + 32px);
                right:var(--outside-padding);
                bottom:unset;
                transition:background 0.3s ease;

                &.absolute {
                    position:absolute;
                    // top:calc(var(--headerHeight) + 32px);
                }
                &.fixed {
                    position:fixed;
                    top:calc(var(--headerHeight) + 32px);
                }
                
                &:not(.primary) {
                    background:rgba(28,10,50,0.45);
                }
                @media (min-width:568px) {
                    top:calc(var(--headerHeight) + 32px);
                }
            }

            section {
                max-width:calc(1440px + var(--outside-padding) + var(--outside-padding));
        margin: 0 auto;
            }
        }
        
        

        .page-title {
            margin:0 0 32px 0;

            &.secondary {
                position:relative;
                display:inline-block;
                margin:0;
                &:after {
                    content:'';
                    display:block;
                    width:100%;
                    height:4px;
                    background: var(--Gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
                    margin-top:8px;
                }
            }
            &:only-child {
                margin:0;
            }
            &.tertiary {
                margin:0;
            }
        }
        .page-nav {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap:8px;
            margin-bottom:16px;
            // color: var(--color-white);
            .page-title {
                // margin-bottom:16px;
                line-height:40px;
            }
            a {flex:none;color:inherit;height:40px;}

            + .description {
                margin-top:0;
            }
        }
        
        section {
            padding: 0 var(--outside-padding);
            margin-bottom: var(--vertical-space);
            // max-width:1440px;
            // margin-left:auto;
            // margin-right:auto;
            &.carousel {
                padding:0;
                .card-carousel {
                    padding: 0 var(--outside-padding);
                }
            }
            &.intro {
                .dekstop-h1 {
                    margin-bottom:32px;
                }
            }

            &:first-of-type {
                .card-row:first-of-type {margin-top:0;}
            }
        }
    }
    &.vertical-nav {
        padding-left: 88px;
    }
    &.horizontal-nav {
        padding-top: var(--headerHeight);

        main {
            padding-top:48px;
        }
    }
    &.profile {
       
    }
    &.skills {
        main section:not(.intro) {
            margin-bottom: 24px;
        }
    }

    // @media (max-height:400px) {
    //     &.horizontal-nav {
    //         padding-top: 0px;
    //         .swoop {display:none;}
    //         main {
    //             padding-top:var(--vertical-space)
    //         }
    //         .bookmark-btn {
    //             opacity:1;
    //         }
    //     }
    // }
    @media (max-width: $breakpointXS)  {
        //show hamburger
        &.horizontal-nav {
            padding-top: var(--headerHeight);
    
            main {
                // padding-top:var(--vertical-space);
                .intro > h1 {margin-top:0;}
            }
        }
        .swoop {display:none;}
        // .swoop-text {margin-bottom:0;}
        .bookmark-btn {
            opacity:1;
        }
    }
    @media (max-width: $breakpointS) {
        //hide search
        .page-header {
            .search-input {display:none;}
        }
    }
}

.swoop {
    position: absolute;
    width: 100%;
    height: 230px;
    top: -1px;
    left: 0px;
    right:0;
    z-index: -1;
    background-image: url('../images/waves.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center bottom;
    // transform:translateY(100px)
    transition: height 0.3s ease-in-out,opacity 0.3s ease-in-out;
    // display: none;
    opacity:0;
    &.large {
        height:300px;
    }
}
.swoop-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.4px;
    margin:0;
}
.swoop-text {
    position: relative;
    padding: 80px var(--outside-padding);
    color:var(--color-white);
    
    // max-width: 1440px;
    margin: 0px auto;
    min-height:calc(300px - 88px);
    background-image: url('../images/waves.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center bottom;
    .page-nav {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap:8px;
        margin-bottom:16px;
        // color: var(--color-white);
        h1 {
            margin-bottom:16px;
        }
        a {flex:none;color:inherit;height:40px;}
    }

    @media (max-width: $breakpointXS) {
        background-image: url('../images/waveMobile.svg');
        background-repeat: no-repeat;
        // background-size: cover;
        background-size: 100% 100%;
        background-position: center bottom;
        margin-top:-40px;
        padding-top:80px;
        padding-bottom:40px;
        // margin-bottom:-20px;
    }
    
    

    &::after {
        // content:'';
    }
    h1 {
        margin:0 0 16px 0;
    }
}

// .profile .swoop {
//     height:500px;
// }
.header.dark-gradient-bg {
    background-image: url('https://www.pearson.com/jobmatch/assets/waves.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    overflow:hidden;
}

.footer-curve {
    background-image: url('../images/footer-curve-2.svg');
    background-repeat: no-repeat;
    // background-size: cover;
    background-size: 100% 120%;
    background-position: center top;
    position: absolute;
    width: 100%;
    height: 200px;
    bottom: 0px;
    right:0;
    z-index: -1;
}

.card {
    .docked-bottom {
        // position:absolute;
        // bottom:0;
    }
}


.small-space-for-demo {
    // padding-top:24px !important;
    // --vertical-space: 24px;
        .intro {
            .card.action-card {
                min-height:270px;

                .badge-information {
                    padding-top:0;
                    .badge.avatar {
                        // max-height:150px;
                        max-height:125px;
                        // max-width:150px;
                        max-width: 125px;

                        &.Aplus {
                            max-height:125px;
                            max-width:150px;
                            img {
                                margin-top:-8px;
                            }
                        }
                    }
                }
                .card-footer {
                    bottom:calc(24px + 45px);
                    // transform:translateY(50%);
                    z-index: 5;
                    &.Aplus {
                        // bottom:calc(24px + 69px);
                    }
                }
            }
        }
        @media (min-width: ($breakpointS + 1)) {
            .intro {
                .card.action-card {
                    .badge-information {
                        padding-right:180px;
                    }
                }
            }
        }
}
.vertical-navigation {
    position:fixed;
    left:0;
    top:0;
    // bottom:0;
    border-radius: 0;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    width:88px;
    // background: linear-gradient(0deg, #05112A, #05112A), linear-gradient(227.6deg, rgba(109, 1, 118, 0.6) 0%, rgba(109, 1, 118, 0) 66.66%);
    background: linear-gradient(251deg, rgba(109, 1, 118, 0.60) 0%, rgba(109, 1, 118, 0.00) 100%), #05112A;
    // background: linear-gradient(227.6deg, rgba(109, 1, 118, 0.6) 0%, rgba(109, 1, 118, 0) 66.66%), #05112A;
    color:var(--color-white);
    transition: width 0.3s ease-in-out;
    overflow-x: hidden;
    height:100vh;
    padding:24px;
    // padding-top:calc(24px + 56px);
    z-index: 1111;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .menu-btn {
        color:var(--color-white);   
        width:48px;
        height:48px; 
    }

    h1 {
        margin-top:0px;
        margin-bottom:64px;
    }
    .logo {
        position:absolute;
        top:24px;
    }

    .nav-menu {
        margin-top:calc(-64px - 96px);
        // padding: 56px 0;
        // h1 {position:absolute;}
    }

    ul {
        list-style-type: none;margin:0;padding:0;

        li {
            
            a {
                position: relative;
                display: flex;
                align-items: center;
                text-align:left;
                gap:8px;
                color:#989BA3;
                
                font-size: 20px;
                min-height: 50px;
                margin-bottom:8px;
                // border-bottom:4px solid transparent;
                /* identical to box height, or 280% */

                letter-spacing: 0.4px;
                text-decoration: none;
                white-space: nowrap;
                &:after {
                    content:'';
                    position: absolute;
                    width:100%;
                    height:2px;
                    background:transparent;
                    bottom:0;

                }
                &:hover {
                    color:var(--color-light-200);
                    font-weight: 700;
                    &:after {
                        background: var(--color-light-200);
                    }
                }
            }

                .icon {
                    min-width:40px;
                    display: inline-block;
                    text-align: center;
                }
               
        }
    }
    // .icon {
    //     color: #989BA3;
    // }
    [aria-selected=true], .selected {
        color: var(--color-white);
        font-weight: 700;
        // &:after {background:linear-gradient(135deg,#e49d2d,#ea0b7e 51.09%,#851b4a);}
    }
    
    &.expanded {
        width: 276px;
        // .icon {min-width:24px;}
        h1, ul li .text {visibility: visible;}
        [aria-selected=true], .selected {
            &:after {
                // background:linear-gradient(135deg,#e49d2d,#ea0b7e 51.09%,#851b4a);
                background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
            }
        }
    }
    

    h1 {visibility: hidden;min-width:100%;}
    ul li .text {visibility: hidden;}

    
    @media (min-width:768px) {
        height: 100vh;
        &:hover {
            width: 276px;
            // .icon {min-width:24px;}
            h1, ul li .text {visibility: visible;}
            [aria-selected=true], .selected {
                &:after {background:linear-gradient(135deg,#e49d2d,#ea0b7e 51.09%,#851b4a);}
            }
        }
        .menu-icon {display: none;}
    }
}

.MuiTabs-root {
    .MuiButtonBase-root {
        font-family: 'TT Commons', sans-serif;
        text-transform: none;
        font-weight:normal;
        font-size: 20px;
        line-height: 24px;
        padding: 18px 0;
        margin: 0 20px;
        color: inherit;
        min-width:24px;
    
        &:first-of-type {
            margin-left:0;
        }
        &.Mui-selected {
            font-weight:bold;
            color:inherit;
        }
        &.MuiTabs-scrollButtons {
            margin:0;
        }
      
        &:hover, &:focus {
            font-weight: 700;
            // text-shadow: 0 0 0.01px var(--color-black);
        }
        > span {
            display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        }
        .hidden-bold {
            font-weight:700;
            // content: attr(data-text);
            // content: attr(data-text) / "";
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            @media speech {
                display: none;
              }
        }
    }
    .MuiTabs-indicator {
        background: var(--text-default);
        // background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
        height:4px;
    }

}

.horizontal-navigation {
    position:fixed;
        top:0;
        left:0;
        right:0;
        z-index: 1111;
        width:100%;
        background: linear-gradient(to right, #05112A, #3E0953);
        // background-image: url('https://www.pearson.com/jobmatch/assets/waves.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        // overflow:hidden;
        // min-height: 64px;
        color: var(--color-white);


    .tab-container {
        // padding: 0 var(--outside-padding);
        .tab-list {
            overflow-x: auto;
            display:flex;
            gap:16px;
            margin:0;
        }
    }
    
}

.page-wrapper.horizontal-nav {
    .menu-icon {display: none;}

    .main-nav {
        &.tab-container {
            padding:0;
            // .tab-action {
            //     margin: 0 1rem;
            //     white-space:nowrap;
            //     padding-bottom:12px;
            // }
        }
    }
    .page-header > .tab-container {
        position: absolute;
        left:50%;
        transform: translateX((-50%)) translateY(0px);
        align-self: flex-end;
    }
    // @media (max-width:1023px) {
    //     .main-nav.tab-container .tab-action {
    //     // font-size:1rem;
    //     margin: 0 0.75rem;
    //     }
    // }
    @media (min-width: 1320px) {
        .main-nav {
            &.tab-container {
                padding:0;
                .tab-action {
                    // font-size:1.25rem;
                    // margin: 0 30px;
                }
            }
        }
    }

    @media (max-width: 1120px) {
        .header-wrapper {
            padding-left: calc(0.5 * var(--outside-padding));
            .page-header {
                padding-left:8px;
            }
        }
        .menu-icon {
            display: block !important;
            .menu-btn {
                width:48px;
                height:48px;
                color: var(--color-white);
                svg {
                    rect {
                        transition: transform 0.3s ease, opacity 0.3s ease;
                        transform-origin: center;
                        opacity:1;
                    }
                }
                &[aria-expanded=true] {
                    svg {
                        rect {
                            opacity:0;
                            &:first-of-type {
                                transform:rotate(45deg);
                                transform-origin:6px 8px;
                                opacity:1;
                            }
                            &:last-of-type {
                                transform:rotate(-45deg);
                                transform-origin: 5px 16px;
                                opacity:1;
                            }
                        }
                    }
                }
            }
        }
        .nav-menu {
            height:calc(100dvh - var(--headerHeight));

            .tab-item, .megamenu-item {
                
            }
            .icon-btn {
                color:#989BA3;
                width:40px;
            }
            button.tab-action {
                width:calc(100% - 2*var(--outside-padding));
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background:transparent;
                text-align:left;
                // color:#989BA3;
                // width:40px;
            }
            .panel {
                display:flex;
                flex-direction:column;
                 padding: 12px;
                 height:100%;
                 max-height:calc(100dvh - var(--headerHeight));
                 overflow:hidden;
                 background: linear-gradient(to right, #05112A, #3E0953);
                 .panel-header {
                    // display:flex;
                    // flex-direction: row;
                    // align-items: center;
                    // justify-content: flex-start;
                    // gap:8px;
                     padding: 8px 8px 8px 0px;
                     border-bottom:1px solid var(--color-border);

                     .flex-row {
                        align-items: center;
                        justify-content: flex-start;
                        // margin-bottom:16px;
                        h2, h3 {margin:0;}
                     }
                 }
                 .panel-title {
                     font-size:1.125rem;
                     line-height:1.5rem;
                     font-weight:700;
                     margin:18px 0 0 0;
                     padding:0 0 0 40px;
                 }
                 ul {
                    padding:8px 0;
                    flex:1;
                    overflow-y:auto;
                 }

                 &.subpanel {
                    ul li a, ul li button {
                        margin-left:40px;
                    }
                 }
                 &.subsubpanel {
                    ul li a, ul li button {
                        margin-left:40px;
                    }
                 }
                
             }
            nav {
                padding-bottom: 24px;
            }
            ul {
                list-style: none;
                margin:0;
                padding:0;
                li {
                    a, button {
                        background:transparent;
                        border:none;
                        position:relative;
                        display:inline-block;
                        color:var(--color-white);
                        font-weight:normal;
                        padding: 8px 0;
                        text-decoration:none;
                        color:#989BA3;
                        margin: 6px var(--outside-padding);
                        font-size: 20px;

                        &:after {
                            content:'';
                                position: absolute;
                                // width:100%;
                                height:2px;
                                background:transparent;
                                bottom:0;
                                left:0;
                                right:0;
                                
                        }
                        &:hover {
                            color:var(--color-light-300);
                            // font-weight: 700;
                            &:after {
                                background: var(--color-light-300);
                            }
                        }
                    }
                    &.active {
                        a {
                            color:var(--color-white);
                            font-weight:700;
                            &:after {
                                
                                // background:linear-gradient(135deg,#e49d2d,#ea0b7e 51.09%,#851b4a);
                                background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
                            }
                        }
                    }
                }
            }
        }
        .page-header > .tab-container {
            display:none;
        }
    }
}

// @media (max-height:400px) {
//     .horizontal-navigation {
//         position: relative;
//     }
// }


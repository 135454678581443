.profile-content {
position: relative;
padding-bottom:80px;
font-size:20px;
&:before {
    // content: " ";
    position: absolute;
    bottom: -36px;
    left: 50%;
    transform:translateX(-50%);
    width: 100vw;
    height: 600px;
    background: linear-gradient(215.84deg, rgb(109, 1, 118) -45.86%, rgba(109, 1, 118, 0) 55.93%), rgb(5, 17, 42);
}


    @media (min-width:1024px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap:24px;

        .col-1 {
            max-width:40%;
        }

        .card {
            &:last-of-type {
                margin-bottom:0;
            }
        }
    }

    .card {
        margin-bottom:24px;
        position: relative;
        border-radius:8px;

        .card-header {
           
            margin-bottom:24px;
           
            .flex-row {
                align-items: center;
                flex-direction: row;
                // margin-bottom:24px;
            }
            h2 {
                margin:0;
            }

            .add-btn {
                color:var(--text-default);
                background-color: var(--bg-level2);
                width:40px;
                height:40px;
            }
        }
        .card-footer {
            display: flex;
            justify-content: flex-end;
        }

        .edit-btn {
            position:absolute;
            right:24px;
            top:24px;
            color:var(--text-default);
        }
    }
    .main-col {
        .card .card-header:not(.no-border) {
            border-bottom: 1px solid var(--color-border);
            padding-bottom:24px;

        }
    }
    .about-me-card {
        text-align:center;
        h1 {
            margin-bottom:16px;
        }
        h1, h2, p {
            text-align:center;
        }
        .avatar {
            img {transform: scale(1.1);}
        }
        .school {text-align:center;}
    }
    .pathway-list {
        display:flex;
        flex-direction: row;
        overflow-x: auto;
        // flex-wrap:wrap;
        padding-bottom:4px;
        // gap:24px;
        .pathway-card {
            width:130px;
            padding: 36px 12px 12px 12px;
            margin-bottom:0;
            .card-label {
                padding:8px;
                font-size:12px;
                top:8px;
                left:8px;
            }

            .image {
                max-height:70px;
                max-width:70px;
                img {
                    // margin-top:-14px;
                }
            }
            .card-title {
                max-height:48px;
                overflow: hidden;
                text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            }
            .issuer {
                overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            }
            &:not(:first-of-type) {
                margin-left:24px;
                &:before {
                    content:url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path d="M14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.34315 0.928932C7.95262 0.538408 7.31946 0.538408 6.92893 0.928932C6.53841 1.31946 6.53841 1.95262 6.92893 2.34315L12.5858 8L6.92893 13.6569C6.53841 14.0474 6.53841 14.6805 6.92893 15.0711C7.31946 15.4616 7.95262 15.4616 8.34315 15.0711L14.7071 8.70711ZM0 9H14V7H0V9Z" fill="black"/></svg>');
                    position:absolute;
                    display:block;
                    width:15px;
                    height:16px;
                    left:-20px;
                    top:calc(50% - 12px);
                }
            }
        }
    }
    .experience-entry, .education-entry {
        position: relative;

        .icon-btn {
            color:var(--text-default);
            position:absolute;
            right:0;
            top:0;
            width:40px;
                height:40px;
        }
        .education-info {
            padding-right:48px;
            p {
                margin:0;
            }
        }
        .employer, .desktop-h2 {
            margin-right:48px;
        }
        .experience-dates {
            font-size:22px;
            color:var(--color-text-muted);
        }
    }
    .col-1 {flex:1;}
    .col-2 {flex:2;}
    .avatar {
        background: #C8C9CB;
        border: 4px solid #FEFEFE;
        border-radius: 80px;
        width:160px;
        height:160px;
        margin:auto;
        overflow:hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;

    }

    .chip-list {
        .chip-btn {
            font-weight:600;
            color: var(--text-muted);
            font-size:14px;
        }
    }
}

.empty-area-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:12px;
    background: var(--bg-level0);
    border: 2px dashed var(--color-border);
    border-radius:8px;
    padding:32px;
    width:100%;
    color: var(--color-text-muted);
    font-size:18px;

    .circle {
        display: block;
        width:40px;
        height:40px;
        border-radius:20px;
        padding:8px;
        // background-color: var(--bg-level0);
        background-color: #122142;
        // background: var(--bg-level2);
        color:var(--color-white);
    }
    &:hover, &:focus {
        // background-color: #12214233;
        // background: rgba(2,9,23,0.1);
        border-color:rgba(2,9,23,0.1);
        background: var(--bg-level2);
        // color:var(--color-white);
        border-color: #122142;
        // border-color:transparent;
        position: relative;
        
        .circle {
            // background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
            // background: rgba(2,9,23,0.1);
            // background-color: #A6ABB8;
            // background: var(--bg-level0);
            // background: rgb(18, 33, 66);
            // color:var(--color-white);
        }
       
    }
}
.badge-list {
    .badge-container {
        border-bottom:1px solid var(--color-border);
        padding: 24px 0;
        &:last-of-type {
            border-bottom:0;
            padding-bottom:0;
        }
        .badge-information {
            gap:30px;
            align-items: flex-start;
            flex-direction: row;
            .badge-details {
                h2,p {
                    text-align: left;
                }
            }

        }
        .badge {
            max-width:84px;
            &.comptia {
                img {transform:scale(1.2) translateY(-5px);}
            }
        }
    }
    .badge-actions {
        margin-top:18px;
        display:flex;
        justify-content: flex-end;
    }
}
.profile.illustration {
    position: relative;
    display: flex;
    height: 350px;
    overflow: hidden;
    background: rgb(5, 17, 42);
    

    &:before {
        content: "";
        position: absolute;
        background: linear-gradient(94deg, rgba(255, 255, 255, 0.1) 0.71%, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100.32%);
        transform: rotate(253deg);
        filter: opacity(0.05);
        width: 24%;
        padding-top: 33%;
        bottom: -50%;
        left: 3%;
        z-index: 2;
    }

    .puppy {

        position: absolute;
        width: 412px;
        height: 306px;
        bottom: 0px;
        right: 150px;
        background-image: url('https://www.pearson.com/jobmatch/assets/images/puppy.png');

        @media (min-width: 834px) and (max-width: 1280px) and (orientation: landscape) {
            right: 112px;
        }
        @media (max-width:833px) {
            right:10px;
        }
    }
    .css-sq2z9g {
        bottom: 0px;
        background: transparent;
        width: 100%;
        height: 154px;
        z-index: 2;
        position: absolute;

        @media screen and (max-width: 1180px){
            bottom: 200px;}

    }
}
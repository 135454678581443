.tab-container {
    .tab-list-container {
        position: relative;
        // margin-bottom:-8px;
    }
    .tab-list {
        list-style: none;
        // margin:0 0 30px 0;
        // padding:0 0 8px 0;
        overflow-x:auto;
        

        li {
            display:inline-block;
        }
    }
    .tab-action {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color:var(--color-black);
        cursor: pointer;
        margin: 0px 10px;
        padding: 6px 0 16px 0;
        text-decoration: none;
        background: none;
        border: 0;
        border-bottom: 4px solid transparent;
        text-align:center;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;   
        white-space:nowrap;
        .hidden-bold {
            font-weight:700;
            // content: attr(data-text);
            // content: attr(data-text) / "";
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            @media speech {
                display: none;
              }
        }
        &[aria-selected=true] {
            font-weight:700;
            border-bottom: 4px solid transparent;
            // border-image: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%) 1 / 1 / 0 stretch;

            // linear-gradient(135deg, rgb(228, 157, 45) 0%, rgb(234, 11, 126) 51.09%, rgb(133, 27, 74) 100%) 1 / 1 / 0 stretch
        }
        &:hover {
            // border-bottom: 4px solid #989BA3;
            font-weight:700;
        }

        @media (min-width: ($breakpointS + 1)) {
            // margin: 0px 36px;   
        }
    }
    // .tab-item:first-of-type {
    //     .tab-action {
    //         margin-left:0;
    //     }
    // }
    .tab-slider {
        // display: none;
        position: absolute;
        height:4px;
        width:auto;
        bottom:0px;
        background:linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
        transition:left .3s ease, width .2s ease;
    }
    &.main-nav {
        .tab-action {
            color:var(--color-white);
        }
    }

    &.channels {
        background-color: #13203B;

        .tab-list {
            border-bottom: 1px solid #535E74;
        }
        
        .tab-action {
            color: var(--color--ui-02);
        }

        .tab-slider {
            
            background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
        }
    }

    &.pill {
        .tab-slider {
            display: none;
        }
        .tab-list {
            // border-top: 1px solid #ccc;
            // padding-top: 10px;
            // margin-top: -10px;
            // border-bottom: 1px solid #ccc;
            // padding-bottom: 10px;
            background-color: #535E744D;
            border-radius:30px;
            padding:4px;
            width:fit-content;
            margin-bottom:20px;
        }
        .tab-item button {
            margin: 0;
            padding: 5px 20px;
            font-size: 16px;
            // color:white;
        }
        .tab-item.active {
            button {
                background: #000;
                color: #fff;
                border-radius: 100px;
            }
        }
    }
}

.course .tab-container {
    &.pill {
        .tab-list {
            background: #182849;
            margin: 30px auto;
        }
        .tab-action {
            color: #fff;
        }
        .tab-item.active .tab-action {
            background: #fff;
            color: #000;
        }
    }
}
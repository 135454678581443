.page-wrapper.sign-in {
    min-height:100vh;
    padding-top:70px;
    display:flex;
    flex-direction:column;



    .footer-container {
        margin-top:0;
    }
}
.create-account {
    flex:1;
    h1 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        margin:0 0 10px 0;
         + p {
            margin:0;
         }
    }

    .badge-earned {
        background-color:#05112A;
        background: linear-gradient(55deg, #05112A 38.55%,#722351 97.87%);
        padding:66px;
        color:var(--color-white);

        .badge-information {
            margin-top:40px;
            margin-left:auto;
            margin-right:auto;
            border-radius: 16px;
            // opacity: 0.12;
            background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 1.64%, rgba(255, 255, 255, 0.05) 1.65%, rgba(0, 133, 255, 0.00) 141.29%);
            text-align:center;
            padding: 60px 40px;
            flex-direction: column;
            align-items: center;

            .badge-title {
                font-size:30px;
            }
            .badge-issuer {
                font-size:24px;
                margin: 10px 0 0 0;
            }
        }
    }
    .create-account-form-container {
        padding:60px;
        max-width:600px;
        .gr-fieldset {width:100%;}
        legend {
            font-size:30px;
            font-weight:700;
            line-height:36px;
            + p {
                margin-top:10px;
                margin-bottom:30px;
            }
        }

        .flex-row {
            margin-bottom:20px;
            gap:15px;

            .form-input {
                width:100%;
            }
        }
        .button.primary {
            width:100%;
        }
        .disclaimer {
            font-size:0.825em;
            margin-top:10px;

        }
    }
    

    @media (min-width: 1024px) {
        display:flex;
        flex-direction:row;
        height:100%;
        .badge-earned, .create-account-form-container {
            width:50%;
            display:flex;
            flex-direction:column;
            align-items: flex-start;
            justify-content: center;
        }

        

    }

}
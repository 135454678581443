.unauth-home {
    background-color: #F7F9FD;
    button.light.outline {
        color: var(--swatch-light-400, #D2D7E2);
        outline: 2px solid var(--swatch-light-400, #D2D7E2)
    }

    .page-header {
        // background: linear-gradient(to right, #05112A, #3E0953);
        // // background-image: url('https://www.pearson.com/jobmatch/assets/waves.svg');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center top;
        position:absolute;
        top:0;
        z-index: 111;
        color: var(--color-white);
    }
    h2 {
        font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    margin: 0;
    color:var(--color-black);
    }
    h3 {
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        margin: 0;
        color:var(--color-black);
    }

    .illustration.slash {
        position: absolute;
        width: 1500px;
        height: 1500px;
        left: -950px;
        top: -750px;
        background: linear-gradient( 273.26deg,rgba(155,161,173,0.08) -5.26%,rgba(232,238,250,0.08) 39.67%,rgba(232,238,250,0.08) 55.59%,rgba(155,161,173,0.08) 103.93% );
        -webkit-transform: rotate(31.39deg);
        -moz-transform: rotate(31.39deg);
        -ms-transform: rotate(31.39deg);
        transform: rotate(31.39deg);

        &.mini {
            background: linear-gradient(273deg, rgba(196, 196, 196, 0.15) -5.26%, rgba(247, 249, 253, 0.07) 39.67%, rgba(247, 249, 253, 0.07) 55.59%, rgba(196, 196, 196, 0.15) 103.93%);
            mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 9.71%, #FFF 47.46%, #FFF 62.57%, rgba(255, 255, 255, 0.00) 100.32%);
            width: 584.403px;
            height: 634.864px;
            transform: rotate(147.328deg) translate(50%, -50%);
            flex-shrink: 0;
            left:0;
            bottom:0;
            top:unset;
            opacity:0.5;
            overflow:hidden;
        }
    }
    .illustration.wave {
        // position: absolute;
        // top: 0;
        // height: 378px;
        // width: 100%;
        // background: linear-gradient(214.54deg,#6D0176 -53.59%,rgba(109,1,118,0) 55.69%);
        // z-index: -10;

        position: absolute;
        left: 0;
        bottom: -25px;
        width: 100%;
        height: 684px;
        background: url('https://www.pearson.com/jobmatch/_next/static/media/wave.3d4ac462.svg') no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        z-index: -5;
    }
    section:not(.intro) {
        position: relative;
        
        
        padding: 80px 0 80px 0;
        overflow:clip;
        background-color: #F7F9FD;
        .section-inner {
            max-width: 1152px;
            margin: 0 auto;
           padding: 0 16px;

           > p {
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.4px;
            // color: #FEFEFE;
            margin: 24px auto 0;
            max-width: 760px;
            color: var(--color-black);
        }
        }

        
    }
    .button-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align:center;
        margin-top:16px;
    }
    .careers-group {
        margin-top:56px;
    }
    .careers-group + .button-row {
        margin-top:72px;
        padding-bottom:100px;
    }
    .card-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // flex-wrap:wrap;
        gap:24px;
        margin-top:32px; //24px from card
        h3 {
            margin-top: 40px;
            text-align: center;
        }

        .description {
            font-size: 24px;
            text-align: center;
            line-height: 28px;
            letter-spacing: 0.4px;
            color: #60646D;
            mix-blend-mode: luminosity;
            margin-top: 16px;
            margin-bottom: 0;
        
        }
        .card {max-width:600px;}

        @media (min-width:880px) {
            // flex-direction: column;
            align-items: flex-start;
            flex-direction: row;
            justify-content: space-between;
        // align-items: flex-start;
        &.courses {
            flex-direction: column;
            align-items: center;
        
        }
        .card {max-width:600px;}

            .course-card {
                max-width:600px;
                min-height: 210px;
                min-width: 0;
                display: flex;
                gap:16px;
                text-align: left;
                .image {width:348px;flex:none;}
                h3 {margin-top:16px;text-align: left;color:var(--color-white);}
                p {text-align: left;color:var(--color-white);}
            }
        }

        @media (min-width:1110px) {
            flex-direction: row;
            align-items: flex-start;
            &.courses {
                flex-direction: row;
            }
            .course-card {
                display: block;
                flex: 1 1 368px;
                // min-width:368px;
                .image {width: 100%;}
            }
        }
    }
    .header {
        position: fixed;
        top: 0;
        width: 100%;
        height: 78px;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        padding: 19px 15px;
        background: linear-gradient(223.42deg,#6D0176 -36.08%,rgba(109,1,118,0) 53.92%) 0 0/auto 543px no-repeat,#05112A;
        .logo {
            width: 133.68px;
            height: 34px;

            @media (max-width:414px) {
                width:34px;
                overflow: hidden;
            }
        }
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
        }
    }
    section.intro {
        position: relative;
        z-index: 5;
        background: linear-gradient(223.42deg,#6D0176 -36.08%,rgba(109,1,118,0) 53.92%),#05112A;
        padding-top: 78px;
        height: 943px;
        clip-path: ellipse(170% 90% at 20% -30px);
        color:var(--color-white);
        text-align:left;
        overflow:hidden;

        .illustration {
            position: absolute;
            // left: 0;
            left:10%;
            top:50%;
            width: 90%;
            height: 60%;
            background: url('https://www.pearson.com/jobmatch/_next/static/media/header-clipart.0e8390c7.png') no-repeat;
            z-index: -10;
            animation: fadeIn 800ms ease-out;
        }
        .intro-inner {
            padding: 0 16px;
            max-width: 1152px;
            margin: 0 auto;
            animation: fadeIn 800ms ease-out;
            p {
                max-width:600px;
            }
        }
        h1 {
            margin-top: 56px;
            font-weight: bold;
            font-size:48px;
            line-height:48px;
        }
        p {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.4px;
            margin-top: 16px;
            width: auto;

            + button {
                margin-top:40px;
            }
        }

        + section {
            position: relative;
            padding-top:250px;
            margin-top: -250px;
            background: #F7F9FD;
            overflow: hidden;
        }

        @media (min-width:769px) {
            height: 543px;
            clip-path: ellipse(120% 90% at 20% -30px);
            .illustration {
                position: absolute;
                // right: 0;
                left:50%;
                top:unset;
                width: 50%;
                height: 400px;
                background: url('https://www.pearson.com/jobmatch/_next/static/media/header-clipart.0e8390c7.png') no-repeat;
                z-index: -10;
               
            }
            .intro-inner {
                p {
                    max-width:45%;
                }
            }
        }
        @media screen and (min-width: 1025px) {
            height: 543px;
            clip-path: ellipse(120% 90% at 20% -30px);
            .illustration {
                position: absolute;
                // right: 0;
                top:unset;
                width: 633.57px;
                height: 684px;
                background: url('https://www.pearson.com/jobmatch/_next/static/media/header-clipart.0e8390c7.png') no-repeat;
                z-index: -10;
               
            }
        }
    }
section.dark-bg {
    .section-inner {
        h2, p {
            color:var(--color-white);
        }
    }
}
    section.dark-gradient-bg {
        clip-path:ellipse(190% 90% at 20% 85px);

        .section-inner {
            h2, p {
                color:var(--color-white);
            }
        }

        

        @media (max-width:768px) {
            padding-bottom:100px;
        }

        @media (min-width:769px) {
            clip-path:ellipse(130% 90% at 20% 85px);
        }
    }
    section.dark-gradient-bg +section {
        position: relative;
        padding-top:250px;
        margin-top: -250px;
        background: #F7F9FD;
        overflow: hidden;
    }
    .insights-path {
        display: flex;
        margin-bottom:70px;
    }
    .insights-step {
        flex:none;
        max-width:calc(1210px * 0.25);
        text-align: center;
        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #FEFEFE;
            margin-top: 15px;
        }
        .image {
            img {width:100%;max-width:100%;}
        }
        &:not(:first-of-type) {
            margin-left: -30px;
        }

        &:nth-of-type(1) {
            margin-top: 60px;
        }

        &:nth-of-type(2) {
            margin-top: 215px;
            // margin-left: -50px;
        }

       &:nth-of-type(3) {
            margin-top: 100px;
        }

        &:nth-of-type(4) {
            margin-top: 160px;
        }
    }
    .course-card {
        background-color: #122142 !important;
        border-color:#203B75;
        color:var(--color-white);
        text-align:left;
        border-radius: 16px;
        cursor: pointer;
        outline: none;
        max-width: 400px;
        // min-width:368px;
        // width:100%;
        // flex:1 0 368px;
        min-height: 296px;
        padding: 8px;
        .image {
            position: relative;
            width: 100%;
            // height: 240px;
            overflow: hidden;
            border-radius: 8px;
            border: 2px solid #667080;
            background: linear-gradient(0deg,rgba(2,9,23,0.24),rgba(2,9,23,0.24));
            height: 208px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
            }
        }
        h3 {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin:12px 12px 0 12px;
            color:var(--color-white);
            text-align: left;
        }
        .description {
            text-align:left;
            color:var(--color-white);
            font-weight: 300;
            font-size: 22px;
            line-height: 24px;
            margin:4px 12px 8px 12px;
        }

        &:hover {
            // padding: 6px;
            -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
            // border: 2px solid #203B75;

            img {
                transform: scale(1.1);
            }
        }
    }
}
.dark-bg {
    background-color: var(--color-dark-blue) !important;
    color:var(--color-white);
}
.dark-gradient-bg {
    background: linear-gradient(214.54deg,#6D0176 -53.59%,var(--color-dark-blue) 55.69%);
    background-color: var(--color-dark-blue) !important;
    color:var(--color-white);
    
}
.border-top {
    border-top:8px solid #0CC0AD;
}
.border-top-gradient {
    // border-top:8px solid transparent;
    position: relative;
    &:before {
        content:'';
        display:block;
        background: linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%);
        height:8px;
        width:100%;
        position:absolute;
        top:0;
        left:0;
        right:0;
        z-index: 11;
    }
}
.overflow-x {
    overflow-x: auto;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
.fade-in-section .card-row .card {
    
    animation-fill-mode: forwards;
    opacity: 0;
}
  .is-visible .card-row {
    .card {
        animation: fadeIn 800ms ease-out;
        animation-fill-mode: forwards;
    }
    .card:nth-of-type(1) {
        animation-delay: 200ms;
        // transition-delay: 400ms;
    }
    .card:nth-of-type(2) {
        animation-delay: 400ms;
        // transition-delay: 800ms;
    }
    .card:nth-of-type(3) {
        animation-delay: 600ms;
        // transition-delay: 1200ms;
    }
  }

  @keyframes fadeIn {
    0% {
        opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    }
    100% {
        opacity: 1;
    transform: none;
    visibility: visible;
    }
  }

  .fadeIn {
    animation: fadeInSmall 400ms ease-out;
    animation-fill-mode: forwards;
  }

  .fadeInUp {
    animation: fadeInUp 400ms ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInSmall {
    0% {
        opacity: 0;
    // transform: translateY(20vh);
    visibility: hidden;
    }
    100% {
        opacity: 1;
    transform: none;
    visibility: visible;
    }
  }
  @keyframes fadeInUp {
    0% {
        opacity: 0;
    transform: translateY(20px);
    visibility: hidden;
    }
    100% {
        opacity: 1;
    transform: none;
    visibility: visible;
    }
  }
  .fadeOut {
    animation: fadeOutCard 300ms ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes fadeOutCard {
    0% {
        opacity: 1;
    transform: none;
    visibility: visible;
        
    }
    100% {
        opacity: 0;
    // transform: translateY(20vh);
    visibility: hidden;
    }
  }
/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/
button {
    cursor: pointer;
  }
  button[disabled] {
    pointer-events: none;
    color: var(--color--interactive-disabled-02);
    background-color: inherit;
  }
.button {
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-family: "TT Commons", sans-serif;
    font-weight: 700;
    letter-spacing: 0.4px;
    background:transparent;
    border:none;
    position: relative;
    // margin:2px;
    border-radius:50px;
    transition:background 0.3s ease;
    text-decoration:none;
    
    &.large {
      min-height:56px;
      min-width:56px;
      padding: 8px 40px;
      font-size: 24px;
      line-height: 40px; /* 166.667% */
      gap: 12px;
      &.icon-left {
        padding-left: 32px;
      }
      &.icon-right {
        padding-right: 32px;
      }
      svg {
        width:32px;
        height:32px;
      }
    }
    &.medium {
      min-height:48px;
      min-width:48px;
      padding: 8px 32px;
      font-size: 20px;
      line-height: 32px; /* 160% */
      gap:12px;
      &.icon-left {
        padding-left: 24px;
      }
      &.icon-right {
        padding-right: 24px;
      }
      svg {
        width:24px;
        height:24px;
      }
    }
    &.small {
      min-height:40px;
      min-width:40px;
      padding: 8px 24px;
      font-size:18px;
      line-height: 18px;
      gap:8px;
      &.icon-left {
        padding-left: 16px;
      }
      &.icon-right {
        padding-right: 16px;
      }
      svg {
        width:16px;
        height:16px;
      }
    }
    &.xsmall {
      min-height:32px;
      min-width:32px;
      padding: 8px 16px;
      font-size:14px;
      line-height: 16px;
      gap:4px;
      &.icon-left {
        padding-left: 12px;
      }
      &.icon-right {
        padding-right: 12px;
      }
      svg {
        width:16px;
        height:16px;
      }
    }
    &.icon-btn {
      padding:0;
      // width:56px;
      // height:56px;
      &.white {
        color:var(--color-white);
      }
      
    }
    &:disabled {
      opacity:0.32;
    }
    
    // Colors
    &.primary {
      background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
      color: var(--color-white);
      &:not(:disabled):hover {
        background:linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
      }
      &:not(:disabled):focus-visible {
        outline:0;
        // margin:-2px;
        background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
        
        box-shadow: 0px 0px 0px 2px #B41863;
        &:after {
          position:absolute;
          content:'';
          left:0px;
          top:0px;
          width:calc(100% - 4px);
          height:calc(100% - 4px);
          border: 2px dashed var(--color-border, #DDE3EE);
          border-radius:50px;
        }
      }
    }
    &.light {
      &.filled {
        color: var(--swatch-dark-800, #272D39);
        background-color: var(--color-white, #fefefe);
        &:not(:disabled):hover {
          // background: var(--swatch-dark-700, #393F4A);
          color: var(--color-white, #fefefe);
         
        }
        @media (prefers-color-scheme:dark) {
          color: var(--color-light-200);
          background-color: var(--bg-level0);
        }
      }
      &.outline {
        // color: var(--swatch-light-400, #D2D7E2);
        // outline: 2px solid var(--swatch-light-400, #D2D7E2)
        outline: 2px solid var(--swatch-dark-800, #272D39);
        outline-offset:-2px;
        color: var(--swatch-dark-800, #272D39);
        // placeholder
        &:not(:disabled):hover {
          background: var(--swatch-dark-700, #393F4A);
          color: var(--color-white, #fefefe);
          &.outline {
            outline: 2px solid var(--swatch-dark-700, #393F4A);
          }
        }
        &:not(:disabled):focus-visible {
          background: var(--swatch-dark-700, #393F4A);
          color: var(--color-white, #fefefe);
          box-shadow: 0px 0px 0px 2px #393F4A;
          outline:0;
          &.outline {
            outline: 2px solid var(--swatch-dark-700, #393F4A);
          }
          
          &:after {
            position:absolute;
            content:'';
            left:0px;
            top:0px;
            width:calc(100% - 4px);
            height:calc(100% - 4px);
            border: 2px dashed var(--color-border, #DDE3EE);
            border-radius:50px;
          }
        }

        @media (prefers-color-scheme:dark) {
          outline-color:#fefefe; //not sure if this is right
          color:#fefefe;//not sure if this is right
        }
      }
    }
    &.dark {
      &.filled {
        background-color: var(--swatch-dark-800, #272D39);
        color: var(--color-white, #fefefe);
      }
      &.outline {
        color: var(--color-default, #020917);
        outline: 2px solid var(--swatch-dark-800, #272D39);
        outline-offset:-2px;
        
      }
      &.clear {
        color: var(--color-default, #020917);
      }
     
      &:not(:disabled):hover {
        background: var(--swatch-dark-700, #393F4A);
        color: var(--color-white, #fefefe);
        &.outline {
          outline: 2px solid var(--swatch-dark-700, #393F4A);
        }
      }
      &:not(:disabled):focus {
        background: var(--swatch-dark-700, #393F4A);
        color: var(--color-white, #fefefe);
        box-shadow: 0px 0px 0px 2px #393F4A;
        outline:0;
        &.outline {
          outline: 2px solid var(--swatch-dark-700, #393F4A);
        }
        
        &:after {
          position:absolute;
          content:'';
          left:0px;
          top:0px;
          width:calc(100% - 4px);
          height:calc(100% - 4px);
          border: 2px dashed var(--color-border, #DDE3EE);
          border-radius:50px;
        }
      }
    }
  }
  
  /*------------------------------------*\
    #UNSTYLED
  \*------------------------------------*/
  .button-unstyled {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
  .button-unstyled::before {
    display: none;
    background: transparent;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  /*------------------------------------*\
    #text button
  \*------------------------------------*/
  .button-link, .link-btn {
    font-size:18px;
    font-weight:700;
    background-color: transparent;
    border: none;
    color: var(--text-link);
      text-decoration:underline;
      padding:0;

      &:hover {
        text-decoration:none;
        color: var(--text-link-hover);
      }

  }
  .button-link.with-icon, .link-btn.with-icon {
    text-decoration: none;
    font-weight:600;
    white-space: nowrap;
    
    svg {
      margin-right:8px;
    }
  }
  .button-link svg, .link-btn svg {
    vertical-align: bottom;
  }

  .circle-btn {
    width:48px;
    height:48px;
    padding: 12px;
    border-radius:50%;
    background: #e8eefa;
    border: 1px solid var(--color-border);
  }
  
  .btn-centered {
    margin: 0 auto;
    width: 100%;
  }

  a {
    color: var(--text-light-theme-link, #655591);
    text-decoration:underline;
    font-weight: 700;

    &.white {
      color:var(--color-white);
      
    }
    @media (prefers-color-scheme:dark) {
      color:var(--text-dark-theme-link, #9B81E2);
    }
  }

  .slider-nav-button {
    background: #020917;
    border: 1px solid #393F4A;
    color:var(--color-white);
    width:48px;
    height:48px;
    padding:12px;
    border-radius:40px;

    &:hover, &:active {
      box-shadow: 0px 0px 16px 0px #05112A;
      border: 1px solid #F7F9FD;
      background: #05112A;
      // backdrop-filter: blur(12px);
    }
    &:focus {
      border: 1px solid #05112A;
      background: #05112A;
      box-shadow: 0px 0px 16px 0px #05112A;
      // backdrop-filter: blur(12px);
      outline: 2px dashed var(--swatch-light-100, #F7F9FD);
      outline-offset:-2px;
    }

  }

  .back-to-top {
    position:fixed;
    bottom:40px;
    right:40px;
    background-color: var(--color-dark-blue);
    color:white;
    opacity:0.8;
    border:1px solid white;
    border-radius:8px;
    z-index: 99999;
    width:48px;
    height:48px;
    padding:11px;
    opacity: 0;
    &.show {
      opacity:1;
    }
  }
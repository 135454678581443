@charset "UTF-8";
@import url("https://static.studychannel-prd.pearsonprd.tech/fonts/tt-commons.css");
:root, .light-mode {
  --text-default: #020917;
  --text-inverse: #FEFEFE;
  --text-muted: #60646D;
  --color-border: #DDE3EE;
  --bg-level0: #fefefe;
  --bg-level1: #F7F9FD;
  --bg-level2: #E8EEFA;
  --color-dark-blue: #05112A;
  --color-dark-purple: #6D0176;
  --color-medium-blue: #122142;
  --color-indigo: #1D3058;
  --grey-light1: #E0E7F0;
  --color-light-base: #FEFEFE;
  --color-light-100: #F7F9FD;
  --color-light-200: #E8EEFA;
  --color-light-300: #DDE3EE;
  --color-light-400: #D2D7E2;
  --color-light-500: #C7CCD6;
  --color-light-600: #BCC1CB;
  --color-light-700: #B2B6BF;
  --color-light-800: #A7ABB4;
  --color-light-900: #989BA3;
  --color-dark-base: #020917;
  --color-dark-100: #B5B7BB;
  --color-dark-200: #9FA2A7;
  --color-dark-300: #898D93;
  --color-dark-400: #777B83;
  --color-dark-500: #60646D;
  --color-dark-600: #4C515B;
  --color-dark-700: #393F4A;
  --color-dark-800: #272D39;
  --color-dark-900: #161C29;
  --color-plum: #6D0176;
  --color-success: #19a292;
  --color-success-500: #1F897C;
  --color-bright-teal: #0CC0AD;
  --text-light-theme-link: #655591;
  --text-light-theme-link-hover: #4C406B;
  --text-dark-theme-link: #9B81E2;
  --text-dark-theme-link-hover: #B79BFF;
  --border-radius: 16px;
  --card-corner-default: var(--border-radius);
  --color-white: #fefefe;
  --color-black: #020917;
  --gradient-01: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
  --gradient-01-hover: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
  --checkbox-border: #60646D;
  --checkbox-checked: #1D3058B2;
  --column-gap: 24px;
  --color-dark-card: #122142;
  --text-link:var(--text-light-theme-link);
  --text-link-hover: var(--text-light-theme-link-hover);
  --interactive-disabled: var(--color-light-400);
  --tooltip-background:#272D39;
  --tooltip-border:#60646D;
  --map-glow-bg:rgba(164, 168, 255, 0.3);
  --map-glow-border:linear-gradient(45deg, #FFE4a7,#f49587, #7825CB, #4b9ce8, #2af6ff);
}

.dark-mode {
  --bg-level0: #020917;
  --bg-level1: #161C29;
  --bg-level2: #272D39;
  --text-default: #fefefe;
  --text-inverse: #020917;
  --text-muted: #989BA3;
  --tooltip-background:#E8EEFA;
  --tooltip-border:#DDE3EE;
  --map-glow-bg:rgba(164, 168, 255, 0.3);
  --map-glow-border:linear-gradient(45deg, #FFE4a7,#f49587, #7825CB, #4b9ce8, #2af6ff);
  --color-border: #393F4A;
  --text-link:var(--text-dark-theme-link);
  --text-link-hover: var(--text-dark-theme-link-hover);
  --interactive-disabled: var(--color-dark-600);
}
@media (prefers-color-scheme: dark) {
  :root, .dark-mode {
    --bg-level0: #020917;
    --bg-level1: #161C29;
    --bg-level2: #272D39;
    --text-default: #fefefe;
    --text-muted: #989BA3;
    --text-inverse: #020917;
    --color-border: #393F4A;
    --text-link:var(--text-dark-theme-link);
    --text-link-hover: var(--text-dark-theme-link-hover);
    --interactive-disabled: var(--color-dark-600);
    --tooltip-background:#E8EEFA;
    --tooltip-border:#DDE3EE;
    --map-glow-bg:rgba(164, 168, 255, 0.3);
    --map-glow-border:linear-gradient(45deg, #FFE4a7,#f49587, #7825CB, #4b9ce8, #2af6ff);
  }
}
@font-face {
  font-family: "TT Commons";
  font-display: block;
  src: url("../fonts/ttcommons-medium.woff2") format("woff2"), url("../fonts/ttcommons-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  font-style: normal;
  font-display: block;
  src: url("../fonts/ttcommons-mediumitalic.woff2") format("woff2"), url("../fonts/ttcommons-mediumitalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  font-display: block;
  src: url("../fonts/ttcommons-demibold.woff2") format("woff2"), url("../fonts/ttcommons-demibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  font-style: normal;
  font-display: block;
  src: url("../fonts/ttcommons-demibolditalic.woff2") format("woff2"), url("../fonts/ttcommons-demibolditalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  font-display: block;
  src: url("../fonts/ttcommons-bold.woff2") format("woff2"), url("../fonts/ttcommons-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  font-style: normal;
  font-display: block;
  src: url("../fonts/ttcommons-bolditalic.woff2") format("woff2"), url("../fonts/ttcommons-bolditalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
body {
  font-family: "TT Commons", sans-serif;
  color: var(--text-default);
}

.desktop-h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}

.desktop-h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

.desktop-h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.desktop-h3-regular {
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.mobile-h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.400000006px;
  text-align: left;
}

.desktop-h4, .mobile-h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.4px;
}
.desktop-h4.bold, .mobile-h4.bold {
  font-weight: 700;
}

.desktop-h5, .mobile-h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  text-align: left;
}
.desktop-h5.bold, .mobile-h5.bold {
  font-weight: 700;
}

.desktop-h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.4px;
}
.desktop-h6.bold {
  font-weight: 700;
}

.mobile-h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  text-align: left;
}

.body-text {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.24px;
}
.body-text.bold {
  font-weight: bold;
}

.body-text.small {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2399999946px;
  text-align: left;
}
.body-text.small.bold {
  font-weight: bold;
}

.body-text.caption {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2399999946px;
  text-align: left;
}

/* Web/Body Text/Paragraph--Link */
.body-text.link {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  color: var(--color-plum);
}

.subtitle-1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
}

.muted {
  color: var(--text-dark-theme-muted, #989BA3);
}

.page-title.level1 {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 12px;
}
.page-title.level1:after {
  content: "";
  height: 4px;
  width: 100%;
  display: block;
  background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
  position: absolute;
  bottom: 0;
}

/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/
button {
  cursor: pointer;
}

button[disabled] {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  background-color: inherit;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: "TT Commons", sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  background: transparent;
  border: none;
  position: relative;
  border-radius: 50px;
  transition: background 0.3s ease;
  text-decoration: none;
}
.button.large {
  min-height: 56px;
  min-width: 56px;
  padding: 8px 40px;
  font-size: 24px;
  line-height: 40px; /* 166.667% */
  gap: 12px;
}
.button.large.icon-left {
  padding-left: 32px;
}
.button.large.icon-right {
  padding-right: 32px;
}
.button.large svg {
  width: 32px;
  height: 32px;
}
.button.medium {
  min-height: 48px;
  min-width: 48px;
  padding: 8px 32px;
  font-size: 20px;
  line-height: 32px; /* 160% */
  gap: 12px;
}
.button.medium.icon-left {
  padding-left: 24px;
}
.button.medium.icon-right {
  padding-right: 24px;
}
.button.medium svg {
  width: 24px;
  height: 24px;
}
.button.small {
  min-height: 40px;
  min-width: 40px;
  padding: 8px 24px;
  font-size: 18px;
  line-height: 18px;
  gap: 8px;
}
.button.small.icon-left {
  padding-left: 16px;
}
.button.small.icon-right {
  padding-right: 16px;
}
.button.small svg {
  width: 16px;
  height: 16px;
}
.button.xsmall {
  min-height: 32px;
  min-width: 32px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 16px;
  gap: 4px;
}
.button.xsmall.icon-left {
  padding-left: 12px;
}
.button.xsmall.icon-right {
  padding-right: 12px;
}
.button.xsmall svg {
  width: 16px;
  height: 16px;
}
.button.icon-btn {
  padding: 0;
}
.button.icon-btn.white {
  color: var(--color-white);
}
.button:disabled {
  opacity: 0.32;
}
.button.primary {
  background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
  color: var(--color-white);
}
.button.primary:not(:disabled):hover {
  background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
}
.button.primary:not(:disabled):focus-visible {
  outline: 0;
  background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
  box-shadow: 0px 0px 0px 2px #B41863;
}
.button.primary:not(:disabled):focus-visible:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--color-border, #DDE3EE);
  border-radius: 50px;
}
.button.light.filled {
  color: var(--swatch-dark-800, #272D39);
  background-color: var(--color-white, #fefefe);
}
.button.light.filled:not(:disabled):hover {
  color: var(--color-white, #fefefe);
}
@media (prefers-color-scheme: dark) {
  .button.light.filled {
    color: var(--color-light-200);
    background-color: var(--bg-level0);
  }
}
.button.light.outline {
  outline: 2px solid var(--swatch-dark-800, #272D39);
  outline-offset: -2px;
  color: var(--swatch-dark-800, #272D39);
}
.button.light.outline:not(:disabled):hover {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
}
.button.light.outline:not(:disabled):hover.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.light.outline:not(:disabled):focus-visible {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
  box-shadow: 0px 0px 0px 2px #393F4A;
  outline: 0;
}
.button.light.outline:not(:disabled):focus-visible.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.light.outline:not(:disabled):focus-visible:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--color-border, #DDE3EE);
  border-radius: 50px;
}
@media (prefers-color-scheme: dark) {
  .button.light.outline {
    outline-color: #fefefe;
    color: #fefefe;
  }
}
.button.dark.filled {
  background-color: var(--swatch-dark-800, #272D39);
  color: var(--color-white, #fefefe);
}
.button.dark.outline {
  color: var(--color-default, #020917);
  outline: 2px solid var(--swatch-dark-800, #272D39);
  outline-offset: -2px;
}
.button.dark.clear {
  color: var(--color-default, #020917);
}
.button.dark:not(:disabled):hover {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
}
.button.dark:not(:disabled):hover.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.dark:not(:disabled):focus {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
  box-shadow: 0px 0px 0px 2px #393F4A;
  outline: 0;
}
.button.dark:not(:disabled):focus.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.dark:not(:disabled):focus:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--color-border, #DDE3EE);
  border-radius: 50px;
}

/*------------------------------------*\
  #UNSTYLED
\*------------------------------------*/
.button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.button-unstyled::before {
  display: none;
  background: transparent;
  border: none;
  box-shadow: none;
}

/*------------------------------------*\
  #text button
\*------------------------------------*/
.button-link, .link-btn {
  font-size: 18px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  color: var(--text-link);
  text-decoration: underline;
  padding: 0;
}
.button-link:hover, .link-btn:hover {
  text-decoration: none;
  color: var(--text-link-hover);
}

.button-link.with-icon, .link-btn.with-icon {
  text-decoration: none;
  font-weight: 600;
  white-space: nowrap;
}
.button-link.with-icon svg, .link-btn.with-icon svg {
  margin-right: 8px;
}

.button-link svg, .link-btn svg {
  vertical-align: bottom;
}

.circle-btn {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 50%;
  background: #e8eefa;
  border: 1px solid var(--color-border);
}

.btn-centered {
  margin: 0 auto;
  width: 100%;
}

a {
  color: var(--text-light-theme-link, #655591);
  text-decoration: underline;
  font-weight: 700;
}
a.white {
  color: var(--color-white);
}
@media (prefers-color-scheme: dark) {
  a {
    color: var(--text-dark-theme-link, #9B81E2);
  }
}

.slider-nav-button {
  background: #020917;
  border: 1px solid #393F4A;
  color: var(--color-white);
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 40px;
}
.slider-nav-button:hover, .slider-nav-button:active {
  box-shadow: 0px 0px 16px 0px #05112A;
  border: 1px solid #F7F9FD;
  background: #05112A;
}
.slider-nav-button:focus {
  border: 1px solid #05112A;
  background: #05112A;
  box-shadow: 0px 0px 16px 0px #05112A;
  outline: 2px dashed var(--swatch-light-100, #F7F9FD);
  outline-offset: -2px;
}

.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: var(--color-dark-blue);
  color: white;
  opacity: 0.8;
  border: 1px solid white;
  border-radius: 8px;
  z-index: 99999;
  width: 48px;
  height: 48px;
  padding: 11px;
  opacity: 0;
}
.back-to-top.show {
  opacity: 1;
}

.header-wrapper {
  display: flex;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px var(--outside-padding);
  gap: 24px;
  min-height: var(--headerHeight);
}
.page-header .logo a {
  color: var(--color-white);
}
.page-header .header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
@media (min-width: 508px) {
  .page-header .header-actions .button {
    white-space: nowrap;
  }
}
.page-header .header-actions .button.outline {
  color: var(--swatch-light-400, #D2D7E2);
}
.page-header .search-input {
  max-width: 720px;
  width: 100%;
  color: var(--color-white);
}
.page-header .search-input input {
  border-color: var(--color-white);
  color: var(--color-white);
  background-color: transparent;
  max-width: 720px;
  width: 100%;
}
.page-header .search-input input::-moz-placeholder {
  color: #F7F9FD;
}
.page-header .search-input input::placeholder {
  color: #F7F9FD;
}
.page-header .search-input .search-icon {
  color: var(--color-white);
}
.page-header .account-menu-container {
  color: var(--color-white);
}
.page-header .account-menu-container .avatar {
  border-color: var(--color-white);
}
.page-header .account-menu-container .icon-18 {
  color: inherit;
}

.account-menu-container {
  position: relative;
  z-index: 11;
}
.account-menu-container .button-unstyled {
  position: relative;
  display: block;
  padding-right: 18px; /*create space for arrow*/
}
.account-menu-container .button-unstyled[aria-expanded=true] .avatar {
  background: var(--bg-level0);
  color: var(--text-default);
}
.account-menu-container .button-unstyled[aria-expanded=true] svg {
  transform: rotate(180deg);
}
.account-menu-container .avatar {
  border: 2px solid var(--text-default);
  border-radius: 100px;
  padding-top: 0;
  height: 36px;
  width: 36px;
  display: block;
  line-height: 36px;
  font-weight: bold;
  overflow: hidden;
}
.account-menu-container .avatar img {
  width: auto;
  height: 110%;
}
.account-menu-container .icon-18 {
  position: absolute;
  top: 10px;
  right: 0px;
  color: var(--text-default);
}
.account-menu-container .icon-18 svg {
  fill: currentColor;
  transition: transform 0.15s ease;
}
.account-menu-container .account-menu {
  display: block;
  position: absolute;
  background: var(--bg-level0);
  width: 200px;
  text-align: right;
  right: -13px;
  padding: 10px 0;
  top: 50px;
  border-radius: 5px;
  box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.5);
  color: var(--text-default);
}
.account-menu-container .account-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.account-menu-container .account-menu a {
  display: block;
  padding: 6px 18px;
  text-align: left;
  background: transparent;
  border: none;
  width: 100%;
  color: var(--text-default);
  text-decoration: none;
  font-weight: normal;
}
.account-menu-container .account-menu a:hover, .account-menu-container .account-menu a:focus {
  background: var(--bg-level2);
}
.account-menu-container .account-menu li label {
  margin: 4px 0 16px 0;
  font-size: 0.825rem;
  display: block;
  text-align: left;
  padding-left: 24px;
}
.account-menu-container .account-menu li label input {
  margin-right: 8px;
}
.account-menu-container .account-menu.hidden {
  display: none;
}

.card {
  display: block;
  position: relative;
  background: var(--bg-level1);
  border: 1px solid var(--bg-level1);
  border-radius: var(--card-padding);
  padding: var(--card-padding);
  text-align: left;
}
.card .bookmark-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  opacity: 0;
  color: var(--color-white);
  z-index: 2;
  width: 24px;
  height: 24px;
}
.card .recommended-tag {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  left: var(--card-padding);
  top: var(--card-padding);
  padding: 4px 8px;
  border-radius: 8px;
  border: 2px solid var(--color-light-300);
  background-color: var(--color-white);
  z-index: 11;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-black);
  transition: max-width 0.15s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}
.card .recommended-tag svg {
  display: block;
  flex: none;
}
.card:hover > .bookmark-btn {
  opacity: 1;
}
.card h2, .card h3 {
  margin: 0;
}
.card h2 + p, .card h3 + p {
  margin: 0;
}
.card > a {
  color: inherit;
  text-decoration: inherit;
}
.card p a, .card p .link-btn {
  font-size: inherit;
  line-height: inherit;
}
.card .card-label {
  flex: none;
  display: flex;
  gap: 4px;
  align-items: center;
  background: rgba(2, 9, 23, 0.7215686275);
  color: var(--color-white);
  padding: 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  position: absolute;
  left: 12px;
  top: 12px;
}
.card .card-label.completed {
  padding: 12px;
}
@media (prefers-color-scheme: dark) {
  .card .card-label {
    background: var(--color-dark-800);
  }
}
.card.user-info:hover {
  outline: none;
}
.card.no-padding {
  padding: 0;
}
.card.transparent {
  border: none;
  background-color: transparent;
}
.card .progress-ring {
  margin-left: auto;
  margin-right: auto;
}
.card .progress-label {
  margin-top: 5px;
  font-weight: bold;
}
.card .card {
  background-color: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: var(--card-corner-default);
  height: 100%;
}
.card .card .card {
  background-color: var(--bg-level1);
  border: 1px solid var(--color-border);
}
.card.vertical-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card.vertical-card .image {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 16px;
  overflow: hidden;
}
.card.vertical-card .image img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  height: 100%;
  max-height: 298px;
}
.card.horizontal-card {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
.card.horizontal-card.dashboard-cta {
  border: none;
  padding: calc(1.5 * var(--card-padding)) var(--card-padding);
  padding-right: 25%;
  overflow: hidden;
}
.card.horizontal-card.dashboard-cta .card-body {
  position: relative;
  z-index: 1;
}
.card.horizontal-card.dashboard-cta .image {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 60%;
  width: 100%;
}
.card.horizontal-card.dashboard-cta .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left;
     object-position: left;
}
.card.horizontal-card .image img {
  width: 100%;
}
.card.horizontal-card .card-body {
  flex: 1;
}
.card.horizontal-card .card-body .card-text {
  flex: 1;
}
.card.horizontal-card .card-body .card-text p + p {
  margin-top: 4px;
  margin-bottom: 0;
}
.card.horizontal-card .card-actions {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card .card-header {
  margin-bottom: 12px;
}
.card .card-header .flex-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
}
@media (min-width: 808px) {
  .card .card-header .flex-row {
    flex-direction: row;
    gap: 12px;
  }
}
.card .card-header p {
  margin: 0;
}
.card .card-header p .link-btn {
  margin: 0;
}
.card .card-title {
  margin-bottom: 4px;
}
.card.edited {
  --icon-width: 0.8em;
}
.card.edited .card-title:after {
  content: "";
  background-image: url("../icons/Completion.svg");
  margin-left: 0.2em;
  padding-left: var(--icon-width);
  background-size: var(--icon-width) var(--icon-width);
  background-position: center center;
  background-repeat: no-repeat;
}
.card .card-subtitle {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: var(--text-default);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.card .card-meta {
  font-size: 14px;
  line-height: 18px;
  color: var(--text-muted);
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0 0;
}
.card .card-meta span:not(:last-of-type):after {
  content: "•";
  margin: 0 5px;
}
.card .image img {
  width: 100%;
  max-width: 100%;
  display: block;
}
.card .card-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
}
.card .card-footer button button, .card .card-footer a button {
  white-space: nowrap;
}
.card .card-footer .link-btn {
  font-size: 20px;
}
.card .card-footer.flex-start {
  justify-content: flex-start;
}
.card .card-footer.mobile-only a {
  text-align: center;
}
.card .card-text {
  display: block;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-text-light);
  margin: 0;
}
.card .card-text:last-child {
  margin-bottom: 0;
}
.card .card-link {
  font-size: 18px;
  line-height: 24px;
  color: var(--color-teal);
}
.card .feature-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.card .feature-list li {
  -webkit-hyphens: auto;
          hyphens: auto;
  vertical-align: middle;
  white-space: nowrap;
}
.card .feature-list li span {
  vertical-align: bottom;
}
.card .feature-list li .circle-icon {
  color: var(--color-text-light);
}
.card .circle-icon {
  display: inline-block;
  width: 29px;
  height: 29px;
  background-color: var(--color-light-200);
  text-align: center;
  border-radius: 50%;
  color: var(--color-text-muted);
  line-height: 32px;
  margin-right: 8px;
  padding: 2px;
}
.card.dark {
  background-color: var(--color-dark-card);
  border-color: var(--color-dark-card);
  color: var(--color-white);
}
.card.dark a:not(.button), .card.dark .link-btn, .card.dark .card-link {
  color: var(--text-light-dark-link, #9B81E2);
}
.card.occupation-card {
  text-decoration: none;
}
.card.occupation-card:hover, .card.occupation-card:focus {
  outline: none;
}
.card.occupation-card:hover:after, .card.occupation-card:focus:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; /* !importanté */
  position: absolute;
  border-radius: var(--card-padding); /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.card.occupation-card:hover .bookmark-btn, .card.occupation-card:focus .bookmark-btn {
  opacity: 1;
}
.card.occupation-card:hover .card-title, .card.occupation-card:focus .card-title {
  text-decoration: underline;
}
.card.occupation-card .image {
  height: 200px;
}
.card.occupation-card .card-info {
  padding-top: 16px;
  display: block;
}
.card.occupation-card .card-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.card.occupation-card .card-meta {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}
.card.snapshot {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.card.snapshot .number {
  font-size: 64px;
  line-height: 72px;
  font-weight: 600;
}
.card.snapshot .label {
  font-size: 24px;
  line-height: 28px;
}
.card .info-btn {
  position: absolute;
  right: 8px;
  top: 10px;
}
.card .info-btn .info-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: var(--color-light-200);
  text-align: center;
  border-radius: 50%;
  color: var(--color-text-muted);
  padding: 2px;
}
.card.overview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 24px;
}
.card.overview .label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 40px;
  margin-bottom: 8px;
}
.card.overview .number {
  font-size: 48px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: auto;
}
.card.overview .footnote {
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}
.card.overview .button-link {
  margin-top: 40px;
}
.card.overview .graph-container {
  margin-top: 12px;
}
.card.overview .graph-container > div {
  margin-bottom: 12px;
}
.card.overview .graph-container > svg {
  width: 100%;
  height: auto;
}
.card.overview .graph-container.trend-up .chip-highlight svg {
  fill: var(--color-plum);
  position: relative;
  top: 3px;
  left: 2px;
}
.card.overview .graph-container.trend-down .chip-highlight svg {
  fill: var(--color-state-orange-dark);
  position: relative;
  top: 3px;
  left: 2px;
}
.card.assessment-mini-card {
  display: flex;
  flex-direction: column;
}
.card.assessment-mini-card .card-body {
  flex: 1;
}
.card.assessment-mini-card .card-title {
  margin-bottom: 4px;
}
.card.assessment-mini-card .image {
  margin-bottom: 24px;
  height: 200px;
  padding: 24px;
}
.card.assessment-mini-card .image.full-width {
  padding: 0;
}
.card.assessment-mini-card .image img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  height: 100%;
}
.card.assessment-mini-card .card-footer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
}
.card.assessment-mini-card .card-footer .button {
  white-space: nowrap;
}
.card.career-card {
  display: flex;
  flex-direction: column;
}
.card.career-card .card-body {
  flex: 1;
}
.card.career-card .card-title {
  margin-bottom: 4px;
}
.card.career-card .image {
  position: relative;
  margin-bottom: 24px;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;
}
.card.career-card .image img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  height: 100%;
}
.card.career-card .image .tag {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  left: 6px;
  top: 6px;
  max-width: 38px;
  padding: 4px 8px;
  border-radius: 8px;
  border: 2px solid var(--color-light-300);
  background-color: var(--color-white);
  z-index: 11;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-black);
  transition: max-width 0.15s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}
.card.career-card .image .tag svg {
  display: block;
  flex: none;
}
@media (max-width: 567px) {
  .card.career-card .image .tag {
    max-width: 100%;
  }
  .card.career-card .image .tag:after {
    content: attr(aria-label);
    opacity: 1;
  }
}
.card.career-card .card-text .body-text.caption {
  line-height: 1.5;
}
.card.career-card .card-text .body-text.caption a {
  color: var(--text-link);
  font-weight: normal;
}
.card.career-card .card-text .body-text.caption a:hover, .card.career-card .card-text .body-text.caption a:focus-visible {
  color: var(--text-link-hover);
  text-decoration: none;
}
.card.career-card .card-footer {
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
}
.card.career-card:hover .tag, .card.career-card:focus-within .tag {
  max-width: 100%;
}
.card.career-card:hover .tag:after, .card.career-card:focus-within .tag:after {
  content: attr(aria-label);
  opacity: 1;
}
.card.video-card, .card.skill-card, .card.occupation-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1 1 320px;
  padding: var(--card-padding);
  height: auto;
  text-decoration: none;
  color: var(--text-default);
  font-weight: normal;
}
.card.video-card .video.image:after, .card.skill-card .video.image:after, .card.occupation-card .video.image:after {
  width: 48px;
  height: 48px;
  display: block;
  background-image: url("../icons/play.svg");
  background-size: 100% 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.card.video-card .video.image .play-icon, .card.skill-card .video.image .play-icon, .card.occupation-card .video.image .play-icon {
  width: 48px;
  height: 48px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  color: rgb(245, 245, 245);
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.card.video-card .video.image .play-icon:hover, .card.skill-card .video.image .play-icon:hover, .card.occupation-card .video.image .play-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: rgb(1, 203, 183) 0px 0px 10px;
  text-shadow: rgb(1, 203, 183) 0px 0px 10px;
}
.card.video-card .progress-bar-container, .card.skill-card .progress-bar-container, .card.occupation-card .progress-bar-container {
  margin-top: 8px;
  margin-bottom: 0px;
}
.card.video-card .progress-bar-container .progress-bar, .card.skill-card .progress-bar-container .progress-bar, .card.occupation-card .progress-bar-container .progress-bar {
  height: 12px;
  background: rgba(0, 0, 0, 0.5);
}
.card.video-card .progress-bar-container .progress-bar-label, .card.skill-card .progress-bar-container .progress-bar-label, .card.occupation-card .progress-bar-container .progress-bar-label {
  display: block;
  font-weight: 500;
  margin-top: 6px;
}
.card.video-card .image, .card.skill-card .image, .card.occupation-card .image {
  position: relative;
  overflow: hidden;
  border-radius: calc(0.5 * var(--card-corner-default));
  max-height: 190px;
  width: 100%;
  background: rgb(2, 9, 23);
}
.card.video-card .image img, .card.skill-card .image img, .card.occupation-card .image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s ease-in-out 0s;
  height: 190px;
  max-width: 100%;
}
.card.video-card .image .tag, .card.skill-card .image .tag, .card.occupation-card .image .tag {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  left: 6px;
  top: 6px;
  max-width: 38px;
  padding: 4px 8px;
  border-radius: 8px;
  border: 2px solid var(--color-light-300);
  background-color: var(--color-white);
  z-index: 11;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-black);
  transition: max-width 0.25s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}
.card.video-card .image .tag svg, .card.skill-card .image .tag svg, .card.occupation-card .image .tag svg {
  display: block;
  flex: none;
}
.card.video-card .avatar, .card.skill-card .avatar, .card.occupation-card .avatar {
  background-color: var(--bg-level1);
  color: var(--text-muted);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.card.video-card .avatar span, .card.skill-card .avatar span, .card.occupation-card .avatar span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.card.video-card .card-body, .card.skill-card .card-body, .card.occupation-card .card-body {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding-top: 16px;
}
.card.video-card .card-title, .card.skill-card .card-title, .card.occupation-card .card-title {
  color: var(--text-default);
  margin: 0 0 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.card.video-card .card-meta, .card.skill-card .card-meta, .card.occupation-card .card-meta {
  white-space: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.card.video-card .bookmark-btn, .card.skill-card .bookmark-btn, .card.occupation-card .bookmark-btn {
  width: 44px;
  height: 44px;
}
.card.video-card:hover .tag, .card.skill-card:hover .tag, .card.occupation-card:hover .tag {
  max-width: 100%;
}
.card.video-card:hover .tag:after, .card.skill-card:hover .tag:after, .card.occupation-card:hover .tag:after {
  content: attr(aria-label);
  opacity: 1;
}
.card.video-card:hover .image img, .card.skill-card:hover .image img, .card.occupation-card:hover .image img {
  opacity: 0.55;
  transform: scale(1.1);
}
@media (max-width: 567px) {
  .card.video-card .image .tag, .card.skill-card .image .tag, .card.occupation-card .image .tag {
    max-width: 100%;
  }
  .card.video-card .image .tag:after, .card.skill-card .image .tag:after, .card.occupation-card .image .tag:after {
    content: attr(aria-label);
    opacity: 1;
  }
  .card.video-card .bookmark-btn, .card.skill-card .bookmark-btn, .card.occupation-card .bookmark-btn {
    opacity: 1;
  }
}
.card.study-card .image.video {
  background: transparent;
  text-align: center;
}
.card.study-card .image.video img {
  width: auto;
  margin: auto;
}
.card.study-card .card-body .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
  background: var(--color-white);
}
.card.forage-card {
  height: 100%;
}
.card.forage-card .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-border);
}
.card.forage-card .forage-card-inner {
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
}
.card.forage-card:before {
  position: absolute;
  left: 32px;
  top: 32px;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  background-color: var(--color-white);
  color: var(--color-black);
  background-image: url("../icons/forage.png");
  background-repeat: no-repeat;
  background-position: 6px center;
  z-index: 11;
  background-size: 16px 16px;
  font-size: 14px;
  transition: width 0.15s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}
.card.forage-card:hover:before {
  content: "Forage";
  width: 80px;
  padding-left: 32px;
  line-height: 32px;
  padding-right: 8px;
}
@media (max-width: 567px) {
  .card.forage-card:before {
    content: "Forage";
    width: 80px;
    padding-left: 32px;
    line-height: 32px;
    padding-right: 8px;
  }
}
.card.saved .image img {
  opacity: 0.55;
}
.card.saved .bookmark-btn {
  opacity: 1;
}
.card.mini-card {
  min-width: 0;
  width: 100%;
  padding: var(--card-padding);
  border-radius: 16px;
  position: relative;
  text-decoration: none;
  color: var(--text-default);
  font-weight: normal;
}
.card.mini-card .bookmark-btn {
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  color: var(--text-default);
}
.card.mini-card .avatar {
  width: 41px;
  height: 41px;
  flex: none;
}
.card.mini-card .card-title {
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
}
.card.mini-card .job-details {
  display: flex;
  gap: 16px;
  align-items: center;
}
.card.mini-card p {
  margin: 0;
}
.card.mini-card .job-company {
  font-size: 1rem;
  line-height: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.25rem;
  max-width: 100%;
}
.card.mini-card div.meta {
  overflow: hidden;
}
.card.mini-card p.meta {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  flex-wrap: wrap;
}
.card.mini-card p.meta span:not(:last-of-type):after {
  content: "•";
  margin: 0 5px;
}
.card.mini-card.saved .card-title {
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card.mini-card.saved .bookmark-btn {
  opacity: 1;
}
.card.mini-card:not(.no-results):hover, .card.mini-card:focus {
  outline: none;
}
.card.mini-card:not(.no-results):hover:after, .card.mini-card:focus:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; /* !importanté */
  position: absolute;
  border-radius: 16px; /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.card.mini-card:not(.no-results):hover .bookmark-btn, .card.mini-card:focus .bookmark-btn {
  opacity: 1;
}
.card.mini-card:not(.no-results):hover .card-title, .card.mini-card:focus .card-title {
  text-decoration: underline;
}
.card .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex: none;
  background-color: var(--bg-level2);
  color: var(--text-muted);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.card .avatar span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.card .avatar img {
  width: 100%;
}
.link-card:hover:after, .link-card:focus-visible:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; /* !importanté */
  position: absolute;
  border-radius: var(--card-padding); /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.card-carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.card-carousel .prev-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 11;
  display: none;
}
.card-carousel .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  z-index: 11;
  display: none;
}
.card-carousel .card-container {
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.card-carousel .card-container > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: var(--column-gap);
  transition: transform 0.3s ease;
}
.card-carousel .card-container > ul > li {
  scroll-snap-align: start;
  flex-shrink: 0;
  flex: none;
}
@media (min-width: 1025px) {
  .card-carousel:not(.firstSlide):before {
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, var(--color-light-bg), rgba(255, 255, 255, 0));
    z-index: 1;
  }
  .card-carousel:not(.lastSlide):after {
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to left, var(--color-light-bg), rgba(255, 255, 255, 0));
    z-index: 1;
  }
  .card-carousel .prev-btn, .card-carousel .next-btn {
    display: block;
  }
  .card-carousel .prev-btn:disabled, .card-carousel .next-btn:disabled {
    opacity: 0;
  }
  .card-carousel .card-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .card-carousel .card-container ul {
    width: -moz-max-content;
    width: max-content;
  }
  .card-carousel .card-container ul li .card {
    height: 100%;
  }
  .card-carousel.dark-blue:not(.firstSlide):before {
    background: linear-gradient(to right, var(--color-dark-blue), rgba(255, 255, 255, 0));
  }
  .card-carousel.dark-blue:not(.lastSlide):after {
    background: linear-gradient(to left, var(--color-dark-blue), rgba(255, 255, 255, 0));
  }
  .card-carousel.dark-blue .prev-btn, .card-carousel.dark-blue .next-btn {
    color: var(--color-black);
  }
}

.card.skills .card-row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.split-card {
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: var(--card-corner-default);
}
.split-card h3 {
  margin: 0 0 24px 0;
}
.split-card .recommended-side {
  flex: 1;
  padding: var(--card-padding);
  border-top-left-radius: var(--card-corner-default);
  border-bottom-left-radius: var(--card-corner-default);
}
.split-card .recommended-side .card-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}
.split-card .recommended-side .card {
  max-width: 100%;
  flex: 1 1 calc(50% - 16px);
  min-width: 180px;
}
.split-card .recommended-side .card .image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  max-height: 90px;
}
.split-card .recommended-side .card .image img {
  height: 90px;
}
.split-card .recommended-side .card .card-info {
  min-width: 0;
}
.split-card .recommended-side .card .card-title {
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-width: 0;
}
.split-card .recommended-side .card .card-meta {
  display: none;
}
.split-card .resume-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  border-radius: 0;
  border-bottom-left-radius: var(--card-corner-default);
  border-bottom-right-radius: var(--card-corner-default);
  background: var(--bg-level2);
}
.split-card .resume-side .card.large {
  background-color: var(--bg-level1);
  border: 1px solid var(--color-border);
  flex: none;
  max-width: 100%;
  border-radius: var(--card-corner-default);
}
.split-card .resume-side .card.large .image {
  max-height: 200px;
}
.split-card .resume-side .card.large .image img {
  height: 200px;
}
.split-card .resume-side .card.large .progress-bar-container {
  padding: 8px 16px;
}
.split-card .resume-side .card.large .progress-bar-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  color: var(--text-muted);
}
.split-card .resume-side .card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding-bottom: 24px;
}
@media (max-width: 567px) {
  .split-card .card.skill-card .avatar {
    width: 24px;
    height: 24px;
  }
  .split-card .card.skill-card .card-title {
    display: -webkit-box;
    font-size: 18px;
    -webkit-hyphens: auto;
            hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    max-height: 50px; /* two lines*/
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}
@media (min-width: 414px) {
  .split-card .recommended-side .card-row .card .image {
    max-height: 60px;
  }
  .split-card .recommended-side .card-row .card .image img {
    height: 60px;
  }
}
@media (min-width: 568px) {
  .split-card {
    flex-direction: row;
  }
  .split-card .recommended-side .card-row {
    grid-gap: 16px;
  }
  .split-card .recommended-side .card-row .card .image {
    max-height: 90px;
  }
  .split-card .recommended-side .card-row .card .image img {
    height: 90px;
  }
  .split-card .resume-side {
    border-radius: 0;
    border-top-right-radius: var(--card-corner-default);
    border-bottom-right-radius: var(--card-corner-default);
  }
}

.card .jobs-carousel {
  margin-top: 24px;
}

.card-row {
  display: flex;
  flex-direction: column;
  gap: var(--column-gap);
  margin-top: 24px;
}
.card-row.jobs-group {
  gap: 16px;
}
.card-row.gap-24 {
  gap: 24px;
}
.card-row.overflow-scroll {
  max-width: 100%;
  overflow-x: auto;
}
.card-row.overflow-wrap {
  flex-wrap: wrap;
  padding-bottom: 0px;
}
.card-row.study-group {
  flex-wrap: wrap;
  margin-top: 0;
}
.card-row.study-group .card {
  flex: 1 1 360px;
}
.card-row .card {
  flex: 1;
}
.card-row .flex-2 {
  flex: 2;
}
@media (min-width: 808px) {
  .card-row {
    flex-direction: row;
  }
  .card-row.one-two {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }
  .card-row.one-two .card {
    height: auto;
  }
  .card-row.one-two > div:last-child {
    grid-column: 2/4;
  }
  .card-row.two-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }
  .card-row.two-one .card {
    height: auto;
  }
  .card-row.two-one > div:first-child {
    grid-column: 1/3;
  }
  .card-row.three-three-three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }
  .card-row .three-col {
    flex: 3;
  }
  .card-row .one-col {
    flex: 1;
  }
  .card-row .two-col {
    flex: 2;
  }
}

.card.dark {
  border-color: transparent;
}
.card.dark .avatar {
  background-color: var(--color-black);
  color: var(--bg-level0);
}
.card.dark-gradient, .card.dark.action-card {
  background: linear-gradient(209deg, rgba(118, 14, 1, 0.4) 0%, rgba(109, 1, 118, 0) 71.12%), linear-gradient(0deg, rgba(37, 23, 78, 0.6) 0%, rgba(37, 23, 78, 0.6) 100%), #000;
  border-color: transparent;
  color: #fefefe;
}
.card.dark-gradient .avatar, .card.dark.action-card .avatar {
  background-color: var(--color-black);
  color: var(--bg-level0);
}
.card.dark-gradient.edited, .card.dark.action-card.edited {
  background: linear-gradient(209deg, rgba(118, 14, 1, 0.4) 0%, rgba(109, 1, 118, 0) 71.12%), linear-gradient(0deg, rgba(61, 74, 194, 0.6) 0%, rgba(61, 74, 194, 0.6) 100%), #000;
}
.card.dark-gradient .card, .card.dark.action-card .card {
  border-color: transparent;
  background: rgba(49, 20, 85, 0.8);
  color: var(--color-white);
}
.card.dark-gradient .card .card-title, .card.dark.action-card .card .card-title {
  color: var(--color-white);
}
.card.dark-gradient .card .job-company, .card.dark.action-card .card .job-company {
  color: #E0E7F0;
}
.card.dark-gradient .card p.meta, .card.dark.action-card .card p.meta {
  color: #A7BAD0;
}
.card.dark-gradient .card .bookmark-btn, .card.dark.action-card .card .bookmark-btn {
  color: var(--color-white);
}
.card.action-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  min-height: 360px;
  border: none;
}
.card.action-card .card-image {
  margin-top: 24px;
  margin-bottom: calc(-1 * var(--card-padding));
}
.card.action-card .card-image .image {
  max-width: 300px;
  margin: 0 auto;
}
.card.action-card .card-actions {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}
.card.action-card .card-header {
  margin-bottom: 0;
}
.card.action-card .card-header .card-title, .card.action-card .card-header .link-btn {
  margin-bottom: 16px;
}
.card.action-card .card-header .link-btn {
  font-size: 22px;
  line-height: 24px;
}
.card.action-card.saved-jobs-cta {
  flex-direction: column;
  justify-content: center;
  padding: 24px 40px;
}
.card.action-card.saved-jobs-cta .card-header {
  margin-bottom: 0;
}
.card.action-card.saved-jobs-cta .card-body {
  flex: none;
  margin-top: 0;
}
.card.action-card.video-card {
  padding: var(--card-padding);
  max-width: 100%;
  color: var(--color-white);
}
.card.action-card.video-card .card-info {
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}
.card.action-card.video-card .card-subtitle.author-name {
  color: inherit;
}
.card.action-card.video-card .progress-bar .bar {
  background: var(--gradient-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.card.action-card.docked-image {
  display: block;
  padding: var(--card-padding);
}
.card.action-card.docked-image .card-body .image {
  max-width: 350px;
  width: 100%;
}
.card.action-card.docked-image .card-body .image img {
  display: block;
}
.card.action-card.docked-image.wide .card-body .image {
  max-width: 100%;
}
.card.action-card.wide .card-body .image {
  max-width: 800px;
}
.card.action-card.full-width .card-body {
  max-width: 100%;
}
.card.action-card.full-width .card-body .image {
  max-width: 100%;
}
.card.action-card .overflow-scroll {
  padding-bottom: 8px;
  scrollbar-color: #B070FF rgba(49, 20, 85, 0.8);
}
.card.action-card .card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
}
.card.action-card .card-body .image {
  max-width: 100%;
  width: 100%;
}
.card.action-card .card-body .image img {
  display: block;
}
.card.action-card .card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: auto;
  margin: 32px auto 0 auto;
}
.card.action-card .card-footer button {
  width: 100%;
}
.card .progress-bar-container {
  margin: 0 0 16px 0;
  max-width: 600px;
}

.stacked-card {
  display: flex;
  flex-direction: column;
}
.stacked-card .card-body {
  flex: 1;
}
.stacked-card.one-col .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stacked-card.one-col .card-body .image {
  max-width: 160px;
  margin: 0 auto;
}

.badge-card:before {
  content: "";
  position: absolute;
  background-image: url("../images/badgeCTAbg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
}
.badge-card .card-header, .badge-card .card-body {
  z-index: 1;
}

.card.goal-card, .goal-card.action-card {
  min-height: 360px;
  flex-direction: column;
}
.card.goal-card .card-header, .goal-card.action-card .card-header {
  position: relative;
  z-index: 1;
}
.card.goal-card .card-body .goal-picker-container, .goal-card.action-card .card-body .goal-picker-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.card.goal-card .image, .goal-card.action-card .image {
  text-align: center;
}
.card.goal-card .image img, .goal-card.action-card .image img {
  display: block;
  max-width: 300px;
  margin: auto;
}
.card.goal-card.goal-mini-card, .goal-card.action-card.goal-mini-card {
  min-height: 120px;
}
.card.goal-card.goal-mini-card .goal-picker-container .goal-dropdown, .goal-card.action-card.goal-mini-card .goal-picker-container .goal-dropdown {
  font-size: 18px;
  font-weight: normal;
}
.card.goal-card.goal-mini-card .goal-picker-container .goal-dropdown .button, .goal-card.action-card.goal-mini-card .goal-picker-container .goal-dropdown .button {
  color: var(--text-muted);
}
.card.goal-card.goal-mini-card .goal-picker-container .goal-dropdown input, .goal-card.action-card.goal-mini-card .goal-picker-container .goal-dropdown input {
  color: var(--text-default);
  font-weight: normal;
}
.card.goal-card.goal-mini-card .goal-picker-container .goal-dropdown .menu button, .goal-card.action-card.goal-mini-card .goal-picker-container .goal-dropdown .menu button {
  color: var(--text-default);
  font-weight: normal;
}
@media (min-width: 808px) {
  .card.goal-card, .goal-card.action-card {
    flex-direction: column;
  }
  .card.goal-card .card-header, .goal-card.action-card .card-header {
    padding-right: 40%;
  }
  .card.goal-card .card-body .image, .goal-card.action-card .card-body .image {
    position: absolute;
    bottom: 0;
    right: 24px;
    max-height: 340px;
    width: 100%;
    max-width: 40%;
    z-index: 0;
  }
  .card.goal-card .card-body .image img, .goal-card.action-card .card-body .image img {
    max-height: 340px;
    max-width: 100%;
    width: auto;
  }
  .card.goal-card.goal-mini-card, .goal-card.action-card.goal-mini-card {
    min-height: 150px;
  }
  .card.goal-card.goal-mini-card .card-header, .goal-card.action-card.goal-mini-card .card-header {
    margin-bottom: 8px;
  }
  .card.goal-card.goal-mini-card .card-header, .card.goal-card.goal-mini-card .card-body, .goal-card.action-card.goal-mini-card .card-header, .goal-card.action-card.goal-mini-card .card-body {
    padding-right: 150px;
  }
  .card.goal-card.goal-mini-card .goal-picker-container, .goal-card.action-card.goal-mini-card .goal-picker-container {
    margin: 0;
  }
  .card.goal-card.goal-mini-card .goal-picker-container .goal-dropdown, .goal-card.action-card.goal-mini-card .goal-picker-container .goal-dropdown {
    margin: 0 8px;
  }
  .card.goal-card.goal-mini-card .image, .goal-card.action-card.goal-mini-card .image {
    max-width: 400px;
    right: 0;
    max-height: 100%;
    border-bottom-right-radius: var(--card-corner-default);
    overflow: hidden;
  }
  .card.goal-card.goal-mini-card .image img, .goal-card.action-card.goal-mini-card .image img {
    max-height: 180px;
    max-width: 100%;
    width: auto;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
}
.flex-row.align-start {
  justify-content: flex-start;
}
.flex-row .image img {
  width: 100%;
  max-width: 100%;
}

.jobs-two-col .jobs-group.card-row .card:nth-child(n+5) {
  display: none;
}

@media (min-width: 808px) {
  .card .card-header .link-btn {
    white-space: nowrap;
  }
  .card.action-card {
    flex-direction: row;
    padding: 0 80px;
  }
  .card.action-card.saved-jobs-cta {
    padding: 0 80px;
  }
  .card.action-card.goal-card, .card.action-card.badge-card {
    flex-direction: column;
  }
  .card.action-card .card-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px 0;
    flex: 1;
    max-width: 401px;
  }
  .card.action-card .card-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 360px;
    margin: 0;
  }
  .card.action-card .card-image .image {
    height: 100%;
    max-width: 100%;
  }
  .card.action-card .card-image img {
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: bottom center;
       object-position: bottom center;
    height: 100%;
  }
  .card.action-card .card-body .flex-row {
    flex-direction: row;
    align-items: flex-end;
    gap: var(--column-gap);
    margin-top: auto;
  }
  .card.action-card .card-body .flex-row .image {
    flex: 1;
  }
  .card.action-card .card-body .flex-row button {
    white-space: nowrap;
  }
  .intro .card.dark .card {
    min-width: 343px;
    max-width: 343px;
  }
  .jobs-group.card-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--column-gap);
    grid-row-gap: 16px;
    overflow: hidden;
    grid-template-rows: auto auto;
    grid-auto-rows: 0px;
  }
  .jobs-group.card-row.single-row {
    grid-template-rows: auto;
  }
  .jobs-two-col .jobs-group.card-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: var(--column-gap);
    grid-row-gap: 16px;
    overflow: hidden;
  }
  .jobs-two-col .jobs-group.card-row .card:nth-child(n+5) {
    display: none;
  }
  .card-row.skills-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--column-gap);
    overflow: hidden;
    grid-template-rows: auto;
    grid-auto-rows: 0px;
  }
  .card-row.skills-group.show-all {
    grid-auto-rows: auto;
  }
  .card-row.skills-group.show-all .card {
    height: 100%;
  }
  .card.action-card .card-header .flex-row {
    align-items: center;
  }
  .card.action-card.padding-even {
    padding: var(--card-padding);
  }
  .card.action-card.docked-image .card-body {
    justify-content: flex-end;
    align-items: center;
    max-width: 50%;
    margin-bottom: calc(-1 * (var(--card-padding)));
  }
  .card.action-card.docked-image.wide .card-body {
    align-items: flex-start;
    max-width: 75%;
    margin-bottom: calc(-1 * (var(--card-padding) + 48px));
  }
  .card.action-card.docked-image.wide .card-body .image {
    max-width: 1200px;
  }
  .card.action-card.docked-image.full-width .card-body {
    max-width: calc(100% + var(--card-padding) + var(--card-padding));
    width: calc(100% + var(--card-padding) + var(--card-padding));
    margin-left: calc(-1 * var(--card-padding));
    margin-right: calc(-1 * var(--card-padding));
  }
  .card.action-card .card-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: absolute;
    bottom: var(--card-padding);
    right: var(--card-padding);
    width: calc(100% - 2 * var(--card-padding));
  }
  .card.action-card .card-footer button {
    width: auto;
  }
}
@media (max-width: 567px) {
  .card.horizontal-card {
    flex-direction: column;
  }
  .card.horizontal-card .card-body {
    flex-direction: column;
  }
  .card.horizontal-card .card-text * {
    text-align: center;
  }
  .card.horizontal-card .image {
    margin: 0 auto;
    max-width: 180px;
  }
  .career-switcher.tab-container .tab-list {
    overflow-x: unset;
  }
  .career-fast-facts .facts-row {
    flex-direction: column;
  }
  .career-fast-facts .facts-row h6 {
    text-align: left;
  }
  .intro > .card-row {
    display: flex;
    flex-direction: column;
  }
  .card.skill-card {
    max-width: 100%;
  }
  .card.skill-card .card-title {
    padding-right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    min-width: 0;
    word-break: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}
@media (min-width: 568px) {
  .card.horizontal-card {
    align-items: center;
    padding-right: 25%;
    overflow: hidden;
  }
  .card.horizontal-card .image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: var(--card-padding);
    width: 25%;
  }
  .card.horizontal-card .image img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 25% center;
       object-position: 25% center;
    height: 100%;
    max-width: 200px;
    margin: auto;
  }
  .card.horizontal-card.dashboard-cta {
    border: none;
    padding: calc(1.5 * var(--card-padding)) var(--card-padding);
    padding-right: 40%;
    overflow: hidden;
  }
  .card.horizontal-card.dashboard-cta .image {
    position: absolute;
    right: 0;
    left: unset;
    bottom: 0;
    top: 0;
    max-width: 60%;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .card.horizontal-card.dashboard-cta .image img {
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: left;
       object-position: left;
  }
}
@media (min-width: 568px) and (max-width: 807px) {
  .career-switcher.tab-container .tab-list {
    overflow-x: unset;
  }
  .career-fast-facts .facts-row {
    flex-direction: column;
  }
  .career-fast-facts .facts-row h6 {
    text-align: left;
  }
  .intro > .card-row {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: var(--column-gap);
  }
  .card.video-card .image, .card.skill-card .image {
    max-height: 100px;
  }
  .card.video-card .image img, .card.skill-card .image img {
    height: 100px;
  }
  .card.action-card .card-footer {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  .card.action-card .card-footer button {
    width: auto;
  }
  .jobs-group.card-row, .card-row.skills-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: var(--column-gap);
    overflow: hidden;
  }
  .jobs-group.card-row .card:nth-child(n+7), .card-row.skills-group .card:nth-child(n+7) {
    display: none;
  }
  .jobs-two-col .jobs-group.card-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: var(--column-gap);
    grid-row-gap: 16px;
    overflow: hidden;
  }
  .jobs-two-col .jobs-group.card-row .card:nth-child(n+5) {
    display: none;
  }
}
@media (min-width: 808px) and (max-width: 1199px) {
  .card-row.skills-group:not(.show-all) .card {
    max-width: 100%;
  }
  .card-row.skills-group:not(.show-all) .card:nth-child(n+4) {
    display: none;
  }
  .jobs-group.card-row .card:nth-child(n+7) {
    display: none;
  }
}
@media (min-width: 1200px) {
  .card.action-card {
    flex-direction: row;
    padding: 0 60px 0 120px;
  }
  .card.action-card.saved-jobs-cta {
    padding: 0 120px;
  }
  .jobs-group.card-row, .card-row.skills-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--column-gap);
    grid-row-gap: var(--column-gap);
    margin-bottom: 0;
  }
  .jobs-group.card-row .card, .card-row.skills-group .card {
    display: block;
  }
  .jobs-group.card-row:not(.show-all) .card, .card-row.skills-group:not(.show-all) .card {
    display: block;
  }
  .jobs-group.card-row:not(.show-all) .card:nth-child(n+7), .card-row.skills-group:not(.show-all) .card:nth-child(n+7) {
    display: none;
  }
  .jobs-two-col .jobs-group.card-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: var(--column-gap);
    grid-row-gap: 16px;
    overflow: hidden;
  }
  .jobs-two-col .jobs-group.card-row .card:nth-child(n+5) {
    display: none;
  }
  .card.dark .card-header .flex-row {
    display: flex;
    gap: 24px;
  }
}
.sandbox section > .card {
  margin-bottom: 40px;
}

.image.skeleton {
  max-width: 455px !important;
  height: 175px;
  border-radius: 8px;
  opacity: 0.8;
  background: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-default);
  font-size: 2em;
  flex: none;
}
.image.skeleton span {
  text-align: center;
}

.chip-label {
  background: rgba(2, 9, 23, 0.0784313725);
  padding: 8px;
  border-radius: 8px;
  color: var(--text-default);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2399999946px;
  text-align: left;
  display: inline-block;
}
@media (prefers-color-scheme: dark) {
  .chip-label {
    background: rgba(254, 254, 254, 0.0784313725);
  }
}

.card .chip-label {
  margin-bottom: 8px;
}
.card.job-result-card:not(.active) .chip-label {
  background: rgba(2, 9, 23, 0.7215686275);
  color: var(--color-white);
}

/* starting ENTER animation */
.ctaCard-enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 11;
}
.ctaCard-enter * {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 200ms;
}
.ctaCard-enter.with-delay {
  transition-delay: 800ms;
}

/* ending ENTER animation */
.ctaCard-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}
.ctaCard-enter-active * {
  opacity: 1;
}

/* starting EXIT animation */
.ctaCard-exit {
  opacity: 1;
}

/* ending EXIT animation */
.ctaCard-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

/* starting ENTER animation */
.jobsCard-enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 11;
}

/* ending ENTER animation */
.jobsCard-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 250ms;
}

/* starting EXIT animation */
.jobsCard-exit {
  opacity: 1;
}

/* ending EXIT animation */
.jobsCard-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 250ms;
}

.card:not(.dark) .card-footer .button.filled.light {
  outline: 1px solid var(--border-dark-theme-border, #393F4A);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-weight: normal;
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

input,
textarea {
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
}

.gr-input {
  display: block;
  margin: 4px 0;
  padding: 0 12px;
  height: 44px;
  color: var(--color-text-light);
  border: 1px solid var(--color-border);
  background: var(--bg-level0);
  border-radius: var(--input-corner, 4px);
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
}

.gr-input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--bg-level0), 0 0 0 4px #1977D4;
}

.gr-input:disabled {
  color: var(--color-border);
  background-color: var(--interactive-disabled);
}

.gr-input:-moz-read-only {
  border: 0;
}

.gr-input:read-only {
  border: 0;
}

.gr-input + .error-state, .gr-textarea + .error-state, .gr-checkbox + .error-state, .gr-radio + .error-state, .gr-select-container + .error-state, .gr-select-container + .error-state {
  display: none;
}

.gr-input.error, .gr-textarea.error, .gr-checkbox.error, .gr-radio.error, .gr-select-container.error, .gr-select-container.error {
  display: flex;
  align-items: center;
  border-color: #DB0020;
}

.gr-input.error + .error-state, .gr-textarea.error + .error-state, .gr-checkbox.error + .error-state, .gr-radio.error + .error-state, .gr-select-container.error + .error-state, .gr-select-container.error + .error-state {
  display: flex;
  align-items: center;
}

.gr-input.error + .error-state svg, .gr-textarea.error + .error-state svg, .gr-checkbox.error + .error-state svg, .gr-radio.error + .error-state svg, .gr-select-container.error + .error-state svg, .gr-select-container.error + .error-state svg {
  fill: #DB0020;
}

.gr-input.error + .error-state span, .gr-textarea.error + .error-state span, .gr-checkbox.error + .error-state span, .gr-radio.error + .error-state span, .gr-select-container.error + .error-state span, .gr-select-container.error + .error-state span {
  margin-left: 8px;
  color: #DB0020;
}

.pay-input {
  position: relative;
}
.pay-input .gr-input {
  padding-left: 32px;
}
.pay-input span {
  font-size: 1.125em;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.input-range .hyphen {
  padding: 16px 24px;
  align-self: flex-end;
}
.input-range .form-input {
  flex: 1;
}

.gr-textarea {
  display: block;
  margin: 4px 0;
  padding: 12px;
  color: var(--color-text-light);
  border: 1px solid var(--color-border);
  background: var(--color-white);
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  resize: vertical;
  line-height: 1.5;
}

.gr-textarea:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-textarea:disabled {
  color: var(--color-border);
  background-color: #E9E9E9;
}

.gr-textarea:-moz-read-only {
  border: 0;
}

.gr-textarea:read-only {
  border: 0;
}

.gr-checkbox {
  margin-bottom: 14px;
  min-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.gr-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.gr-checkbox input[type=checkbox] ~ label {
  display: inline-block;
  line-height: 1.5;
  min-height: 24px;
  padding-left: 2.5em;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 14px;
}

.gr-checkbox input[type=checkbox] ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid var(--color-border);
  background: var(--color-white);
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.gr-checkbox input[type=checkbox]:disabled ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid var(--color-border);
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: #E9E9E9;
}

.gr-checkbox input[type=checkbox]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-checkbox input[type=checkbox] ~ span svg {
  height: 24px;
  opacity: 0;
  width: 24px;
  top: -3px;
  position: relative;
  left: -3px;
  fill: #007FA3;
}

.gr-checkbox input[type=checkbox]:disabled ~ span svg {
  fill: var(--color-border);
}

.gr-checkbox input[type=checkbox]:checked ~ span svg {
  opacity: 1;
}

.gr-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.gr-fieldset legend, .legend, .form-label {
  padding: 0px;
  position: relative;
  color: var(--text-default);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

.recommended {
  margin-top: 1rem;
}

.recommended .secondary-label {
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.24px;
  margin-bottom: 4px;
  margin-top: 1rem;
}

.chip-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-left: -4px;
  margin-right: -4px;
}
.chip-list .chip-btn {
  margin: 0 4px;
}

.selected-list {
  margin-top: 10px;
}

.chip-btn {
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--border-light-theme-border, #DDE3EE);
  background: var(--bg-level0);
  padding: 4px 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  color: var(--text-default);
  margin: 0 4px;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  .chip-btn {
    border-color: #60646D;
    background: var(--bg-level2);
  }
}
.chip-btnbutton {
  cursor: pointer;
}
.chip-btn.small {
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px !important;
}
.chip-btn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.chip-btn[aria-pressed=true] {
  background: var(--background-dark-theme-bg-level-2, #272D39);
  color: #FEFEFE;
  border-color: var(--background-dark-theme-bg-level-2, #272D39);
}
.chip-btn.search-filter-chip {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 24px;
  gap: 4px;
  border-radius: 4px;
}
.chip-btn.search-filter-chip.dark .icon-btn {
  color: var(--color-white);
}
.chip-btn.search-filter-chip .chip-btn-toggle-text {
  background: transparent;
  border: none;
  padding: 0 4px;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: var(--text-muted);
  border-radius: 4px;
}
.chip-btn.search-filter-chip .chip-btn-toggle-text:hover {
  text-decoration: underline;
}
.chip-btn.search-filter-chip .icon-btn {
  min-width: 24px;
  min-height: 24px;
  color: var(--text-muted);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}
.chip-btn.search-filter-chip .icon-btn:hover, .chip-btn.search-filter-chip .icon-btn:focus {
  background: rgba(2, 9, 23, 0.0784313725);
}
.chip-btn.search-filter-chip.selected {
  background-color: #05112A;
  border-color: #05112A;
  color: var(--color-white);
  font-weight: 700;
}
.chip-btn.search-filter-chip.selected .chip-btn-toggle-text {
  color: var(--color-white);
  font-weight: 700;
}
.chip-btn.search-filter-chip.selected .icon-btn {
  color: var(--color-white);
}
.chip-btn.search-filter-chip.selected .icon-btn:hover, .chip-btn.search-filter-chip.selected .icon-btn:focus {
  background: rgba(254, 254, 254, 0.2);
}
.chip-btn.with-remove {
  padding: 4px 4px 4px 8px;
}
.chip-btn.with-remove button {
  width: 24px;
  height: 24px;
  padding: 0;
  min-height: 24px;
  min-width: 24px;
}
.chip-btn.dark {
  background: var(--background-dark-theme-bg-level-2, #272D39);
  color: #FEFEFE;
  border-color: var(--background-dark-theme-bg-level-2, #272D39);
}
.chip-btn.dark button, .chip-btn.dark span {
  color: #FEFEFE;
}
.chip-btn.dark svg {
  vertical-align: text-bottom;
}
.chip-btn.dark .icon-btn:hover, .chip-btn.dark .icon-btn:focus {
  background: rgba(254, 254, 254, 0.2431372549);
}
@media (prefers-color-scheme: dark) {
  .chip-btn.search-filter-chip.selected {
    background: #fefefe;
    border-color: #fefefe;
    color: #05112A;
  }
  .chip-btn.search-filter-chip.selected .chip-btn-toggle-text {
    color: inherit;
  }
}

.form-actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
}

.radio-group.radio-row {
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
}

.gr-radio {
  min-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 0;
}
.gr-radio:last-of-type {
  padding-bottom: 0;
}
.gr-radio + .gr-radio {
  margin-top: 4px;
}

.gr-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}

.gr-radio input[type=radio]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-radio input[type=radio] + label {
  display: inline-block;
  padding-left: 28px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.24px;
  cursor: pointer;
}

label .meta {
  display: block;
  font-style: italic;
}

.gr-radio input[type=radio] ~ span {
  -webkit-box-sizing: content-box;
  border: 2px solid var(--checkbox-border);
  background: var(--color-white);
  border-radius: 50%;
  box-sizing: content-box;
  color: var(--checkbox-checked);
  display: block;
  height: 5px;
  left: 0;
  padding: 3px 6px 6px 3px;
  pointer-events: none;
  position: absolute;
  top: 7px;
  width: 5px;
}

.gr-radio input[type=radio] ~ span svg {
  height: 18px;
  opacity: 0;
  width: 18px;
}

.gr-radio input[type=radio]:checked ~ span svg {
  opacity: 1;
  top: -5px;
  position: relative;
  left: -5px;
  fill: var(--checkbox-checked);
}

.gr-radio input[type=radio]:disabled ~ span svg {
  opacity: 1;
  fill: var(--color-border);
  top: -5px;
  left: -5px;
  position: relative;
}

.gr-select-container {
  position: relative;
}

.gr-select-container svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 9px);
  fill: var(--text-muted);
}

.gr-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  margin: 4px 0;
  padding: 0 12px;
  padding-right: 42px;
  height: 44px;
  color: var(--text-muted);
  border: 1px solid var(--color-border);
  background: var(--bg-level0);
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
}

.gr-select:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--bg-level0), 0 0 0 4px #1977D4;
}

.gr-select:disabled {
  color: var(--color-border);
  background-color: #E9E9E9;
}

.gr-select:disabled + svg {
  fill: var(--color-border);
}

.gr-select[multiple] {
  height: auto;
}

.gr-select[multiple] option {
  cursor: pointer;
}

/* HED styles */
/* check-box-list.component.less */
.checkbox-group {
  margin-left: 8px;
}

.checkbox {
  padding: 6px 0;
}
.checkbox:last-of-type {
  padding-bottom: 0;
}
.checkbox + .checkbox {
  margin-top: 4px;
}

.check-box-list label,
.checkbox label {
  display: flex;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.24px;
  color: var(--text-default);
  align-items: flex-start;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  cursor: pointer;
}

.check-box-list label:not([class*=-inline]) + label,
.checkbox label:not([class*=-inline]) + label {
  margin-top: 1rem;
}

.check-box-list label[class*=-inline],
.checkbox label[class*=-inline] {
  display: inline-flex;
  margin: 0 0.625rem 0.625rem 0;
}

.check-box-list label[class*=-inline]:only-of-type,
.checkbox label[class*=-inline]:only-of-type,
.check-box-list label[class*=-inline]:last-of-type,
.checkbox label[class*=-inline]:last-of-type {
  margin-bottom: 0;
}

.small-controls.check-box-list label,
.small-controls.checkbox label {
  font-size: var(--typesize-minor);
}

.form-inverse .check-box-list label,
.form-inverse .checkbox label {
  color: var(--color-white);
}

.check-box-list [type=checkbox],
.checkbox [type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  aspect-ratio: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--checkbox-border);
  margin: 0 0.8125rem 0 0;
  position: relative;
  transition: all 0.125s ease-in-out;
  border-radius: 0.125em;
  transform: translate(2px, 2px);
}

.check-box-list [type=checkbox]::before,
.checkbox [type=checkbox]::before {
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color-light-300);
  flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: all 0.25s ease-in-out;
  outline-color: transparent;
  outline-style: solid;
  outline-width: 0.1875em;
  outline-offset: -0.1875em;
}

.small-controls.check-box-list [type=checkbox]::before,
.small-controls.checkbox [type=checkbox]::before {
  width: 2em;
  height: 2em;
}

.form-inverse .check-box-list [type=checkbox]::before,
.form-inverse .checkbox [type=checkbox]::before {
  background-color: var(--color--ui-04);
}

.check-box-list [type=checkbox]:hover:not([disabled]),
.checkbox [type=checkbox]:hover:not([disabled]) {
  outline-color: var(--text-default);
}

.check-box-list [type=checkbox]:hover:not([disabled])::before,
.checkbox [type=checkbox]:hover:not([disabled])::before {
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
}

.check-box-list [type=checkbox]:focus:not([disabled]),
.checkbox [type=checkbox]:focus:not([disabled]) {
  outline-color: var(--checkbox-border);
}

.check-box-list [type=checkbox]:focus:not([disabled])::before,
.checkbox [type=checkbox]:focus:not([disabled])::before {
  background-color: transparent;
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field);
}

.small-controls.check-box-list [type=checkbox],
.small-controls.checkbox [type=checkbox] {
  width: 1rem;
  height: 1rem;
  outline-width: 0.125rem;
}

.check-box-list [type=checkbox]:checked,
.checkbox [type=checkbox]:checked {
  outline-color: var(--checkbox-checked);
}

.check-box-list [type=checkbox]:checked:hover,
.checkbox [type=checkbox]:checked:hover {
  outline-color: var(--checkbox-border);
}

.check-box-list [type=checkbox]:focus,
.checkbox [type=checkbox]:focus,
.check-box-list [type=checkbox]:focus-visible,
.checkbox [type=checkbox]:focus-visible {
  outline-color: var(--color--interactive-focus-field);
}

.check-box-list [type=checkbox][required][aria-checked=false][aria-invalid=false],
.checkbox [type=checkbox][required][aria-checked=false][aria-invalid=false],
.has-error.check-box-list [type=checkbox],
.has-error.checkbox [type=checkbox],
.has-error .check-box-list [type=checkbox],
.has-error .checkbox [type=checkbox] {
  outline-color: var(--color--alert-error);
}

.check-box-list [type=checkbox]:checked:not([disabled]),
.checkbox [type=checkbox]:checked:not([disabled]) {
  background: var(--checkbox-checked);
}

.has-error.check-box-list [type=checkbox]:checked:not([disabled]),
.has-error.checkbox [type=checkbox]:checked:not([disabled]),
.has-error .check-box-list [type=checkbox]:checked:not([disabled]),
.has-error .checkbox [type=checkbox]:checked:not([disabled]) {
  background: var(--color--alert-error);
}

.form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]),
.form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]),
.form-inverse .has-error .check-box-list [type=checkbox]:checked:not([disabled]),
.form-inverse .has-error .checkbox [type=checkbox]:checked:not([disabled]) {
  background: var(--color--alert-error-inverse);
}

.check-box-list [type=checkbox]:checked:not([disabled]):focus,
.checkbox [type=checkbox]:checked:not([disabled]):focus {
  outline-color: var(--checkbox-checked);
}

.has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
.has-error.checkbox [type=checkbox]:checked:not([disabled]):focus,
.has-error .check-box-list [type=checkbox]:checked:not([disabled]):focus,
.has-error .checkbox [type=checkbox]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error);
}

.form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
.form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]):focus,
.form-inverse .has-error .check-box-list [type=checkbox]:checked:not([disabled]):focus,
.form-inverse .has-error .checkbox [type=checkbox]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error-inverse);
}

.check-box-list [type=checkbox]:checked::after,
.checkbox [type=checkbox]:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  background: var(--color-white);
  margin: auto;
  position: absolute;
}

.check-box-list [type=checkbox][disabled],
.checkbox [type=checkbox][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none;
}

.check-box-list [type=checkbox][disabled]::-moz-placeholder, .checkbox [type=checkbox][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-02);
}

.check-box-list [type=checkbox][disabled]::placeholder,
.checkbox [type=checkbox][disabled]::placeholder {
  color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled],
.form-inverse .checkbox [type=checkbox][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled]::-moz-placeholder, .form-inverse .checkbox [type=checkbox][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-01);
}

.form-inverse .check-box-list [type=checkbox][disabled]::placeholder,
.form-inverse .checkbox [type=checkbox][disabled]::placeholder {
  color: var(--color--interactive-disabled-01);
}

.check-box-list [type=checkbox][disabled]:checked,
.checkbox [type=checkbox][disabled]:checked {
  outline-color: var(--color--ui-01);
}

.check-box-list [type=checkbox][disabled]:checked::after,
.checkbox [type=checkbox][disabled]:checked::after {
  background-color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled],
.form-inverse .checkbox [type=checkbox][disabled] {
  outline-color: var(--color--interactive-disabled-03);
}

.form-inverse .check-box-list [type=checkbox][disabled]:checked,
.form-inverse .checkbox [type=checkbox][disabled]:checked {
  outline-color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled]:checked::after,
.form-inverse .checkbox [type=checkbox][disabled]:checked::after {
  background: var(--color--interactive-disabled-03);
}

.small-controls.check-box-list [type=checkbox]:checked::after,
.small-controls.checkbox [type=checkbox]:checked::after {
  width: 0.75rem;
  height: 0.75rem;
}

@supports selector(:focus-visible) {
  .check-box-list [type=checkbox],
  .checkbox [type=checkbox] {
    box-sizing: content-box;
    outline-color: var(--checkbox-border);
  }
  .check-box-list [type=checkbox]:hover:not([disabled]),
  .checkbox [type=checkbox]:hover:not([disabled]) {
    outline-color: var(--text-default);
  }
  .check-box-list [type=checkbox]:focus:not([disabled]),
  .checkbox [type=checkbox]:focus:not([disabled]) {
    outline-color: var(--color--border-02);
  }
  .check-box-list [type=checkbox]:focus:not([disabled])::before,
  .checkbox [type=checkbox]:focus:not([disabled])::before {
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
  }
  .check-box-list [type=checkbox]:checked:not([disabled]),
  .checkbox [type=checkbox]:checked:not([disabled]) {
    outline-color: var(--checkbox-checked);
  }
  .has-error.check-box-list [type=checkbox]:checked:not([disabled]),
  .has-error.checkbox [type=checkbox]:checked:not([disabled]) {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]),
  .form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]) {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type=checkbox]:checked:not([disabled]):focus,
  .checkbox [type=checkbox]:checked:not([disabled]):focus {
    outline-color: var(--checkbox-checked);
  }
  .has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
  .has-error.checkbox [type=checkbox]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
  .form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type=checkbox]:checked:not([disabled]):hover,
  .checkbox [type=checkbox]:checked:not([disabled]):hover {
    outline-color: var(--color--interactive-primary);
  }
  .has-error.check-box-list [type=checkbox]:checked:not([disabled]):hover,
  .has-error.checkbox [type=checkbox]:checked:not([disabled]):hover,
  .has-error .check-box-list [type=checkbox]:checked:not([disabled]):hover,
  .has-error .checkbox [type=checkbox]:checked:not([disabled]):hover {
    outline-color: var(--color--alert-error);
  }
  .check-box-list [type=checkbox][disabled],
  .checkbox [type=checkbox][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-02);
  }
  .form-inverse .check-box-list [type=checkbox][disabled],
  .form-inverse .checkbox [type=checkbox][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-03);
  }
  .small-controls.check-box-list [type=checkbox],
  .small-controls.checkbox [type=checkbox] {
    width: 1rem;
    height: 1rem;
    outline: unset;
    box-sizing: border-box;
    border-width: 0.14285714em;
  }
  .small-controls.check-box-list [type=checkbox]:focus,
  .small-controls.checkbox [type=checkbox]:focus,
  .small-controls.check-box-list [type=checkbox]:focus-visible,
  .small-controls.checkbox [type=checkbox]:focus-visible {
    outline: unset;
  }
  .small-controls.check-box-list [type=checkbox]:checked::after,
  .small-controls.checkbox [type=checkbox]:checked::after {
    transform: scale(1.15);
  }
  .check-box-list [type=checkbox][required][aria-checked=false][aria-invalid=false],
  .checkbox [type=checkbox][required][aria-checked=false][aria-invalid=false],
  .has-error.check-box-list [type=checkbox],
  .has-error.checkbox [type=checkbox],
  .has-error .check-box-list [type=checkbox],
  .has-error .checkbox [type=checkbox] {
    border-color: var(--color--alert-error);
  }
}
.filter-menu {
  position: relative;
  z-index: 12;
}
.filter-menu button {
  border-radius: 8px;
  padding: 4px 12px;
}
.filter-menu button[aria-expanded=true] {
  background-color: var(--text-default);
  color: var(--bg-level0);
}
.filter-menu .menu {
  position: absolute;
  z-index: 1111;
  right: 0;
  top: calc(100% + 4px);
  background-color: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 200px;
}
.filter-menu .menu .form-input {
  margin: 1em 0;
}
.filter-menu.career-fields-dropdown {
  width: 100%;
  text-align: right;
}
.filter-menu.career-fields-dropdown > .button {
  margin-left: auto;
}
.filter-menu.career-fields-dropdown .menu {
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  max-width: calc(100vw - 80px);
}
.filter-menu.career-fields-dropdown .menu button {
  text-align: left;
}

.dropdown {
  position: relative;
  z-index: 12;
}
.dropdown.open {
  z-index: 13;
}
.dropdown .menu {
  position: absolute;
  z-index: 1111;
  left: 0;
  top: 100%;
  background-color: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1215686275), 0px 2px 2px 0px rgba(0, 0, 0, 0.1411764706), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  color: var(--text-default);
  font-size: 18px;
}
.dropdown .menu span, .dropdown .menu label {
  font-size: 1.125rem;
  line-height: 1.3;
}
.dropdown .menu .chip-btn span {
  font-size: 14px;
  line-height: 24px;
}
.dropdown .menu .form-input {
  margin: 1em 0;
}
.dropdown .menu .filter-body {
  padding: 16px;
}
.dropdown .menu .menu-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: #F7F9FD;
  padding: 16px;
}
.dropdown .menu .menu-footer button {
  white-space: nowrap;
  flex: 1;
}

.search-container, .search-input {
  position: relative;
  width: 100%;
}
.search-container .gr-input, .search-input .gr-input {
  border: 1px solid var(--color-border);
  color: var(--text-default);
  border-radius: 24px;
  padding: 8px 16px 8px 44px;
  max-width: 490px;
  height: 44px;
}
.search-container .gr-input::-moz-placeholder, .search-input .gr-input::-moz-placeholder {
  color: var(--text-muted);
}
.search-container .gr-input::placeholder, .search-input .gr-input::placeholder {
  color: var(--text-muted);
}
.search-container .search-icon, .search-input .search-icon {
  position: absolute;
  color: var(--text-default);
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}
.search-container .clear-icon, .search-input .clear-icon {
  position: absolute;
  color: var(--text-default);
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}

.autocomplete-container {
  position: relative;
}
.autocomplete-container .gr-input {
  max-width: 100%;
  text-overflow: ellipsis;
  padding-right: 48px;
}
.autocomplete-container .drop-down-icon {
  position: absolute;
  display: block;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  transition: translate 0.3s ease;
}
.autocomplete-container .gr-input[aria-expanded=true] + .drop-down-icon {
  transform: translateY(-50%) rotate(180deg);
}

.autocomplete-list {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  background: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  z-index: 111;
  max-height: 280px;
  overflow-y: auto;
}
.autocomplete-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.autocomplete-list ul button {
  padding: 16px;
  text-align: left;
  text-transform: capitalize;
  background: transparent;
  border: none;
  width: 100%;
  color: var(--text-default);
}
.autocomplete-list ul button:hover, .autocomplete-list ul button:focus {
  background: var(--bg-level2);
}
.autocomplete-list ul li:not(:last-of-type) {
  border-bottom: 1px solid var(--color-border);
}
.autocomplete-list .result-category {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #60646D;
  margin: 0;
  padding: 0 30px;
}
.autocomplete-list .results-list {
  border: 0;
}
.autocomplete-list .results-list li {
  border: 0 !important;
}
.autocomplete-list .results-list a, .autocomplete-list .results-list button {
  display: block;
  color: #454D58;
  text-decoration: none;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding: 0 3rem;
  width: 100%;
}
.autocomplete-list .results-list a:hover, .autocomplete-list .results-list a:focus, .autocomplete-list .results-list button:hover, .autocomplete-list .results-list button:focus {
  background: var(--bg-level2);
  text-decoration: underline;
}

.filter-body .career-search-list .autocomplete-list {
  padding: 0.5rem 0;
  border: 1px solid #BCC1CB;
}
.filter-body .career-search-list .autocomplete-list .result-category {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--color-black);
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}
.filter-body .career-search-list .autocomplete-list .no-options {
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  color: var(--text-muted);
  margin: 0;
}
.filter-body .career-search-list .autocomplete-list .results-list a, .filter-body .career-search-list .autocomplete-list .results-list button, .filter-body .career-search-list .autocomplete-list .results-list p {
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0.625rem 1rem;
  margin: 0;
}

.career-search-list .autocomplete-list {
  padding: 24px 0;
  border: 1px solid #687787;
  border-radius: 8px;
  max-height: 408px;
}
.career-search-list .autocomplete-list ul li {
  border: 0;
}
.career-search-list .pathway-results + .occupation-results {
  margin-top: 8px;
}
@media (prefers-color-scheme: dark) {
  .career-search-list .autocomplete-list {
    background: #020917;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    border-color: #020917;
  }
  .career-search-list .result-category {
    color: #fefefe;
  }
  .career-search-list .results-list li a, .career-search-list .results-list li button {
    color: #989BA3;
  }
}

.company-list {
  max-width: 570px;
  width: 100%;
}
.company-list .autocomplete-list button {
  text-transform: uppercase;
}

.form-input.password {
  position: relative;
}
.form-input.password .show-password {
  position: absolute;
  right: 12px;
  bottom: 14px;
}
.form-input.password input {
  padding-right: 40px;
}

.slider-container {
  position: relative;
  padding-bottom: 0.5em;
  width: 100%;
}
.slider-container label {
  display: block;
  font-size: 1.125rem !important;
  margin-bottom: 4px;
}
.slider-container .slider {
  -webkit-appearance: none; /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  height: 4px; /* Specified height */
  background: #B2B6BF; /* Grey background */
  outline: none; /* Remove outline */
  border-radius: 4px;
}
.slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 16px; /* Set a specific slider handle width */
  height: 16px; /* Slider handle height */
  border-radius: 24px;
  border: 1px solid var(--Swatch-Light-600, #BCC1CB);
  background: var(--background-light-theme-bg-level-0, #FEFEFE);
  cursor: pointer; /* Cursor on hover */
}
.slider-container .slider::-moz-range-thumb {
  width: 16px; /* Set a specific slider handle width */
  height: 16px; /* Slider handle height */
  border-radius: 24px;
  border: 1px solid var(--Swatch-Light-600, #BCC1CB);
  background: var(--background-light-theme-bg-level-0, #FEFEFE);
  cursor: pointer; /* Cursor on hover */
}
.slider-container .min {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1rem;
}
.slider-container .max {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1rem;
}

.vertical-navigation {
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  width: 88px;
  background: linear-gradient(251deg, rgba(109, 1, 118, 0.6) 0%, rgba(109, 1, 118, 0) 100%), #05112A;
  color: var(--color-white);
  transition: width 0.3s ease-in-out;
  overflow-x: hidden;
  height: 100vh;
  padding: 24px;
  z-index: 1111;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.vertical-navigation .menu-btn {
  color: var(--color-white);
  width: 48px;
  height: 48px;
}
.vertical-navigation h1 {
  margin-top: 0px;
  margin-bottom: 64px;
}
.vertical-navigation .logo {
  position: absolute;
  top: 24px;
}
.vertical-navigation .nav-menu {
  margin-top: -160px;
}
.vertical-navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.vertical-navigation ul li a {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px;
  color: #989BA3;
  font-size: 20px;
  min-height: 50px;
  margin-bottom: 8px;
  /* identical to box height, or 280% */
  letter-spacing: 0.4px;
  text-decoration: none;
  white-space: nowrap;
}
.vertical-navigation ul li a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: transparent;
  bottom: 0;
}
.vertical-navigation ul li a:hover {
  color: var(--color-light-200);
  font-weight: 700;
}
.vertical-navigation ul li a:hover:after {
  background: var(--color-light-200);
}
.vertical-navigation ul li .icon {
  min-width: 40px;
  display: inline-block;
  text-align: center;
}
.vertical-navigation [aria-selected=true], .vertical-navigation .selected {
  color: var(--color-white);
  font-weight: 700;
}
.vertical-navigation.expanded {
  width: 276px;
}
.vertical-navigation.expanded h1, .vertical-navigation.expanded ul li .text {
  visibility: visible;
}
.vertical-navigation.expanded [aria-selected=true]:after, .vertical-navigation.expanded .selected:after {
  background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
}
.vertical-navigation h1 {
  visibility: hidden;
  min-width: 100%;
}
.vertical-navigation ul li .text {
  visibility: hidden;
}
@media (min-width: 768px) {
  .vertical-navigation {
    height: 100vh;
  }
  .vertical-navigation:hover {
    width: 276px;
  }
  .vertical-navigation:hover h1, .vertical-navigation:hover ul li .text {
    visibility: visible;
  }
  .vertical-navigation:hover [aria-selected=true]:after, .vertical-navigation:hover .selected:after {
    background: linear-gradient(135deg, #e49d2d, #ea0b7e 51.09%, #851b4a);
  }
  .vertical-navigation .menu-icon {
    display: none;
  }
}

.MuiTabs-root .MuiButtonBase-root {
  font-family: "TT Commons", sans-serif;
  text-transform: none;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  padding: 18px 0;
  margin: 0 20px;
  color: inherit;
  min-width: 24px;
}
.MuiTabs-root .MuiButtonBase-root:first-of-type {
  margin-left: 0;
}
.MuiTabs-root .MuiButtonBase-root.Mui-selected {
  font-weight: bold;
  color: inherit;
}
.MuiTabs-root .MuiButtonBase-root.MuiTabs-scrollButtons {
  margin: 0;
}
.MuiTabs-root .MuiButtonBase-root:hover, .MuiTabs-root .MuiButtonBase-root:focus {
  font-weight: 700;
}
.MuiTabs-root .MuiButtonBase-root > span {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.MuiTabs-root .MuiButtonBase-root .hidden-bold {
  font-weight: 700;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
@media speech {
  .MuiTabs-root .MuiButtonBase-root .hidden-bold {
    display: none;
  }
}
.MuiTabs-root .MuiTabs-indicator {
  background: var(--text-default);
  height: 4px;
}

.horizontal-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1111;
  width: 100%;
  background: linear-gradient(to right, #05112A, #3E0953);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  color: var(--color-white);
}
.horizontal-navigation .tab-container .tab-list {
  overflow-x: auto;
  display: flex;
  gap: 16px;
  margin: 0;
}

.page-wrapper.horizontal-nav .menu-icon {
  display: none;
}
.page-wrapper.horizontal-nav .main-nav.tab-container {
  padding: 0;
}
.page-wrapper.horizontal-nav .page-header > .tab-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(0px);
  align-self: flex-end;
}
@media (min-width: 1320px) {
  .page-wrapper.horizontal-nav .main-nav.tab-container {
    padding: 0;
  }
}
@media (max-width: 1120px) {
  .page-wrapper.horizontal-nav .header-wrapper {
    padding-left: calc(0.5 * var(--outside-padding));
  }
  .page-wrapper.horizontal-nav .header-wrapper .page-header {
    padding-left: 8px;
  }
  .page-wrapper.horizontal-nav .menu-icon {
    display: block !important;
  }
  .page-wrapper.horizontal-nav .menu-icon .menu-btn {
    width: 48px;
    height: 48px;
    color: var(--color-white);
  }
  .page-wrapper.horizontal-nav .menu-icon .menu-btn svg rect {
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: center;
    opacity: 1;
  }
  .page-wrapper.horizontal-nav .menu-icon .menu-btn[aria-expanded=true] svg rect {
    opacity: 0;
  }
  .page-wrapper.horizontal-nav .menu-icon .menu-btn[aria-expanded=true] svg rect:first-of-type {
    transform: rotate(45deg);
    transform-origin: 6px 8px;
    opacity: 1;
  }
  .page-wrapper.horizontal-nav .menu-icon .menu-btn[aria-expanded=true] svg rect:last-of-type {
    transform: rotate(-45deg);
    transform-origin: 5px 16px;
    opacity: 1;
  }
  .page-wrapper.horizontal-nav .nav-menu {
    height: calc(100dvh - var(--headerHeight));
  }
  .page-wrapper.horizontal-nav .nav-menu .icon-btn {
    color: #989BA3;
    width: 40px;
  }
  .page-wrapper.horizontal-nav .nav-menu button.tab-action {
    width: calc(100% - 2 * var(--outside-padding));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    text-align: left;
  }
  .page-wrapper.horizontal-nav .nav-menu .panel {
    display: flex;
    flex-direction: column;
    padding: 12px;
    height: 100%;
    max-height: calc(100dvh - var(--headerHeight));
    overflow: hidden;
    background: linear-gradient(to right, #05112A, #3E0953);
  }
  .page-wrapper.horizontal-nav .nav-menu .panel .panel-header {
    padding: 8px 8px 8px 0px;
    border-bottom: 1px solid var(--color-border);
  }
  .page-wrapper.horizontal-nav .nav-menu .panel .panel-header .flex-row {
    align-items: center;
    justify-content: flex-start;
  }
  .page-wrapper.horizontal-nav .nav-menu .panel .panel-header .flex-row h2, .page-wrapper.horizontal-nav .nav-menu .panel .panel-header .flex-row h3 {
    margin: 0;
  }
  .page-wrapper.horizontal-nav .nav-menu .panel .panel-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin: 18px 0 0 0;
    padding: 0 0 0 40px;
  }
  .page-wrapper.horizontal-nav .nav-menu .panel ul {
    padding: 8px 0;
    flex: 1;
    overflow-y: auto;
  }
  .page-wrapper.horizontal-nav .nav-menu .panel.subpanel ul li a, .page-wrapper.horizontal-nav .nav-menu .panel.subpanel ul li button {
    margin-left: 40px;
  }
  .page-wrapper.horizontal-nav .nav-menu .panel.subsubpanel ul li a, .page-wrapper.horizontal-nav .nav-menu .panel.subsubpanel ul li button {
    margin-left: 40px;
  }
  .page-wrapper.horizontal-nav .nav-menu nav {
    padding-bottom: 24px;
  }
  .page-wrapper.horizontal-nav .nav-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .page-wrapper.horizontal-nav .nav-menu ul li a, .page-wrapper.horizontal-nav .nav-menu ul li button {
    background: transparent;
    border: none;
    position: relative;
    display: inline-block;
    color: var(--color-white);
    font-weight: normal;
    padding: 8px 0;
    text-decoration: none;
    color: #989BA3;
    margin: 6px var(--outside-padding);
    font-size: 20px;
  }
  .page-wrapper.horizontal-nav .nav-menu ul li a:after, .page-wrapper.horizontal-nav .nav-menu ul li button:after {
    content: "";
    position: absolute;
    height: 2px;
    background: transparent;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .page-wrapper.horizontal-nav .nav-menu ul li a:hover, .page-wrapper.horizontal-nav .nav-menu ul li button:hover {
    color: var(--color-light-300);
  }
  .page-wrapper.horizontal-nav .nav-menu ul li a:hover:after, .page-wrapper.horizontal-nav .nav-menu ul li button:hover:after {
    background: var(--color-light-300);
  }
  .page-wrapper.horizontal-nav .nav-menu ul li.active a {
    color: var(--color-white);
    font-weight: 700;
  }
  .page-wrapper.horizontal-nav .nav-menu ul li.active a:after {
    background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
  }
  .page-wrapper.horizontal-nav .page-header > .tab-container {
    display: none;
  }
}

.profile-content {
  position: relative;
  padding-bottom: 80px;
  font-size: 20px;
}
.profile-content:before {
  position: absolute;
  bottom: -36px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 600px;
  background: linear-gradient(215.84deg, rgb(109, 1, 118) -45.86%, rgba(109, 1, 118, 0) 55.93%), rgb(5, 17, 42);
}
@media (min-width: 1024px) {
  .profile-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
  }
  .profile-content .col-1 {
    max-width: 40%;
  }
  .profile-content .card:last-of-type {
    margin-bottom: 0;
  }
}
.profile-content .card {
  margin-bottom: 24px;
  position: relative;
  border-radius: 8px;
}
.profile-content .card .card-header {
  margin-bottom: 24px;
}
.profile-content .card .card-header .flex-row {
  align-items: center;
  flex-direction: row;
}
.profile-content .card .card-header h2 {
  margin: 0;
}
.profile-content .card .card-header .add-btn {
  color: var(--text-default);
  background-color: var(--bg-level2);
  width: 40px;
  height: 40px;
}
.profile-content .card .card-footer {
  display: flex;
  justify-content: flex-end;
}
.profile-content .card .edit-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  color: var(--text-default);
}
.profile-content .main-col .card .card-header:not(.no-border) {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 24px;
}
.profile-content .about-me-card {
  text-align: center;
}
.profile-content .about-me-card h1 {
  margin-bottom: 16px;
}
.profile-content .about-me-card h1, .profile-content .about-me-card h2, .profile-content .about-me-card p {
  text-align: center;
}
.profile-content .about-me-card .avatar img {
  transform: scale(1.1);
}
.profile-content .about-me-card .school {
  text-align: center;
}
.profile-content .pathway-list {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 4px;
}
.profile-content .pathway-list .pathway-card {
  width: 130px;
  padding: 36px 12px 12px 12px;
  margin-bottom: 0;
}
.profile-content .pathway-list .pathway-card .card-label {
  padding: 8px;
  font-size: 12px;
  top: 8px;
  left: 8px;
}
.profile-content .pathway-list .pathway-card .image {
  max-height: 70px;
  max-width: 70px;
}
.profile-content .pathway-list .pathway-card .card-title {
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.profile-content .pathway-list .pathway-card .issuer {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.profile-content .pathway-list .pathway-card:not(:first-of-type) {
  margin-left: 24px;
}
.profile-content .pathway-list .pathway-card:not(:first-of-type):before {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path d="M14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.34315 0.928932C7.95262 0.538408 7.31946 0.538408 6.92893 0.928932C6.53841 1.31946 6.53841 1.95262 6.92893 2.34315L12.5858 8L6.92893 13.6569C6.53841 14.0474 6.53841 14.6805 6.92893 15.0711C7.31946 15.4616 7.95262 15.4616 8.34315 15.0711L14.7071 8.70711ZM0 9H14V7H0V9Z" fill="black"/></svg>');
  position: absolute;
  display: block;
  width: 15px;
  height: 16px;
  left: -20px;
  top: calc(50% - 12px);
}
.profile-content .experience-entry, .profile-content .education-entry {
  position: relative;
}
.profile-content .experience-entry .icon-btn, .profile-content .education-entry .icon-btn {
  color: var(--text-default);
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
.profile-content .experience-entry .education-info, .profile-content .education-entry .education-info {
  padding-right: 48px;
}
.profile-content .experience-entry .education-info p, .profile-content .education-entry .education-info p {
  margin: 0;
}
.profile-content .experience-entry .employer, .profile-content .experience-entry .desktop-h2, .profile-content .education-entry .employer, .profile-content .education-entry .desktop-h2 {
  margin-right: 48px;
}
.profile-content .experience-entry .experience-dates, .profile-content .education-entry .experience-dates {
  font-size: 22px;
  color: var(--color-text-muted);
}
.profile-content .col-1 {
  flex: 1;
}
.profile-content .col-2 {
  flex: 2;
}
.profile-content .avatar {
  background: #C8C9CB;
  border: 4px solid #FEFEFE;
  border-radius: 80px;
  width: 160px;
  height: 160px;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.profile-content .chip-list .chip-btn {
  font-weight: 600;
  color: var(--text-muted);
  font-size: 14px;
}

.empty-area-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background: var(--bg-level0);
  border: 2px dashed var(--color-border);
  border-radius: 8px;
  padding: 32px;
  width: 100%;
  color: var(--color-text-muted);
  font-size: 18px;
}
.empty-area-button .circle {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 8px;
  background-color: #122142;
  color: var(--color-white);
}
.empty-area-button:hover, .empty-area-button:focus {
  border-color: rgba(2, 9, 23, 0.1);
  background: var(--bg-level2);
  border-color: #122142;
  position: relative;
}
.badge-list .badge-container {
  border-bottom: 1px solid var(--color-border);
  padding: 24px 0;
}
.badge-list .badge-container:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}
.badge-list .badge-container .badge-information {
  gap: 30px;
  align-items: flex-start;
  flex-direction: row;
}
.badge-list .badge-container .badge-information .badge-details h2, .badge-list .badge-container .badge-information .badge-details p {
  text-align: left;
}
.badge-list .badge-container .badge {
  max-width: 84px;
}
.badge-list .badge-container .badge.comptia img {
  transform: scale(1.2) translateY(-5px);
}
.badge-list .badge-actions {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}

.profile.illustration {
  position: relative;
  display: flex;
  height: 350px;
  overflow: hidden;
  background: rgb(5, 17, 42);
}
.profile.illustration:before {
  content: "";
  position: absolute;
  background: linear-gradient(94deg, rgba(255, 255, 255, 0.1) 0.71%, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100.32%);
  transform: rotate(253deg);
  filter: opacity(0.05);
  width: 24%;
  padding-top: 33%;
  bottom: -50%;
  left: 3%;
  z-index: 2;
}
.profile.illustration .puppy {
  position: absolute;
  width: 412px;
  height: 306px;
  bottom: 0px;
  right: 150px;
  background-image: url("https://www.pearson.com/jobmatch/assets/images/puppy.png");
}
@media (min-width: 834px) and (max-width: 1280px) and (orientation: landscape) {
  .profile.illustration .puppy {
    right: 112px;
  }
}
@media (max-width: 833px) {
  .profile.illustration .puppy {
    right: 10px;
  }
}
.profile.illustration .css-sq2z9g {
  bottom: 0px;
  background: transparent;
  width: 100%;
  height: 154px;
  z-index: 2;
  position: absolute;
}
@media screen and (max-width: 1180px) {
  .profile.illustration .css-sq2z9g {
    bottom: 200px;
  }
}

.toggle button {
  font-size: 24px;
  position: relative;
  display: block;
  width: 64px;
  height: 32px;
  padding: 0;
  border: 0;
  border-radius: 32px;
  margin: 0;
  background-color: var(--color-light-300);
  color: var(--color-dark-600);
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: visible;
  min-width: 64px;
}
.toggle button:before {
  content: "";
  background: var(--color-white);
  display: block;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: left 0.3s;
  border-radius: 100%;
  z-index: 2;
}
.toggle button .off {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: flex;
  transition: opacity 0.15s ease;
}
.toggle button .on {
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: flex;
  opacity: 0;
  transition: opacity 0.15s ease;
}
.toggle button[aria-checked=true]:before {
  left: calc(50% - 2px);
}
.toggle button[aria-checked=true] .off {
  opacity: 0;
}
.toggle button[aria-checked=true] .on {
  opacity: 1;
}
@media (prefers-color-scheme: dark) {
  .toggle button {
    background-color: var(--color-dark-300);
    color: var(--color-light-600);
  }
  .toggle button:before {
    background: var(--color-black);
  }
}

.mode-toggle-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
.mode-toggle-container .mode-toggle svg {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: 1;
}
.mode-toggle-container .mode-toggle svg.sun {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.mode-toggle-container .mode-toggle svg.moon {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.mode-toggle-container .toggle-label {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-muted);
  font-weight: 400;
}
.mode-toggle-container .toggle-label.right {
  text-align: left;
  min-width: 50px;
}
.mode-toggle-container .toggle-label.left {
  text-align: right;
  min-width: 50px;
}
.mode-toggle-container .toggle-label.selected {
  font-weight: 600;
  color: var(--color-default);
}

.dark-mode .toggle button {
  background: var(--background-dark-theme-bg-level-1, #161C29);
}
.dark-mode .toggle button:before {
  background-color: #393F4A;
}

body {
  background-color: var(--bg-level2);
  margin: 0;
  padding: 0;
  --headerHeight: 64px;
}

.page-wrapper main {
  width: 100%;
  min-height: calc(100vh - var(--headerHeight) - 160px - 32px);
  max-width: calc(1440px + var(--outside-padding) + var(--outside-padding));
  margin: 0 auto;
}
.page-wrapper main .floating-action-btn {
  position: fixed;
  z-index: 1110;
  bottom: var(--outside-padding);
  right: var(--outside-padding);
}
.page-wrapper main.careers-main {
  max-width: 100%;
}
.page-wrapper main.careers-main .intro h1, .page-wrapper main.careers-main .intro p {
  margin: 0;
}
.page-wrapper main.careers-main .intro p {
  margin-top: 12px;
}
.page-wrapper main.careers-main .floating-action-btn {
  color: var(--color-white);
  top: calc(var(--headerHeight) + 200px + 32px);
  right: var(--outside-padding);
  bottom: unset;
  transition: background 0.3s ease;
}
.page-wrapper main.careers-main .floating-action-btn.absolute {
  position: absolute;
}
.page-wrapper main.careers-main .floating-action-btn.fixed {
  position: fixed;
  top: calc(var(--headerHeight) + 32px);
}
.page-wrapper main.careers-main .floating-action-btn:not(.primary) {
  background: rgba(28, 10, 50, 0.45);
}
@media (min-width: 568px) {
  .page-wrapper main.careers-main .floating-action-btn {
    top: calc(var(--headerHeight) + 32px);
  }
}
.page-wrapper main.careers-main section {
  max-width: calc(1440px + var(--outside-padding) + var(--outside-padding));
  margin: 0 auto;
}
.page-wrapper main .page-title {
  margin: 0 0 32px 0;
}
.page-wrapper main .page-title.secondary {
  position: relative;
  display: inline-block;
  margin: 0;
}
.page-wrapper main .page-title.secondary:after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background: var(--Gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
  margin-top: 8px;
}
.page-wrapper main .page-title:only-child {
  margin: 0;
}
.page-wrapper main .page-title.tertiary {
  margin: 0;
}
.page-wrapper main .page-nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}
.page-wrapper main .page-nav .page-title {
  line-height: 40px;
}
.page-wrapper main .page-nav a {
  flex: none;
  color: inherit;
  height: 40px;
}
.page-wrapper main .page-nav + .description {
  margin-top: 0;
}
.page-wrapper main section {
  padding: 0 var(--outside-padding);
  margin-bottom: var(--vertical-space);
}
.page-wrapper main section.carousel {
  padding: 0;
}
.page-wrapper main section.carousel .card-carousel {
  padding: 0 var(--outside-padding);
}
.page-wrapper main section.intro .dekstop-h1 {
  margin-bottom: 32px;
}
.page-wrapper main section:first-of-type .card-row:first-of-type {
  margin-top: 0;
}
.page-wrapper.vertical-nav {
  padding-left: 88px;
}
.page-wrapper.horizontal-nav {
  padding-top: var(--headerHeight);
}
.page-wrapper.horizontal-nav main {
  padding-top: 48px;
}
.page-wrapper.skills main section:not(.intro) {
  margin-bottom: 24px;
}
@media (max-width: 567px) {
  .page-wrapper.horizontal-nav {
    padding-top: var(--headerHeight);
  }
  .page-wrapper.horizontal-nav main .intro > h1 {
    margin-top: 0;
  }
  .page-wrapper .swoop {
    display: none;
  }
  .page-wrapper .bookmark-btn {
    opacity: 1;
  }
}
@media (max-width: 807px) {
  .page-wrapper .page-header .search-input {
    display: none;
  }
}

.swoop {
  position: absolute;
  width: 100%;
  height: 230px;
  top: -1px;
  left: 0px;
  right: 0;
  z-index: -1;
  background-image: url("../images/waves.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}
.swoop.large {
  height: 300px;
}

.swoop-subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.4px;
  margin: 0;
}

.swoop-text {
  position: relative;
  padding: 80px var(--outside-padding);
  color: var(--color-white);
  margin: 0px auto;
  min-height: 212px;
  background-image: url("../images/waves.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
}
.swoop-text .page-nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}
.swoop-text .page-nav h1 {
  margin-bottom: 16px;
}
.swoop-text .page-nav a {
  flex: none;
  color: inherit;
  height: 40px;
}
@media (max-width: 567px) {
  .swoop-text {
    background-image: url("../images/waveMobile.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center bottom;
    margin-top: -40px;
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.swoop-text h1 {
  margin: 0 0 16px 0;
}

.header.dark-gradient-bg {
  background-image: url("https://www.pearson.com/jobmatch/assets/waves.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  overflow: hidden;
}

.footer-curve {
  background-image: url("../images/footer-curve-2.svg");
  background-repeat: no-repeat;
  background-size: 100% 120%;
  background-position: center top;
  position: absolute;
  width: 100%;
  height: 200px;
  bottom: 0px;
  right: 0;
  z-index: -1;
}

.small-space-for-demo .intro .card.action-card {
  min-height: 270px;
}
.small-space-for-demo .intro .card.action-card .badge-information {
  padding-top: 0;
}
.small-space-for-demo .intro .card.action-card .badge-information .badge.avatar {
  max-height: 125px;
  max-width: 125px;
}
.small-space-for-demo .intro .card.action-card .badge-information .badge.avatar.Aplus {
  max-height: 125px;
  max-width: 150px;
}
.small-space-for-demo .intro .card.action-card .badge-information .badge.avatar.Aplus img {
  margin-top: -8px;
}
.small-space-for-demo .intro .card.action-card .card-footer {
  bottom: 69px;
  z-index: 5;
}
@media (min-width: 808px) {
  .small-space-for-demo .intro .card.action-card .badge-information {
    padding-right: 180px;
  }
}

.unauth-home {
  background-color: #F7F9FD;
}
.unauth-home button.light.outline {
  color: var(--swatch-light-400, #D2D7E2);
  outline: 2px solid var(--swatch-light-400, #D2D7E2);
}
.unauth-home .page-header {
  position: absolute;
  top: 0;
  z-index: 111;
  color: var(--color-white);
}
.unauth-home h2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  color: var(--color-black);
}
.unauth-home h3 {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  margin: 0;
  color: var(--color-black);
}
.unauth-home .illustration.slash {
  position: absolute;
  width: 1500px;
  height: 1500px;
  left: -950px;
  top: -750px;
  background: linear-gradient(273.26deg, rgba(155, 161, 173, 0.08) -5.26%, rgba(232, 238, 250, 0.08) 39.67%, rgba(232, 238, 250, 0.08) 55.59%, rgba(155, 161, 173, 0.08) 103.93%);
  transform: rotate(31.39deg);
}
.unauth-home .illustration.slash.mini {
  background: linear-gradient(273deg, rgba(196, 196, 196, 0.15) -5.26%, rgba(247, 249, 253, 0.07) 39.67%, rgba(247, 249, 253, 0.07) 55.59%, rgba(196, 196, 196, 0.15) 103.93%);
  -webkit-mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 9.71%, #FFF 47.46%, #FFF 62.57%, rgba(255, 255, 255, 0) 100.32%);
          mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 9.71%, #FFF 47.46%, #FFF 62.57%, rgba(255, 255, 255, 0) 100.32%);
  width: 584.403px;
  height: 634.864px;
  transform: rotate(147.328deg) translate(50%, -50%);
  flex-shrink: 0;
  left: 0;
  bottom: 0;
  top: unset;
  opacity: 0.5;
  overflow: hidden;
}
.unauth-home .illustration.wave {
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 100%;
  height: 684px;
  background: url("https://www.pearson.com/jobmatch/_next/static/media/wave.3d4ac462.svg") no-repeat;
  background-size: cover;
  z-index: -5;
}
.unauth-home section:not(.intro) {
  position: relative;
  padding: 80px 0 80px 0;
  overflow: clip;
  background-color: #F7F9FD;
}
.unauth-home section:not(.intro) .section-inner {
  max-width: 1152px;
  margin: 0 auto;
  padding: 0 16px;
}
.unauth-home section:not(.intro) .section-inner > p {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.4px;
  margin: 24px auto 0;
  max-width: 760px;
  color: var(--color-black);
}
.unauth-home .button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
}
.unauth-home .careers-group {
  margin-top: 56px;
}
.unauth-home .careers-group + .button-row {
  margin-top: 72px;
  padding-bottom: 100px;
}
.unauth-home .card-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-top: 32px;
}
.unauth-home .card-row h3 {
  margin-top: 40px;
  text-align: center;
}
.unauth-home .card-row .description {
  font-size: 24px;
  text-align: center;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #60646D;
  mix-blend-mode: luminosity;
  margin-top: 16px;
  margin-bottom: 0;
}
.unauth-home .card-row .card {
  max-width: 600px;
}
@media (min-width: 880px) {
  .unauth-home .card-row {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .unauth-home .card-row.courses {
    flex-direction: column;
    align-items: center;
  }
  .unauth-home .card-row .card {
    max-width: 600px;
  }
  .unauth-home .card-row .course-card {
    max-width: 600px;
    min-height: 210px;
    min-width: 0;
    display: flex;
    gap: 16px;
    text-align: left;
  }
  .unauth-home .card-row .course-card .image {
    width: 348px;
    flex: none;
  }
  .unauth-home .card-row .course-card h3 {
    margin-top: 16px;
    text-align: left;
    color: var(--color-white);
  }
  .unauth-home .card-row .course-card p {
    text-align: left;
    color: var(--color-white);
  }
}
@media (min-width: 1110px) {
  .unauth-home .card-row {
    flex-direction: row;
    align-items: flex-start;
  }
  .unauth-home .card-row.courses {
    flex-direction: row;
  }
  .unauth-home .card-row .course-card {
    display: block;
    flex: 1 1 368px;
  }
  .unauth-home .card-row .course-card .image {
    width: 100%;
  }
}
.unauth-home .header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 78px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 19px 15px;
  background: linear-gradient(223.42deg, #6D0176 -36.08%, rgba(109, 1, 118, 0) 53.92%) 0 0/auto 543px no-repeat, #05112A;
}
.unauth-home .header .logo {
  width: 133.68px;
  height: 34px;
}
@media (max-width: 414px) {
  .unauth-home .header .logo {
    width: 34px;
    overflow: hidden;
  }
}
.unauth-home .header .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.unauth-home section.intro {
  position: relative;
  z-index: 5;
  background: linear-gradient(223.42deg, #6D0176 -36.08%, rgba(109, 1, 118, 0) 53.92%), #05112A;
  padding-top: 78px;
  height: 943px;
  -webkit-clip-path: ellipse(170% 90% at 20% -30px);
          clip-path: ellipse(170% 90% at 20% -30px);
  color: var(--color-white);
  text-align: left;
  overflow: hidden;
}
.unauth-home section.intro .illustration {
  position: absolute;
  left: 10%;
  top: 50%;
  width: 90%;
  height: 60%;
  background: url("https://www.pearson.com/jobmatch/_next/static/media/header-clipart.0e8390c7.png") no-repeat;
  z-index: -10;
  animation: fadeIn 800ms ease-out;
}
.unauth-home section.intro .intro-inner {
  padding: 0 16px;
  max-width: 1152px;
  margin: 0 auto;
  animation: fadeIn 800ms ease-out;
}
.unauth-home section.intro .intro-inner p {
  max-width: 600px;
}
.unauth-home section.intro h1 {
  margin-top: 56px;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
}
.unauth-home section.intro p {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.4px;
  margin-top: 16px;
  width: auto;
}
.unauth-home section.intro p + button {
  margin-top: 40px;
}
.unauth-home section.intro + section {
  position: relative;
  padding-top: 250px;
  margin-top: -250px;
  background: #F7F9FD;
  overflow: hidden;
}
@media (min-width: 769px) {
  .unauth-home section.intro {
    height: 543px;
    -webkit-clip-path: ellipse(120% 90% at 20% -30px);
            clip-path: ellipse(120% 90% at 20% -30px);
  }
  .unauth-home section.intro .illustration {
    position: absolute;
    left: 50%;
    top: unset;
    width: 50%;
    height: 400px;
    background: url("https://www.pearson.com/jobmatch/_next/static/media/header-clipart.0e8390c7.png") no-repeat;
    z-index: -10;
  }
  .unauth-home section.intro .intro-inner p {
    max-width: 45%;
  }
}
@media screen and (min-width: 1025px) {
  .unauth-home section.intro {
    height: 543px;
    -webkit-clip-path: ellipse(120% 90% at 20% -30px);
            clip-path: ellipse(120% 90% at 20% -30px);
  }
  .unauth-home section.intro .illustration {
    position: absolute;
    top: unset;
    width: 633.57px;
    height: 684px;
    background: url("https://www.pearson.com/jobmatch/_next/static/media/header-clipart.0e8390c7.png") no-repeat;
    z-index: -10;
  }
}
.unauth-home section.dark-bg .section-inner h2, .unauth-home section.dark-bg .section-inner p {
  color: var(--color-white);
}
.unauth-home section.dark-gradient-bg {
  -webkit-clip-path: ellipse(190% 90% at 20% 85px);
          clip-path: ellipse(190% 90% at 20% 85px);
}
.unauth-home section.dark-gradient-bg .section-inner h2, .unauth-home section.dark-gradient-bg .section-inner p {
  color: var(--color-white);
}
@media (max-width: 768px) {
  .unauth-home section.dark-gradient-bg {
    padding-bottom: 100px;
  }
}
@media (min-width: 769px) {
  .unauth-home section.dark-gradient-bg {
    -webkit-clip-path: ellipse(130% 90% at 20% 85px);
            clip-path: ellipse(130% 90% at 20% 85px);
  }
}
.unauth-home section.dark-gradient-bg + section {
  position: relative;
  padding-top: 250px;
  margin-top: -250px;
  background: #F7F9FD;
  overflow: hidden;
}
.unauth-home .insights-path {
  display: flex;
  margin-bottom: 70px;
}
.unauth-home .insights-step {
  flex: none;
  max-width: 302.5px;
  text-align: center;
}
.unauth-home .insights-step span {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #FEFEFE;
  margin-top: 15px;
}
.unauth-home .insights-step .image img {
  width: 100%;
  max-width: 100%;
}
.unauth-home .insights-step:not(:first-of-type) {
  margin-left: -30px;
}
.unauth-home .insights-step:nth-of-type(1) {
  margin-top: 60px;
}
.unauth-home .insights-step:nth-of-type(2) {
  margin-top: 215px;
}
.unauth-home .insights-step:nth-of-type(3) {
  margin-top: 100px;
}
.unauth-home .insights-step:nth-of-type(4) {
  margin-top: 160px;
}
.unauth-home .course-card {
  background-color: #122142 !important;
  border-color: #203B75;
  color: var(--color-white);
  text-align: left;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  max-width: 400px;
  min-height: 296px;
  padding: 8px;
}
.unauth-home .course-card .image {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid #667080;
  background: linear-gradient(0deg, rgba(2, 9, 23, 0.24), rgba(2, 9, 23, 0.24));
  height: 208px;
}
.unauth-home .course-card .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s ease-in-out;
}
.unauth-home .course-card h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 12px 12px 0 12px;
  color: var(--color-white);
  text-align: left;
}
.unauth-home .course-card .description {
  text-align: left;
  color: var(--color-white);
  font-weight: 300;
  font-size: 22px;
  line-height: 24px;
  margin: 4px 12px 8px 12px;
}
.unauth-home .course-card:hover {
  filter: brightness(1.1);
}
.unauth-home .course-card:hover img {
  transform: scale(1.1);
}

.dark-bg {
  background-color: var(--color-dark-blue) !important;
  color: var(--color-white);
}

.dark-gradient-bg {
  background: linear-gradient(214.54deg, #6D0176 -53.59%, var(--color-dark-blue) 55.69%);
  background-color: var(--color-dark-blue) !important;
  color: var(--color-white);
}

.border-top {
  border-top: 8px solid #0CC0AD;
}

.border-top-gradient {
  position: relative;
}
.border-top-gradient:before {
  content: "";
  display: block;
  background: linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%);
  height: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
}

.overflow-x {
  overflow-x: auto;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section .card-row .card {
  animation-fill-mode: forwards;
  opacity: 0;
}

.is-visible .card-row .card {
  animation: fadeIn 800ms ease-out;
  animation-fill-mode: forwards;
}
.is-visible .card-row .card:nth-of-type(1) {
  animation-delay: 200ms;
}
.is-visible .card-row .card:nth-of-type(2) {
  animation-delay: 400ms;
}
.is-visible .card-row .card:nth-of-type(3) {
  animation-delay: 600ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
.fadeIn {
  animation: fadeInSmall 400ms ease-out;
  animation-fill-mode: forwards;
}

.fadeInUp {
  animation: fadeInUp 400ms ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeInSmall {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
.fadeOut {
  animation: fadeOutCard 300ms ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeOutCard {
  0% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.career-switcher.tab-container {
  display: flex;
  flex-direction: column;
  max-width: 956px;
  margin: auto;
}
.career-switcher.tab-container .tab-list-container {
  position: relative;
  margin-bottom: 0;
}
.career-switcher.tab-container .tab-list-container .single-career.image {
  border-radius: calc(var(--card-corner-default));
  height: 100%;
  overflow: hidden;
  margin-bottom: 28px;
}
.career-switcher.tab-container .tab-list-container .single-career.image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.career-switcher.tab-container .tab-list {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.career-switcher.tab-container .tab-list .tab-item {
  display: block;
  font-weight: 400;
  margin-bottom: 8px;
}
.career-switcher.tab-container .tab-list .tab-item:last-of-type {
  margin-bottom: 0;
}
.career-switcher.tab-container .tab-list .tab-item button {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  padding: 0px;
  background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 1.64%, rgba(255, 255, 255, 0.05) 1.65%, rgba(0, 133, 255, 0) 120%);
  border: 0;
  border-radius: 8px;
  margin: 0;
  color: #fefefe;
  position: relative;
  max-height: 60px;
  transition: max-height 0.2s ease-in-out;
  transition: none;
  overflow: hidden;
}
.career-switcher.tab-container .tab-list .tab-item button .image {
  opacity: 0;
  height: 0;
  transition: none;
}
.career-switcher.tab-container .tab-list .tab-item button strong {
  color: var(--text-default);
}
.career-switcher.tab-container .tab-list .tab-item button .tab-label {
  padding: 6px 28px;
  white-space: normal;
  overflow: hidden;
  max-width: 100%;
  min-height: 56px;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
}
.career-switcher.tab-container .tab-list .tab-item button .tab-label span {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.career-switcher.tab-container .tab-list .tab-item button:after {
  content: "";
  width: 6px;
  height: calc(100% + 2px);
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  background: linear-gradient(137.32deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  z-index: 1;
}
.career-switcher.tab-container .tab-list .tab-item button[aria-selected=true] {
  max-height: 226px;
  transition: max-height 0.3s ease-in-out;
}
.career-switcher.tab-container .tab-list .tab-item button[aria-selected=true] .image {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  transition-delay: 0.2s;
}
.career-switcher.tab-container .tab-list .tab-item button[aria-selected=true]:after {
  opacity: 1;
}
.career-switcher.tab-container .tab-list .tab-item button:hover:after {
  opacity: 1;
}
.career-switcher.tab-container .tab-list .tab-item .image {
  border-bottom-right-radius: 8px;
  overflow: hidden;
  max-height: 170px;
  margin-right: -1px;
  margin-left: 5px;
  width: calc(100% - 4px);
}
.career-switcher.tab-container .tab-list .tab-item .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.career-switcher.tab-container .tab-list .tab-item span {
  display: block;
}
.career-switcher.tab-container .tab-list .tab-item span.number {
  font-size: 32px;
  margin-top: 16px;
  color: var(--color-black);
}
.career-switcher.tab-container .tab-list .tab-item span svg {
  vertical-align: middle;
  fill: var(--color-plum);
}
@media (max-width: 806px) {
  .career-switcher.tab-container .chart-container {
    background: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: var(--card-corner-default);
    padding: 16px;
    margin-bottom: 16px;
  }
}
@media (min-width: 807px) {
  .career-switcher.tab-container {
    flex-direction: row;
    gap: 32px;
  }
  .career-switcher.tab-container .tab-content {
    flex: 0 1 66%;
    overflow: hidden;
  }
  .career-switcher.tab-container .tab-list-container {
    flex: 1 0 33%;
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }
  .career-switcher.tab-container .tab-list-container .tab-list {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .career-switcher.tab-container .tab-list-container .tab-list .tab-item.active {
    flex: 1;
  }
  .career-switcher.tab-container .tab-list-container .tab-list .tab-item.active button[aria-selected=true] {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .career-switcher.tab-container .tab-list-container .tab-list .tab-item.active button[aria-selected=true] .image {
    flex: 1 1 173px;
    max-height: 100%;
  }
  .career-switcher.tab-container .tab-list-container .button {
    margin: 0 auto;
    text-align: center;
  }
}

.card.career-fast-facts {
  background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 1.64%, rgba(255, 255, 255, 0.05) 1.65%, rgba(0, 133, 255, 0) 120%);
  color: #fefefe;
  border: 0;
  border-radius: 8px;
  padding: 32px;
  height: 100%;
}
.card.career-fast-facts .facts-row > div {
  flex: 1;
}
.card.career-fast-facts .card-footer {
  margin-top: 40px;
  margin-bottom: 8px;
}
.card.career-fast-facts .card-footer a {
  color: var(--text-dark-theme-link);
  font-size: 18px;
}

.career-accordion-btn {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  padding: 0px;
  background: transparent;
  border: none;
  margin: 0;
  color: #fefefe;
  position: relative;
  max-height: 56px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}
.career-accordion-btn + .career-info-box .card.career-fast-facts {
  background: transparent;
  border-color: transparent;
}
.career-accordion-btn + .career-info-box .card.career-fast-facts h4 {
  display: none;
}
.career-accordion-btn + .career-info-box .card.career-fast-facts .fast-facts-title {
  padding-top: 0;
}
.career-accordion-btn + .career-info-box .card.career-fast-facts .facts-row {
  gap: 24px;
  margin: 40px 0;
}
.career-accordion-btn + .career-info-box .card.career-fast-facts .card-footer {
  margin-top: 0;
}
.career-accordion-btn .image {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.2s;
}
.career-accordion-btn strong {
  color: var(--text-default);
}
.career-accordion-btn .tab-label {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  height: 56px;
}
.career-accordion-btn .tab-label span {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.career-accordion-btn[aria-expanded=true], .career-accordion-btn .open {
  max-height: 226px;
}
.career-accordion-btn[aria-expanded=true] .image, .career-accordion-btn .open .image {
  opacity: 1;
}
.career-accordion-btn[aria-expanded=true]:after, .career-accordion-btn .open:after {
  opacity: 1;
}
.career-accordion-btn .image {
  overflow: hidden;
  max-height: 170px;
  margin-right: -1px;
  margin-left: 5px;
  width: calc(100% - 4px);
  transform: translateY(-1px);
}
.career-accordion-btn .image img {
  width: 100%;
}
.career-accordion-btn span {
  display: block;
}
.career-accordion-btn span.number {
  font-size: 32px;
  margin-top: 16px;
  color: var(--color-black);
}
.career-accordion-btn span svg {
  vertical-align: middle;
  fill: var(--color-plum);
}

.career-group {
  background: linear-gradient(94deg, rgba(255, 255, 255, 0.06) 1.64%, rgba(255, 255, 255, 0.06) 1.65%, rgba(0, 133, 255, 0) 141.29%);
  color: #FEFEFE;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  position: relative;
}
.career-group:last-of-type {
  margin-bottom: 20px;
}
.career-group.single {
  margin-bottom: 20px;
}
.career-group.single .image {
  opacity: 1;
  width: 100%;
  border-radius: 8px;
  margin: 0;
}
@media (max-width: 806px) {
  .career-group.single .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.career-group.single:after {
  content: none;
}
.career-group + .button {
  margin: 0 auto;
  text-align: center;
}
.career-group:after {
  content: "";
  width: 6px;
  height: calc(100% + 2px);
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  background: linear-gradient(137.32deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  z-index: 1;
}
.career-group:hover:after, .career-group.open:after {
  opacity: 1;
}
.career-info-box {
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.career-info-box.open {
  height: 100%;
  opacity: 1;
}

.career-accordion-enter {
  height: 0;
  opacity: 0;
}

.career-accordion-appear.career-accordion-appear-active, .career-accordion-enter.career-accordion-enter-active {
  height: 100%;
  opacity: 1;
  transition: height 500ms ease, opacity 500ms ease;
}

.career-accordion-enter-done {
  height: 100%;
  opacity: 1;
  transition: height 500ms ease, opacity 500ms ease;
}

.career-accordion-leave {
  height: 100%;
  opacity: 1;
}

.career-accordion-leave.career-accordion-leave-active {
  height: 0;
  opacity: 0;
  transition: height 300ms ease, opacity 500ms ease;
}

.card.career-fast-facts .salary-chart {
  text-align: center;
}
.card.career-fast-facts .salary-chart .chart-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.card.career-fast-facts .salary-chart h6 {
  text-align: center;
  margin: 0 auto 12px auto;
}
.card.career-fast-facts .salary-chart .salary-chart-render {
  position: relative;
  padding: 0px 0 24px 0;
  font-size: 16px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
}
.card.career-fast-facts .salary-chart .salary-chart-render img {
  display: block;
  margin: auto;
  max-width: 250px;
  width: 100%;
}
.card.career-fast-facts .salary-chart .salary-chart-render .bottom-10 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.card.career-fast-facts .salary-chart .salary-chart-render .top-10 {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}
.card.career-fast-facts .salary-chart .salary-chart-render .average {
  position: absolute;
  text-align: center;
  left: 50%;
  top: -4px;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.card.career-fast-facts .salary-chart .salary-caption {
  font-size: 18px;
  line-height: 24px;
}
.card.career-fast-facts .salary-chart .salary-caption strong {
  display: block;
}

.progress-bar-container .progress-bar {
  position: relative;
  width: 100%;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.5);
  height: 16px;
}
.progress-bar-container .progress-bar .bar {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 100px;
  background: var(--gradient-teal, linear-gradient(170deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.progress-bar-container .progress-bar.loading .bar {
  width: 100%;
  background: #2E839E;
  position: relative;
  overflow: hidden;
}
.progress-bar-container .progress-bar.loading .bar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(125deg, rgba(12, 192, 173, 0.6) 0%, #2E839E 40%, #29657A 100%);
  transform: skewX(-30deg);
  z-index: 1;
  background-size: 45px 45px;
  background-position: 0 0;
  animation: move 1s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}
.progress-bar-container .progress-bar-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  margin-top: 8px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 45px 0px;
  }
}
.careers.page-wrapper {
  --mapHover: hue-rotate(74deg) brightness(100%) contrast(100%);
  --mapTextStroke: #f5f5f5;
  --mapTextHover: #B41863;
}
.careers.page-wrapper main {
  padding-top: 0;
}
.careers.page-wrapper .search-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: var(--vertical-space);
}
.careers.page-wrapper .search-header .link-btn {
  flex: none;
  min-width: 40px;
  text-align: center;
}
.careers.page-wrapper .search-header .link-btn svg {
  margin: auto;
  fill: var(--text-default);
}
.careers.page-wrapper .search-header .link-btn .mobile-only {
  display: block;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
}
.careers.page-wrapper .search-header .link-btn:hover .mobile-only, .careers.page-wrapper .search-header .link-btn:focus-visible .mobile-only {
  background: var(--map-glow-bg);
}
.careers.page-wrapper .search-header .career-search-list {
  flex: 1;
}
@media (min-width: 808px) {
  .careers.page-wrapper .search-header {
    gap: 24px;
  }
}
@media (prefers-color-scheme: dark) {
  .careers.page-wrapper {
    --mapHover: hue-rotate(28deg) brightness(160%) contrast(110%);
    background-color: #020917;
    --mapTextStroke: #181031;
    --mapTextHover: #F69D8A;
  }
}

.careers.page-wrapper .career-map {
  max-width: 100%;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  padding-bottom: 64px;
}
.careers.page-wrapper .career-map + .footer-container {
  margin-top: -64px;
  z-index: 3;
}
.careers.page-wrapper .career-map:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("../images/map/map_light_mobile.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.careers.page-wrapper .career-map:after {
  content: "";
  position: absolute;
  width: 100vw;
  left: 50%;
  right: 0;
  top: 0;
  height: 600px;
  transform: translateX(-50%);
  background: linear-gradient(165deg, #E8EEFA 27.59%, rgba(232, 232, 232, 0) 67.16%);
  -webkit-mask-image: linear-gradient(to bottom, black, transparent);
          mask-image: linear-gradient(to bottom, black, transparent);
  z-index: 0;
  pointer-events: none;
}
@media (prefers-color-scheme: dark) {
  .careers.page-wrapper .career-map:before {
    background-image: url("../images/map/map_dark_mobile.png");
  }
  .careers.page-wrapper .career-map:after {
    background: linear-gradient(to right, #05112A, #3E0953);
    opacity: 0.7;
  }
}
.careers.page-wrapper .career-map .intro {
  position: relative;
  padding: 48px var(--outside-padding) 32px var(--outside-padding);
  width: 100%;
  max-width: calc(1440px + var(--outside-padding) + var(--outside-padding));
  margin: 0 auto;
  z-index: 11;
}
.careers.page-wrapper .career-map .intro .search-input {
  max-width: 100%;
  pointer-events: all;
  z-index: 222;
}
.careers.page-wrapper .career-map .intro .search-input .gr-input {
  background: transparent;
  max-width: 100%;
  border-color: #020917;
  font-size: 1.125rem;
}
.careers.page-wrapper .career-map .intro .search-input .gr-input::-moz-placeholder {
  color: #020917;
}
.careers.page-wrapper .career-map .intro .search-input .gr-input::placeholder {
  color: #020917;
}
.careers.page-wrapper .career-map .intro .search-input .gr-input:focus-visible {
  background: var(--bg-level0);
}
@media (prefers-color-scheme: dark) {
  .careers.page-wrapper .career-map .intro .search-input {
    color: #fefefe;
  }
  .careers.page-wrapper .career-map .intro .search-input .gr-input {
    background: transparent;
    color: #fefefe;
    border-color: #fefefe;
  }
  .careers.page-wrapper .career-map .intro .search-input .gr-input::-moz-placeholder {
    color: #fefefe;
  }
  .careers.page-wrapper .career-map .intro .search-input .gr-input::placeholder {
    color: #fefefe;
  }
  .careers.page-wrapper .career-map .intro .search-input .gr-input:focus-visible {
    background: linear-gradient(180deg, #05112A 50%, rgba(5, 17, 42, 0) 100%);
  }
}
.careers.page-wrapper .career-map .intro h1, .careers.page-wrapper .career-map .intro p {
  max-width: 550px;
  z-index: 2;
  margin: 0;
}
@media (prefers-color-scheme: dark) {
  .careers.page-wrapper .career-map .intro h1, .careers.page-wrapper .career-map .intro p {
    color: #fefefe;
  }
}
.careers.page-wrapper .career-map .intro p {
  margin-top: 12px;
}
.careers.page-wrapper .career-map .map-container, .careers.page-wrapper .career-map .map-image {
  width: 100%;
  max-width: calc(1440px + var(--outside-padding) + var(--outside-padding));
  margin: 0 auto;
}
@media (min-width: 808px) {
  .careers.page-wrapper .career-map {
    background-image: url("../images/map/map_light_xwide.png");
    background-attachment: scroll;
    background-size: cover;
    background-position: center -70px;
    margin-bottom: -134px;
    padding-top: 60px;
  }
  .careers.page-wrapper .career-map:before {
    content: none;
  }
  .careers.page-wrapper .career-map .intro {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
  }
  .careers.page-wrapper .career-map section.map-image:before {
    height: 100%;
    width: 340px;
    background: linear-gradient(88deg, #E8EEFA 30%, rgba(232, 232, 232, 0) 75%);
    position: absolute;
    left: -276px;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .careers.page-wrapper .career-map section.map-image:after {
    height: 100%;
    width: 340px;
    background: linear-gradient(272deg, #E8EEFA 30%, rgba(232, 232, 232, 0) 75%);
    position: absolute;
    right: -276px;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}
@media (min-width: 808px) and (prefers-color-scheme: dark) {
  .careers.page-wrapper .career-map {
    background-image: url("../images/map/map_dark_xwide.png");
  }
}
@media (min-width: 1025px) {
  .careers.page-wrapper .career-map {
    padding-top: 0px;
  }
}
@media (min-width: 2200px) {
  .careers.page-wrapper .career-map {
    background-size: cover;
    background-position-x: center;
    background-position-y: 70%;
  }
}

.career-info {
  position: relative;
  z-index: 1;
  text-align: left;
  padding: 32px var(--outside-padding) 32px var(--outside-padding);
  width: 100%;
  max-width: calc(1440px + var(--outside-padding) + var(--outside-padding));
  margin: 0 auto;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.career-info h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  max-width: 550px;
  margin: 0;
  padding-right: 60px;
}
.career-info p {
  margin-top: 16px;
  margin-bottom: 0;
  max-width: 550px;
}
@media (min-width: 568px) {
  .career-info {
    padding-right: calc(var(--outside-padding) + 48px);
  }
  .career-info h1 {
    padding-right: 0;
  }
  .career-info h1, .career-info p {
    color: #FEFEFE;
  }
}

.banner-image {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.banner-image .video-container {
  width: 100%;
  height: 200px;
}
.banner-image .video-container .video-actions {
  position: absolute;
  z-index: 2;
  right: 24px;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.banner-image .video-container .video-actions button {
  color: #fefefe;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #fefefe;
  padding: 8px;
  border-radius: 8px;
}
.banner-image .video-container .video-actions button:hover {
  background-color: #05112A;
}
.banner-image .video-container video:hover .career-info, .banner-image .video-container iframe:hover .career-info {
  opacity: 0.5;
}
.banner-image video, .banner-image img, .banner-image iframe {
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.banner-image:after {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 5.35%, #160F37 91.99%);
  pointer-events: none;
}
@media (min-width: 568px) {
  .banner-image {
    min-height: 360px;
  }
  .banner-image:after {
    content: "";
    height: 100%;
  }
  .banner-image .video-container {
    position: absolute;
    min-height: 360px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 808px) {
  .banner-image:after {
    content: "";
    height: 100%;
    background: linear-gradient(345deg, rgba(0, 0, 0, 0) 50.35%, rgba(14, 5, 25, 0.45) 56.36%, rgba(28, 10, 50, 0.7) 62.25%, rgba(25, 12, 52, 0.87) 79.2%, #160F37 91.99%);
  }
}

.career-body .career-body-header {
  margin-top: 40px;
}
.career-body h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.career-body h2 + p {
  margin-top: 8px;
  margin-bottom: 0;
}
.career-body h3, .career-body h4 {
  margin: 0;
}
.career-body .pathway-group {
  margin-top: 40px;
}
.career-body .career-information {
  padding: 40px;
  border-bottom: 1px solid #C7CCD6;
  margin-bottom: 40px;
}
.career-body .career-information .career-features {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
}
.career-body .career-information .career-features .feature-group {
  flex: 1 1 360px;
  text-align: center;
}
.career-body .career-information .career-features .feature-number {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 400;
  display: block;
}
.career-body .career-information .career-features .feature-description {
  margin-top: 12px;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 700;
  display: block;
}
.career-body .detail-group {
  margin-bottom: 80px;
}
.career-body .detail-group .desktop-h4 {
  margin: 0 0 24px 0;
}
.career-body .detail-group:last-of-type {
  margin-bottom: 0;
}
.career-body .detail-group .jobs-list p {
  margin: 0;
}
.career-body .detail-group .jobs-list p + p {
  margin-top: 8px;
}
.career-body .detail-group .jobs-list p a {
  font-weight: normal;
}
.career-body .detail-group .skills-list {
  gap: 12px;
}
.career-body .detail-group .skills-list .chip-btn {
  margin: 0;
  line-height: 20px;
  min-height: 34px;
}
.career-body .detail-group .skills-list .chip-btn .verified {
  height: 20px;
  width: 20px;
}
.career-body .detail-group .detail-list-container {
  transition: max-height 0.3s ease;
  overflow: hidden;
}
.career-body .detail-group .detail-list-container.condensed {
  max-height: 320px;
  -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
          mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}
.career-body .detail-group .detail-list-container.expanded {
  max-height: 1000vh;
}
.career-body .detail-group .detail-list-container + .action-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.career-body .detail-group .detail-list ul {
  padding-left: 2rem;
  margin: 0;
}
.career-body .detail-group .detail-list ul li::marker {
  font-size: 24px;
  line-height: 28px;
}
.career-body .detail-group .detail-list ul li p {
  margin: 0;
}
.career-body .detail-group .detail-list ul li + li {
  margin-top: 24px;
}
@media (min-width: 808px) {
  .career-body .detail-group .detail-list {
    -moz-columns: 2;
         columns: 2;
  }
  .career-body .detail-group .detail-list li {
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
         break-inside: avoid;
  }
}
.career-body .quick-facts {
  margin-top: 80px;
}
.career-body .quick-facts h3 {
  margin-bottom: 24px;
}
.career-body .quick-facts .quick-facts-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
@media (min-width: 808px) {
  .career-body .quick-facts .quick-facts-grid {
    grid-template-columns: 1fr 1fr;
  }
}
.career-body .quick-facts .card {
  border-radius: 8px;
}
.career-body .quick-facts .quick-fact h4 {
  margin-bottom: 12px;
}
.career-body .quick-facts .quick-fact p {
  margin: 0;
}
.career-body .pathway-group-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;
}

.careers-grid {
  margin: 20px auto 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 16px;
  position: relative;
}
@media (min-width: 808px) {
  .careers-grid {
    gap: 24px;
  }
}

.career-pop-up {
  position: absolute;
  border-radius: 16px;
  padding: 0px;
  color: var(--text-default);
  text-align: left;
  overflow: hidden;
  z-index: 111;
  opacity: 0;
}
.career-pop-up.odd {
  left: 0;
  top: 0;
}
.career-pop-up.even {
  right: 0;
  top: 0;
}
.career-pop-up .modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4F5561;
  padding-bottom: 8px;
}
.career-pop-up .modal-header h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  text-align: left;
  margin: 0;
}
.career-pop-up .description {
  font-size: 18px;
  line-height: 24px;
}
.career-pop-up .link-btn {
  font-size: 18px;
  line-height: 24px;
}
.career-pop-up .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}
.career-pop-up.open {
  border: 1px solid #05112A;
  padding: 16px;
  animation: open 600ms ease-in-out;
  animation-fill-mode: forwards;
}
.career-pop-up.close {
  animation: close 600ms ease-in-out;
  animation-fill-mode: forwards;
}

@media (max-width: 807px) {
  .desktop-only {
    display: none !important;
  }
}
@media (min-width: 808px) {
  .mobile-only {
    display: none !important;
  }
}
@keyframes open {
  0% {
    opacity: 0;
    width: 0px;
    max-height: 0px;
    overflow: hidden;
  }
  90% {
    width: 100%;
    max-height: calc(60vh - 98px);
  }
  100% {
    opacity: 1;
    width: 100%;
    max-height: 70vh;
  }
}
@keyframes close {
  0% {
    opacity: 1;
    width: 100%;
    max-height: 70vh;
    padding: 16px;
  }
  100% {
    opacity: 1;
    width: 0;
    max-height: 0px;
    padding: 0px;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.career-details {
  text-align: left;
}
.career-details .tab-container {
  margin: 0px auto;
  padding: 16px;
}
@media (min-width: 769px) {
  .career-details {
    background: transparent;
    color: var(--color-black);
  }
  .career-details .tab-container {
    padding: 0 var(--outside-padding);
  }
  .career-details .tab-container section {
    padding: 0;
  }
  .career-details .tab-header {
    margin-bottom: 32px;
  }
}
.career-details .menu-icon {
  display: none;
}
.career-details section {
  margin-bottom: 40px;
}
.career-details h3 {
  font-size: 24px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 24px;
}
.career-details p, .career-details h4 {
  font-size: 18px;
  line-height: 24px;
}

.career-fast-facts .card-footer {
  text-align: center;
  margin-top: 0;
}
.career-fast-facts .fast-facts-title {
  width: 100%;
  border-bottom: 1px solid #4F5561;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0 0 18px 0;
}
.career-fast-facts .facts-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 26px;
  margin: 26px;
  text-align: center;
}
.career-fast-facts .facts-row h6, .career-fast-facts .facts-row p {
  text-align: center;
}
.career-fast-facts h6 {
  margin: 0 0 4px 0;
}
.career-fast-facts h6 svg, .career-fast-facts h6 img {
  margin-right: 8px;
}
.career-fast-facts h6 + p {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
}
.career-fast-facts .card-header {
  border-bottom: 1px solid var(--color-border);
}
.career-fast-facts .card-header h3 {
  margin-bottom: 16px;
}
.career-fast-facts h4 {
  margin: 0;
}
.career-fast-facts h4 + p {
  margin-top: 0;
}
.career-fast-facts ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.career-fast-facts ul li:last-child p {
  margin-bottom: 0;
}

.map-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 70px);
}
.map-container .toolbar {
  position: absolute;
  z-index: 11;
  bottom: 24px;
  left: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.map-container .toolbar button {
  width: 30px;
  height: 30px;
  padding: 6px;
  color: var(--text-default);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
}
.map-container .drawer-btn {
  background: transparent;
  border: none;
  position: relative;
}
.map-container .drawer-btn img {
  width: 100%;
}
.map-container .drawer-btn .map-hover {
  position: absolute;
  left: 0;
  top: 0;
  color: #F05B95;
  opacity: 0;
  pointer-events: none;
}
.map-container .drawer-btn:hover .map-hover, .map-container .drawer-btn:focus .map-hover {
  mix-blend-mode: color;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.map-container .drawer-btn:hover img, .map-container .drawer-btn:focus img {
  filter: var(--mapHover);
}

.map-image {
  position: relative;
  z-index: 2;
}
.map-image .image {
  overflow: hidden;
  width: 100%;
  background: none;
}
.map-image .image img {
  width: 100%;
  max-width: 100%;
  opacity: 0;
}
.map-image .image.dark-mode {
  display: none;
}
.map-image .hotspot-container {
  background: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: calc(1440px + var(--outside-padding) + var(--outside-padding));
  margin: 0 auto;
}
.map-image .hotspot-container.dark-mode {
  display: none;
}
.map-image .hotspot-container .drawer-btn {
  position: absolute;
  width: 20%;
  height: 20%;
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}
.map-image .hotspot-container .drawer-btn img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: translateY(0);
  animation: hotspot-easeOutBounce 0.6s;
}
.map-image .hotspot-container .drawer-btn .map-hover {
  position: absolute;
  left: 0;
  top: 0;
  color: #F05B95;
  opacity: 0;
  pointer-events: none;
}
.map-image .hotspot-container .drawer-btn .recommended-glow {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  margin: 0 auto;
  transform: scaleX(1) scaleY(0.65) translateY(35%) translateX(-50%);
  z-index: -1;
}
.map-image .hotspot-container .drawer-btn .recommended-glow:before {
  content: "";
  border: 30px solid var(--map-glow-bg);
  border-radius: 50%;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  position: absolute;
  left: 2px;
  bottom: 0;
  animation: pulsate 1.6s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  z-index: 99;
}
.map-image .hotspot-container .drawer-btn .recommended-glow:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid transparent; /*2*/
  background: var(--map-glow-border) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.map-image .hotspot-container .drawer-btn span {
  display: inline-block;
  text-align: center;
  color: var(--text-default);
  font-size: 1.4vw;
  line-height: 1.1;
  margin-top: 8px;
}
@media (min-width: 1280px) {
  .map-image .hotspot-container .drawer-btn span {
    font-size: 1.125rem;
  }
}
.map-image .hotspot-container .drawer-btn:focus-visible {
  outline: 0;
}
.map-image .hotspot-container .drawer-btn:focus-visible span {
  text-decoration: underline;
}
.map-image .hotspot-container .drawer-btn:hover img, .map-image .hotspot-container .drawer-btn:focus-visible img {
  transform: translateY(-10%);
  transition: transform 0.4s ease;
  animation: none;
}
.map-image .hotspot-container .drawer-btn:hover .map-hover, .map-image .hotspot-container .drawer-btn:focus-visible .map-hover {
  mix-blend-mode: overlay;
  transform: translate3d(0, -10%, 0);
  opacity: 0.5;
  transition: transform 0.4s ease, opacity 0.3s ease;
}
@media (prefers-color-scheme: dark) {
  .map-image .hotspot-container .drawer-btn:hover .map-hover, .map-image .hotspot-container .drawer-btn:focus-visible .map-hover {
    mix-blend-mode: color-dodge;
  }
}
.map-image .hotspot-container .drawer-btn:hover span, .map-image .hotspot-container .drawer-btn:focus-visible span {
  color: var(--mapTextHover);
}
@media (prefers-reduced-motion: reduce) {
  .map-image .hotspot-container .drawer-btn img {
    animation: none !important;
  }
  .map-image .hotspot-container .drawer-btn .recommended-glow:before {
    opacity: 0.5;
    animation: none !important;
  }
}
.map-image .hotspot-container .drawer-btn.cluster2 {
  left: 4.5%;
  top: 20.5%;
  height: 11%;
  width: 15%;
}
.map-image .hotspot-container .drawer-btn.cluster6 {
  top: 21.25%;
  left: 31.5%;
  width: 12%;
  height: 11%;
}
.map-image .hotspot-container .drawer-btn.cluster13 {
  top: 19.5%;
  left: 54%;
  width: 12%;
  height: 12.7%;
}
.map-image .hotspot-container .drawer-btn.cluster13 .recommended-glow {
  transform-origin: center;
  transform: translateX(-50%) scaleX(1.35) scaleY(0.6) translateY(38%);
}
.map-image .hotspot-container .drawer-btn.cluster14 {
  height: 9%;
  width: 11.5%;
  top: 19%;
  right: 15%;
}
.map-image .hotspot-container .drawer-btn.cluster18 {
  top: 22.3%;
  right: -4%;
  height: 10%;
  width: 16.5%;
}
.map-image .hotspot-container .drawer-btn.cluster18 span {
  display: inline-block;
  width: 85%;
}
.map-image .hotspot-container .drawer-btn.cluster18 .recommended-glow {
  transform-origin: center;
  transform: translateX(-50%) scaleX(1.15) scaleY(0.85) translateY(15%);
}
.map-image .hotspot-container .drawer-btn.cluster4 {
  top: 37.5%;
  left: 0%;
  height: 19.5%;
  width: 17.55%;
  height: 14.7%;
}
.map-image .hotspot-container .drawer-btn.cluster4 .recommended-glow {
  transform-origin: center;
  transform: translateX(-50%) translateY(35%) scaleX(0.65) scaleY(0.35);
}
.map-image .hotspot-container .drawer-btn.cluster7 {
  top: 34%;
  left: 18.7%;
  width: 16.3%;
  height: 11.6%;
}
.map-image .hotspot-container .drawer-btn.cluster7 .recommended-glow {
  transform-origin: center;
  transform: translateX(-50%) translateY(10%) scaleX(1.25) scaleY(0.9);
}
.map-image .hotspot-container .drawer-btn.cluster8 {
  top: 37%;
  left: 44.5%;
  width: 11.65%;
  height: 9.3%;
}
.map-image .hotspot-container .drawer-btn.cluster11 {
  top: 33.5%;
  right: 21%;
  height: 12.4%;
  width: 11%;
}
.map-image .hotspot-container .drawer-btn.cluster11 .recommended-glow {
  transform-origin: center;
  transform: translateX(-50%) scaleX(1.15) scaleY(0.5) translateY(55%);
}
.map-image .hotspot-container .drawer-btn.cluster3 {
  top: 43%;
  right: 1.8%;
  width: 16%;
  height: 9%;
}
.map-image .hotspot-container .drawer-btn.cluster3 span {
  margin-top: 0;
}
.map-image .hotspot-container .drawer-btn.cluster17 {
  top: 60%;
  left: 0.5%;
  width: 12.6%;
  height: 7.6%;
}
.map-image .hotspot-container .drawer-btn.cluster9 {
  top: 53%;
  left: 21%;
  width: 11%;
  height: 9.3%;
}
.map-image .hotspot-container .drawer-btn.cluster15 {
  top: 51%;
  left: 39%;
  width: 17%;
  height: 11.8%;
}
.map-image .hotspot-container .drawer-btn.cluster15 span {
  margin-top: 0.25rem;
}
.map-image .hotspot-container .drawer-btn.cluster15 .recommended-glow {
  transform-origin: center;
  transform: translateX(-50%) scaleX(0.65) scaleY(0.45) translateY(65%);
}
.map-image .hotspot-container .drawer-btn.cluster19 {
  top: 53%;
  left: 62.5%;
  width: 15%;
  height: 8.5%;
}
.map-image .hotspot-container .drawer-btn.cluster1 {
  height: 6.2%;
  width: 13%;
  top: 67.5%;
  left: 27.5%;
}
.map-image .hotspot-container .drawer-btn.cluster16 {
  top: 68%;
  right: 15.3%;
  height: 11%;
  width: 17.5%;
}
.map-image .hotspot-container .drawer-btn.cluster16 span {
  margin-top: 0;
}
@media (prefers-color-scheme: dark) {
  .map-image .image.dark-mode {
    display: block;
  }
  .map-image .image.light-mode {
    display: none;
  }
  .map-image .hotspot-container.dark-mode {
    display: block;
  }
  .map-image .hotspot-container.light-mode {
    display: none;
  }
  .map-image .card-row.dark-mode {
    display: flex;
  }
  .map-image .card-row.light-mode {
    display: none;
  }
}

.cluster-grid .card-row {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
}
.cluster-grid .card-row.dark-mode {
  display: none;
}
@media (prefers-color-scheme: dark) {
  .cluster-grid .card-row.dark-mode {
    display: flex;
  }
  .cluster-grid .card-row.light-mode {
    display: none;
  }
}
.cluster-grid .card {
  flex: 1 1 200px;
  text-decoration: none;
}
.cluster-grid .card h2 {
  text-align: center;
  font-weight: normal;
  text-decoration: none;
  color: var(--text-default);
}
.cluster-grid .card .card-title {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
}
.cluster-grid .card .image {
  height: 200px;
  width: 200px;
  margin: 0 auto;
}
.cluster-grid .card .image img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
  -o-object-position: bottom;
     object-position: bottom;
}
.cluster-grid .card:hover, .cluster-grid .card:focus {
  outline: none;
}
.cluster-grid .card:hover:after, .cluster-grid .card:focus:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  position: absolute;
  border-radius: 24px; /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.cluster-grid .card:hover .bookmark-btn, .cluster-grid .card:focus .bookmark-btn {
  opacity: 1;
}
.cluster-grid .card:hover .card-title, .cluster-grid .card:focus .card-title {
  text-decoration: underline;
}
@keyframes hotspot-easeOutBounce {
  0% {
    transform: translateY(-10%);
  }
  16% {
    transform: translateY(-3.227%);
  }
  28% {
    transform: translateY(1.4%);
  }
  44% {
    transform: translateY(-2.463%);
  }
  59% {
    transform: translateY(1.17%);
  }
  73% {
    transform: translateY(0.058%);
  }
  88% {
    transform: translateY(0.5%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
.tab-container .tab-list-container {
  position: relative;
}
.tab-container .tab-list {
  list-style: none;
  overflow-x: auto;
}
.tab-container .tab-list li {
  display: inline-block;
}
.tab-container .tab-action {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: var(--color-black);
  cursor: pointer;
  margin: 0px 10px;
  padding: 6px 0 16px 0;
  text-decoration: none;
  background: none;
  border: 0;
  border-bottom: 4px solid transparent;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.tab-container .tab-action .hidden-bold {
  font-weight: 700;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
@media speech {
  .tab-container .tab-action .hidden-bold {
    display: none;
  }
}
.tab-container .tab-action[aria-selected=true] {
  font-weight: 700;
  border-bottom: 4px solid transparent;
}
.tab-container .tab-action:hover {
  font-weight: 700;
}
.tab-container .tab-slider {
  position: absolute;
  height: 4px;
  width: auto;
  bottom: 0px;
  background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
  transition: left 0.3s ease, width 0.2s ease;
}
.tab-container.main-nav .tab-action {
  color: var(--color-white);
}
.tab-container.channels {
  background-color: #13203B;
}
.tab-container.channels .tab-list {
  border-bottom: 1px solid #535E74;
}
.tab-container.channels .tab-action {
  color: var(--color--ui-02);
}
.tab-container.channels .tab-slider {
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
.tab-container.pill .tab-slider {
  display: none;
}
.tab-container.pill .tab-list {
  background-color: rgba(83, 94, 116, 0.3019607843);
  border-radius: 30px;
  padding: 4px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.tab-container.pill .tab-item button {
  margin: 0;
  padding: 5px 20px;
  font-size: 16px;
}
.tab-container.pill .tab-item.active button {
  background: #000;
  color: #fff;
  border-radius: 100px;
}

.course .tab-container.pill .tab-list {
  background: #182849;
  margin: 30px auto;
}
.course .tab-container.pill .tab-action {
  color: #fff;
}
.course .tab-container.pill .tab-item.active .tab-action {
  background: #fff;
  color: #000;
}

.footer-container {
  position: relative;
  margin-top: 32px;
  padding-top: 56px;
  z-index: 1;
}
.footer-container .footer-curve {
  top: 0px;
  background: transparent;
  width: 100%;
  height: 160px;
  z-index: -1;
  position: absolute;
}
.footer-container .footer-curve svg {
  width: 100%;
  height: 100%;
  display: block;
}
.page-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  font-size: 16px;
  line-height: 150%;
  padding: 40px calc(0.5 * var(--outside-padding));
  background: #020917;
}
.page-footer ul li a {
  color: var(--color-white);
  text-decoration: none;
  font-weight: normal;
}
.page-footer ul {
  margin: 0;
  padding: 0;
}
.page-footer .css-1904l99 {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 21px;
  font-weight: normal;
  color: var(--color-white);
}
.page-footer .copyright {
  color: var(--color-white);
}

.css-bxmrmw {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  margin-left: 8px;
}

.css-15pzk {
  display: inline-block;
  width: 52px;
  height: 52px;
  background: url("https://www.pearson.com/jobmatch/assets/images/app-icon.svg");
  vertical-align: middle;
}

.css-1c9jac0 {
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  flex-direction: row;
  justify-content: flex-start;
}

.css-o9b79t {
  list-style: none;
}

@media (min-width: 568px) {
  .page-footer {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .page-footer .css-1c9jac0 {
    justify-content: center;
  }
}
@media (min-width: 808px) {
  .page-footer {
    flex-direction: row;
    justify-content: center;
    gap: 50px;
  }
  .page-footer .css-1c9jac0 {
    gap: 50px;
  }
}
.accordion-container {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: calc(0.5 * var(--card-corner-default));
  margin-bottom: 1em;
  transition: box-shadow 0.125s ease-in-out;
  color: var(--color-black);
}
.accordion-container .accordion-body {
  display: none;
  padding: 0 32px 32px 32px;
  text-align: left;
  font-size: 24px;
}
.accordion-container .accordion-btn[aria-expanded=true] {
  font-weight: bold;
}
.accordion-container .accordion-btn[aria-expanded=true] + .accordion-body {
  display: block;
}
.accordion-container .accordion-btn[aria-expanded=true] .icon-24 {
  transform: rotate(180deg);
}
.accordion-container.filter-accordion {
  box-shadow: none;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 0;
  border-radius: 0;
}
.accordion-container.filter-accordion:last-child {
  border-bottom: 0;
}
.accordion-container.filter-accordion .accordion-btn {
  font-size: 20px;
  padding: 8px 24px;
}
.accordion-container.filter-accordion .filter-amount {
  background: rgba(2, 9, 23, 0.1607843137);
  font-size: 0.825em;
  font-weight: 500;
  padding: 4px 8px;
  margin-left: 8px;
  border-radius: 4px;
}

.accordion-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 26px 32px 29.5px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  background: transparent;
  border: none;
  color: var(--color-black);
  text-align: left;
}
.accordion-btn .icon-24 {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  transition: transform 0.3s ease;
  text-align: center;
  flex: none;
}
.accordion-btn .icon-24 svg {
  vertical-align: middle;
}
.accordion-btn:hover .icon-24 {
  background: var(--color-light-200);
}

.update-container .accordion {
  font-size: 22px;
  line-height: 24px;
  padding: 8px 24px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  margin: 10px 0;
}
.update-container .accordion .icon-18 {
  float: right;
  position: relative;
  top: 7px;
}
.update-container .right.label {
  text-align: right;
  color: var(--color-text-muted);
  margin-bottom: 8px;
}

.scroll-carousel {
  position: relative;
}
.scroll-carousel .carousel-nav .slider-nav-button {
  position: absolute;
  z-index: 11;
}
.scroll-carousel .carousel-nav .slider-nav-button:disabled {
  opacity: 0;
}
.scroll-carousel .carousel-nav .slider-nav-button.prev {
  left: -28px;
  top: 50%;
  transform: translateY(calc(-50% - 14px));
}
.scroll-carousel .carousel-nav .slider-nav-button.next {
  right: -28px;
  top: 50%;
  transform: translateY(calc(-50% - 14px));
}
.scroll-carousel .carousel-body {
  width: 100%;
  padding: 0 16px 8px 16px;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  display: flex;
  gap: 8px;
}
.scroll-carousel .carousel-body .carousel-tile {
  scroll-snap-align: center;
  border-radius: 16px;
  padding: 24px;
  width: calc(100% - 0px);
  max-width: 368px;
  flex: none;
  border: 0;
  position: relative;
}
.scroll-carousel .carousel-body .carousel-tile .bookmark-btn {
  position: absolute;
  right: 16px;
  top: 16px;
}
.scroll-carousel .carousel-body .carousel-tile a {
  margin-bottom: 1em;
  text-decoration: none;
}
.scroll-carousel .carousel-body .carousel-tile .company-name {
  display: flex;
  align-items: center;
}
.scroll-carousel .carousel-body .carousel-tile .avatar {
  width: 40px;
  height: 40px;
  border: 1px solid #fefefe;
  background-color: #60646D;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
}
.scroll-carousel .carousel-body .carousel-tile h4 {
  margin: 1em 0;
}
.scroll-carousel .carousel-body .carousel-tile h4 + p {
  margin-top: 0;
  margin-bottom: 0;
}
.scroll-carousel .carousel-body .carousel-tile ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.scroll-carousel .carousel-body .carousel-tile ul p {
  margin: 0;
}
.scroll-carousel .carousel-body .carousel-tile .card-footer {
  border-top: 1px solid var(--color-border);
  padding-top: 16px;
  margin-top: 16px;
}
.scroll-carousel .carousel-body .mini-card {
  min-width: 343px;
  max-width: 343px;
}
.scroll-carousel .carousel-footer {
  margin-top: 16px;
}
.scroll-carousel .carousel-footer .dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.scroll-carousel .carousel-footer .dots .dot {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #60646D;
  border-radius: 50%;
  margin: 1px;
}
.scroll-carousel .carousel-footer .dots .dot.active {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
  margin: 0;
}
@media (max-width: 457px) {
  .scroll-carousel .carousel-body .mini-card {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
}
@media (min-width: 769px) {
  .scroll-carousel .carousel-body {
    margin: 0;
    gap: 24px;
    width: 100%;
    padding: 0;
  }
}

.work-style-insights {
  margin-bottom: 40px;
}
.work-style-insights .insights-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.work-style-insights .insights-header h2 {
  margin-bottom: 0;
}
.work-style-insights .insights-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.work-style-insights p {
  text-align: center;
}
.work-style-insights a, .work-style-insights button {
  margin: 0 auto;
  text-align: center;
}

.insight-block {
  padding: 24px 32px;
  background: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 24px;
}
@media (max-width: 400px) {
  .insight-block {
    flex-direction: column;
  }
}
.insight-block .bar-group, .insight-block .insight-title {
  flex: 1;
}
.insight-block .bar-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.insight-block .bar-container {
  width: 100%;
  position: relative;
}
.insight-block .bar {
  height: 16px;
  border-radius: 4px;
  border: 2px solid transparent;
}
.insight-block.work .bar {
  background-color: #F05B95;
}
.insight-block.work .bar-container.hollow .bar {
  border: 2px dashed #F05B95;
  background-color: rgba(240, 91, 149, 0.2);
}
.insight-block.relate .bar {
  background-color: #F8BB69;
}
.insight-block.relate .bar-container.hollow .bar {
  border: 2px dashed #F8BB69;
  background-color: rgba(248, 187, 105, 0.2);
}
.insight-block.emotions .bar {
  background-color: #0CC0AD;
}
.insight-block.emotions .bar-container.hollow .bar {
  border: 2px dashed #0CC0AD;
  background-color: rgba(12, 192, 173, 0.2);
}
.insight-block.thinking .bar {
  background-color: #31B9E1;
}
.insight-block.thinking .bar-container.hollow .bar {
  border: 2px dashed #31B9E1;
  background-color: rgba(49, 185, 225, 0.2);
}

.insights-container .intro {
  margin-bottom: 24px;
}
.insights-container .page-nav button {
  height: 40px;
  flex: none;
  color: inherit;
}
.insights-container .insights-level1 {
  width: 50%;
  flex: 0 0 50%;
}
.insights-container .insights-level2 {
  width: 50%;
  flex: 0 0 50%;
}
.insights-container.is-mobile {
  display: flex;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  width: 200%;
}
.insights-container.is-mobile.level1-open {
  transform: translateX(0);
}
.insights-container.is-mobile.level1-open .insights-level2 {
  animation: 0.1s hide forwards;
  animation-delay: 0.3s;
}
.insights-container.is-mobile.level2-open {
  transform: translateX(-50%);
}
.insights-container.is-mobile.level2-open .insights-level1 {
  animation: 0.1s hide forwards;
  animation-delay: 0.3s;
}

@keyframes hide {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
  }
}
.insights, .insights-drawer {
  padding: 0 var(--outside-padding);
}
.insights .tab-header, .insights-drawer .tab-header {
  margin-bottom: var(--vertical-space);
}
.insights .tab-header h1, .insights .tab-header p, .insights .tab-header a, .insights-drawer .tab-header h1, .insights-drawer .tab-header p, .insights-drawer .tab-header a {
  color: var(--color-white);
}
.insights .tab-header .icon-btn, .insights-drawer .tab-header .icon-btn {
  min-width: 40px;
  min-height: 40px;
}
.insights .tab-header .page-nav, .insights-drawer .tab-header .page-nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
  color: var(--color-white);
  transform: translateX(-40px);
}
.insights .tab-header .page-nav a, .insights .tab-header .page-nav button, .insights-drawer .tab-header .page-nav a, .insights-drawer .tab-header .page-nav button {
  flex: none;
}
.insights .employee-nav, .insights-drawer .employee-nav {
  display: none;
}
.insights .intro, .insights-drawer .intro {
  padding-top: 60px;
}
.insights .intro .back-btn, .insights-drawer .intro .back-btn {
  margin-bottom: 20px;
}
.insights .tab-list-container .tab-action, .insights-drawer .tab-list-container .tab-action {
  color: var(--color-white);
}
.insights .work-insights.card, .insights-drawer .work-insights.card {
  height: auto;
  padding: 0;
  border-color: var(--color-border);
}
.insights .work-insights.card .section-header, .insights-drawer .work-insights.card .section-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  background-color: var(--color-white);
  border-top-left-radius: var(--card-padding);
  border-top-right-radius: var(--card-padding);
  padding: 32px 40px;
}
.insights .work-insights.card .section-header h2, .insights-drawer .work-insights.card .section-header h2 {
  margin: 0;
}
.insights .work-insights.card .tab-list-container, .insights-drawer .work-insights.card .tab-list-container {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-border);
}
.insights .work-insights.card .tab-panel, .insights-drawer .work-insights.card .tab-panel {
  padding: 32px 40px;
}
.insights .work-insights.card .MuiTabs-root .MuiButtonBase-root:first-of-type, .insights-drawer .work-insights.card .MuiTabs-root .MuiButtonBase-root:first-of-type {
  padding-left: 0;
}
.insights .work-insights.mobile-group, .insights-drawer .work-insights.mobile-group {
  margin-top: 40px;
  margin-left: calc(-1 * var(--outside-padding));
  margin-right: calc(-1 * var(--outside-padding));
}
.insights .insights-group, .insights-drawer .insights-group {
  margin-top: 64px;
  margin-bottom: 32px;
}
.insights .insights-group:first-of-type, .insights-drawer .insights-group:first-of-type {
  margin-top: 32px;
}
.insights .insights-group.work, .insights-drawer .insights-group.work {
  --bar-color: #F05B95;
}
.insights .insights-group.relate, .insights-drawer .insights-group.relate {
  --bar-color: #F8BB69;
}
.insights .insights-group.emotions, .insights-drawer .insights-group.emotions {
  --bar-color:#0CC0AD;
}
.insights .insights-group.thinking, .insights-drawer .insights-group.thinking {
  --bar-color: #31B9E1;
}
.insights .insights-group .insights-title, .insights-drawer .insights-group .insights-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background: transparent;
  border: none;
  color: var(--text-default);
  text-align: left;
  padding: 0;
  font-weight: 700;
  word-break: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.insights .insights-group .insights-title .icon, .insights-drawer .insights-group .insights-title .icon {
  display: inline-block;
  margin-right: 8px;
}
.insights .insights-group .insights-title .icon svg, .insights-drawer .insights-group .insights-title .icon svg {
  transition: transform 0.3s ease;
}
.insights .insights-group .insights-title[aria-expanded=true] .icon svg, .insights-drawer .insights-group .insights-title[aria-expanded=true] .icon svg {
  transform: rotate(180deg);
}
.insights .insights-group .insights-title[aria-expanded=true] + .insights-body .insights-bar-container .labels strong, .insights-drawer .insights-group .insights-title[aria-expanded=true] + .insights-body .insights-bar-container .labels strong {
  transition: font-weight 70ms ease;
  transition-delay: 100ms;
}
.insights .insights-group .insights-title[aria-expanded=true] + .insights-body .bar-description, .insights-drawer .insights-group .insights-title[aria-expanded=true] + .insights-body .bar-description {
  display: block;
  visibility: visible;
  max-height: 500px;
  transition: visibility 500ms ease-in, max-height 500ms ease-in;
}
.insights .insights-group .insights-title[aria-expanded=true] + .insights-body .insights-more-details, .insights-drawer .insights-group .insights-title[aria-expanded=true] + .insights-body .insights-more-details {
  display: block;
  visibility: visible;
  max-height: 2000px;
  transition: visibility 600ms ease-in, max-height 600ms ease-in;
}
.insights .insights-group .insights-definition, .insights-drawer .insights-group .insights-definition {
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}
.insights .insights-group .insights-bar-container, .insights-drawer .insights-group .insights-bar-container {
  padding-top: 65px;
  font-size: 1em;
  line-height: 1.5;
}
.insights .insights-group .insights-bar-container .bar-container, .insights-drawer .insights-group .insights-bar-container .bar-container {
  position: relative;
  height: 32px;
  width: 100%;
  border: 2px solid var(--bar-color);
  border-radius: 4px;
}
.insights .insights-group .insights-bar-container .bar-container .bar, .insights-drawer .insights-group .insights-bar-container .bar-container .bar {
  height: 32px;
  background-color: var(--bar-color);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  left: 0;
  top: -2px;
}
.insights .insights-group .insights-bar-container .bar-container .bar span, .insights-drawer .insights-group .insights-bar-container .bar-container .bar span {
  position: absolute;
  right: 0;
  bottom: 54px;
  font-size: 1.125rem;
  text-align: center;
  transform: translateX(50%);
  line-height: 1.1;
  white-space: nowrap;
}
.insights .insights-group .insights-bar-container .bar-container .bar span strong, .insights-drawer .insights-group .insights-bar-container .bar-container .bar span strong {
  display: block;
}
.insights .insights-group .insights-bar-container .bar-container .bar span.align-right, .insights-drawer .insights-group .insights-bar-container .bar-container .bar span.align-right {
  right: 10px;
}
.insights .insights-group .insights-bar-container .bar-container .bar span.align-left, .insights-drawer .insights-group .insights-bar-container .bar-container .bar span.align-left {
  transform: translateX(0px);
  right: unset;
  left: 0;
}
.insights .insights-group .insights-bar-container .bar-container .bar:after, .insights-drawer .insights-group .insights-bar-container .bar-container .bar:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--bar-color);
  z-index: 1;
  position: absolute;
  top: -20px;
  right: 0;
  transform: translateX(50%);
}
@media (max-width: 807px) {
  .insights .insights-group .insights-bar-container .bar-container .bar span, .insights-drawer .insights-group .insights-bar-container .bar-container .bar span {
    bottom: 46px;
  }
  .insights .insights-group .insights-bar-container .bar-container .bar:after, .insights-drawer .insights-group .insights-bar-container .bar-container .bar:after {
    top: -10px;
  }
}
.insights .insights-group .insights-bar-container .bar-container .fifty, .insights-drawer .insights-group .insights-bar-container .bar-container .fifty {
  position: absolute;
  left: 50%;
  font-weight: 700;
}
.insights .insights-group .insights-bar-container .bar-container .fifty > span, .insights-drawer .insights-group .insights-bar-container .bar-container .fifty > span {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  transform: translateX(-50%);
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1;
}
.insights .insights-group .insights-bar-container .bar-container .fifty .icon, .insights-drawer .insights-group .insights-bar-container .bar-container .fifty .icon {
  display: inline-block;
  color: var(--text-light-theme-link);
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.insights .insights-group .insights-bar-container .bar-container .fifty:before, .insights-drawer .insights-group .insights-bar-container .bar-container .fifty:before {
  content: "";
  display: block;
  height: 28px;
  width: 3px;
  background-color: var(--color-black);
  z-index: 1;
  transform: translateX(-1px);
}
.insights .insights-group .insights-bar-container .labels, .insights-drawer .insights-group .insights-bar-container .labels {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10%;
  padding-top: 0.5rem;
  font-size: 1.125rem;
  line-height: 1;
}
.insights .insights-group .insights-bar-container .labels strong, .insights-drawer .insights-group .insights-bar-container .labels strong {
  display: block;
  font-weight: normal;
  margin-bottom: 4px;
}
.insights .insights-group .insights-bar-container .labels .maximum-label, .insights-drawer .insights-group .insights-bar-container .labels .maximum-label {
  text-align: right;
}
@media (min-width: 1025px) {
  .insights .insights-group .insights-bar-container .labels, .insights-drawer .insights-group .insights-bar-container .labels {
    padding-top: 0.5rem;
    gap: 24%;
  }
  .insights .insights-group .insights-bar-container .labels .minimum-label, .insights .insights-group .insights-bar-container .labels .maximum-label, .insights-drawer .insights-group .insights-bar-container .labels .minimum-label, .insights-drawer .insights-group .insights-bar-container .labels .maximum-label {
    flex: 0 1 38%;
  }
}
.insights .insights-group .insights-bar-container.first-bar .labels, .insights-drawer .insights-group .insights-bar-container.first-bar .labels {
  padding-top: 2.5em;
}
@media (min-width: 1025px) {
  .insights .insights-group .insights-bar-container.first-bar .labels, .insights-drawer .insights-group .insights-bar-container.first-bar .labels {
    padding-top: 0.5rem;
  }
}
.insights .insights-group .insights-body, .insights-drawer .insights-group .insights-body {
  padding-left: 32px;
}
.insights .insights-group .insights-body .insights-bar-container .labels strong, .insights-drawer .insights-group .insights-body .insights-bar-container .labels strong {
  transition: font-weight 70ms ease;
  transition-delay: 300ms;
}
.insights .insights-group .insights-body .bar-description, .insights-drawer .insights-group .insights-body .bar-description {
  display: block;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: visibility 500ms ease-in, max-height 500ms cubic-bezier(0, 0.6, 0.6, 1);
}
.insights .insights-group .insights-body .insights-more-details, .insights-drawer .insights-group .insights-body .insights-more-details {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: visibility 400ms ease-in, max-height 400ms cubic-bezier(0, 0.6, 0.6, 1);
}
.insights .insights-group .insights-body .insights-more-details p:first-of-type, .insights-drawer .insights-group .insights-body .insights-more-details p:first-of-type {
  margin-top: 0;
}
.insights .insights-group .insights-body .insights-more-details p:last-of-type, .insights-drawer .insights-group .insights-body .insights-more-details p:last-of-type {
  margin-bottom: 0;
}
.insights .insights-group .insights-body h4, .insights-drawer .insights-group .insights-body h4 {
  margin-bottom: 1rem;
  margin-top: 0;
}
.insights .insights-group .insights-body .insights-meaning, .insights-drawer .insights-group .insights-body .insights-meaning {
  padding-top: 80px;
  margin-bottom: 60px;
}
.insights .insights-group.expanded .minimum-label strong, .insights .insights-group.expanded .maximum-label strong, .insights-drawer .insights-group.expanded .minimum-label strong, .insights-drawer .insights-group.expanded .maximum-label strong {
  font-weight: 700;
}
.insights .insights-group.nodata .insights-title, .insights-drawer .insights-group.nodata .insights-title {
  padding-left: 32px;
}
.insights .insights-group.nodata .insights-bar-container, .insights-drawer .insights-group.nodata .insights-bar-container {
  padding-top: 0;
}
.insights .insights-group.nodata .insights-bar-container .bar:after, .insights-drawer .insights-group.nodata .insights-bar-container .bar:after {
  content: none;
}
@media (max-width: 1024px) {
  .insights .intro, .insights-drawer .intro {
    background: transparent;
  }
  .insights .insights-bar-container, .insights-drawer .insights-bar-container {
    font-size: 90%;
  }
}

.part-two-message {
  text-align: center;
  padding: 80px 0 0px 0;
  border-top: 1px solid var(--color-border);
  margin-top: 80px;
}
.part-two-message button {
  margin: 24px auto;
}
@media (max-width: 807px) {
  .part-two-message {
    padding: var(--outside-padding);
    margin-top: 24px;
    border-top: none;
  }
}

.insights-accordion {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}
.insights-accordion .tab-action {
  width: 100%;
  text-align: left;
  background-color: var(--color-white);
  color: var(--text-default);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 24px;
  text-decoration: none;
  border: 0;
}
.insights-accordion .tab-action .icon {
  display: block;
  background-color: var(--color--accent-01-medium);
  border-radius: 50%;
  padding: 3px;
  width: 30px;
  height: 30px;
}
.insights-accordion .tab-action .icon .vert {
  transition: opacity 0.3s ease;
}
.insights-accordion .tab-action .icon .horiz {
  transition: transform 0.3s ease;
  transform-origin: center;
}
.insights-accordion .tab-action[aria-expanded=true] {
  font-weight: 700;
}
.insights-accordion .tab-action[aria-expanded=true] .icon .vert {
  opacity: 0;
}
.insights-accordion .tab-action[aria-expanded=true] .icon .horiz {
  transform: rotate(90deg);
}

.work-style-quiz {
  position: relative;
  background-color: var(--color-light-bg);
  min-height: 100vh;
}
.work-style-quiz:before {
  content: "";
  background: transparent;
  background-image: url("../images/background-swoop-small.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  min-height: 170px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: none;
  z-index: 0;
}
.work-style-quiz header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  background: transparent;
  padding: 12px 21px;
}
.work-style-quiz header a {
  color: var(--color-white);
}
.work-style-quiz main {
  position: relative;
  padding: 100px 20px 40px 20px;
  max-width: 900px;
  margin: auto;
  z-index: 1;
}
.work-style-quiz main section h1 {
  margin-bottom: 60px;
  text-align: center;
}
.work-style-quiz main p {
  margin-bottom: 1em;
}
.work-style-quiz .assessment-header {
  text-align: center;
  padding: 20px 0;
  min-height: 60px;
}
.work-style-quiz .question-counter {
  font-size: 24px;
}
.work-style-quiz fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.work-style-quiz fieldset legend {
  text-align: center;
}
.work-style-quiz .radio-options {
  max-width: 300px;
  margin: 32px auto;
}
.work-style-quiz .radio-options .radio-option {
  position: relative;
  background-color: #E8EEFA;
  padding: 12px 40px;
  margin-top: 16px;
  border-radius: 8px;
}
.work-style-quiz .radio-options .radio-option input[type=radio] {
  opacity: 0;
  position: absolute;
}
.work-style-quiz .radio-options .radio-option input[type=radio] ~ span {
  border: 2px solid #707D89;
  background: var(--color-white);
  border-radius: 50%;
  box-sizing: content-box;
  color: var(--color-teal);
  display: block;
  height: 18px;
  width: 18px;
  pointer-events: none;
  position: absolute;
  top: 12px;
  left: 40px;
}
.work-style-quiz .radio-options .radio-option input[type=radio] ~ span svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: currentColor;
  opacity: 0;
}
.work-style-quiz .radio-options .radio-option input[type=radio]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}
.work-style-quiz .radio-options .radio-option input[type=radio]:checked ~ span {
  border-color: var(--color-teal);
}
.work-style-quiz .radio-options .radio-option input[type=radio]:checked ~ span svg {
  opacity: 1;
}
.work-style-quiz .radio-options label {
  font-size: 24px;
  line-height: 28px;
  padding-left: 40px;
  cursor: pointer;
}
.work-style-quiz .button-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 80px;
}
.work-style-quiz .button-footer button {
  white-space: nowrap;
}

/* starting ENTER animation */
.collapse-enter {
  opacity: 0;
  max-height: 0;
}
.collapse-enter * {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  transition-delay: 200ms;
}
.collapse-enter.with-delay {
  transition-delay: 800ms;
}

/* ending ENTER animation */
.collapse-enter-active {
  opacity: 1;
  max-height: 1000px;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}
.collapse-enter-active * {
  opacity: 1;
}

/* starting EXIT animation */
.collapse-exit {
  opacity: 1;
  max-height: 1000px;
}

/* ending EXIT animation */
.ctaCard-exit-active {
  opacity: 0;
  max-height: 0px;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}

.insights-drawer {
  padding: 0;
}
.insights-drawer .drawer-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 48px 24px 40px 24px;
  margin-bottom: 0 !important;
  position: sticky;
  top: 64px;
  z-index: 113;
  background: var(--bg-level2);
}
.insights-drawer .drawer-header .page-title {
  margin: 0;
}
.insights-drawer .insights-group {
  background-color: var(--bg-level1);
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  padding: 0;
  margin: 0;
  transition: background-color 0.3s ease-in-out;
  transition-delay: 0.1s;
}
.insights-drawer .insights-group:first-of-type {
  margin: 0;
}
.insights-drawer .insights-group .insights-title {
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px;
}
.insights-drawer .insights-group .insights-title .mobile-h4 {
  margin: 0;
}
.insights-drawer .insights-group .insights-title[aria-expanded=true] {
  position: sticky;
  top: 192px;
  background-color: var(--bg-level0);
  z-index: 15;
}
.insights-drawer .insights-group .insights-body {
  padding: 0px 24px 32px 24px;
}
.insights-drawer .insights-group .insights-body .insights-definition {
  padding: 0px;
  visibility: hidden;
  margin: 0 0 16px 0;
  max-height: 0px;
  overflow: hidden;
  transition: visibility 1450ms ease-in, max-height 1400ms cubic-bezier(0, 0.6, 0.6, 1), margin 1200ms cubic-bezier(0, 0.6, 0.6, 1);
}
.insights-drawer .insights-group .insights-body h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.insights-drawer .insights-group .insights-body .insights-meaning {
  padding-top: 0;
  margin-bottom: 24px;
}
.insights-drawer .insights-group .insights-bar-container {
  background: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 72px 16px 8px 16px;
  position: relative;
  transition: margin 500ms ease;
}
.insights-drawer .insights-group .insights-bar-container .bar-container .fifty > span {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;
}
.insights-drawer .insights-group .insights-bar-container .bar-container .bar span {
  font-size: 1rem;
  line-height: 1;
}
.insights-drawer .insights-group .insights-bar-container .bar-container .bar span:after {
  margin: 4px auto;
}
.insights-drawer .insights-group .insights-bar-container .labels {
  font-size: 1rem;
  line-height: 1.5;
  padding-top: 4px;
}
.insights-drawer .insights-group .insights-bar-container .labels > div {
  flex: 1;
}
.insights-drawer .insights-group .insights-bar-container .labels > div.fifty {
  flex: none;
  text-align: center;
}
.insights-drawer .insights-group .info-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #0CC0AD;
  width: 24px;
  height: 24px;
  z-index: 11;
  border-radius: 50%;
}
.insights-drawer .insights-group .info-btn svg {
  fill: currentColor;
}
.insights-drawer .insights-group .info-btn:hover {
  background: var(--bg-level2);
}
.insights-drawer .insights-group:not(.expanded) .insights-definition {
  display: block;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: visibility 450ms ease-in, max-height 400ms cubic-bezier(0, 0.6, 0.6, 1), margin 300ms cubic-bezier(0, 0.6, 0.6, 1);
}
.insights-drawer .insights-group.expanded {
  background-color: var(--bg-level0);
}
.insights-drawer .insights-group.expanded .insights-definition {
  visibility: visible;
  display: block;
  max-height: 300px;
}
.insights-drawer .insights-group.expanded .insights-bar-container {
  margin: 24px 0;
}
.insights-drawer .insights-group.expanded .insights-body {
  padding-top: 0;
  padding-bottom: 24px;
}
.insights-drawer .insights-group.expanded .insights-body h4 {
  margin-bottom: 0;
}
.insights-drawer .insights-group.expanded .insights-body .insights-meaning {
  padding-top: 0;
  margin-bottom: 24px;
}
.insights-drawer .insights-group.nodata .insights-title {
  padding-left: 24px;
}
.insights-drawer .insights-group.nodata .insights-definition {
  display: block;
  visibility: visible;
  max-height: 100px;
}
.insights-drawer .insights-group.nodata .insights-bar-container {
  padding: 16px;
}

.insights-modal .bar-container {
  position: relative;
  height: 32px;
  width: 100%;
  border: 2px solid #020917;
  border-radius: 4px;
  margin-bottom: 4px;
}
.insights-modal .bar-container .bar {
  height: 32px;
  background-color: rgba(2, 9, 23, 0.4);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  left: 0;
  top: -2px;
}
.insights-modal .bar-container .bar span {
  position: absolute;
  right: 0;
  bottom: 34px;
  font-size: 1.125rem;
  text-align: center;
  transform: translateX(50%);
  line-height: 1.1;
}
.insights-modal .bar-container .bar span strong {
  display: block;
}
.insights-modal .bar-container .bar span:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #020917;
  z-index: 1;
  margin: 0px auto;
}
.insights-modal .bar-container .fifty {
  position: absolute;
  left: 50%;
  font-weight: 700;
}
.insights-modal .bar-container .fifty > span {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 9px;
  transform: translateX(-50%);
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1;
}
.insights-modal .bar-container .fifty .icon {
  display: inline-block;
  color: var(--text-light-theme-link);
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.insights-modal .bar-container .fifty:before {
  content: "";
  display: block;
  height: 28px;
  width: 3px;
  background-color: var(--color-black);
  z-index: 1;
  transform: translateX(-1px);
}

.page-wrapper.horizontal-nav .insights-main.mobile {
  overflow-x: clip;
  padding-top: 0;
}
.page-wrapper.horizontal-nav .insights-main.mobile .page-nav {
  padding-top: 48px;
  margin-bottom: 0 !important;
}

.skill-details {
  text-align: left;
  color: var(--text-default);
}
.skill-details > .tab-container {
  margin: 0px auto;
  padding: 16px;
}
.skill-details > .tab-container section {
  padding: 0;
}
.skill-details > .tab-container .tab-list {
  white-space: nowrap;
}
.skill-details .tab-header {
  margin-bottom: 16px;
}
.skill-details .tab-header .page-nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 8px;
}
.skill-details .tab-header .page-nav a {
  flex: none;
  color: inherit;
  height: 28px;
}
.skill-details .chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
}
.skill-details .chip-list.condensed {
  max-height: 35px;
  overflow: hidden;
}
.skill-details .chip-list + .link-btn {
  white-space: nowrap;
  float: right;
}
.skill-details .chip-btn {
  display: inline-block;
  border: 1px solid #dde3ee;
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-default);
  margin: 0;
  transition: background-color 0.15s ease-in-out;
}
.skill-details .chip-btn:hover, .skill-details .chip-btn:focus-visible {
  background: #CAA0FF;
}
.skill-details .chip-btn:focus-visible {
  outline: 0;
  border-style: dashed;
}
.skill-details .chip-btn svg {
  vertical-align: text-bottom;
  margin-right: 4px;
}
.skill-details .chip-btn[aria-pressed=true] {
  padding-left: 14px;
  background: #CAA0FF;
}
.skill-details .chip-btn.removeable {
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
}
.skill-details .chip-btn.removeable svg {
  margin: 0;
}
.skill-details .chip-btn.removeable .button:hover {
  background: rgba(255, 255, 255, 0.8);
}
@media (prefers-color-scheme: dark) {
  .skill-details .chip-btn:hover, .skill-details .chip-btn:focus-visible, .skill-details .chip-btn[aria-pressed=true] {
    background-color: #4C406B;
  }
}
@media (min-width: 769px) {
  .skill-details {
    background: transparent;
    color: var(--text-default);
  }
  .skill-details > .tab-container {
    padding: 0 var(--outside-padding);
  }
  .skill-details > .tab-container section {
    padding: 0;
  }
  .skill-details .tab-header {
    margin-bottom: 32px;
  }
}

.tabs-card {
  background: var(--bg-level1);
  border: 1px solid var(--color-border);
  border-radius: var(--card-corner-default);
  padding: 0;
  height: auto;
}
.tabs-card .tab-list-container {
  background: var(--bg-level0);
  border-top-left-radius: var(--card-corner-default);
  border-top-right-radius: var(--card-corner-default);
  padding: 16px 0 0 0;
  border-bottom: 1px solid var(--color-border);
  overflow-x: auto;
}
.tabs-card .tab-list-container .tab-list {
  margin-bottom: 0;
  padding-bottom: 0;
}
.tabs-card .tab-list-container .tab-action {
  margin: 0 16px;
  font-size: 18px;
}
@media (max-width: 807px) {
  .tabs-card .tab-list-container .tab-action.active:after {
    content: "";
    height: 4px;
    width: 100%;
    background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.tabs-card .tab-list-container .tab-slider {
  bottom: 0;
}
@media (max-width: 807px) {
  .tabs-card .tab-list-container .tab-slider {
    display: none;
  }
}
.tabs-card .tab-content {
  padding: var(--card-padding);
}
.tabs-card .tab-content .card-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 8px;
}
.tabs-card .tab-content .card {
  background: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: var(--card-corner-default);
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.45);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1132;
}
.modal-overlay.hide {
  display: none;
}

.modal {
  background-color: var(--bg-level1);
  border-radius: 16px;
  margin: 20px auto;
  padding: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}
.modal.light-bg {
  background-color: var(--bg-level0, #fefefe);
}
.modal .close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  color: var(--text-default);
}
.modal h1, .modal h2, .modal h3 {
  text-align: center;
  margin: 0 0 12px 0;
}
.modal p, .modal li {
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.modal .modal-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.modal .modal-body {
  overflow-y: auto;
}
.modal .course-description {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 32px;
}
.modal .course-description .avatar {
  flex: none;
  width: 60px;
  height: 60px;
}
.modal .course-description .avatar img {
  width: 100%;
}
.modal .forage-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modal .forage-info .avatar {
  flex: none;
  width: 40px;
}
.modal .forage-info .avatar img {
  width: 100%;
}
.modal .forage-benefits ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.modal .forage-benefits ul li {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 4px;
}
.modal .forage-benefits ul li .icon {
  flex: none;
  width: 24px;
}
.modal .forage-benefits ul li .icon img {
  width: 100%;
}
.modal .desc-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}
.modal .form-group + .form-group {
  margin-top: var(--vertical-space);
}
.modal.pathway-modal {
  max-width: calc(100vw - 128px);
  margin: 0;
  max-height: calc(100vh - 128px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.modal.pathway-modal h1, .modal.pathway-modal h2, .modal.pathway-modal h3 {
  text-align: left;
}
.modal.pathway-modal .modal-header {
  border-bottom: 2px solid var(--color-border);
  margin-bottom: 0;
  padding: 32px;
}
.modal.pathway-modal .modal-header h1 {
  margin-bottom: 32px;
}
.modal.pathway-modal .modal-header .autocomplete-container {
  margin-bottom: 32px;
}
.modal.pathway-modal .modal-body {
  overflow-y: auto;
  padding: 32px;
  background: var(--bg-level0);
}

.modal-header {
  margin-bottom: 48px;
}
.modal-header.border-bottom {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 16px;
}
.modal-header.border-bottom h1 {
  margin-bottom: 0;
}
.modal-header h1.text-left {
  text-align: left;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.insights-modal h1, .insights-modal h2 {
  text-align: left;
}
.insights-modal h2 {
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
}
.insights-modal.bar-modal h2 {
  margin-top: 0;
}
.insights-modal.bar-modal .group {
  margin-top: 1.5rem;
  padding-top: 10px;
}
@media (min-width: 768px) {
  .modal {
    max-width: 600px;
  }
  .modal.forage-modal {
    max-width: 774px;
  }
  .modal.pathway-modal {
    max-width: calc(100vw - 128px);
    margin: 0;
    max-height: calc(100vh - 128px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .modal.pathway-modal h1, .modal.pathway-modal h2, .modal.pathway-modal h3 {
    text-align: left;
  }
  .modal.pathway-modal .modal-header {
    border-bottom: 2px solid var(--color-border);
    margin-bottom: 0;
    padding: 32px;
  }
  .modal.pathway-modal .modal-header h1 {
    margin-bottom: 32px;
  }
  .modal.pathway-modal .modal-header .autocomplete-container {
    margin-bottom: 32px;
  }
  .modal.pathway-modal .modal-body {
    overflow-y: auto;
    padding: 32px;
    background: var(--bg-level0);
  }
  .modal .forage-info {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  .modal .forage-info > div {
    flex: 1;
  }
}
@media (max-width: 768px) {
  .modal {
    max-width: 440px;
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .modal {
    width: calc(100% - 40px);
  }
}
.toast {
  position: fixed;
  left: 50%;
  bottom: -400px;
  transform: translateX(calc(-50% - 24px));
  background: var(--background-dark-theme-bg-level-0, #020917);
  padding: 16px;
  border-radius: 16px;
  max-width: 480px;
  min-height: 56px;
  width: 100%;
  margin: 24px;
  color: var(--color-white);
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
  z-index: 11111;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 540px) {
  .toast {
    width: calc(100% - 32px);
  }
}
.toast > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding-right: 40px;
}
.toast > div > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px;
  justify-content: space-between;
}
.toast .icon {
  color: var(--color-success-500);
  height: 24px;
}
.toast .icon.info {
  color: var(--color-white);
}
.toast .text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.toast button.close {
  color: var(--color-white);
  position: absolute;
  right: 4px;
  top: 4px;
}
.toast .link-btn {
  color: var(--text-dark-theme-link, #9B81E2);
  line-height: 24px;
}
.toast.fadeIn {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  animation: fadein 0.5s;
  animation-fill-mode: forwards;
}
.toast.fadeOut {
  visibility: visible; /* Show the snackbar */
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

/* Animations to fade the snackbar in and out */
@keyframes fadein {
  from {
    bottom: -400px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 0px;
    opacity: 1;
  }
  to {
    bottom: -400px;
    opacity: 0;
  }
}
.badge-details .page-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.badge-details .page-nav h1 {
  margin-bottom: 0;
}
.badge-details .page-nav .icon-btn {
  color: var(--color-black);
}

.badge-intro h2 {
  margin: 0;
}
.badge-intro p {
  margin: 0;
}

.badge-information {
  display: flex;
  align-items: center;
  gap: 40px;
}
.badge-information .badge.avatar {
  background-color: transparent !important;
  width: 186px;
  height: auto;
  border-radius: 0;
  flex: none;
}
.badge-information .badge.avatar img {
  width: 100%;
  max-width: 100%;
}
.badge-information .flex-row {
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 16px;
}
.badge-information h2 {
  margin: 0 0 4px 0;
}
.badge-information .badge-issuer + button {
  margin-top: 32px;
}
.badge-information .badge-description {
  font-weight: 300;
}
@media (max-width: 567px) {
  .badge-information {
    flex-direction: column;
    align-items: center;
    gap: 0;
    text-align: center;
  }
  .badge-information .badge-details h2, .badge-information .badge-details p {
    text-align: center;
  }
  .badge-information .button {
    margin: auto;
  }
}
.badge-skills, .badge-earn {
  margin-top: 48px;
}
.badge-skills h3, .badge-earn h3 {
  margin: 0 0 8px 0;
  font-weight: 700;
}
.badge-skills .chip-list, .badge-earn .chip-list {
  padding-top: 4px;
}
.badge-skills .badge-description, .badge-earn .badge-description {
  font-weight: 300;
}

.action-card.dark .badge-information {
  padding: 24px 0 0 0;
}
.action-card.dark .badge-information .badge-details {
  padding: 24px 0;
}

.badge-benefits .salary-caption {
  font-weight: 600;
  margin-bottom: 0;
}
.benefit-highlight {
  text-align: center;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.benefit-highlight span {
  display: block;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.24px;
}
.benefit-highlight .callout {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 100% */
  margin-bottom: 10px;
}
.benefit-highlight .callout.teal {
  background: var(--gradient-02, linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.benefit-highlight .callout.purple {
  background: var(--gradient-blue, linear-gradient(137deg, #5344FF -1.88%, #4F2CB0 66.28%, #230180 102.82%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.goal-picker-container {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.4px;
  gap: 8px;
  margin: 36px 0 12px 0;
  text-align: left;
}
.goal-picker-container + .goal-picker-container .goal-dropdown {
  z-index: 1;
}
.goal-picker-container + .goal-picker-container .goal-dropdown.open {
  z-index: 3;
}

.goal-dropdown {
  display: inline-block;
  position: relative;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin: 8px;
  min-width: 200px;
  flex: 1;
  max-width: -moz-max-content;
  max-width: max-content;
  z-index: 2;
}
.goal-dropdown.autocomplete {
  min-width: 100%;
}
@media (min-width: 568px) {
  .goal-dropdown.autocomplete {
    min-width: 440px;
  }
}
.goal-dropdown.open {
  z-index: 3;
  max-width: 550px;
}
.goal-dropdown.open > .button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  width: 100%;
}
.goal-dropdown.open > .button svg {
  transform: rotate(180deg);
}
.goal-dropdown.open > .button input {
  color: var(--text-muted);
}
.goal-dropdown > .button {
  border-radius: 16px;
  border: 1px solid var(--border-light-theme-border, #DDE3EE);
  background: var(--bg-level0);
  color: var(--text-light-theme-link, #655591);
  padding: 8px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  min-height: 44px;
  text-align: left;
}
.goal-dropdown > .button .none {
  color: var(--text-muted);
}
.goal-dropdown > .button input {
  border: none;
  flex: 1;
  background: transparent;
  height: 100%;
  padding: 8px 28px 8px 18px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 100%;
  text-overflow: ellipsis;
  color: var(--text-light-theme-link, #655591);
}
.goal-dropdown > .button input :first-letter {
  text-transform: uppercase;
}
.goal-dropdown > .button .clear-icon {
  position: absolute;
  right: 48px;
}
.goal-dropdown > .button svg {
  flex: none;
  transition: transform 0.15s ease;
}
.goal-dropdown > .button[aria-expanded=true] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  width: 100%;
}
.goal-dropdown > .button[aria-expanded=true] svg {
  transform: rotate(180deg);
}
.goal-dropdown .menu {
  position: absolute;
  z-index: 1111;
  left: 0;
  top: 100%;
  background: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-top: 0;
  border-radius: 0 0 16px 16px;
  padding: 8px 0;
  box-shadow: none;
  min-width: 200px;
  width: 100%;
  color: var(--color-black);
  max-height: 300px;
  overflow-y: auto;
}
.goal-dropdown .menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.goal-dropdown .menu .none {
  text-align: left;
  width: 100%;
  padding: 4px 24px;
  color: var(--text-light-theme-link, #655591);
}
.goal-dropdown .menu button {
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  padding: 8px 24px;
  color: var(--text-light-theme-link, #655591);
  font-weight: 500;
}
.goal-dropdown .menu button:hover, .goal-dropdown .menu button:focus {
  background-color: var(--bg-level2);
  font-weight: 700;
}
.goal-dropdown .menu button[aria-selected=true] {
  color: var(--text-light-theme-link, #655591);
}

.share-options {
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 20px;
}

.share-option {
  background: transparent;
  border: none;
  text-align: center;
  min-width: 96px;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
.share-option span {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.24px;
  text-decoration: none;
  color: var(--color-black);
}
.share-option .share-avatar {
  background-color: var(--background-light-theme-bg-level-1, #F7F9FD);
  border: 1px solid var(--border-light-theme-border, #DDE3EE);
  border-radius: 40px;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  padding: 15px;
}
.share-option .share-avatar:hover {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
}
.share-option .share-avatar.linkedin {
  border: 0;
  background-color: #2867B2;
  background-image: url("../icons/share/linkedin2.jpg");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center center;
}
.share-option .share-avatar.twitter {
  border: 0;
  background-color: #000;
  background-image: url("../icons/share/twitter.png");
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center center;
}
.share-option .share-avatar.facebook {
  border: 0;
  background-image: url("../icons/share/facebook.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.share-option .share-avatar.ziprecruiter {
  border: 0;
  background-image: url("../icons/share/ziprecruiter.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.share-option .share-type {
  margin-top: 10px;
}

.modal.share-badge {
  max-width: 700px;
}
.modal.share-badge .modal-body h2, .modal.share-badge .modal-body h3, .modal.share-badge .modal-body h4, .modal.share-badge .modal-body p {
  text-align: left;
  margin: 0;
}
.modal.share-badge .modal-body .badge-issuer {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}
.modal.share-badge .modal-body .badge-issuer a {
  font-weight: 400;
}
.modal.share-badge .modal-body h3 {
  margin-top: 40px;
}
.modal.share-badge .badge-information {
  padding-bottom: 40px;
  border-bottom: 1px solid #DDE3EE;
}

.loader {
  position: relative;
}
.loader > .card {
  opacity: 0.5;
  background-color: var(--bg-level1);
  border: 0;
}

.personalizing-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.personalizing-spinner .ellipsis {
  display: inline-block;
  position: relative;
}
.personalizing-spinner .ellipsis:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  animation: dots 1.5s infinite steps(1, end);
  -webkit-animation: dots 1.5s infinite steps(1, end);
}
.personalizing-spinner .spinner {
  position: relative;
  width: 160px;
  height: 160px;
}
.personalizing-spinner .spinner span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.personalizing-spinner .spinner span:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 8px;
  height: 8px;
  background: var(--color-black);
  border-radius: 50%;
  animation: sk-circleBounceDelay 2.8s infinite ease-in-out both;
}
.personalizing-spinner .spinner span:nth-of-type(1) {
  transform: rotate(25.7142857143deg);
}
.personalizing-spinner .spinner span:nth-of-type(1):before {
  animation-delay: 0.2s;
}
.personalizing-spinner .spinner span:nth-of-type(2) {
  transform: rotate(51.4285714286deg);
}
.personalizing-spinner .spinner span:nth-of-type(2):before {
  animation-delay: 0.4s;
}
.personalizing-spinner .spinner span:nth-of-type(3) {
  transform: rotate(77.1428571429deg);
}
.personalizing-spinner .spinner span:nth-of-type(3):before {
  animation-delay: 0.6s;
}
.personalizing-spinner .spinner span:nth-of-type(4) {
  transform: rotate(102.8571428571deg);
}
.personalizing-spinner .spinner span:nth-of-type(4):before {
  animation-delay: 0.8s;
}
.personalizing-spinner .spinner span:nth-of-type(5) {
  transform: rotate(128.5714285714deg);
}
.personalizing-spinner .spinner span:nth-of-type(5):before {
  animation-delay: 1s;
}
.personalizing-spinner .spinner span:nth-of-type(6) {
  transform: rotate(154.2857142857deg);
}
.personalizing-spinner .spinner span:nth-of-type(6):before {
  animation-delay: 1.2s;
}
.personalizing-spinner .spinner span:nth-of-type(7) {
  transform: rotate(180deg);
}
.personalizing-spinner .spinner span:nth-of-type(7):before {
  animation-delay: 1.4s;
}
.personalizing-spinner .spinner span:nth-of-type(8) {
  transform: rotate(205.7142857143deg);
}
.personalizing-spinner .spinner span:nth-of-type(8):before {
  animation-delay: 1.6s;
}
.personalizing-spinner .spinner span:nth-of-type(9) {
  transform: rotate(231.4285714286deg);
}
.personalizing-spinner .spinner span:nth-of-type(9):before {
  animation-delay: 1.8s;
}
.personalizing-spinner .spinner span:nth-of-type(10) {
  transform: rotate(257.1428571429deg);
}
.personalizing-spinner .spinner span:nth-of-type(10):before {
  animation-delay: 2s;
}
.personalizing-spinner .spinner span:nth-of-type(11) {
  transform: rotate(282.8571428571deg);
}
.personalizing-spinner .spinner span:nth-of-type(11):before {
  animation-delay: 2.2s;
}
.personalizing-spinner .spinner span:nth-of-type(12) {
  transform: rotate(308.5714285714deg);
}
.personalizing-spinner .spinner span:nth-of-type(12):before {
  animation-delay: 2.4s;
}
.personalizing-spinner .spinner span:nth-of-type(13) {
  transform: rotate(334.2857142857deg);
}
.personalizing-spinner .spinner span:nth-of-type(13):before {
  animation-delay: 2.6s;
}
.personalizing-spinner .spinner span:nth-of-type(14) {
  transform: rotate(360deg);
}
.personalizing-spinner .spinner span:nth-of-type(14):before {
  animation-delay: 2.8s;
}
@media (max-width: 807px) {
  .personalizing-spinner {
    display: none;
  }
}

.personalizing-modal {
  background-color: rgba(247, 249, 253, 0.5);
}
.personalizing-modal .loader {
  padding: 0;
  min-height: 100vh;
}
.personalizing-modal .personalizing-spinner {
  display: flex;
}
.personalizing-modal .card {
  display: none;
}
.personalizing-modal .card .skeleton {
  display: none;
}

.skeleton {
  background-color: rgba(2, 9, 23, 0.0784313725) !important;
  animation: skeleton 1s infinite;
}

@keyframes skeleton {
  0% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes spinnerDot {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(2);
  }
}
@keyframes dots {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
.page-wrapper.sign-in {
  min-height: 100vh;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
}
.page-wrapper.sign-in .footer-container {
  margin-top: 0;
}

.create-account {
  flex: 1;
}
.create-account h1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 10px 0;
}
.create-account h1 + p {
  margin: 0;
}
.create-account .badge-earned {
  background-color: #05112A;
  background: linear-gradient(55deg, #05112A 38.55%, #722351 97.87%);
  padding: 66px;
  color: var(--color-white);
}
.create-account .badge-earned .badge-information {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 1.64%, rgba(255, 255, 255, 0.05) 1.65%, rgba(0, 133, 255, 0) 141.29%);
  text-align: center;
  padding: 60px 40px;
  flex-direction: column;
  align-items: center;
}
.create-account .badge-earned .badge-information .badge-title {
  font-size: 30px;
}
.create-account .badge-earned .badge-information .badge-issuer {
  font-size: 24px;
  margin: 10px 0 0 0;
}
.create-account .create-account-form-container {
  padding: 60px;
  max-width: 600px;
}
.create-account .create-account-form-container .gr-fieldset {
  width: 100%;
}
.create-account .create-account-form-container legend {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}
.create-account .create-account-form-container legend + p {
  margin-top: 10px;
  margin-bottom: 30px;
}
.create-account .create-account-form-container .flex-row {
  margin-bottom: 20px;
  gap: 15px;
}
.create-account .create-account-form-container .flex-row .form-input {
  width: 100%;
}
.create-account .create-account-form-container .button.primary {
  width: 100%;
}
.create-account .create-account-form-container .disclaimer {
  font-size: 0.825em;
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .create-account {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  .create-account .badge-earned, .create-account .create-account-form-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.page-wrapper.jobs {
  background: linear-gradient(to right, #05112A, #3E0953);
  color: var(--color-white);
  animation-delay: 0.1s;
  min-height: 100vh;
}
.page-wrapper.jobs .swoop {
  display: block;
  max-height: 100vh;
  overflow: hidden;
}
.page-wrapper.jobs .toast {
  max-width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-wrapper.jobs main a, .page-wrapper.jobs main .link-btn {
  color: var(--text-dark-theme-link);
}
.page-wrapper.jobs main > section:only-child {
  overflow-x: hidden;
  margin-bottom: 0;
}
.page-wrapper.jobs .filter-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  background: transparent;
  color: var(--color-white);
  padding: 8px 8px 8px 16px;
  min-height: 44px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}
.page-wrapper.jobs .filter-btn svg {
  transition: transform 0.3s ease;
}
.page-wrapper.jobs .filter-btn .filter-amount {
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(254, 254, 254, 0.1607843137);
  margin-left: 8px;
  vertical-align: text-bottom;
}
.page-wrapper.jobs .filter-btn.mobile-view {
  position: relative;
  background: transparent;
  border: 0;
  padding: 8px 0;
}
.page-wrapper.jobs .filter-btn.mobile-view .filter-amount {
  font-size: 0.75em;
  border-radius: 24px;
  position: absolute;
  top: 0px;
  right: 0;
  transform: translateX(calc(-50% + 10px));
  border: 1px solid #3a0950;
  padding: 2px 4px;
  min-width: 20px;
  min-height: 20px;
  line-height: 14px;
  color: var(--text-default);
  background: var(--bg-level0);
  font-weight: 600;
}
.page-wrapper.jobs .filter-btn[aria-expanded=true] {
  background: var(--color-white);
  color: var(--background-dark-theme-bg-level-2, #272D39);
  border: 1px solid #393F4A;
}
.page-wrapper.jobs .filter-btn[aria-expanded=true] .filter-amount {
  background: rgba(2, 9, 23, 0.1607843137);
}
.page-wrapper.jobs .filter-btn[aria-expanded=true] svg {
  transform: rotate(180deg);
}
@media (prefers-color-scheme: dark) {
  .page-wrapper.jobs .filter-btn[aria-expanded=true] {
    color: var(--color-white);
    background-color: var(--bg-level0);
  }
}
.page-wrapper.jobs .search-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
}
.page-wrapper.jobs .search-header .link-btn, .page-wrapper.jobs .search-header .filter-btn {
  flex: none;
  min-width: 40px;
  text-align: center;
}
.page-wrapper.jobs .search-header .link-btn svg, .page-wrapper.jobs .search-header .filter-btn svg {
  margin: auto;
}
.page-wrapper.jobs .search-header .jobs-search-list {
  flex: 1;
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input {
  -webkit-appearance: textfield;
  max-width: 100%;
  background: transparent !important;
  background-color: transparent !important;
  border-color: var(--color-white);
  color: var(--color-white);
  font-size: 18px;
  transition: all 0.3s ease;
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input::-moz-placeholder {
  color: var(--color-light-300);
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input::placeholder {
  color: var(--color-light-300);
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input::-webkit-autofill, .page-wrapper.jobs .search-header .jobs-search-list .gr-input::-webkit-autofill:focus {
  -webkit-transition: background-color 0s 600000s, color 0s 600000s;
  transition: background-color 0s 600000s, color 0s 600000s;
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input::-webkit-search-cancel-button {
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24px' height='24px'><path d='M12,10.5857864 L17.2928932,5.29289322 C17.6834175,4.90236893 18.3165825,4.90236893 18.7071068,5.29289322 C19.0976311,5.68341751 19.0976311,6.31658249 18.7071068,6.70710678 L13.4142136,12 L18.7071068,17.2928932 C19.0976311,17.6834175 19.0976311,18.3165825 18.7071068,18.7071068 C18.3165825,19.0976311 17.6834175,19.0976311 17.2928932,18.7071068 L12,13.4142136 L6.70710678,18.7071068 C6.31658249,19.0976311 5.68341751,19.0976311 5.29289322,18.7071068 C4.90236893,18.3165825 4.90236893,17.6834175 5.29289322,17.2928932 L10.5857864,12 L5.29289322,6.70710678 C4.90236893,6.31658249 4.90236893,5.68341751 5.29289322,5.29289322 C5.68341751,4.90236893 6.31658249,4.90236893 6.70710678,5.29289322 L12,10.5857864 Z' fill-opacity='0.8' fill='%23fefefe' /></svg>");
  appearance: none;
  -webkit-appearance: none;
  color: var(--color-white);
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input:hover, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus-within, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus {
  background-color: var(--bg-level0) !important;
  color: var(--text-muted);
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input:hover::-moz-placeholder, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus-within::-moz-placeholder, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus::-moz-placeholder {
  color: var(--text-muted);
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input:hover::placeholder, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus-within::placeholder, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus::placeholder {
  color: var(--text-muted);
}
.page-wrapper.jobs .search-header .jobs-search-list .gr-input:hover + .search-icon, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:hover ~ .clear-icon, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus-within + .search-icon, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus-within ~ .clear-icon, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus + .search-icon, .page-wrapper.jobs .search-header .jobs-search-list .gr-input:focus ~ .clear-icon {
  color: var(--text-muted);
}
.page-wrapper.jobs .search-header .jobs-search-list .search-icon, .page-wrapper.jobs .search-header .jobs-search-list .clear-icon {
  color: var(--color-white);
}
@media (min-width: 808px) {
  .page-wrapper.jobs .search-header {
    gap: 24px;
  }
}
.page-wrapper.jobs .filter-controls {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 32px;
}
.page-wrapper.jobs .filter-controls .menu {
  min-width: 300px;
}
.page-wrapper.jobs .filter-controls .menu .recent-searches {
  margin-top: 12px;
}
.page-wrapper.jobs .filter-controls .menu .group-label {
  font-size: 1.125rem !important;
  margin-top: 0;
  margin-bottom: 4px;
}
.page-wrapper.jobs .filter-controls .dropdown .menu label {
  font-size: 1.125rem;
  line-height: 1.3;
}
@media (min-width: 808px) {
  .page-wrapper.jobs main {
    overflow: hidden;
  }
  .page-wrapper.jobs main section {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--headerHeight) - 48px - 24px);
    overflow: hidden;
  }
  .page-wrapper.jobs main section .job-search-results {
    flex: 1;
    overflow: hidden;
  }
}

.job-search-results {
  padding: 0;
  flex: 0 0 auto;
  width: 200%;
}
.job-search-results .job-search-sidebar {
  overflow-y: hidden;
  flex: 1 1 auto;
}
.job-search-results .job-search-sidebar .card {
  height: auto;
  margin-bottom: 24px;
}
.job-search-results .job-search-sidebar .job-search-sidebar-results {
  overflow-y: auto;
  flex: 1 1 auto;
  scrollbar-gutter: stable;
}
.job-search-results .job-search-sidebar-header {
  margin-bottom: 32px;
}
.job-search-results .job-search-sidebar-header .search-results-title, .job-search-results .job-search-sidebar-header .search-results-description {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}
.job-search-results .job-search-sidebar-header .search-results-description {
  max-height: 72px;
}
.job-search-results .job-search-sidebar-header .search-results-description .search-text:before {
  content: "“";
}
.job-search-results .job-search-sidebar-header .search-results-description .search-text:after {
  content: "”";
}
.job-search-results .job-search-sidebar-header .search-results-description + .search-results-description {
  margin-top: 16px;
}
.job-search-results .job-search-sidebar-header.search-results {
  margin-bottom: 24px;
}
.job-search-results .job-search-sidebar-header.search-results > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.job-search-results .job-search-sidebar-header.search-results > div .icon-btn {
  color: var(--color-white);
  width: 32px;
  height: 32px;
  margin-right: -4px;
  margin-left: -4px;
}
.job-search-results .job-search-sidebar-header.search-results > div .icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.job-search-results .job-search-sidebar-header.search-results .search-results-title {
  font-weight: 400;
  margin: 0;
}
.job-search-results .job-search-sidebar-header.search-results .job-search-alert-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}
.job-search-results .job-search-sidebar-header.search-results .job-search-alert-actions .toggle {
  flex: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
}
.job-search-results .job-search-sidebar-header.search-results .job-search-alert-actions .toggle button {
  border-radius: 100px;
  border: 2px solid var(--bg-level0, #FEFEFE);
  background: var(--text-default, #020917);
  color: var(--text-default, #020917);
  min-width: 52px;
  width: 52px;
}
.job-search-results .job-search-sidebar-header p {
  margin: 0;
}
.job-search-results .job-search-result {
  flex: 1 1 auto;
}
.job-search-results .job-posting {
  --postingPadding: 20px;
  background: var(--bg-level0);
  color: var(--text-default);
  border-radius: 12px;
  padding: var(--postingPadding);
  position: relative;
  overflow-y: auto;
  max-height: 100%;
}
.job-search-results .job-posting.no-result {
  flex-grow: 1;
  height: 100%;
  background: url(../../assets/images/nora.png) center bottom/contain no-repeat rgb(18, 33, 66);
}
.job-search-results .job-posting .job-posting-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: none;
  margin-left: auto;
}
.job-search-results .job-posting .job-posting-actions .icon-btn {
  color: var(--text-default);
}
.job-search-results .job-posting .company-and-apply {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 16px;
}
.job-search-results .job-posting .company-name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-search-results .job-posting .company-name .job-company {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  margin: 0;
}
.job-search-results .job-posting .avatar {
  background-color: var(--bg-level2);
  color: var(--text-muted);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  width: 41px;
  height: 41px;
  flex: none;
  border-radius: 50%;
  overflow: hidden;
}
.job-search-results .job-posting .avatar span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.job-search-results .job-posting .avatar img {
  width: 100%;
}
.job-search-results .job-posting .job-title {
  margin: 12px 0;
}
.job-search-results .job-posting .job-posting-header {
  margin-bottom: 32px;
}
.job-search-results .job-posting .job-details {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  margin: 0;
  color: var(--text-muted);
}
.job-search-results .job-posting .job-details span:not(:last-child):after {
  content: "•";
  padding: 0 4px;
}
.job-search-results .job-posting .job-posting-body {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.job-search-results .job-posting .job-posting-body h4 {
  font-size: 1.5rem;
  line-height: 1.1667em;
  font-weight: 700;
  margin-bottom: 32px;
}
.job-search-results .job-posting .job-posting-body p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
@media (max-width: 807px) {
  .job-search-results {
    display: flex;
    align-items: flex-start;
    gap: var(--outside-padding);
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    padding-bottom: 40px;
  }
  .job-search-results .back-to-results {
    margin-bottom: 24px;
  }
  .job-search-results.sidebar-only {
    transform: translateX(0);
  }
  .job-search-results.sidebar-only .job-search-result {
    animation: 0.1s hide forwards;
    animation-delay: 0.3s;
  }
  .job-search-results.job-posting-only {
    transform: translateX(calc(-50% - var(--outside-padding)));
  }
  .job-search-results.job-posting-only .job-search-sidebar {
    animation: 0.1s hide forwards;
    animation-delay: 0.3s;
  }
  .job-search-results .job-search-sidebar {
    width: 50%;
    flex: 0 0 50%;
  }
  .job-search-results .job-search-result {
    width: 50%;
    flex: 0 0 50%;
  }
  .job-search-results .card.job-result-card.active {
    border: 1px solid #122142;
    background: #122142;
    color: var(--color-white) !important;
  }
  .job-search-results .card.job-result-card.active .bookmark-btn {
    color: var(--color-white) !important;
  }
  .job-search-results .card.job-result-card.active .chip-label {
    background: rgba(2, 9, 23, 0.7215686275);
    color: var(--color-white);
  }
}
@media (min-width: 808px) {
  .job-search-results {
    display: flex;
    gap: 20px;
    width: auto;
  }
  .job-search-results .job-search-sidebar {
    max-width: 360px;
    min-width: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .job-search-results .job-search-sidebar .job-search-sidebar-header {
    padding-right: 4px;
  }
  .job-search-results .job-search-sidebar .job-search-sidebar-results {
    padding-right: 4px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    height: 100%;
  }
  .job-search-results .job-search-result {
    max-height: 100%;
    overflow: hidden;
  }
  .job-search-results .job-search-result .job-posting {
    --postingPadding:56px;
    overflow-y: auto;
    max-height: 100%;
  }
}

.card.job-result-card {
  border: 1px solid #122142;
  background: #122142;
  color: var(--color-white) !important;
}
.card.job-result-card .bookmark-btn {
  color: var(--color-white) !important;
}
.card.job-result-card.active {
  border: 1px solid var(--color-border);
  background: var(--bg-level1, #F7F9FD);
  color: var(--text-default) !important;
}
.card.job-result-card.active .bookmark-btn {
  color: var(--text-default) !important;
}
.card.job-result-card.no-results {
  border: none;
  font-size: 1.125rem;
  flex: 1;
  margin-bottom: 0;
}
.card.job-result-card.no-results h3 {
  text-align: center;
  margin: 10vh 0 10vh 0;
}
.card.job-result-card.no-results p {
  margin: 1em 0;
}
.card.job-result-card.no-results button {
  margin: auto;
  font-size: inherit;
}
.card.job-result-card.no-results.more-recommended {
  background: transparent;
  border: none;
  font-size: 1.125rem;
  text-align: center;
  flex: none;
  margin-top: -8px;
}
.card.job-result-card.no-results.more-recommended h3 {
  margin: 0 0 12px 0;
}
.card.job-result-card.no-results.more-recommended p {
  margin: 1em 0;
}
.card.job-result-card.no-results.more-recommended button {
  margin: auto;
}
@media (min-width: 807px) {
  .card.job-result-card .bookmark-btn {
    display: none;
  }
  .card.job-result-card.saved .bookmark-btn {
    display: flex;
  }
  .card.job-result-card.no-results {
    margin-bottom: 0;
  }
  .card.job-result-card.no-results h3 {
    text-align: center;
    margin: 10vh 0 1em 0;
  }
}

.alerts-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.no-alerts {
  text-align: center;
}
.no-alerts .illustration {
  max-width: 200px;
  margin: 0 auto 24px auto;
}
.no-alerts .illustration img {
  width: 100%;
  max-width: 100%;
}

.job-alert-item {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  text-align: left;
  width: 100%;
}
.job-alert-item.disabled .alert-info {
  opacity: 0.4;
}
.job-alert-item:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.job-alert-item .alert-info {
  flex: 1;
}
.job-alert-item .job-alert-title {
  text-align: left;
  margin: 0 0 4px 0;
}
.job-alert-item .alert-meta {
  margin: 0;
}
.job-alert-item .alert-meta span:not(:last-of-type):after {
  content: "•";
  margin: 0 5px;
}
.job-alert-item .alert-frequency {
  border: 0;
  padding: 0;
  margin: 0;
}
.job-alert-item .alert-frequency legend {
  font-weight: 500;
  margin-bottom: 12px;
}
.job-alert-item .alert-frequency legend p {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}
.job-alert-item .alert-frequency .radio-options {
  gap: 16px;
}
.job-alert-item .alert-frequency .radio-options label, .job-alert-item .alert-frequency .radio-options span {
  font-size: 18px;
  line-height: 18px;
}
.job-alert-item .alert-frequency .radio-options .gr-radio:last-of-type {
  margin-bottom: 0;
}

.filter-drawer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-level0);
  color: var(--text-default);
  z-index: 1112;
  animation: slideIn 0.3s ease-in-out forwards;
  font-size: 1rem;
}
.filter-drawer .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  margin: 0;
}
.filter-drawer .modal-header h1, .filter-drawer .modal-header h2 {
  margin: 0;
}
.filter-drawer .modal-body {
  padding: 24px;
  flex: 1;
  overflow-y: auto;
}
.filter-drawer .modal-body p {
  font-size: 18px;
}
.filter-drawer .modal-body .gr-radio span {
  font-size: 18px;
}
.filter-drawer .modal-body .accordion-btn {
  padding: 8px 0;
}
.filter-drawer .modal-body .accordion-body {
  padding: 0 4px 32px 4px;
}
.filter-drawer .modal-body .accordion-body .gr-radio:last-of-type {
  margin-bottom: 0;
}
.filter-drawer .modal-body .recent-searches {
  margin-top: 12px;
}
.filter-drawer .modal-body .group-label {
  font-size: 1.125rem !important;
  margin-top: 0;
  margin-bottom: 4px;
}
.filter-drawer .modal-footer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px;
  background: var(--bg-level0);
  margin-top: 0;
}

/* starting ENTER animation */
.filterDrawer-enter {
  position: absolute;
  top: 0;
  left: 0;
  right: -120vw;
  bottom: 0;
  opacity: 0;
  z-index: 11;
}
.filterDrawer-enter * {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 200ms;
}
.filterDrawer-enter.with-delay {
  transition-delay: 800ms;
}

/* ending ENTER animation */
.filterDrawer-enter-active {
  opacity: 1;
  right: 0;
  transition: opacity 1000ms ease-in-out, right 1000ms ease-in-out;
}
.filterDrawer-enter-active * {
  opacity: 1;
}

/* starting EXIT animation */
.filterDrawer-exit {
  opacity: 1;
}

/* ending EXIT animation */
.filterDrawer-exit-active {
  opacity: 0;
  right: -120vw;
  transition: opacity 1000ms ease-in-out, right 1000ms ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: translateX(120vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes jobsSwoopEnter {
  0% {
    height: 300px;
    opacity: 1;
  }
  100% {
    height: 150vh;
    opacity: 1;
  }
}
@keyframes fadeInBG {
  0% {
    background: transparent;
  }
  100% {
    background: linear-gradient(to right, #05112A, #3E0953);
  }
}
@keyframes hide {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
  }
}
.pathway-diagrams .link-btn, .pathway-diagrams .card .card-header .link-btn {
  font-size: 18px;
}
.pathway-diagrams .card.action-card {
  display: block;
  padding: var(--card-padding);
  padding-bottom: 48px;
  background: linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0) 55.93%), #05112A;
  --icon-width: 24px;
}
.pathway-diagrams .card.action-card .card-title.edited:after {
  content: "";
  background-image: url("../icons/Completion.svg");
  margin-left: 0.2em;
  padding-left: var(--icon-width);
  background-size: var(--icon-width) var(--icon-width);
  background-position: center center;
  background-repeat: no-repeat;
}
.pathway-diagrams .card.action-card .card-body {
  margin-top: 0px;
}
.pathway-diagrams .card.action-card .card-body .flex-row {
  padding-top: 24px;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--column-gap);
  overflow-x: auto;
  padding-bottom: 4px;
  margin-bottom: -4px;
}
.pathway-diagrams .card.action-card .card-body .card {
  background: var(--color-white);
  border-color: var(--color-border);
  color: var(--color-black);
  height: auto;
}
.pathway-diagrams .card.action-card .card-body .card .card-title, .pathway-diagrams .card.action-card .card-body .card p {
  color: var(--color-black);
}
.pathway-diagrams .card.action-card .card-body .card:not(.completed):before {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path d="M14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.34315 0.928932C7.95262 0.538408 7.31946 0.538408 6.92893 0.928932C6.53841 1.31946 6.53841 1.95262 6.92893 2.34315L12.5858 8L6.92893 13.6569C6.53841 14.0474 6.53841 14.6805 6.92893 15.0711C7.31946 15.4616 7.95262 15.4616 8.34315 15.0711L14.7071 8.70711ZM0 9H14V7H0V9Z" fill="white"/></svg>');
  position: absolute;
  display: block;
  width: 15px;
  height: 16px;
  left: -20px;
  top: calc(50% - 12px);
}
.pathway-diagrams .card.pathway-suggestions {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -24px;
}
.pathway-diagrams .card.pathway-suggestions .suggested-certificates {
  position: relative;
}
.pathway-diagrams .card.pathway-suggestions .card-row {
  overflow-x: auto;
  padding-bottom: 4px;
}
.pathway-diagrams .card.pathway-suggestions .card-row .card {
  flex: none;
}

.associated-roles .role-information {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.associated-roles .quick-stats {
  flex: 1;
}
.associated-roles .quick-stats .desktop-h5 {
  margin: 0;
}
.associated-roles .quick-stats .card.outlook-item {
  padding: 24px 10px;
  margin-top: 32px;
  background: var(--bg-level0);
}
.associated-roles .quick-stats .card.outlook-item p {
  margin: 0;
  font-size: 1.125rem;
}
.associated-roles .quick-stats .card.outlook-item p + p {
  margin-top: 4px;
}
.associated-roles .roles-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}
.associated-roles .roles-list .roles-list-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}
.associated-roles .roles-list .roles-list-container.condensed {
  max-height: 50px;
  overflow: hidden;
}
.associated-roles .roles-list .chip-btn {
  white-space: nowrap;
  padding: 12px 24px;
  background: #E0E7F0;
  border-color: #E0E7F0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 8px;
}
.associated-roles .roles-list .chip-btn:hover, .associated-roles .roles-list .chip-btn:focus {
  border: 1px dashed #122142;
}
.associated-roles .roles-list .chip-btn[aria-selected=true] {
  background: #122142;
  border-color: #122142;
  color: var(--color-white);
}
.associated-roles .roles-list .chip-btn[aria-selected=true]:hover {
  border: 1px solid #122142;
}
@media (max-width: 807px) {
  .associated-roles .role-information {
    flex-direction: column;
  }
  .associated-roles .card.skills-table-container {
    overflow-x: auto;
  }
}
.associated-roles .secondary-tabs.tabs-card {
  background: var(--bg-level0);
}
.associated-roles .tab-list-container {
  background: var(--bg-level2);
}
.associated-roles .tab-content .card {
  background: var(--bg-level1);
}
.associated-roles .MuiTabs-root .MuiTabs-indicator {
  background: var(--color-black);
}
.associated-roles .card.skills-table-container {
  padding: 24px 0;
  flex: 2;
  max-width: 100%;
}
.associated-roles .skills-table {
  width: 100%;
  font-size: 1.125rem;
  border-collapse: collapse;
}
.associated-roles .skills-table thead th {
  text-align: center;
  font-weight: normal;
  vertical-align: top;
  padding-bottom: 28px;
  padding-left: 28px;
  padding-right: 28px;
}
.associated-roles .skills-table thead th strong, .associated-roles .skills-table thead th span {
  display: block;
}
.associated-roles .skills-table thead th:first-of-type {
  text-align: left;
}
.associated-roles .skills-table tbody tr:nth-child(2n+1) th, .associated-roles .skills-table tbody tr:nth-child(2n+1) td {
  background: var(--bg-level0, #FEFEFE);
}
.associated-roles .skills-table tbody th {
  font-weight: normal;
  padding: 8px 28px;
  width: 100%;
}
.associated-roles .skills-table tbody th .icon {
  color: #01CBB7;
  margin-right: 4px;
  vertical-align: middle;
}
.associated-roles .skills-table tbody td {
  padding: 8px 28px;
  min-height: 50px;
  text-align: center;
}
.associated-roles .skills-table tbody td .chip-btn {
  white-space: nowrap;
  font-size: 14px;
}
.associated-roles .skills-table tbody td .chip-btn.dark {
  font-weight: 700;
}
.associated-roles .skills-table tbody td .chip-btn.dark:hover, .associated-roles .skills-table tbody td .chip-btn.dark:focus {
  background: var(--background-dark-theme-bg-level-2, #272D39);
  color: #FEFEFE;
  border-color: var(--background-dark-theme-bg-level-2, #272D39);
}
.associated-roles .skills-table tbody .null {
  width: 100%;
  text-align: center;
  color: #60646D;
  font-weight: bold;
  display: inline-block;
  line-height: 34px;
}

.pathway-card {
  padding: 50px 16px 16px 16px;
  width: 180px;
  flex: none;
  color: var(--text-default);
}
.pathway-card .card-title {
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.pathway-card .issuer {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.pathway-card .image {
  display: flex;
  align-items: center;
  flex: none;
  width: 130px !important;
  overflow: hidden;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}
.pathway-card.active {
  background-color: #122142;
  color: var(--color-white);
}
.pathway-card .remove-path-btn {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  opacity: 0;
}
.pathway-card .remove-path-btn:focus {
  opacity: 1;
}
.pathway-card:hover .remove-path-btn {
  opacity: 1;
}

.accordion-container.suggested-certificate-accordion {
  box-shadow: none;
  border: 1px solid var(--color-border);
  background: var(--bg-level0);
}

.accordion-btn.pathway-card {
  border: 0;
  width: 100%;
  align-items: center;
}
.accordion-btn.pathway-card .icon-24 {
  margin-bottom: 34px;
}
.accordion-btn.pathway-card .pathway-info {
  display: flex;
  align-items: center;
  gap: 12px;
}
.accordion-btn.pathway-card .pathway-info .image {
  max-width: 100px;
}
.add-to-path {
  background: var(--transparent-dark-32, rgba(2, 9, 23, 0.32));
  border: 3px dashed #393F4A;
  border-radius: var(--card-corner-default);
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  color: var(--color-white);
  font-size: 1.25rem;
  font-weight: 600;
  flex: none;
}
.add-to-path:hover, .add-to-path:focus {
  background: rgba(2, 9, 23, 0.75);
  border-color: rgba(254, 254, 254, 0.7215686275);
}

.outlook-items {
  gap: 8px;
  flex-wrap: wrap;
}

.outlook-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.outlook-item .icon {
  background: #E8EEFA;
  border: 1px solid var(--color-border);
  color: var(--color-black);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}
.outlook-item .outlook-title {
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0;
}
.outlook-item .outlook-title + p {
  margin-top: 4px;
}

@keyframes hideOnScroll {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    overflow: hidden;
    margin: 0;
  }
}
.modal.pathway-modal {
  text-align: left;
}
.modal.pathway-modal.minimize .modal-header .autocomplete-container {
  max-height: 0;
  animation: hideOnScroll 0.3s ease forwards;
  animation-delay: 0.2s;
}
.modal.pathway-modal.minimize .modal-header .card-row {
  margin-top: 0;
}
.modal.pathway-modal.minimize .modal-header .card .card-text {
  max-height: 0;
  overflow: hidden;
  animation: hideOnScroll 0.3s ease;
  animation-delay: 0.2s;
}
.modal.pathway-modal .modal-header .autocomplete-container {
  max-height: 100px;
  transition: max-height 0.5s ease;
}
.modal.pathway-modal .modal-header .card-row {
  transition: margin 0.2s ease;
  transition-delay: 0.1s;
}
.modal.pathway-modal .modal-header .card .card-text {
  max-height: 1000px;
  transition: max-height 0.5s ease;
}
.modal.pathway-modal .card-row {
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 4px;
  margin-bottom: -4px;
}
.modal.pathway-modal .card-row .pathway-card {
  flex: none;
}
.modal.pathway-modal .modal-title {
  padding-right: 40px;
}
.modal.pathway-modal .certificate-title {
  text-align: left;
}
.modal.pathway-modal .pathway-card:not(.active) {
  background: var(--bg-level0);
}
.modal.pathway-modal .certificate-info .certificate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}
.modal.pathway-modal .certificate-info .certificate-header h2 {
  margin: 0;
}
.modal.pathway-modal .certificate-tagline {
  display: flex;
  align-items: flex-start;
  gap: 56px;
}
.modal.pathway-modal .certificate-tagline .avatar {
  max-width: 150px;
  width: 100%;
  max-height: 150px;
  overflow: hidden;
}
.modal.pathway-modal .certificate-tagline .avatar img {
  width: 100%;
  max-width: 100%;
}
.modal.pathway-modal .certificate-tagline p + p {
  margin-top: 12px;
}
.modal.pathway-modal .certificate-info-group {
  margin-top: 56px;
}
.modal.pathway-modal .certificate-info-group h3 {
  margin-bottom: 0;
}
.modal.pathway-modal .certificate-info-group h4 {
  margin-top: 1rem;
  margin-bottom: 0;
}
.modal.pathway-modal .certificate-info-group .chip-list {
  margin-top: 12px;
}
.modal.pathway-modal .certificate-info-group .chip-list .chip-btn {
  font-size: 14px;
}
.modal.pathway-modal .certificate-info-group .certificate-description {
  margin-top: 1em;
}
.modal.pathway-modal .certificate-info-group .button-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
}
.modal.pathway-modal .tabs-card {
  margin-top: 1rem;
  background: var(--bg-level0);
}
.modal.pathway-modal .tabs-card .tab-list-container {
  background: var(--bg-level2);
}
.modal.pathway-modal .tabs-card .tab-content .card {
  background: var(--bg-level1);
}
.modal.pathway-modal .MuiTabs-root .MuiTabs-indicator {
  background: var(--color-black);
}
@media (max-width: 1199px) {
  .modal.pathway-modal .certificate-tagline {
    gap: 24px;
  }
}
@media (max-width: 807px) {
  .modal.pathway-modal {
    max-width: calc(100vw - 32px);
    width: 100%;
    max-height: calc(100vh - 32px);
  }
  .modal.pathway-modal .certificate-header {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .modal.pathway-modal .certificate-header .certificate-title {
    text-align: center;
  }
  .modal.pathway-modal .certificate-tagline {
    flex-direction: column;
    gap: 24px;
  }
  .modal.pathway-modal .certificate-tagline .avatar {
    margin: auto;
  }
  .modal.pathway-modal .card.study-card .image.video img {
    height: auto;
    max-width: 120px;
  }
}

.pop-up-container {
  position: absolute;
  flex: none;
  padding-top: 20px;
  width: 100%;
  max-width: 500px;
}
.pop-up-container .certificate-popup {
  position: relative;
  z-index: 111;
  border-radius: 16px;
  border: 1px solid var(--color-border, #DDE3EE);
  background: var(--bg-level1, #F7F9FD);
}

.certificate-popup {
  padding: 32px;
  width: 100%;
  max-width: 500px;
  animation: popIn 0.3s ease-in;
}
.certificate-popup.certificate-accordion-body {
  padding: 0;
  max-width: 100%;
}
.certificate-popup.certificate-accordion-body .button-row {
  flex-wrap: wrap;
}
.certificate-popup.certificate-accordion-body .button-row button {
  white-space: nowrap;
  flex: 1 0 auto;
}
.certificate-popup.hide {
  display: none;
}
.certificate-popup .arrow {
  position: absolute;
  bottom: 100%;
  width: 48px;
  height: 29px;
  overflow: hidden;
  z-index: 112;
}
.certificate-popup .arrow.left {
  left: 32px;
}
.certificate-popup .arrow.right {
  right: 32px;
}
.certificate-popup .arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: var(--color-border, #DDE3EE);
  transform: rotate(-45deg) translate(-25%, 25%);
  transform-origin: center;
}
.certificate-popup .arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: var(--bg-level1, #F7F9FD);
  transform-origin: center;
  transform: scale(0.9) rotate(-45deg) translate(-25%, 25%);
}
.certificate-popup .desktop-h4 {
  margin: 0 0 4px 0;
}
.certificate-popup .desktop-h6 {
  margin: 24px 0 12px 0;
}
.certificate-popup .chip-label {
  font-size: 14px;
  font-weight: 500;
}
.certificate-popup .button-row {
  margin-top: 32px;
  display: flex;
  gap: 12px;
}
.certificate-popup .button-row button {
  flex: 1;
}

.remove-path-btn {
  width: 40px;
  height: 40px;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  color: #60646D;
}
.remove-path-btn:hover {
  background-color: var(--bg-level2);
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.email-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
}
.email-wrapper .material-symbols-rounded, .email-wrapper .material-symbols-outlined {
  color: #6a7070;
}
.email-wrapper .flex-row {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}
.email-wrapper .icon.red .material-symbols-rounded {
  color: #EA4335;
}
.email-wrapper .icon.blue .material-symbols-rounded {
  color: #1B73E8;
}
.email-wrapper .icon.green .material-symbols-rounded {
  color: #1F8E3E;
}
.email-wrapper .icon.yellow .material-symbols-rounded {
  color: #F7CB58;
}
.email-wrapper .compose-btn {
  align-items: center;
  background-color: var(--dt-surface-bright, #fff);
  border-radius: var(--dt-corner-fab, 6.25rem);
  border-width: 0;
  box-shadow: var(--dt-shadow-elevation-1, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15));
  box-sizing: border-box;
  color: var(--dt-on-surface, rgb(60, 64, 67));
  cursor: pointer;
  display: inline-flex;
  fill: var(--dt-on-surface, rgb(60, 64, 67));
  gap: 0.75rem;
  grid-template-columns: auto 1fr;
  height: 2.75rem;
  font-size: 1.125rem;
  min-width: 6.25rem;
  padding: 0 1rem;
  place-content: center start;
  transition: box-shadow 0.08s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px 10px;
}
.email-wrapper .compose-btn svg {
  fill: currentColor;
}
.email-wrapper .email-header {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid var(--color-border);
}
.email-wrapper .email-header > div:first-child {
  width: 180px;
  flex: none;
}
.email-wrapper .email-header .search {
  flex: 1;
  background-color: #F5F5F5;
  color: #6a7070;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 10px;
}
.email-wrapper .email-header .search .placeholder {
  flex: 1;
}
.email-wrapper .email-header .search + .flex-row {
  margin-left: 80px;
}
.email-wrapper .email-wrapper-body {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.email-wrapper .email-wrapper-body .email-tools {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid var(--color-border);
}
.email-wrapper .email-wrapper-body .email-tools .group {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 15px;
}
.email-wrapper .email-wrapper-body .email-tools .group + .group {
  border-left: 1px solid var(--color-border);
}
.email-wrapper .email-wrapper-body .email-tools .pagination {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
  color: #6a7070;
  font-size: 0.825rem;
  padding: 0 15px;
}
.email-wrapper .email-wrapper-body .email-tools .pagination span.material-symbols-rounded:not(:last-child) {
  opacity: 0.5;
}
.email-wrapper .email-wrapper-body .email-tools .material-symbols-rounded, .email-wrapper .email-wrapper-body .email-tools .material-symbols-outlined {
  font-size: 20px;
}
.email-wrapper .email-wrapper-body .avatar {
  flex: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url("https://lh3.googleusercontent.com/a/default-user=s40-p");
}
.email-wrapper .email-wrapper-body .email-wrapper-body-sidebar {
  width: 200px;
  flex: none;
  overflow-y: hidden;
  border-right: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
}
.email-wrapper .email-wrapper-body .email-wrapper-body-sidebar .nav-list {
  flex: 1;
  overflow-y: auto;
}
.email-wrapper .email-wrapper-body .email-wrapper-body-sidebar .meet {
  border-top: 1px solid var(--color-border);
  padding: 20px 0;
}
.email-wrapper .email-wrapper-body .email-wrapper-body-message {
  flex: 1;
  overflow-y: auto;
  position: relative;
}
.email-wrapper .email-wrapper-body .email-wrapper-body-message .email-tools {
  position: sticky;
  top: 0;
  background: white;
  z-index: 11;
}
@media (max-width: 807px) {
  .email-wrapper .email-wrapper-body .email-wrapper-body-sidebar {
    display: none;
  }
}
.email-wrapper .email-wrapper-body .email-message-header {
  padding-bottom: 20px;
}
.email-wrapper .email-wrapper-body .email-message-header .flex-row {
  gap: 20px;
}
.email-wrapper .email-wrapper-body .email-message-header h1 {
  margin: 0;
  font-size: 1.5rem;
}
.email-wrapper .email-wrapper-body .email-message-header h1 .inbox-label {
  font-size: 0.75rem;
  font-weight: normal;
  color: #666;
  background-color: #ddd;
  padding: 4px 8px;
  border-radius: 4px;
  vertical-align: top;
}
.email-wrapper .email-wrapper-body .email-message-header h1 .inbox-label span {
  margin-left: 4px;
}
.email-wrapper .email-wrapper-body .email-message-header h1 .icon {
  vertical-align: bottom;
}
.email-wrapper .email-wrapper-body .message-details {
  flex: 1;
}
.email-wrapper .email-wrapper-body .message-details p {
  margin: 0;
  color: #5e5e5e;
  font-size: 0.75rem;
  line-height: 20px;
}
.email-wrapper .email-wrapper-body .message-details p strong {
  color: var(--text-default);
}
.email-wrapper .email-wrapper-body .message-details p .ajz {
  background-image: url("https://www.gstatic.com/images/icons/material/system_gm/1x/arrow_drop_down_black_20dp.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: none;
  height: 20px;
  margin: 0 0 0 auto;
  position: relative;
  right: 0;
  top: 0;
  width: 20px;
  display: inline-block;
  opacity: 0.71;
  padding: 0;
  vertical-align: middle;
}

.nav-item {
  padding: 2px 10px 2px 20px;
  margin-right: 10px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.nav-item .material-symbols-rounded {
  font-size: 20px;
  line-height: 20px;
}
.nav-item .icon {
  width: 40px;
}
.nav-item.active {
  background-color: #FCE8E7;
  color: #D0201A;
}
.nav-item.active .material-symbols-rounded {
  color: #D0201A;
}

.gmail-logo {
  display: block;
  width: 109px;
  height: 40px;
  background-image: url("https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_2x_r5.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.credly-badge-email {
  font-family: "TT Commons", sans-serif;
  background: #F7F9FD;
  position: relative;
  overflow: hidden;
  min-height: 80vh;
}
.credly-badge-email * {
  z-index: 1;
}
.credly-badge-email:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(273deg, rgba(155, 161, 173, 0.08) -5.26%, rgba(232, 238, 250, 0.08) 39.67%, rgba(232, 238, 250, 0.08) 55.59%, rgba(155, 161, 173, 0.08) 103.93%);
  transform: translateX(-75%) skewX(-30deg);
  transform-origin: bottom left;
  z-index: 0;
}
.credly-badge-email .email-top {
  background-image: url("../images/email-hero.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 360px;
  color: var(--color-white);
  position: relative;
  padding: 60px 40px;
}
.credly-badge-email .email-top .credly-email-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 40px;
}
.credly-badge-email .email-top .credly-email-header a {
  color: var(--color-white);
  font-weight: normal;
}
.credly-badge-email .email-top .text {
  text-align: center;
}
.credly-badge-email .email-top .text .meta {
  opacity: 0.68;
  font-size: 0.825em;
}
.credly-badge-email .email-top .text h1 {
  margin-top: 40px;
  margin-bottom: 10px;
}
.credly-badge-email .email-top .text .subtitle-1 {
  text-align: center;
}
.credly-badge-email .email-top .badge {
  max-width: 300px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: -140px;
}
.credly-badge-email .email-top .badge img {
  width: 100%;
}
.credly-badge-email .credly-email-body {
  padding: 60px 40px 40px;
  max-width: 740px;
  margin: auto;
}
.credly-badge-email .credly-email-body .desktop-h3 {
  text-align: center;
}
.credly-badge-email .credly-email-body .desktop-h5 {
  margin: 0 0 0.5em 0;
  font-weight: 600;
}
.credly-badge-email .credly-email-body .learn-more {
  margin-top: 80px;
}
.credly-badge-email .credly-email-body .learn-more p {
  margin: 0;
}
.credly-badge-email .credly-email-body .learn-more .chip-list {
  gap: 0;
  margin-top: 10px;
}
.credly-badge-email .credly-email-body .learn-more .chip-list .chip-btn {
  font-size: 14px;
  margin: 2px;
}
.credly-badge-email .credly-email-body .learn-more .flex-row {
  gap: 24px;
  margin: 40px auto;
  max-width: 900px;
}
.credly-badge-email .credly-email-body .learn-more .one-col {
  flex: 1;
}
.credly-badge-email .credly-email-body .learn-more .three-col {
  flex: 3;
}
.credly-badge-email .credly-email-body .fine-print {
  margin-top: 80px;
  padding-top: 20px;
  border-top: 1px solid var(--color-border);
  text-align: center;
}
.credly-badge-email .credly-email-body .fine-print p {
  font-size: 0.75em;
  color: var(--text-muted);
  margin: 4px 15%;
}
.credly-badge-email .credly-email-body .fine-print p:first-child {
  font-size: 0.825em;
  margin: 0 0 1.5em 0;
}
.credly-badge-email .credly-email-body .button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
  margin-top: 40px;
}
.credly-badge-email .credly-email-body .button-row a {
  display: block;
}

.megamenu-container > .tab-action-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
}
.megamenu-container > .tab-action-container .tab-action {
  padding-right: 28px;
}
.megamenu-container button.icon-btn {
  width: 24px;
  height: 24px;
  color: var(--color-white);
  position: absolute;
  right: 10px;
  top: 6px;
}
.megamenu-container button.icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.megamenu-container button.icon-btn svg {
  transition: transform 0.3s ease;
}
.megamenu-container button.icon-btn[aria-expanded=true] svg {
  transform: rotate(180deg);
}

.megamenu-menu {
  position: fixed;
  top: var(--headerHeight);
  left: 0;
  border-radius: 4px;
  border: 1px solid var(--primary-light-grey, #E8EEFA);
  background: var(--bg-level0);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 111;
  display: grid;
  grid-auto-flow: column;
  color: var(--text-default);
  max-height: 800px;
  height: calc(100vh - var(--headerHeight) - 60px);
  min-height: 0;
  overflow: hidden;
  max-width: 100%;
}
.megamenu-menu:focus {
  outline: none;
}
.megamenu-menu:focus-visible {
  outline: none;
}
.megamenu-menu .link-card {
  display: flex;
  align-items: center;
  gap: var(--card-padding-small);
  text-decoration: none;
  color: var(--text-default);
  margin-bottom: var(--card-padding-small);
  border: 1px solid var(--color-border);
  padding: var(--card-padding-small);
}
.megamenu-menu .link-card p {
  font-weight: normal;
}
.megamenu-menu .link-card .image {
  max-width: 72px;
}
.megamenu-menu .panel {
  flex-direction: column;
  padding: 12px;
  height: 100%;
  overflow: hidden;
  display: none;
}
.megamenu-menu .panel .panel-header {
  padding: 8px 16px 8px 26px;
  border-bottom: 1px solid var(--color-border);
  opacity: 0;
}
.megamenu-menu .panel .panel-title {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.megamenu-menu .panel.visible {
  display: flex;
  width: calc((100vw - 360px) / 3);
  scrollbar-gutter: stable;
}
.megamenu-menu .panel.visible.panel-small {
  width: auto;
  max-width: 360px;
  padding-top: 20px;
  padding-right: 26px;
}
.megamenu-menu .panel.visible .panel-header {
  opacity: 1;
}
.megamenu-menu ul {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
.megamenu-menu .megamenu-item {
  width: 100%;
  padding: 10px 18px 10px 26px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.megamenu-menu .megamenu-item a {
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: inherit;
  font-weight: normal;
}
.megamenu-menu .megamenu-item a:hover {
  text-decoration: underline;
}
.megamenu-menu .megamenu-item a:focus-visible {
  outline: none;
  box-shadow: 0px 0px 0px 2px #B41863;
}
.megamenu-menu .megamenu-item button {
  color: inherit;
}
.megamenu-menu .megamenu-item button svg {
  fill: currentColor;
}
.megamenu-menu .megamenu-item button:focus-visible {
  outline: none;
  box-shadow: 0px 0px 0px 2px #B41863;
}
.megamenu-menu .megamenu-item:hover, .megamenu-menu .megamenu-item.selected {
  background: var(--background-light-theme-bg-level-1, #F7F9FD);
}
@media (prefers-color-scheme: dark) {
  .megamenu-menu {
    border: 0;
  }
  .megamenu-menu .panel-header {
    border-color: #C7CCD6;
  }
  .megamenu-menu .megamenu-item {
    color: var(--text-muted);
  }
  .megamenu-menu .megamenu-item a {
    color: var(--text-muted);
  }
  .megamenu-menu .megamenu-item:hover, .megamenu-menu .megamenu-item.selected {
    background: var(--bg-level1);
  }
}

.coachmark {
  padding: 24px;
  position: fixed;
  z-index: 1111;
  background: #fefefe;
  border-radius: 8px;
  max-width: 345px;
  width: calc(100% - var(--outside-padding));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.coachmark:after {
  content: url('data:image/svg+xml; utf8, <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5858 1.41421C13.3668 0.633164 14.6332 0.633165 15.4142 1.41421L24.5858 10.5858C25.8457 11.8457 24.9534 14 23.1716 14H4.82843C3.04662 14 2.15428 11.8457 3.41421 10.5858L12.5858 1.41421Z" fill="%23FEFEFE"/></svg>');
  position: absolute;
  display: block;
  width: 28px;
  height: 14px;
}
.coachmark.top-right {
  top: calc(var(--headerHeight) + 4px);
  right: calc(var(--outside-padding) - 4px);
}
.coachmark.top-right:after {
  top: -11px;
  right: 26px;
}
.coachmark .coach-title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.400000006px;
  text-align: left;
  color: var(--color-black);
}
.coachmark .coach-body {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.24px;
  text-align: left;
  margin: 0;
  color: var(--color-black);
}
.coachmark .coach-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
}

.recruiter-home header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color-black);
  color: var(--color-white);
  width: 100%;
  z-index: 111;
}
.recruiter-home main {
  max-width: 1100px;
  margin: auto;
  padding-left: var(--outside-padding);
  padding-right: var(--outside-padding);
}
.recruiter-home .main-header .flex-row {
  align-items: center;
}
.recruiter-home .main-header a {
  flex: none;
}
.recruiter-home .form-group {
  margin: var(--vertical-space) 0;
}
.recruiter-home .search-input {
  width: 100%;
  margin-bottom: var(--vertical-space);
}
.recruiter-home .search-input .gr-input {
  max-width: 100%;
  border-radius: 4px;
}
.recruiter-home .autocomplete-container .search-input {
  margin-bottom: 0;
}
.recruiter-home .form-actions {
  justify-content: space-between;
}
.recruiter-home .form-actions .flex-row {
  gap: 12px;
}
.recruiter-home .filter-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--text-default);
  background: transparent;
  color: var(--text-default);
  padding: 8px 8px 8px 16px;
  min-height: 44px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}
.recruiter-home .filter-btn svg {
  transition: transform 0.3s ease;
}
.recruiter-home .filter-btn .filter-amount {
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(254, 254, 254, 0.1607843137);
  margin-left: 8px;
  vertical-align: text-bottom;
}
.recruiter-home .filter-btn.mobile-view {
  position: relative;
  background: transparent;
  border: 0;
  padding: 8px 0;
}
.recruiter-home .filter-btn.mobile-view .filter-amount {
  font-size: 0.75em;
  border-radius: 24px;
  position: absolute;
  top: 0px;
  right: 0;
  transform: translateX(calc(-50% + 10px));
  border: 1px solid #3a0950;
  padding: 2px 4px;
  min-width: 20px;
  min-height: 20px;
  line-height: 14px;
  color: var(--text-default);
  background: var(--bg-level0);
  font-weight: 600;
}
.recruiter-home .filter-btn[aria-expanded=true] {
  background: var(--color-white);
  color: var(--background-dark-theme-bg-level-2, #272D39);
}
.recruiter-home .filter-btn[aria-expanded=true] .filter-amount {
  background: rgba(2, 9, 23, 0.1607843137);
}
.recruiter-home .filter-btn[aria-expanded=true] svg {
  transform: rotate(180deg);
}
@media (prefers-color-scheme: dark) {
  .recruiter-home .filter-btn[aria-expanded=true] {
    color: var(--color-white);
    background-color: var(--bg-level0);
  }
}
.recruiter-home .skills-table {
  margin: var(--vertical-space) 0;
  width: 100%;
  border-collapse: collapse;
}
.recruiter-home .skills-table thead th {
  text-align: left;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 8px;
}
.recruiter-home .skills-table thead th strong, .recruiter-home .skills-table thead th span {
  display: block;
}
.recruiter-home .skills-table thead th button {
  background: transparent;
  border: none;
  text-align: left;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
}
.recruiter-home .skills-table thead th:first-of-type {
  text-align: left;
}
.recruiter-home .skills-table tbody tr:nth-child(2n+1) th, .recruiter-home .skills-table tbody tr:nth-child(2n+1) td {
  background: none;
}
.recruiter-home .skills-table tbody th {
  font-weight: normal;
  padding: 8px;
  text-align: left;
}
.recruiter-home .skills-table tbody th .icon {
  color: #01CBB7;
  margin-right: 4px;
  vertical-align: middle;
}
.recruiter-home .skills-table tbody td {
  padding: 8px;
  min-height: 50px;
  text-align: left;
}
.recruiter-home .skills-table tbody td .chip-btn {
  white-space: nowrap;
  font-size: 14px;
}
.recruiter-home .skills-table tbody td .chip-btn.dark {
  font-weight: 700;
}
.recruiter-home .skills-table tbody td .chip-btn.dark:hover, .recruiter-home .skills-table tbody td .chip-btn.dark:focus {
  background: var(--background-dark-theme-bg-level-2, #272D39);
  color: #FEFEFE;
  border-color: var(--background-dark-theme-bg-level-2, #272D39);
}
.recruiter-home .skills-table tbody .null {
  width: 100%;
  text-align: center;
  color: #60646D;
  font-weight: bold;
  display: inline-block;
  line-height: 34px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: var(--color-black);
}
.dot.active {
  background-color: #01CBB7;
}
.dot.paused {
  background-color: #EEA42E;
}
.dot.closed {
  background-color: #FF4949;
}

* {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

.icon-16 {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.icon-18 {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

.icon-24 {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

:root {
  --card-padding: 24px;
  --card-padding-small: 16px;
  --card-gap: 24px;
  --column-gap: 24px;
  --outside-padding: 24px;
  --vertical-space: 32px;
  --card-padding-skills: 4px;
  --card-padding-mini: 12px 20px;
}

@media (max-width: 413px) {
  :root {
    --outside-padding: 24px;
    --card-padding-skills: 4px;
  }
}
@media (min-width: 808px) {
  :root {
    --outside-padding: 32px;
    --card-padding-skills: 8px;
  }
}
@media (min-width: 1200px) {
  :root {
    --outside-padding: 64px;
  }
}/*# sourceMappingURL=styles.css.map */